/**** Cart Page***/
.inner-page {
    &#cart {
        padding-bottom: 120px;

        .inner-content {
            width: 80.5%;
            margin: 0 auto;

            #empty-address {
                display: none;
            }

            .cart-menu {
                ul {
                    list-style: none;
                    .flex-container(row, nowrap);
                    position: relative;
                    margin: 0;
                    padding: 0;

                    li {
                        &:not(:last-child) {
                            &::after {
                                content: "|";
                                position: absolute;
                                .font(@head-font, 26px, 500, 87px);
                                margin: 0 0 0 15px;
                            }
                        }

                        &:first-child {
                            padding: 0 15px 0 0;
                        }

                        padding: 0 15px;

                        a {

                            .font(@head-font, 30px, 500, 87px);

                            &:not(.active) {
                                color: @dark;
                                .trans(all 0.2s);

                                &:hover {
                                    color: @mainblue;
                                }

                            }

                        }
                    }
                }
            }

            .modal{
                .related-list{
                    ul{
                        li{
                            padding-bottom: 10px;
                            span{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .related-notification{
                margin: 10px 0;
                padding: 10px 5px 10px 30px;
                background: @lightgrey;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    color: @dark;
                    padding: 0;
                    margin: 0;
                    a{
                        border-bottom: 2px solid @dark;
                        color: @dark;
                        .trans(border 0.3s);
                        &:hover{
                            border-bottom-color: transparent;
                        }
                    }
                }
            }

            .cart-table {
                table {
                    width: 100%;
                    max-width: 100%;
                    margin: 30px 0 0px 0;

                    thead {
                        th {
                            border-bottom: 2px solid @darkcolor1;
                            font-size: 18px;
                            font-weight: bold;
                            border-top: 0;
                            border-right: 0;
                            padding: 15px 0px;
                            line-height: 18px;
                            text-align: left;

                            &:nth-child(5) {
                                text-align: right;
                            }
                        }
                    }

                    tr {
                        td {
                            vertical-align: middle;
                            text-align: left;
                            font-size: 16px;
                            font-weight: 600;
                            border-bottom: 1px solid @border-table;
                            padding: 20px 10px;

                            &:first-child {
                                width: 100px;

                                .product-outer {
                                    max-height: 150px;
                                    width: 100%;
                                }
                            }

                            .item-info {
                                a {
                                    display: block;
                                    text-decoration: none;
                                    font-size: 16px;
                                }
                                .leadtime {
                                    font-style:italic;
                                    font-size: 14px;
                                    display: block;
                                }
                            }

                            &:last-child {
                                a {
                                    color: red;
                                    font-weight: bold;
                                    font-size: 14px;
                                }
                            }

                            &:nth-child(5) {
                                text-align: right;
                            }

                            &:nth-child(6) {
                                text-align: right;
                                padding-right: 30px;
                            }

                            input[type=text] {
                                border: 2px solid @select_border;
                                width: 65px;
                                padding: 14px 10px;
                                text-align: center;

                                &.placeholder-fix {
                                    &:focus::-webkit-input-placeholder {
                                        color: transparent;
                                    }

                                    &:focus::-moz-placeholder {
                                        color: transparent;
                                    }

                                    &:-moz-placeholder {
                                        color: transparent;
                                    }
                                }
                            }                            

                            form{
                                .trigger{
                                    display: none;
                                }
                            }
                        }

                        &.dark-border {
                            td {
                                border-bottom: 2px solid @darkcolor1;;
                            }
                        }

                    }

                    tfoot {
                        tr {
                            &.cart-actions {
                                p {
                                    &.order-note {
                                        text-align: left;
                                        width: 85%;
                                    }
                                }
                            }
                        }
                    }

                    &.cart_total {
                        margin: 0 0 35px 0;

                        tr {
                            th {
                                text-align: left;
                                strong {
                                    font-weight: bold;
                                    font-size: 24px;
                                    line-height: 50px;
                                }
                                border-bottom: 1px solid @border-table;
                            }

                            td {
                                padding: 30px 0;
                                text-align: right;
                                span{
                                    font-size: 16px;
                                }
                            }

                            &.total {
                                th {
                                    border-bottom: none;
                                    strong {
                                        font-weight: bold;
                                        font-size: 33px;
                                        line-height: 50px;
                                    }
                                }

                                td {
                                    font-weight: bold;
                                    font-size: 33px;
                                    border-bottom: none;

                                    &.total {
                                        font-family: @head-font;
                                        font-size: 45px;

                                        span {
                                            color: @mainblue;
                                            font-family: @head-font;
                                            font-size: 45px;
                                            &.currency{
                                                color: @dark;
                                            }
                                        }
                                    }
                                }
                            }
                            &.tax-exempt {
                                .tooltip {
                                    position: relative;
                                    color: @mainblue;
                                    .trans(all 0.5s);
                                    .tooltiptext {     
                                        display: none;                
                                        background-color: @mainblue;
                                        color: @white;
                                        padding: 15px;
                                        border-radius: 6px;
                                        z-index: 999;
                                        font-size: 14px;
                                        font-weight:500;
                                        text-align: left;
                                        width: 600px;
                                        top: 10px;
                                        right: 0px;
                                        position: absolute;
                                        @media screen and (max-width: 700px) {
                                            width: 325px;
                                        }
                                        a {
                                            color: @white !important;
                                            text-decoration: underline;
                                            font-weight: 300 !important;
                                        }
                                    }
                                    &:hover {
                                        .tooltiptext {
                                            .flex-container(row, nowrap);
                                            animation-name: fadeInLeft;
                                            animation-duration: 0.5s;
                                        }
                                    }
                                }
                                .text {
                                    margin: 0;
                                    cursor: pointer;
                                    position: relative;
                                    &::before {
                                        content: "";
                                        background: none;
                                        border: 2px solid #61b9e7;
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 12px;
                                        height: 12px;
                                        padding: 0;
                                        margin-right: 5px;
                                        text-align: center;
                                        border-radius: 20%;
                                    }
                                }
                                .custom {
                                    opacity: 0;
                                    position: absolute;
                                    display: inline-block;
                                    cursor: pointer;
                                    &:checked {
                                        + .text {
                                            &:before {
                                                background: @mainblue;
                                                box-shadow: inset 0px 0px 0px 4px #fff;
                                            }
                                        }
                                    }
                                }
                                label {
                                    &.terms {
                                        font-size: 12px;
                                    }
                                }                                
                                input {
                                    &#vat_number {
                                        padding: 3px;
                                        border: 1px solid @table-border;
                                        color: @select-text;
                                        font-family: Montserrat;
                                        font-size: 16px;
                                        font-weight: 600;
                                        line-height: normal;
                                        width: 50%;
                                    }
                                }
                            }
                        }
                    }

                    & .cart-actions {
                        & td {
                            width: 100%;
                            border: none;
                            padding: 10px 0;
                            &:first-child{
                                width: auto;
                            }

                            & .form-row {
                                padding: 0;
                                .flex-container();
                                .justify-content(flex-end);
                            }
                        }
                    }
                }
            }

            a {
                &.checkout {
                    .flex-container(row, nowrap);
                    .justify-content(flex-end);
                }
            }

            .checkout {
                .flex-container(row, nowrap);
                .justify-content(space-between);

                .checkout-form {
                    width: 55.5%;

                    h3 {
                        font-size: 18px;
                        line-height: 54px;
                        font-family: @font1;
                        margin: 0;
                    }

                    .row-item {
                        .flex-container(row, nowrap);
                        .justify-content(space-between);
                        .align-items(center);

                        a {
                            font-size: 18px;
                            line-height: 54px;
                        }

                        .text {
                            margin: 0;
                            font-size: 16px;
                            color: @select-text;
                            cursor: pointer;
                            position: relative;
                        }

                        .custom {
                            opacity: 0;
                            position: absolute;
                            display: inline-block;
                            cursor: pointer;

                            + .text {
                                &:before {
                                    content: '';
                                    background: none;
                                    border: 2px solid @mainblue;
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 15px;
                                    height: 15px;
                                    padding: 0px;
                                    margin-right: 5px;
                                    text-align: center;
                                    border-radius: 20%;
                                }
                            }

                            &:checked {
                                + .text {
                                    &:before {
                                        background: @mainblue;
                                        box-shadow: inset 0px 0px 0px 4px #fff;
                                    }
                                }
                            }
                        }


                    }

                    .form-row {
                        .flex-container(row, nowrap);
                        .justify-content(space-between);
                        padding: 0 0 20px 0;

                        .input-field {
                            .flex-container(row, nowrap);
                            .justify-content(space-between);
                            width: 100%;

                            &.two-section {
                                width: 48%;
                            }

                            & span.input {
                                padding-top: 0;
                            }

                            label {
                                padding: 10px 0;

                                span {
                                    &.required {
                                        color: red;
                                    }
                                }
                            }

                            .input__field {
                                padding: 15px 15px;
                                border: 1px solid @table-border;
                                color: @select-text;
                                font-family: Montserrat;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: normal;
                                width: 100%;
                            }
                            .input-select{
                                background-image: linear-gradient(45deg, transparent 50%, @mainblue 0),
                                linear-gradient(135deg, @mainblue 50%, transparent 0);
                                background-position: ~"calc(100% - 21px)" ~"calc(1.4em + 0px)",
                                ~"calc(100% - 16px)" ~"calc(1.4em + 0px)",
                                ~"calc(100% - 2.5em)" 0.5em;
                                background-size: 5px 5px,
                                5px 5px,
                                1px 1.5em;
                                background-repeat: no-repeat;
                            }

                            textarea {
                                padding: 15px 15px;
                                border: 1px solid @table-border;
                                color: @select-text;
                                font-family: Montserrat;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: normal;
                            }

                            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: @select-text;
                            }

                            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                                color: @select-text;
                            }

                            ::-ms-input-placeholder { /* Microsoft Edge */
                                color: @select-text;
                            }
                        }
                        .month-selector{
                            min-width: 105px;
                        }

                        &.btn {
                            .justify-content(flex-end);

                            a {
                                font-size: 23px;
                                line-height: 22px;
                            }

                            padding: 20px 0;
                        }

                        @media screen and (max-width: 640px) {
                            .flex-container(column, nowrap);
                            .input-field {
                                &.two-section {
                                    &:first-child {
                                        margin: 0;
                                    }
                                }
                            }

                            &.btn {
                                .align-items(flex-end);
                            }
                        }
                    }

                    .checkout-personal {
                        border-bottom: 2px solid @lightgrey;
                    }

                    .address {
                        padding: 0;
                        .flex-container(row, nowrap);
                        .justify-content(space-between);
                        border-bottom: 2px solid @lightgrey;

                        .address-item {
                            width: 48%;

                            .row-item {
                                .billing {
                                    .flex-container(row, nowrap);
                                    .justify-content(space-between);
                                    .align-items(baseline);
                                }

                            }
                        }

                    }

                    ul.payment_methods{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li{
                            padding-bottom: 10px;
                        }
                    }

                    .payment {
                        width: 72%;
                        padding: 20px 0 0;

                        h4 {
                            font-size: 21px;
                            line-height: 54px;
                            font-family: @font1;
                            margin: 0;
                        }

                        .row-item {
                            .align-items(baseline);
                            margin: 0 0 15px 0;
                        }

                        .form-row {
                            .input-field {
                                &.three-section {
                                    width: 30%;
                                }
                            }
                        }
                    }
                }

                .cart-sidebar {
                    border-left: 2px solid @bg-table;
                    width: 38%;
                    padding-left: 40px;

                    h4 {
                        font-size: 18px;
                        line-height: 54px;
                        font-family: @font1;
                        margin: 0;
                    }

                    &.cart-table {
                        table {
                            margin: 0;

                            tr {
                                td {
                                    border-bottom: none;
                                    padding: 20px 0px 20px 10px;
                                    &:first-child{
                                        padding-left: 0;
                                    }
                                    &:nth-of-type(2) {
                                        a {
                                            margin: 0;
                                        }
                                    }

                                    &:nth-of-type(4) {
                                        .material-icons {
                                            color: @red2;
                                            font-size: 30px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            &.cart_total {
                                tr {
                                    &:first-child {
                                        td {
                                            border-top: 1px solid @border-table;
                                        }

                                        th {
                                            border-top: 1px solid @border-table;
                                        }
                                    }

                                    th {
                                        strong {
                                            font-size: 18px;
                                            line-height: 50px;
                                        }
                                    }

                                    td {
                                        padding: 30px 0;
                                        border-bottom: 1px solid @border-table;
                                    }

                                    &.total {

                                        th {
                                            strong {
                                                font-size: 24px;
                                                padding: 0 10px;
                                                font-weight: 600;
                                            }
                                        }

                                        td {
                                            &.total {
                                                line-height: 40px;
                                                span {
                                                    font-size: 35px;
                                                    line-height: 50px;
                                                    font-family: @font1;
                                                    font-weight: 700;
                                                }
                                            }
                                            border-bottom: none;
                                            padding: 30px 0;
                                        }
                                    }
                                    &.taxes {
                                        td {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                        select {
                            &.shipping-selector {
                                margin: 0;
                                text-align: center;
                                background-color: #ffffff;
                                padding: 15px;
                                border: 1px solid #b7b7b7;
                                color: #585858;
                                font-family: Montserrat;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: normal;
                                width: 100%;
                                background-image: linear-gradient(45deg,transparent 50%,#61b9e7 0),linear-gradient(135deg,#61b9e7 50%,transparent 0);
                                background-position: calc(100% - 6px) calc(1.4em + 0px),calc(100% - 5px) calc(1.4em + 0px),calc(100% - 2.5em) .5em;
                                background-size: 5px 5px,5px 5px,1px 1.5em;
                                background-repeat: no-repeat;

                            }
                        }
                    }
                }
                &.action-btn {
                    i {
                        &.fa-plus {
                            font-size: 20px;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }

        .rep-text {
            .flex-container(column, wrap);
            .align-items(center);
            .justify-content(center);

            .head {
                font-size: 25px;
                line-height: 32px;
                font-weight: bold;
            }

            a {
                .flex-container(row, wrap);
                .align-items(center);
                padding: 10px 0;

                .material-icons {
                    font-size: 25px;
                    line-height: 33px;
                }

                span {
                    font-size: 25px;
                    line-height: 33px;
                    font-weight: bold;
                }
            }
        }

        .modal {
            display: none;
            position: fixed;
            z-index: 13000;
            padding-top: 100px;
            left: 0;
            overflow-y: auto;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);

            .modal-content {
                background-color: @white;
                margin: 0 auto;
                padding: 15px 40px 30px;
                width: 650px;
                top: 32%;
                position: absolute;
                left: 25%;
                right: 25%;
                @media screen and (max-width: 700px) {
                    width: 325px;
                    left: 15%;
                }

                .modal-top {
                    .flex-container(row, nowrap);
                    .justify-content(space-between);
                    .align-items(center);

                    .close {
                        color: @mainblue;

                        &:hover {
                            cursor: pointer;
                        }

                        .material-icons {
                            font-size: 37px;
                            line-height: 96px
                        }
                    }

                    h6 {
                        font-size: 37px;
                        line-height: 56px;
                        margin: 0;
                    }
                }
            }
        }

        /////////******MEDIA QUERY*****/
        @media screen and (max-width: 1700px) {
            .inner-content {
                width: 84.5%;

                .checkout {
                    .checkout-form {
                        width: 58%;
                    }
                }
            }

            &.checkout-page {
                .inner-content {
                    width: 90%;
                }
            }
        }
        @media screen and (max-width: 1670px) {
            .inner-content {
                .cart-table {
                    table {
                        &.cart_total {
                            tr {
                                &.total {
                                    td {
                                        &.total {
                                            font-size: 40px;

                                            span {
                                                font-size: 40px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .checkout {
                    .checkout-form {
                        .address {
                            .address-item {
                                h3 {
                                    padding: 0 0 40px 0;
                                }

                                &.shipping {
                                    .row-item {
                                        h3 {
                                            padding: 0;
                                        }

                                        .flex-container(column, nowrap);
                                        .align-items(flex-start);

                                        .text {
                                            padding: 0 0 20px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        @media screen and (max-width: 1480px) {
            .inner-content {
                width: 90%;
            }

        }
        @media screen and (max-width: 1400px) {
            .inner-content {
                width: 100%;
            }

            &.checkout-page {
                .inner-content {
                    width: 100%;
                }
            }
        }
        @media screen and (max-width: 1125px) {
            .inner-content {
                .cart-table {
                    table {
                        &.cart_total {
                            tr {
                                &.total {
                                    td {
                                        &.total {
                                            width: 40%;
                                            font-size: 34px;

                                            span {
                                                font-size: 34px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .checkout {
                    .flex-container(column, nowrap);

                    .checkout-form {
                        //.row-item{
                        //    .flex-container(column,nowrap);
                        //    .align-items(flex-start);
                        //}
                        //.checkout-personal{
                        //    .row-item{
                        //        padding: 0 0 20px 0;
                        //    }
                        //}
                        width: 100%;
                    }

                    .cart-sidebar {
                        width: 100%;
                        border-left: none;
                        padding: 40px 0;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .inner-content {

                .cart-table {

                    table {
                        display: block;
                        min-width: auto;

                        thead {
                            display: block;

                            tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }
                        }

                        th {
                            display: block;
                        }

                        tbody {
                            display: block;

                            tr {
                                border-bottom: 1px solid @border-table;
                                display: block;

                                td {
                                    display: block;
                                    border: none;
                                    border-bottom: none;
                                    position: relative;
                                    padding-left: 50%;
                                    text-align: right;

                                    &:before {

                                        /* Now like a table header */
                                        position: absolute;
                                        /* Top/left values mimic padding */
                                        top: 20px;
                                        left: 0;
                                        width: 45%;
                                        padding-right: 10px;
                                        white-space: nowrap;
                                        text-align: left;

                                    }
                                    &:first-child{
                                        padding: 10px;
                                    }

                                    &:nth-of-type(1) {
                                        width: 100%;
                                        text-align: center;

                                        .product-outer {
                                            max-height: 75px;
                                            img {
                                                max-width: 50%;
                                            }
                                        }
                                    }

                                    &:nth-of-type(2) {
                                        padding-right: 0;
                                        &:before {
                                            content: "Product";
                                        }
                                    }

                                    &:nth-of-type(3) {
                                        &:before {
                                            content: "Price";
                                        }
                                    }

                                    &:nth-of-type(4) {
                                        padding-right: 0;

                                        &:before {
                                            content: "QTY";
                                        }
                                    }

                                    &:nth-of-type(5) {
                                        text-align: right;

                                        &:before {
                                            content: "Total";
                                        }
                                    }

                                    &:nth-of-type(6) {
                                        padding-right: 0;
                                        text-align: right;

                                        &:before {
                                            content: "";
                                        }
                                    }

                                    input[type=text] {
                                        width: 50px;
                                        height: 50px;
                                    }
                                }

                                &.dark-border {
                                    border-bottom: 2px solid @darkcolor1;

                                    td {
                                        border: none;
                                    }
                                }

                            }

                        }

                        &.cart_total {

                            tr {
                                .flex-container(row, nowrap);
                                .justify-content(space-between);
                                .align-items(center);
                                border-bottom: 1px solid @border-table;

                                th {
                                    border: none;
                                }

                                td {
                                    border: none;
                                }

                                &:nth-of-type(1) {
                                    td {
                                        width: auto;

                                        &:before {
                                            content: "";
                                        }
                                    }
                                }

                                &:nth-of-type(2) {
                                    td {
                                        width: auto;

                                        &:before {
                                            content: "";
                                        }
                                    }
                                }

                                &:nth-of-type(3) {
                                    td {
                                        width: auto;

                                        &:before {
                                            content: "";
                                        }
                                    }
                                }

                                &:nth-of-type(4) {
                                    td {
                                        width: auto !important;

                                        &:before {
                                            content: "";
                                        }
                                    }

                                    border-bottom: none;
                                }

                            }
                        }
                    }
                }

                .checkout {
                    .cart-sidebar {
                        button {
                            &.checkout {
                                display: block;
                                font-size: 25px;
                            }
                        }
                    }
                }

            }
        }
        @media screen and (max-width: 700px) {
            .inner-content {
                .checkout {
                    .checkout-form {
                        .row-item {
                            .flex-container(column, nowrap);
                            .align-items(flex-start);
                            padding: 0 0 20px 0;

                            a {
                                font-size: 14px;
                            }
                        }

                        .form-row {
                            padding: 0;
                            .flex-container(column, nowrap);

                            .input-field {
                                padding: 0 0 20px 0;

                                &.two-section {
                                    width: 100%;

                                }
                            }
                        }

                        .address {
                            .flex-container(column, nowrap);
                            .align-items(flex-start);

                            .address-item {
                                width: 100%;
                            }
                        }

                        .payment {
                            width: 100%;

                            .form-row {
                                .input-field {
                                    &.three-section {
                                        width: 100%;

                                    }
                                }
                            }
                        }
                    }
                }

            }
        }

        @media screen and (max-width: 645px) {
            .inner-content {
                .cart-menu {
                    ul {
                        li {
                            a {
                                font-size: 26px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 575px) {
            .inner-content {
                .cart-menu {
                    ul {
                        li {
                            &:not(:last-child) {
                                &::after {
                                    font-size: 20px;
                                }
                            }

                            a {
                                font-size: 20px;
                            }
                        }
                    }
                }

                .cart-table {
                    table {
                        &.cart_total {
                            tr {
                                th{
                                    strong{
                                        font-size: 16px;
                                    }
                                }
                                &.total {
                                    td {
                                        &.total {
                                            font-size: 30px;

                                            span {
                                                font-size: 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 500px) {
            .inner-content {
                .cart-menu {
                    ul {
                        li {
                            padding: 0 8px;

                            &:not(:last-child) {
                                &::after {
                                    margin: 0 0 0 6px;
                                }
                            }

                            &:first-child {
                                padding: 0 8px 0 0;

                            }
                        }
                    }
                }

                .checkout {
                    .cart-sidebar {
                        &.cart-table {
                            table {
                                &.cart_total {
                                    tr {
                                        &:nth-of-type(4) {
                                            td {
                                                padding: 0;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
        @media screen and (max-width: 400px) {
            .inner-content {
                .cart-menu {
                    ul {
                        li {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }

                button {
                    &.checkout {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    section {
        &.cart {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 84.5%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    section {
        &.cart {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    section {
        &.cart {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    section {
        &.checkout {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    section {
        &.checkout {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    section {
        &.checkout {
            .page-outer-wrapper {
                .page-title-wrapper {
                    .page-title {
                        width: 100%;
                    }
                }
            }
        }
    }
}

