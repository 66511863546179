
section.categories {
    .breadcrumb {
        z-index: 1;
        position: relative;
        width: 100%;
        background: @lightgrey;
        padding: 25px 0;
        .flex-container(row, nowrap);
        .align-items(center);

        .hierarchy {
            width: 84.6%;
            margin: 0 auto;
            letter-spacing: 1px;
            .font(@font1, 16px, bold, normal);
            @media screen and (max-width: 1290px) {
                width: 95.8%;
            }
            @media screen and (max-width: 850px) {
                width: 88.8%;
            }
            @media screen and (max-width: 768px) {
                width: 88.8%;
            }

            a {
                .font(@font1, 16px, bold, normal);
                color: @product-head !important;
            }

            span {
                .font(@font1, 16px, bold, normal);

                &.active {
                    color: @mainblue;
                }

                &.arrow {
                    padding: 0 1%;
                    //@media screen and (max-width: 768px) {
                    //    padding: 0 1%;
                    //}
                }
            }
        }
    }

    .category {
        .flex-container(row, nowrap);

        &.container {
            width: 1520px;
            margin: 0 auto;
            @media screen and (max-width: 1735px) {
                width: 84.6%;
            }
            @media screen and (max-width: 1290px) {
                width: 100%;
                margin: 0;
            }
            @media screen and (max-width: 768px) {
                .flex-container(column, nowrap);
            }
        }

        .category_menu {
            width: 21.3%;
            margin: 60px 0;
            padding-right: 20px;
            @media screen and (max-width: 1290px) {
                width: 300px;
            }
            @media screen and (max-width: 768px) {
                padding-right: 0;
                width: 100%;
                margin: 0;
            }

            h2 {
                .font(@head-font, 35px, bold, 40px);
                margin: 0 0 40px;
                @media screen and (max-width: 1290px) {
                    display: none;
                }
            }
        }

        .category_section {
            width: 78.7%;
            margin: 60px 0 40px 0;

            @media screen and (max-width: 1440px) {
                margin: 60px 0 40px;
            }
            @media screen and (max-width: 1290px) {
                width: 79.5%;
                margin: 30px auto 40px auto;
            }

            @media screen and (max-width: 768px) {
                width: 88.80%;
                margin: 30px auto 60px auto;
            }

            .category-item {
                margin: 40px 0 50px;
                .flex-container(column, nowrap);
                @media screen and (max-width: 1290px) {
                    margin: 40px 0 0;
                }
                &:not(:first-child) {
                    @media screen and (max-width: 768px) {
                        margin: 60px 0 0;
                    }
                }
                .item-top {
                    background: @lightgrey;
                    padding-bottom: 20px;
                    .flex-container(column, nowrap);
                    .item-heading {
                        margin: -25px 0 0 0;
                        padding: 0 40px 0 40px;
                        @media screen and (max-width: 1290px) {
                            margin: -25px 0 0 0;
                            padding: 0 35px 0 35px;
                        }

                        .header {
                            .font(@head-font, 40px, bold, 35px);
                            @media screen and (max-width: 1735px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1624px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1505px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1440px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1290px) {
                                .font(@head-font, 38px, bold, 35px);
                            }
                        }

                        .blue_border {
                            border-bottom: 13px solid @mainblue;
                            width: 145px;
                            margin: 20px 0 10px;
                            @media screen and (max-width: 1330px) {
                                border-bottom: 10px solid #65b9e7;
                                width: 100px;
                                margin: 10px 0 10px 0;
                            }
                        }
                        .item-description{
                            @media screen and (max-width: 1290px) {
                                padding-top: 10px;
                            }
                        }

                        .head-description{
                            padding-top: 5px;
                            font-size: 14px;
                            font-weight: 300;
                            font-family: 'Roboto', sans-serif;
                            line-height: 22px;
                            color: #000000;
                            b{
                                font-family: 'Roboto', sans-serif;
                            }
                            h2{
                                font-size: 17px;
                                font-weight: 500;
                                line-height: 22px;
                                color: @mainblue;
                                font-family: 'Roboto', sans-serif;
                                margin: 15px 0 10px 0;
                                padding: 0;
                            }
                            p{
                                font-size: 14px;
                                font-weight: 300;
                                font-family: 'Roboto', sans-serif;
                                line-height: 22px;
                                color: #000000;
                                padding-top:0px;
                                padding-bottom: 0px;
                            }
                            ul{
                                li{
                                    font-size: 14px;
                                    font-weight: 300;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 22px;
                                    color: #000000;
                                }
                            }
                            br{
                                display: block;
                            }
                        }

                        p {
                            padding-top: 20px;
                            padding-bottom: 5px;
                            line-height: 24px;
                        }
                    }
                    &.product-description-top{
                        background: white;
                        .item-heading{
                            padding-left: 0;
                        }
                    }
                }

                .item-bottom {
                    //.flex-container(row, wrap);
                    margin: 20px 0 30px 0;
                    //.align-items(flex-start);
                    display: flex; //for 2-column if we want it
                    flex-wrap: wrap; //for 2-column if we want it
                    width: 100%;   
                    @media screen and (max-width: 1290px) {
                        //.flex-container(row, wrap);
                        margin: 50px 0 30px;
                    }                                                                            
                    @media screen and (max-width: 1040px) {
                        //.flex-container(row, wrap);
                        margin: 20px 0 30px;
                    }
                    @media screen and (max-width: 768px) {
                        .flex-container(row, wrap);
                        .align-items(flex-start);
                    }
                    .subcategory {
                        padding: 20px 0 20px 0;
                        //position: relative;
                        //width: 100%;
                        flex: 0 0 48%; //for 2-column if we want it
                        @media screen and (max-width: 1440px) {
                            padding: 0;                            
                        }
                        @media screen and (max-width: 1390px) {
                            //width: 23%;
                        }
                        @media screen and (max-width: 1040px) {
                            padding: 20px 0 0 0;
                            min-height: 250px;
                        }
                        @media screen and (max-width: 1024px) {
                            //margin-top: 90px;
                            //width: 30.3%;
                        }
                        @media screen and (max-width: 768px) {
                            //width: 48%;
                            margin: 20px 0 0 0;
                            flex: 0 0 100%; //for 1-column if we want it
                        }
                        @media screen and (max-width: 499px) {                            
                            padding: 0;
                            margin-top: 15px !important;
                        }
                        &:not(:last-child) {
                            //margin-right: 4%;
                            @media screen and (max-width: 1390px) {
                                margin-right: 2%;
                            }
                            @media screen and (max-width: 1024px) {
                                margin-right: 4%;
                            }
                            @media screen and (max-width: 768px) {
                                margin: 0px 0 0px 0;
                            }
                        }
                        &:nth-child(odd) {
                            @media screen and (max-width: 768px) {
                                margin-right: 4% !important;
                            }
                        }

                        &:nth-child(4n + 0) {
                            margin-right: 0;
                            @media screen and (max-width: 1024px) {
                                margin-right: 3%;
                            }
                            @media screen and (max-width: 768px) {
                                margin-right: 0;
                            }
                        }
                        &:nth-child(3n + 0) {
                            @media screen and (max-width: 1024px) {
                                margin-right: 0;
                            }
                            @media screen and (max-width: 768px) {
                                margin-right: 0;
                            }
                        }
                        &:first-child {
                            @media screen and (max-width: 768px) {
                                margin-top: 40px !important;
                            }
                            @media screen and (max-width: 420px) {
                                margin-top: 0 !important;
                            }
                        }
                        .category-action {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 10;
                        }
                        .expand-item{
                            position: absolute;
                            bottom: 20px;
                            right: 0px;
                            z-index: 15;
                            cursor: pointer;
                            padding: 10px;
                            display: none;
                            i{
                                color: @mainblue;
                            }
                            @media screen and (max-width: 1040px) {
                                bottom: -12px;
                            }
                        }
                        .collapse-item{
                            position: absolute;
                            bottom: 26px;
                            right: 0px;
                            color: @mainblue;
                            opacity: 0;
                            visibility: hidden;
                            padding: 10px;
                            display: none;
                            @media screen and (max-width: 1040px) {
                                bottom: 0px;
                            }
                        }

                        .subcategory-container {
                            a {
                                color: @dark !important;
                            }
                            //.flex-container(column, nowrap);
                            position: relative;
                            display: -webkit-box;
                            display: -webkit-flex;
                            display: -ms-flexbox;
                            display: flex;
                            .justify-content(start);
                            min-height: 200px;
                            .box-overlay {
                                min-height: 90px;
                                .trans(min-height .5s ease .3s);
                                position: absolute;
                                width: 100%;
                                z-index: -9999;
                                overflow: hidden;
                                @media screen and (max-width: 1040px) {
                                    min-height: 70px;
                                }
                                @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                                    background: transparent;
                                    top: 30px;
                                }
                            }

                            .subcategory-container-wrapper {
                                //width: 100%;
                                //min-height: 190px;
                                //max-height: 200px;
                                //max-width: 300px;
                                display: flex;
                                //margin-left: 20px;
                                .align-self(center);
                                .justify-content(start);
                                //padding: 10px 0 0 0;
                                @media screen and (max-width: 1040px) {
                                    min-height: 170px;
                                    max-height: 170px;
                                }
                                @media screen and (max-width: 768px) {
                                    //min-height: 200px;
                                    max-height: none;
                                }
                                @media screen and (max-width: 420px) {
                                    //min-height: 180px;
                                    //max-height: 180px;
                                    padding: 10px 0 0 0;
                                    min-height: 0;
                                    max-height: none;
                                }

                                img {
                                    //position: absolute;
                                    //top: 5px;
                                    max-width: 170px;
                                    max-height: 120px;
                                    @media screen and (max-width: 1680px) {
                                        //top: 45px;
                                    }
                                    @media screen and (max-width: 1580px) {
                                        //top: 55px;
                                    }
                                    @media screen and (max-width: 1440px) {
                                        //top: 60px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        //top: 22px;
                                    }
                                    @media screen and (max-width: 920px) {
                                        //top: 0px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        position: relative;
                                        //top: 5px;
                                        //opacity: 0.7;
                                    }
                                    @media screen and (max-width: 420px) {
                                        //top: 0;  
                                        max-width: 130px;
                                        max-height: 130px;                                     
                                    }
                                }
                            }

                            .overlay {
                                visibility: visible;
                                width: 100%;
                            }

                            .text {
                                color: @bgheaderdark;
                                //position: absolute;
                                //top: -20%;
                                left: 20%;
                                //-webkit-transform: translate(-30%, -150%);
                                //-ms-transform: translate(-30%, -150%);
                                //transform: translate(-30%, -150%);
                                padding: 25px 25px 25px 25px;
                                width: 90%;
                                @media screen and (max-width: 1735px) {
                                    //top: -15%;
                                }
                                @media screen and (max-width: 1505px) {
                                    //top: 12%;
                                }
                                @media screen and (max-width: 1440px) {
                                    //top: 35%;
                                }
                                @media screen and (max-width: 1330px) {
                                    //top: 50%;
                                    padding: 25px 18px;
                                }
                                @media screen and (max-width: 1290px) {
                                    //top: 35%;
                                    padding: 20px 15px 20px;
                                }
                                @media screen and (max-width: 1160px) {
                                    //top: 45%;
                                }
                                @media screen and (max-width: 1070px) {
                                    //top: 54%;
                                    width: 70%;
                                }
                                @media screen and (max-width: 1040px) {
                                    color: @bgheaderdark;
                                    //-webkit-transform: translate(-20%, -150%);
                                    //-ms-transform: translate(-20%, -150%);
                                    //transform: translate(-20%, -150%);                                    
                                    width: 100%;                                    
                                    left: 40%;
                                }
                                @media screen and (max-width: 850px) {                                    
                                    padding: 20px 12px 30px;
                                }
                                @media screen and (max-width: 768px) {                                    
                                    //-webkit-transform: translate(-50%, -150%);
                                    //-ms-transform: translate(-50%, -150%);
                                    //transform: translate(-50%, -150%);                                    
                                    width: 100%;
                                    padding: 20px;    
                                }
                                @media screen and (max-width: 420px) {                                    
                                    padding: 20px 10px;                                    
                                    margin-top: -20px; 
                                }

                                p {
                                    .font(@font1, 14px, 600, 22px);
                                    padding-bottom: 0;
                                    @media screen and (max-width: 1580px) {
                                        font-size: 14px;
                                        line-height: 30px;
                                        //margin-top: 40px;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        font-size: 14px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        font-size: 14px;
                                        line-height: 22px;
                                    }
                                    @media screen and (max-width: 920px) {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                    @media screen and (max-width: 420px) {
                                        font-size: 12px;
                                    }
                                }

                                .btn-container {
                                    @media screen and (max-width: 920px) {
                                        display: flex;
                                        flex-wrap: wrap;
                                    }
                                    @media screen and (max-width:768) {
                                        justify-content: space-between;
                                    }
                                    .btn {
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: @mainblue !important;
                                        @media screen and (max-width: 1440px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 1330px) {
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 1290px) {
                                            font-size: 16px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            font-size: 13px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            font-size: 16px;
                                            line-height: 30px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 850px) {
                                            font-size: 14px;
                                        }
                                        span {
                                            font-size: 16px;
                                            font-weight: 800;
                                        }
                                    }  
                                }                              
                            }

                            .sub-heading {
                                //padding: 0 25px 0 25px;
                                margin: -35px 0 0 0;
                                position: relative;
                                @media screen and (max-width: 1290px) {
                                    //padding: 0 0 0 15px;
                                    margin: -70px 0 0 0;
                                }
                                @media screen and (max-width: 1040px) {
                                    //padding: 0 0 0 15px;
                                    margin: -55px 0 0 0;
                                }
                                @media screen and (max-width: 768px) {
                                    margin: -75px 0 0 0;
                                }
                                @media screen and (max-width: 420px) {
                                    margin: 0;
                                }

                                .head {
                                    min-height: 40px;
                                    .flex-container();
                                    .align-items(flex-end);
                                    color: @product-head;
                                    .font(@head-font, 26px, bold, 26px);

                                    @media screen and (max-width: 1735px) {
                                        font-size: 24px;
                                        line-height: 24px;
                                    }

                                    span {
                                        .font(@head-font, 26px, bold, 26px);
                                        @media screen and (max-width: 1735px) {
                                            font-size: 24px;
                                            line-height: 24px;
                                        }
                                        @media screen and (max-width: 1505px) {
                                            font-size: 21px;
                                            line-height: 21px;
                                        }
                                        @media screen and (max-width: 1440px) {
                                            font-size: 18px;
                                            line-height: 18px;
                                        }
                                    }
                                }

                                .blue_border {
                                    border-bottom: 16px solid @mainblue;
                                    width: 116px;
                                    margin: 15px 0 0 0;
                                    @media screen and (max-width: 1290px) {
                                        border-bottom: 10px solid @mainblue;
                                        width: 80px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        width: 116px;
                                        border-bottom: 16px solid @mainblue;
                                    }
                                }
                            }
                        }

                        &.active {
                            cursor: pointer;
                            padding-top: 50px;
                            .expand-item{
                                opacity: 0;
                                visibility: hidden;
                            }
                            .collapse-item{
                                opacity: 1;
                                visibility: visible;
                                z-index: 15;
                            }
                            .subcategory-container {
                                .text{
                                    @media screen and (max-width: 768px) {
                                        transform: none;
                                        animation-delay: 0s;
                                        animation-duration: 0s;
                                        -webkit-animation-name: none;
                                        animation-name: none;
                                        animation-fill-mode: none;
                                        top: auto;
                                        left: auto;
                                    }
                                }
                                .box{
                                    @media screen and (max-width: 768px) {
                                        position: absolute;
                                        top: -69px;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .box-overlay {
                                    min-height: 220px;
                                    .trans(min-height .1s ease .1s);
                                    @media screen and (max-width: 1735px) {
                                        min-height: 217px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        //min-height: 160px;
                                        min-height: 100%;
                                    }
                                }

                                .subcategory-container-wrapper {
                                    max-height: unset;
                                    min-height: 100px;
                                    position: relative;
                                    //@media screen and (max-width: 1040px) {
                                    //    max-height: inherit;
                                    //    min-height: 170px;
                                    //    position: unset;
                                    //}
                                    @media screen and (max-width: 768px) {
                                        //min-height: unset;
                                        //max-height: none;
                                        //height: 200px;
                                        //min-height: 200px;
                                        //max-height: 200px;
                                        //padding: 0;
                                        min-height: 200px;
                                    }
                                    @media screen and (max-width: 420px) {
                                        //min-height: 180px;
                                        //max-height: 180px;
                                        padding: 10px 0 0 0;
                                        min-height: 0;
                                        max-height: none;
                                    }
                                }

                                .overlay {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInText;
                                    animation-name: fadeInText;
                                    height: auto;
                                    width: auto;
                                    animation-fill-mode: both;
                                    min-height: 100px;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0s;
                                    //    animation-duration: 0s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    height: auto;
                                    //    width: auto;
                                    //    animation-fill-mode: none;
                                    //    min-height: 0;
                                    //    visibility: hidden;
                                    //}
                                }

                                .sub-heading {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryHead;
                                    animation-name: fadeInUpCategoryHead;
                                    animation-fill-mode: forwards;
                                    position: relative;
                                    bottom: -100px;
                                    @media screen and (max-width: 1680px) {
                                        padding: 0 10px;
                                    }
                                    @media screen and (max-width: 1290px) {
                                        bottom: -91px;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        bottom: -84px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        bottom: -101px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        bottom: 0;
                                        margin: 0;
                                        padding: 0 20px;
                                        animation-delay: 0s;
                                        animation-duration: 0s;
                                        -webkit-animation-name: none;
                                        animation-name: none;
                                        height: auto;
                                        width: auto;
                                        animation-fill-mode: none;
                                    }
                                    &.multiline {
                                        bottom: -158px;
                                        @media screen and (max-width: 1290px) {
                                            bottom: -91px;
                                        }
                                        @media screen and (max-width: 768px) {

                                        }
                                        &.x {
                                            bottom: -214px;
                                        }
                                    }
                                }

                                img {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryImg;
                                    animation-name: fadeInUpCategoryImg;
                                    animation-fill-mode: forwards;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-duration: 0s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //    position: unset;
                                    //}
                                }

                            }
                        }

                        &.inactive {
                            .expand-item{
                                opacity: 1;
                                visibility: visible;
                                z-index: 15;
                            }
                            .subcategory-container {
                                .box-overlay {
                                    min-height: 90px;
                                    .trans(min-height .3s ease .3s);
                                    @media screen and (max-width: 1040px) {
                                        min-height: 70px;
                                        .trans(min-height 0s ease 0s);
                                    }
                                }

                                img {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategoryImg;
                                    animation-name: fadeInDownCategoryImg;
                                    animation-fill-mode: both;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0.5s;
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //}
                                }

                                .sub-heading {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategory;
                                    animation-name: fadeInDownCategory;
                                    animation-fill-mode: both;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0.3s;
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //}

                                }

                                .overlay {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeOut;
                                    animation-name: fadeOut;
                                    animation-fill-mode: forwards;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: forwards;
                                    //}
                                    a {
                                        transition: none;
                                    }
                                }

                            }
                        }
                    }

                    //.ui-accordion {
                    //    width: 100%;
                    //    position: relative;                        
                    //    .ui-accordion-content {
                    //        padding: 20px 7% 70px;;
                    //        position: relative;
                    //        top: -40px;
    
                    //        &.ui-accordion-content-active {
                    //            .box-shadow(0px 13px 20px -10px rgba(0, 0, 0, 0.2));
                    //            border: none;
                    //        }
                    //    }
                    //    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
                    //        border: none !important;
                    //        background: @white !important;
                    //        font-weight: normal;
                    //        color: @dark !important;
                    //      }                 
                                     
                        .product-listing {
                            display: none;
                            .font(@font1, 16px, bold, 27px);
                            margin: 0 0 50px 0;
                            max-width: 100%;
                            @media screen and (max-width: 992px) {
                                padding-right: 5%;
                            }
                            //overflow-x: auto;
                              
                            table {
                                width: 100%;
                                max-width: 100%;
                                border: 1px solid @table-border;
                                border-bottom: 0;
                                border-left: 0;
                
                                td {
                                    border: 1px solid @table-border;
                                    // padding: 12px 40px;
                                    vertical-align: middle;
                                    .font(@font1, 12px, bold, 57px);
                                    text-align: center;
                                    border-top: 0;
                                    border-right: 0;
                                }
                
                                thead {
                                    th {
                                        border: 1px solid @table-border;
                                        font-size: 13px;
                                        font-weight: bold;
                                        border-top: 0;
                                        border-right: 0;
                                        padding: 5px 10px;
                                        line-height: 18px;
                                        strong {
                                            font-size: 13px;
                                        }
                                    }
                
                                    tr {
                                        background-color: @lightgrey;
                                    }
                                }
                
                                tbody {
                                    th {
                                        border: 1px solid @table-border;
                                        font-size: 13px;
                                        font-weight: bold;
                                        border-top: 0;
                                        border-right: 0;
                                        padding: 5px 10px;
                                        line-height: 18px;
                                        strong {
                                            font-size: 13px;
                                        }
                                    }
                                }
                
                                &.table-striped {
                                    tbody {
                                        tr {
                                            .trans(all 0.2s);
                
                                            &:nth-of-type(even) {
                                                background-color: @lightgrey;
                                            }
                
                                            td {
                                                top: 0;
                                                left: 6px;
                                                width: 5%;
                                                padding-right: 10px;
                                                white-space: nowrap;
                                                content: attr(data-heading);                                                
                                                &.primary-col{
                                                    text-decoration: underline;
                                                    text-underline-position: under;
                                                    color: @mainblue;
                                                    width: 15%;
                                                }
                                                &:first-child{
                                                    text-decoration: underline;
                                                    text-underline-position: under;
                                                    color: @mainblue;
                                                    width: 15%;
                                                }
                                                a {
                                                    color: @mainblue !important;
                                                    padding: 0 1px;
                                                    .font(@font1, 13px, bold, 57px);
                                                    text-decoration: underline;
                                                    text-underline-position: under;
                                                    strong {
                                                        .font(@font1, 13px, bold, 57px);
                                                    }
                                                }
                                                &:after {
                                                    display: block;
                                                    height: 2px;
                                                    background: blue;
                                                    margin-top: 20px;
                                                }
                                                i {
                                                    font-size: 22px;
                                                    color: @mainblue;
                                                }
                
                                                padding: 0 10px;
                                            }
                
                                            &:hover {
                                                box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
                                                td {
                                                    border: none;
                                                    background: @white;
                                                    cursor: pointer;
                                                    .scale(1.1);
                                                }
                                            }
                                        }
                                    }
                                }
                                &.mobile-table {
                                    tr {
                                        td {
                                            line-height: 37px;
                                            &:last-child {
                                                min-width: 35%;
                                            }
                                            a {
                                                color: @mainblue;
                                                padding: 0 15px;
                                                .font(@font1, 12px, bold, 57px);
                                                text-decoration: underline;
                                                text-underline-position: under;
                                            }
                                            &:after {
                                                display: block;
                                                height: 2px;
                                                background: blue;
                                                margin-top: 20px;
                                            }
                                        }
                                        &.even {
                                            background-color: @lightgrey;
                                        }
                                    }
                                }
                            }
                        }    

                        .subcategory {
                            padding: 20px 0 20px 0;
                            position: relative;
                            width: 100%;
                            @media screen and (max-width: 1440px) {
                                padding: 0 0 20px 0;                            
                            }
                            @media screen and (max-width: 1390px) {
                                //width: 23%;
                            }
                            @media screen and (max-width: 1040px) {
                                padding: 20px 0 0 0;
                                min-height: 250px;
                            }
                            @media screen and (max-width: 1024px) {
                                //margin-top: 90px;
                                //width: 30.3%;
                            }
                            @media screen and (max-width: 768px) {
                                //width: 48%;
                                margin: 20px 0 0 0;
                            }
                            @media screen and (max-width: 499px) {                            
                                padding: 0;
                                margin-top: 15px !important;
                            }
                            &:not(:last-child) {
                                //margin-right: 4%;
                                @media screen and (max-width: 1390px) {
                                    margin-right: 2%;
                                }
                                @media screen and (max-width: 1024px) {
                                    margin-right: 4%;
                                }
                                @media screen and (max-width: 768px) {
                                    margin: 0px 0 0px 0;
                                }
                            }
                            &:nth-child(odd) {
                                @media screen and (max-width: 768px) {
                                    margin-right: 4% !important;
                                }
                            }

                            &:nth-child(4n + 0) {
                                margin-right: 0;
                                @media screen and (max-width: 1024px) {
                                    margin-right: 3%;
                                }
                                @media screen and (max-width: 768px) {
                                    margin-right: 0;
                                }
                            }
                            &:nth-child(3n + 0) {
                                @media screen and (max-width: 1024px) {
                                    margin-right: 0;
                                }
                                @media screen and (max-width: 768px) {
                                    margin-right: 0;
                                }
                            }
                            &:first-child {
                                @media screen and (max-width: 768px) {
                                    margin-top: 40px !important;
                                }
                                @media screen and (max-width: 420px) {
                                    margin-top: 0 !important;
                                }
                            }
                            .category-action {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 10;
                            }
                            .expand-item{
                                position: absolute;
                                bottom: 20px;
                                right: 0px;
                                z-index: 15;
                                cursor: pointer;
                                padding: 10px;
                                display: none;
                                i{
                                    color: @mainblue;
                                }
                                @media screen and (max-width: 1040px) {
                                    bottom: -12px;
                                }
                            }
                            .collapse-item{
                                position: absolute;
                                bottom: 26px;
                                right: 0px;
                                color: @mainblue;
                                opacity: 0;
                                visibility: hidden;
                                padding: 10px;
                                display: none;
                                @media screen and (max-width: 1040px) {
                                    bottom: 0px;
                                }
                            }

                            .subcategory-container {
                                a {
                                    color: @dark !important;
                                }
                                //.flex-container(column, nowrap);
                                position: relative;
                                display: -webkit-box;
                                display: -webkit-flex;
                                display: -ms-flexbox;
                                display: flex;
                                .justify-content(start);
                                .box-overlay {
                                    min-height: 90px;
                                    .trans(min-height .5s ease .3s);
                                    position: absolute;
                                    width: 100%;
                                    z-index: -9999;
                                    overflow: hidden;
                                    @media screen and (max-width: 1040px) {
                                        min-height: 70px;
                                    }
                                    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                                        background: transparent;
                                        top: 30px;
                                    }
                                }

                                .subcategory-container-wrapper {
                                    //width: 100%;
                                    //min-height: 190px;
                                    //max-height: 200px;                                    
                                    //max-width: 300px;
                                    //display: flex;
                                    //margin-left: 20px;
                                    //.align-self(center);
                                    //.justify-content(start);
                                    //padding: 10px 0 0 0;
                                    @media screen and (max-width: 1040px) {
                                        min-height: 170px;
                                        max-height: 170px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        min-height: 200px;
                                        max-height: none;
                                    }
                                    @media screen and (max-width: 420px) {
                                        //min-height: 180px;
                                        //max-height: 180px;
                                        padding: 10px 0 0 0;
                                        min-height: 0;
                                        max-height: none;
                                    }

                                    img {
                                        //position: absolute;
                                        //top: 5px;
                                        max-width: 170px;
                                        @media screen and (max-width: 1680px) {
                                            //top: 45px;
                                        }
                                        @media screen and (max-width: 1580px) {
                                            //top: 55px;
                                        }
                                        @media screen and (max-width: 1440px) {
                                            //top: 60px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            //top: 22px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            //top: 0px;
                                        }
                                        @media screen and (max-width: 768px) {
                                            position: relative;
                                            //top: 5px;
                                            //opacity: 0.7;
                                        }
                                        @media screen and (max-width: 420px) {
                                            //top: 0;    
                                            max-width: 130px;                                    
                                        }
                                    }
                                }

                                .overlay {
                                    visibility: visible;
                                    width: 100%;
                                }

                                .text {
                                    color: @bgheaderdark;
                                    //position: absolute;
                                    //top: -20%;
                                    //left: 20%;
                                    //-webkit-transform: translate(-30%, -150%);
                                    //-ms-transform: translate(-30%, -150%);
                                    //transform: translate(-30%, -150%);
                                    padding: 25px 25px 25px 25px;
                                    width: 90%;
                                    @media screen and (max-width: 1735px) {
                                        //top: -15%;
                                    }
                                    @media screen and (max-width: 1505px) {
                                        //top: 12%;
                                    }
                                    @media screen and (max-width: 1440px) {
                                        //top: 35%;
                                    }
                                    @media screen and (max-width: 1330px) {
                                        //top: 50%;
                                        padding: 25px 18px;
                                    }
                                    @media screen and (max-width: 1290px) {
                                        //top: 35%;
                                        padding: 20px 15px 20px;
                                    }
                                    @media screen and (max-width: 1160px) {
                                        //top: 45%;
                                    }
                                    @media screen and (max-width: 1070px) {
                                        //top: 54%;
                                        width: 70%;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        color: @bgheaderdark;
                                        //-webkit-transform: translate(-20%, -150%);
                                        //-ms-transform: translate(-20%, -150%);
                                        //transform: translate(-20%, -150%);                                    
                                        width: 100%;                                    
                                        left: 40%;
                                    }
                                    @media screen and (max-width: 850px) {                                    
                                        padding: 20px 12px 30px;
                                    }
                                    @media screen and (max-width: 768px) {                                    
                                        //-webkit-transform: translate(-50%, -150%);
                                        //-ms-transform: translate(-50%, -150%);
                                        //transform: translate(-50%, -150%);                                    
                                        width: 100%;
                                        padding: 20px;    
                                    }
                                    @media screen and (max-width: 420px) {                                    
                                        padding: 20px 10px;                                    
                                        margin-top: -20px; 
                                    }

                                    p {
                                        .font(@font1, 14px, 600, 22px);
                                        padding-bottom: 0;
                                        @media screen and (max-width: 1580px) {
                                            font-size: 14px;
                                            line-height: 30px;
                                            //margin-top: 40px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            font-size: 14px;
                                            line-height: 20px;
                                        }
                                        @media screen and (max-width: 420px) {
                                            font-size: 12px;
                                        }
                                    }

                                    .btn {
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: @mainblue !important;
                                        &.capacity-table {
                                            margin-left: 25px;
                                            @media screen and (max-width: 920px) {
                                                margin-left: 0px;
                                            }
                                        }
                                        @media screen and (max-width: 1440px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 1330px) {
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 1290px) {
                                            font-size: 16px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            font-size: 13px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            font-size: 16px;
                                            line-height: 30px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 850px) {
                                            font-size: 14px;
                                        }
                                    }                                
                                }

                                .sub-heading {
                                    //padding: 0 25px 0 25px;
                                    margin: -35px 0 0 0;
                                    position: relative;
                                    @media screen and (max-width: 1290px) {
                                        //padding: 0 0 0 15px;
                                        margin: -70px 0 0 0;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        //padding: 0 0 0 15px;
                                        margin: -55px 0 0 0;
                                    }
                                    @media screen and (max-width: 768px) {
                                        margin: -75px 0 0 0;
                                    }
                                    @media screen and (max-width: 420px) {
                                        margin: 0;
                                    }

                                    .head {
                                        min-height: 40px;
                                        .flex-container();
                                        .align-items(flex-end);
                                        color: @product-head;
                                        .font(@head-font, 26px, bold, 26px);

                                        @media screen and (max-width: 1735px) {
                                            font-size: 24px;
                                            line-height: 24px;
                                        }

                                        span {
                                            .font(@head-font, 26px, bold, 26px);
                                            @media screen and (max-width: 1735px) {
                                                font-size: 24px;
                                                line-height: 24px;
                                            }
                                            @media screen and (max-width: 1505px) {
                                                font-size: 21px;
                                                line-height: 21px;
                                            }
                                            @media screen and (max-width: 1440px) {
                                                font-size: 18px;
                                                line-height: 18px;
                                            }
                                        }
                                    }

                                    .blue_border {
                                        border-bottom: 16px solid @mainblue;
                                        width: 116px;
                                        margin: 15px 0 0 0;
                                        @media screen and (max-width: 1290px) {
                                            border-bottom: 10px solid @mainblue;
                                            width: 80px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            width: 116px;
                                            border-bottom: 16px solid @mainblue;
                                        }
                                    }
                                }
                            }

                            &.active {
                                cursor: pointer;
                                padding-top: 50px;
                                .expand-item{
                                    opacity: 0;
                                    visibility: hidden;
                                }
                                .collapse-item{
                                    opacity: 1;
                                    visibility: visible;
                                    z-index: 15;
                                }
                                .subcategory-container {
                                    .text{
                                        @media screen and (max-width: 768px) {
                                            transform: none;
                                            animation-delay: 0s;
                                            animation-duration: 0s;
                                            -webkit-animation-name: none;
                                            animation-name: none;
                                            animation-fill-mode: none;
                                            top: auto;
                                            left: auto;
                                        }
                                    }
                                    .box{
                                        @media screen and (max-width: 768px) {
                                            position: absolute;
                                            top: -69px;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .box-overlay {
                                        min-height: 220px;
                                        .trans(min-height .1s ease .1s);
                                        @media screen and (max-width: 1735px) {
                                            min-height: 217px;
                                        }
                                        @media screen and (max-width: 768px) {
                                            //min-height: 160px;
                                            min-height: 100%;
                                        }
                                    }

                                    .subcategory-container-wrapper {
                                        max-height: unset;
                                        min-height: 100px;
                                        position: relative;
                                        //@media screen and (max-width: 1040px) {
                                        //    max-height: inherit;
                                        //    min-height: 170px;
                                        //    position: unset;
                                        //}
                                        @media screen and (max-width: 768px) {
                                            //min-height: unset;
                                            //max-height: none;
                                            //height: 200px;
                                            //min-height: 200px;
                                            //max-height: 200px;
                                            //padding: 0;
                                            min-height: 200px;
                                        }
                                        @media screen and (max-width: 420px) {
                                            //min-height: 180px;
                                            //max-height: 180px;
                                            padding: 10px 0 0 0;
                                            min-height: 0;
                                            max-height: none;
                                        }
                                    }

                                    .overlay {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeInText;
                                        animation-name: fadeInText;
                                        height: auto;
                                        width: auto;
                                        animation-fill-mode: both;
                                        min-height: 100px;
                                        //@media screen and (max-width: 1040px) {
                                        //    animation-delay: 0s;
                                        //    animation-duration: 0s;
                                        //    -webkit-animation-name: none;
                                        //    animation-name: none;
                                        //    height: auto;
                                        //    width: auto;
                                        //    animation-fill-mode: none;
                                        //    min-height: 0;
                                        //    visibility: hidden;
                                        //}
                                    }

                                    .sub-heading {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeInUpCategoryHead;
                                        animation-name: fadeInUpCategoryHead;
                                        animation-fill-mode: forwards;
                                        position: relative;
                                        bottom: -100px;
                                        @media screen and (max-width: 1680px) {
                                            padding: 0 10px;
                                        }
                                        @media screen and (max-width: 1290px) {
                                            bottom: -91px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            bottom: -84px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            bottom: -101px;
                                        }
                                        @media screen and (max-width: 768px) {
                                            bottom: 0;
                                            margin: 0;
                                            padding: 0 20px;
                                            animation-delay: 0s;
                                            animation-duration: 0s;
                                            -webkit-animation-name: none;
                                            animation-name: none;
                                            height: auto;
                                            width: auto;
                                            animation-fill-mode: none;
                                        }
                                        &.multiline {
                                            bottom: -158px;
                                            @media screen and (max-width: 1290px) {
                                                bottom: -91px;
                                            }
                                            @media screen and (max-width: 768px) {

                                            }
                                            &.x {
                                                bottom: -214px;
                                            }
                                        }
                                    }

                                    img {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeInUpCategoryImg;
                                        animation-name: fadeInUpCategoryImg;
                                        animation-fill-mode: forwards;
                                        //@media screen and (max-width: 1040px) {
                                        //    animation-duration: 0s;
                                        //    -webkit-animation-name: none;
                                        //    animation-name: none;
                                        //    animation-fill-mode: none;
                                        //    position: unset;
                                        //}
                                    }

                                }
                            }

                            &.inactive {
                                .expand-item{
                                    opacity: 1;
                                    visibility: visible;
                                    z-index: 15;
                                }
                                .subcategory-container {
                                    .box-overlay {
                                        min-height: 90px;
                                        .trans(min-height .3s ease .3s);
                                        @media screen and (max-width: 1040px) {
                                            min-height: 70px;
                                            .trans(min-height 0s ease 0s);
                                        }
                                    }

                                    img {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeInDownCategoryImg;
                                        animation-name: fadeInDownCategoryImg;
                                        animation-fill-mode: both;
                                        //@media screen and (max-width: 1040px) {
                                        //    animation-delay: 0.5s;
                                        //    animation-duration: 0.3s;
                                        //    -webkit-animation-name: none;
                                        //    animation-name: none;
                                        //    animation-fill-mode: none;
                                        //}
                                    }

                                    .sub-heading {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeInDownCategory;
                                        animation-name: fadeInDownCategory;
                                        animation-fill-mode: both;
                                        //@media screen and (max-width: 1040px) {
                                        //    animation-delay: 0.3s;
                                        //    animation-duration: 0.3s;
                                        //    -webkit-animation-name: none;
                                        //    animation-name: none;
                                        //    animation-fill-mode: none;
                                        //}

                                    }

                                    .overlay {
                                        animation-duration: 0.3s;
                                        -webkit-animation-name: fadeOut;
                                        animation-name: fadeOut;
                                        animation-fill-mode: forwards;
                                        //@media screen and (max-width: 1040px) {
                                        //    animation-duration: 0.3s;
                                        //    -webkit-animation-name: none;
                                        //    animation-name: none;
                                        //    animation-fill-mode: forwards;
                                        //}
                                        a {
                                            transition: none;
                                        }
                                    }

                                }
                            }
                        }
                    //}
                }
            }
        }
    }
}