/* 
 * MOBY.LESS VERSION 2.0.7
 */

@moby-background: @white;
@moby-color: @dark;
@moby-width: 333px;
@moby-blue: blue;
@border: #efefef;
.moby-flex-container(@flow: row, @wrap: nowrap) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-box;
    display: flex;
    -ms-flex-direction: @flow;
    -webkit-flex-flow: @flow @wrap;
    flex-flow: @flow @wrap;
}

.moby-flex-order(@order) {
    -webkit-box-ordinal-group: @order;
    -ms-box-ordinal-group: @order;
    -ms-flex-order: @order;
    -webkit-order: @order;
    order: @order;
}

.moby-flex(...) {
    -webkit-box-flex: @arguments;
    -moz-box-flex: @arguments;
    -webkit-flex: @arguments;
    -ms-flex: @arguments;
    flex: @arguments;
}

.moby-align-items(@param) {
    -webkit-align-items: @param;
    align-items: @param;
}

.moby-justify-content(@param) {
    -webkit-justify-content: @param;
    justify-content: @param;
}

.moby-transition(...) {
    -webkit-transition: @arguments;
    -moz-transition: @arguments;
    -o-transition: @arguments;
    -ms-transition: @arguments;
    transition: @arguments;
}

.moby-transform(...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -ms-transform: @arguments;
    transform: @arguments;
}

.moby-overlay {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: fixed;
    .moby-transform(~"translate3d(0,0,0)");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6000;
    visibility: hidden;
    opacity: 0;
    .moby-transition(all, 0.2s);
    &.light {
        background: #289DCC;
    }
    &.moby-overlay-active {
        visibility: visible !important;
        opacity: 0.2 !important;
        background: @dark;
    }
}

.moby-body-fixed {
    overflow: hidden !important;
}

.moby {

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    & * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    background: @moby-background;
    .moby-transition(~"all 0.4s");
    overflow: auto;
    &.moby-hidden {
        display: none;
    }
    &.moby-demo {
        color: @moby-background;
        background: @moby-blue;
        position: fixed;
        z-index: 3000;
        top: 0;
        height: 100%;
        width: 30%;
        right: 0;
        min-width: @moby-width;
        .moby-transform(~"translateX(100%)");
        padding: 60px 0 20px 0;
        overflow: auto;
        &.moby-active {
            .moby-transform(~"translateX(0)");
        }
        & .moby-inner {
            height: 100%;
            min-height: 520px;
            & > p {
                height: 40px;
                .moby-flex-container();
                .moby-align-items(center);
                .moby-justify-content(center);
                padding: 0 30px;
                font-size: 13px;
                text-align: center;
            }
        }

        & .moby-close {
            color: @moby-background;
            cursor: pointer;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            padding: 12px 20px;
            .moby-flex-container();
            .moby-align-items(center);
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            & .moby-close-icon {
                margin-right: 0;
                height: 20px;
                width: 20px;
                display: inline-block;
                position: relative;
                &::before,
                &::after {
                    background: @moby-background;
                }
            }
        }
        & .moby-wrap {
            height: ~"calc(100% - 40px)";
            .moby-flex-container(row, wrap);
            .moby-align-items(center);
            .moby-justify-content(center);
            & > div {
                width: 100%;
            }
            & img {
                margin: 0 auto 40px auto;
                display: block;
                width: 100px;
                height: auto;
            }
            & .moby-menu {
                width: 100%;
                & > * {
                    width: 100%;
                }
            }
            .moby-menu {
                width: 80%;
            }
        }
        & ul {
            & li {
                & ul {
                    & a {
                        padding: 20px 70px 20px 70px;
                        font-size: 16px;
                        background: darken(@moby-blue, 10%);
                    }
                }
                & a {
                    position: relative;
                    color: @moby-background;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 400;
                    display: inline-block;
                    padding: 20px 70px 20px 70px;
                    text-align: center;
                    .moby-transition(color, 0.3s);
                    &:hover {
                        color: #e0e0e0;
                    }
                    & .moby-expand {
                        display: none;
                        &:hover {
                            color: #e0e0e0;
                        }
                        & i,
                        & span {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    &.right-side {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: auto;
        width: 28%;
        right: 0;
        min-width: @moby-width;

        .moby-transform(~"translateX(100%)");
        .material-icons {
            color: #ffffff;
            font-size: 22px;
        }

        @media screen and (max-width: 400px) {
            min-width: 300px;
        }
        &.moby-active {
            .moby-transform(~"translateX(0)");
            box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
        }
        @media screen and (max-width: 640px) {
            width: 100%;
            height: 100%;
        }
    }

    &.fade {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;

        }
        & ul {
            width: 100%;
        }

    }
    &.left-side {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 30%;
        min-width: @moby-width;
        .moby-transform(~"translateX(-100%)");
        &.moby-active {
            .moby-transform(~"translateX(0)");

        }
    }
    &.top-full {
        position: fixed;
        z-index: 13000;
        top: 0;
        .moby-transform(~"translateY(-100%)");
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        &.moby-active {
            .moby-transform(~"translateY(0)");
        }
    }
    &.bottom-full {
        position: fixed;
        z-index: 13000;
        .moby-transform(~"translateY(100%)");
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        &.moby-active {
            .moby-transform(~"translateY(0)");
        }
    }
    &.grow-out {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        .moby-transform(~"scale(.7)");
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;
            .moby-transform(~"scale(1)");
        }
    }
    &.shrink-in {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        .moby-transform(~"scale(1.2)");
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;
            .moby-transform(~"scale(1)");

        }
    }
    & .menu {
        list-style: none;
        margin: 30px 0 80px;
        @media screen and (max-width: 900px) {
            margin: 30px 0 -30px;
        }
        padding: 0;
        .moby-transform(~"translate3d(0,0,0)");
        & ul {
            display: none;
        }
    }
    & ul {
        & li {
            & ul {
                & a {
                    padding: 20px 20px 20px 34px;
                    font-size: 14px;
                }
                & li {
                    & ul {
                        & li {
                            a {
                                padding-left: 42px;
                            }
                        }
                    }

                }
            }
            & a {
                position: relative;
                color: @moby-color;
                text-decoration: none;
                font-size: 15px;
                font-weight: normal;
                display: inline-block;
                padding: 27px 10%;
                text-align: left;
                position: relative;
                .font(@head-font, 27px, normal, normal);
                .trans(all 0.2s);
                &:hover {
                    color: @mainblue;
                    &::after {
                        width: 100%;
                    }
                }
                & .moby-expand {
                    display: none;
                    & i,
                    & span {
                        font-size: 30px;
                        color: @white;
                    }
                }
            }

            .dropdown-toggle {
                display: none;
            }
        }
    }
    & .moby-wrap {
        .flex-container(row-reverse, nowrap);
        //box-shadow: 0px 0px 2px #000;
        height: 100%;
        @media screen and (max-width: 640px) {
            height: auto;
        }
    }
    & .moby-menu {
        width: 80%;
    }
    & .moby-close {
        width: 20%;
        color: @moby-background;
        cursor: pointer;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        padding: 40px 0;
        background: @dark;
        .moby-flex-container();

        &:hover {
            color: lighten(@moby-background, 8%);
        }
        & .moby-close-icon {
            font-weight: bold;
            margin: 0 auto;
            position: relative;
            height: 20px;
            width: 20px;
            &:hover {
                &::before {
                    background: lighten(@moby-background, 8%);
                }
                &::after {
                    background: lighten(@moby-background, 8%);
                }
            }
        }
    }
    & .mob-top {
        .justify-content(space-between);
        padding: 0 0 0 10%;
        .flex-container(row, wrap);
        display: none;
        @media screen and (max-width: 1024px) {
            .flex-container(row, wrap);
        }
        .unit-select{
            margin: 15px 0 0;
        }
        .language {
            margin: 15px 15px 0 0;
            color: @dark;
            .font(@font1, 15px, 600, 20px);
            padding: 0;
            align-self: center;
            select{
                margin: 0;
            }
        }
        .country_icon {
            margin: 15px 15px 0 0;
            color: @dark;
            .font(@font1, 15px, 600, 20px);
            padding: 40px 0 0;
            align-self: center;
            img {
                margin: 10px 0 0 0;
            }
            @media screen and (max-width: 640px) {
                //padding: 0;
            }
        }
        & .location_sel{
            list-style: none;
            padding: 0;
            margin: 0;
            & li{
                position: relative;
                & > span,
                & a{
                    .flex-container();
                    .align-items(center);
                    cursor: pointer;
                    color: @mainblue;
                    padding: 0 10px;
                }
                & img{
                    max-width: 20px;
                    margin: 0;
                    margin-right: 5px;
                }
                & > ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: 110%;
                    left: 0;
                    max-width: 100%;
                    opacity: 0;
                    visibility: hidden;
                    z-index: -1;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                    background: #ffffff;
                    padding-top: 30px;
                    &.active{
                        opacity: 1;
                        visibility: visible;
                        z-index: 10;
                        top: 100%;
                    }
                    & li{
                        margin: 5px 0;
                    }
                }
                &:hover{
                    & > ul{
                        opacity: 1;
                        visibility: visible;
                        z-index: 10;
                        top: 100%;
                    }
                }
            }
        }
        .register, .login {
            margin: 0 55px 0 0;
            align-self: center;
            padding: 80px 0;
            a {
                text-decoration: none;
                color: @mainblue;
                .font(@font1, 16px, 700, 30px);
                .trans(all 0.2s);
                &:hover {
                    cursor: pointer;
                    color: @darkblue;
                }
            }
        }
        &.usermenu{
            .flex-container(row, nowrap);
            .dashboard {
                &.header_item_right {
                    a{
                        text-decoration: none;
                        display: block;
                        padding: 55px 0%;
                        text-align: left;
                        position: relative;
                        font-family: Titillium-BoldUpright,Titillium Web;
                        font-size: 27px;
                        font-weight: 400;
                        line-height: normal;
                        transition: all .2s;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            &.usermenu {
                padding: 0 0 0 10%;
                .flex-container(row, nowrap);
            }
        }
        @media screen and (max-width: 900px) {
            //.flex-container(row, nowrap);
            &.usermenu {
                .register, .login {
                    a {
                        font-size: 20px;
                    }
                }
            }
        }
    }

}

.second-level-menu() {

    > ul {
        position: absolute;

        top: 51px;
        left: 0px;
        width: 200px;
        opacity: 0;
        visibility: hidden;
        .trans(all 0.0s);

        padding: 0;
        display: block;
        z-index: 5;

        li {
            position: relative;
            display: block;
            list-style: none;
            text-align: left;
            padding-right: 0;
            background: @menu-bg;
            padding: 3px 0;
            border-bottom: 1px solid @bg-gray;
            &:last-child {
                border-bottom: none;
            }
            a {
                color: @white1;
                padding: 8px 20px;
                display: inline-block;
                font-size: 14px;
                text-transform: capitalize;
                width: 100%;

            }
            &:hover {

                a {
                    color: @sub-hover;
                }
            }
            .third-level-menu();
        }
    }

    &:hover {
        > ul {
            opacity: 1;
            visibility: visible;
        }
    }

}

.third-level-menu() {

    > ul {
        position: absolute;
        top: 0px;
        left: 200px;
        opacity: 0;
        visibility: hidden;
        .trans(all 0.0s);
        padding: 0;
        display: block;
        z-index: 5;

        li {
            display: block;
            list-style: none;
            text-align: left;
            padding-right: 0;
            background: @menu-bg;
            padding: 3px 0;
            border-bottom: 1px solid @bg-gray;
            a {
                color: @white1 !important;
                padding: 8px 20px;
                display: inline-block;
                font-size: 14px;
                text-transform: capitalize;
                width: 100%;

            }
            &:hover {
                a {
                    color: @sub-hover !important;
                }
            }
        }
    }

    &:hover {
        ul {
            opacity: 1;
            visibility: visible;
        }
    }

}







