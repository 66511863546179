#close_cookie {
    display:none;
    &:checked {
        + #cookie_consent_popup {
            display: none;
        }
    }
}
	
#cookie_consent_popup {
	position:fixed;
	bottom:30px;
    left:30px;
	width:400px;
	height:250px;
	background-color:@mainblue;
	padding:20px;
	z-index:2;
    h1 {
        font-size:1.2em;
        &:before {
            content:"";
			padding:0;
        }
    }
    p {
        font-size:0.8em;
        line-height: 25px !important;
    }
    a {
        color: @white !important;
        font-size: 1em !important;
        font-weight: 500 !important;
        &:hover {
            color: @darkblue !important;
        }
    }
    #close_cookie_box{
		position:absolute;
		top:20px;right:20px;
		cursor:pointer;
		font-size:1.3em;
	}
    #ok_cookie_box{
		position:absolute;
		bottom:20px;right:20px;
		cursor:pointer;
		font-size:1.6em;
		padding:10px 20px;
		font-weight:700;
		color:@white;
	}
    @media screen and (max-width: 460px) {        
        bottom:10px;
        left:10px;        
        width:350px;
        height:300px;
    }
}
.inner-content {
    &.cookies {
        form {
            label {
                color:@dark;
            }
        }
    }
}
.required-cookies {
    &:before {
        content: '';
        border: 2px solid @table-border;
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        padding: 0px;
        margin-right: 5px;
        text-align: center;
        border-radius: 20%;
        background: @table-border;
        box-shadow: inset 0px 0px 0px 4px @white;
    }
}