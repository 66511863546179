@-webkit-keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
}

/* SLIDER  */

.hero-slider {
    .hero-slide {
        height: 625px;
        display: flex !important;
        align-items: flex-end;
        position: relative;
        background-image: url('../images/hero.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
        @media screen and (max-width: 1020px) {
            height: 900px;
        }
        @media screen and (max-width: 640px) {
            height: 820px;
        }
        .container {
            z-index: 1;
            position: relative;
            width: 1920px;
            margin: 0 auto;
            @media screen and (max-width: 1600px) {
                width: 100%;
            }
        }
        .slide-content {
            width: 1220px;
            .flex-container(row, nowrap);
            margin: 0 auto;
            .justify-content(space-between);
            .align-items(center);
            @media screen and (max-width: 1600px) {
                width: 77%;
            }
            @media screen and (max-width: 1370px) {
                width: 72%;
            }
            @media screen and (max-width: 1300px) {
                width: 70%;
            }
            @media screen and (max-width: 1024px) {
                width: 68%;
            }
            @media screen and (max-width: 1020px) {
                width: 74%;
                .flex-container(column-reverse, nowrap);
            }
            .slide-content-left {
                width: 60%;
                @media screen and (max-width: 1020px) {
                    width: 100%;
                }
                span {
                    color: @mainblue;
                    font-size: 55px;
                    font-family: @head-font;
                    @media screen and (max-width: 1440px) {
                        font-size: 55px;
                        line-height: 65px;
                    }
                    @media screen and (max-width: 1370px) {
                        font-size: 50px;
                        line-height: 52px;
                    }
                    @media screen and (max-width: 1024px) {
                        width: 55%;
                    }
                    @media screen and (max-width: 1020px) {
                        font-size: 38px;
                        line-height: 41px;
                    }
                }
                .h1 {
                    font-weight: bold;
                    font-size: 55px;
                    line-height: 65px;
                    margin-bottom: 30px;
                    margin-top: 5px;
                    @media screen and (max-width: 1440px) {
                        font-size: 71px;
                        line-height: 75px;
                        margin-bottom: 25px;
                    }
                    @media screen and (max-width: 1370px) {
                        font-size: 50px;
                        line-height: 52px;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 47px;
                    }
                    @media screen and (max-width: 1020px) {
                        //margin-top: -40px;
                        margin-top: 0;
                        font-size: 38px;
                        line-height: 41px;
                    }
                    @media screen and (max-width: 640px) {
                        margin-top: -20px;
                    }
                    @media screen and (max-width: 420px) {
                        font-size: 26px;
                        line-height: 30px;
                    }
                    & span{
                        @media screen and (max-width: 420px) {
                            font-size: 26px;
                            line-height: 30px;
                        }
                    }
                    &.slide1-title{
                        font-size: 55px;
                        line-height: 65px;
                        @media screen and (max-width: 1370px) {
                            font-size: 50px;
                            line-height: 52px;
                        }
                        @media screen and (max-width: 1024px) {
                            font-size: 47px;
                        }
                        @media screen and (max-width: 1020px) {
                            font-size: 38px;
                            line-height: 41px;
                        }
                        @media screen and (max-width: 640px) {
                            font-size: 30px;
                            line-height: 36px;
                        }
                        @media screen and (max-width: 420px) {
                            font-size: 26px;
                            line-height: 30px;
                        }
                        & span{
                            @media screen and (max-width: 640px) {
                                font-size: 30px;
                                line-height: 36px;
                            }
                            @media screen and (max-width: 420px) {
                                font-size: 26px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                p {
                    text-align: left;
                    @media screen and (max-width: 640px) {
                        line-height: 22px;
                        padding-bottom: 20px;
                        margin: 0;
                    }
                    @media screen and (max-width: 360px) {
                        padding-bottom: 10px;
                    }
                }
                .slider_button {
                    color: @mainblue;
                    text-decoration: none;
                    .font(@font1bold, 25px, normal, 33px);
                    .trans(all 0.2s);
                    @media screen and (max-width: 420px) {
                        font-size: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                        color: @darkblue;
                    }
                    i {
                        &.fa-plus {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .slide-content-right {
                width: 40%;
                text-align: center;
                @media screen and (max-width: 1024px) {
                    width: 45%;
                }
                @media screen and (max-width: 1020px) {
                    width: 100%;
                }
                & img {
                    display: inline-block;
                    padding: 10px 0;
                    @media screen and (max-width: 1020px) {
                        margin: 0 auto;
                        max-height: 500px;
                        padding: 40px 0;
                    }
                    @media screen and (max-width: 640px) {
                        max-height: 400px;
                        padding-top: 20px;
                    }
                }
            }
        }

        .slide-btn {
            position: relative;
            padding-left: 0;
            :after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 100%;
                border-bottom: 3px solid #d2282e;
                transition: width .3s ease-in-out;
            }
            &:hover:after {
                width: 90%;
            }
        }

        &.slick-active {
            .slide-content {
                .slide-content-left {
                    animation-name: fadeInUpSD;
                    animation-duration: 1s;
                }
                .slide-content-right {
                    img {
                        animation-name: fadeInUpSD;
                        animation-duration: 1s;
                        animation-delay: 0.3s;
                        animation-fill-mode: both;
                    }
                }
            }
        }

    }

    //.slider_1 {
    //    background-image: url('../images/hero.jpg') !important;
    //    background-position: center center !important;
    //}
    //.slider_2 {
    //    background-image: url('../images/hero.jpg') !important;
    //    background-position: center center !important;
    //}
    //.slider_3 {
    //    background-image: url('../images/hero.jpg') !important;
    //    background-position: center center !important;
    //
    //}
    //.slider_4 {
    //    background-image: url('../images/hero.jpg') !important;
    //    background-position: center center !important;
    //}
    .slick-prev {
        cursor: pointer;
        position: absolute;
        top: 300px;
        width: auto;
        padding: 40px;
        color: @white;
        font-weight: normal;
        font-size: 0px;
        user-select: none;
        -webkit-user-select: none;
        background-color: @bgheaderdark;
        left: 0px;
        z-index: 1;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        outline: none;
        border: none;
        @media screen and (max-width: 1020px) {
            display: none !important;
        }
        .material-icons {
            color: #ffffff;
            font-size: 28px;
        }
    }
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 300px;
        width: auto;
        padding: 40px;
        font-weight: normal;
        font-size: 0px;
        user-select: none;
        -webkit-user-select: none;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: @white;
        border: none;
        outline: none;
        background-color: @bgheaderdark;
        z-index: 1;
        right: 0px;
        @media screen and (max-width: 1020px) {
            display: none !important;
        }
        .material-icons {
            color: #ffffff;
            font-size: 28px;
        }
    }

    .slick-dots {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0%);
        .flex-container(row, wrap);
        margin-bottom: 35px;
        .justify-content(center);
        .align-items(center);
        list-style: none;
        padding: 0;

        li {
            position: relative;
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0 5px;
            padding: 0 10px;
            cursor: pointer;

            &.slick-active button:before {
                background: @mainblue;
                border: 2px solid @mainblue;
                box-shadow: inset 0px 0px 0px 3px #ffffff;
            }

            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    content: "";
                    text-align: center;
                    border: 2px solid @darkcolor1;
                    border-radius: 50%;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
}