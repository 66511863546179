form.form {
    & .form-response{
        display: none;
        margin-bottom: 40px;
        padding: 20px 10px;
        padding-left: 50px;
        border: 1px solid;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        position: relative;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        & p{
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
        }
        & a{
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
            text-decoration: underline;
            &:hover{
                color: #ffffff;
            }
        }
        &::before{
            color: white;
            position: absolute;
            left: 15px;
            top: 18px;
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        &.error{
            background-color: @red2;
            color: white;
            padding-left: 50px;
            &::before{
                content: "\e000";
            }
        }
        &.success{
            background-color: @darkblue;
            color: white;
            &::before{
                content: "\e5ca";
            }
        }
    }
    &.show-response{
        & .form-response{
            display: block;
        }
    }
    & .fields-container {
        .flex-container();
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        .justify-content(space-between);
        width: 100%;
        &.single {
            & .form-row {
                width: 100%;
            }
        }
        &.double {
            & .form-row {
                width: calc(~"50% - 9px");
                @media screen and (max-width: 640px) {
                    width: 100%;
                }
            }
        }
        &.triple {
            & .form-row {
                width: calc(~"33% - 9px");
                @media screen and (max-width: 640px) {
                    width: 100%;
                }
            }
        }
        &.two-half {
            .flex-container(row, wrap);
            & .form-row {
                width: calc(~"25% - 9px");
                @media screen and (max-width: 960px) {

                    width: calc(~"50% - 9px");
                }
                @media screen and (max-width: 640px) {
                    width: 100%;
                }
            }
        }
    }
    .form-row {
        width: 100%;
        .flex-container(row, nowrap);
        .justify-content(space-between);
        padding: 0 0 12px 0;
        @media screen and (max-width: 768px) {
            .flex-container(column, wrap);
            padding: 0;
        }
        &.final-row {
            &.jc_end {
                .justify-content(flex-end);
            }
        }
        & .row-item{
            padding: 5px 0;
            p {
                &.tax-exempt {
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
        .custom {
            opacity: 0;
            position: absolute;
            display: inline-block;
            cursor: pointer;
            + .text {
                &:before {
                    content: '';
                    background: none;
                    border: 2px solid @mainblue;
                    display: inline-block;
                    vertical-align: middle;
                    width: 15px;
                    height: 15px;
                    padding: 0px;
                    margin-right: 5px;
                    text-align: center;
                    border-radius: 20%;
                }
            }
            &:checked {
                + .text {
                    &:before {
                        background: @mainblue;
                        box-shadow: inset 0px 0px 0px 4px #fff;
                    }
                }
            }
        }
        label {
            font-weight: 700;
            color: @select-text;
            font-size: 16px;
            & a{
                font-size: 16px;
            }
        }
        .input-field {
            width: 100%;
            @media screen and (max-width: 768px) {
                padding: 0 0 25px 0;
            }
            &.two-section {
                width: 48%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
            .input {
                position: relative;
                z-index: 1;
                display: inline-block;
                vertical-align: top;
                padding-top: 1em;
                padding-bottom: 1em;
                width: 100%;
                .input--filled {
                    .input__label {
                        -webkit-animation: anim-chisato-1 0.25s forwards;
                        animation: anim-chisato-1 0.25s forwards;
                        .input__label-content {
                            -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
                            animation: anim-chisato-2 0.25s forwards ease-in;
                        }

                    }
                }
                & .input__field {
                    position: relative;
                    display: block;
                    -webkit-appearance: none;
                    background: transparent;
                    padding: 25px 25px;
                    border: 1px solid @table-border;
                    color: @select-text;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: normal;
                    width: 100%;

                    &:focus {
                        + .input__label {

                            -webkit-animation: anim-chisato-1 0.25s forwards;
                            animation: anim-chisato-1 0.25s forwards;
                            .input__label-content {
                                -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
                                animation: anim-chisato-2 0.25s forwards ease-in;
                            }
                        }
                    }
                }
                & .input__label {
                    font-weight: bold;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    width: 100%;
                    position: absolute;
                    top: -6px;
                    text-align: left;
                    overflow: hidden;
                    padding: 0;
                    pointer-events: none;
                    -webkit-transform: translate3d(0, 3em, 0);
                    transform: translate3d(0, 3em, 0);
                    .input__label-content {
                        position: relative;
                        display: block;
                        width: 100%;
                        padding: 0 25px;
                        font-weight: 700;
                        color: @select-text;
                        font-size: 14px;
                        &::after {
                            content: attr(data-content);
                            position: absolute;
                            top: -210%;
                            left: 25px;
                            color: @grey2;
                            font-weight: 700;
                            font-size: 14px;
                        }
                        & .required-tip{
                            color: @red;
                            padding-left: 2px;
                        }
                    }
                }
                & .field-tip{
                    position: absolute;
                    bottom: -2px;
                    font-size: 14px;
                    &.error{
                        color: @red2;
                    }
                }
            }
            &.valid{
                & .input{
                    & .field-tip{
                        display: none;
                    }
                }
            }
        }
        & .form-button {
            padding: 0;
            border: none;
            background: transparent;
            color: @mainblue;
            .font(@font1bold, 25px, bold, 33px);
            .trans(all 0.2s);
            outline: none;
            & span {
                .font(@font1bold, 25px, bold, 33px);
                &.process{
                    position: relative;
                    padding-right: 5px;
                }
                i {
                    &.fa-plus {
                        font-size: 20px;
                    }
                }
            }
            &.btn_login {
                font-size: 23px;
                line-height: 20px;
            }
            .lds-ring {
                display: inline-block;
                display: none;
                position: relative;
                width: 16px;
                height: 20px;
            }
            .lds-ring div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 16px;
                height: 20px;
                margin: 0px;
                border: 6px solid @mainblue;
                border-radius: 50%;
                animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: @mainblue transparent transparent transparent;
            }
            .lds-ring div:nth-child(1) {
                animation-delay: -0.45s;
            }
            .lds-ring div:nth-child(2) {
                animation-delay: -0.3s;
            }
            .lds-ring div:nth-child(3) {
                animation-delay: -0.15s;
            }
            @keyframes lds-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            &:hover {
                cursor: pointer;
                color: @darkblue;
            }
            &.active{
                & span.process{
                    & .base{
                        display: none;
                    }
                    & .lds-ring{
                        display: inline-block;
                    }
                }
            }
            &:disabled {
                color: @darkblue;
            }
        }
        a {
            &.btn_forgot_password {
                font-size: 19px;
                line-height: 20px;
                color: @grey3;
            }
        }
    }
    .input__field:focus + .input__label,
    .input--filled .input__label{
        -webkit-animation: anim-chisato-1 0.25s forwards;
        animation: anim-chisato-1 0.25s forwards;
    }

    .input__field:focus + .input__label .input__label-content,
    .input--filled .input__label-content {
        -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
        animation: anim-chisato-2 0.25s forwards ease-in;
    }
    &.error{
        & .form-row{
            & .form-button{
                color: @red2;
                &.active{
                    color: @mainblue;
                }
            }
        }
    }
}
select {
    border: 2px solid @select_border;
    color: @dark;
    line-height: 1.5em;
    padding: 0.5em 2.5em 0.5em 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    .font(@font1, 15px, 600, 20px);
    background-image: linear-gradient(45deg, transparent 50%, @mainblue 0),
    linear-gradient(135deg, @mainblue 50%, transparent 0);
    background-position: ~"calc(100% - 21px)" ~"calc(1em + 0px)",
    ~"calc(100% - 16px)" ~"calc(1em + 0px)",
    ~"calc(100% - 2.5em)" 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
    margin: 0 40px 0 0;
    align-self: center;
    &::-ms-expand {
        display: none;
    }
    option{
        font-family: "Montserrat", sans-serif;
    }
    & .font-ms{
        font-family: "Montserrat", sans-serif;
    }
}
//.custom-select-variation {
//    display: inline-block;
//    width: 100%;
//    vertical-align: middle;
//    position: relative;
//    text-align: left;
//    background: #fff;
//    z-index: 1;
//    -webkit-touch-callout: none;
//    -webkit-user-select: none;
//    -khtml-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    user-select: none;
//    color: @select-text;
//    &:focus {
//        outline: none;
//    }
//    select {
//        display: none;
//    }
//    span {
//        display: block;
//        position: relative;
//        cursor: pointer;
//        padding: 1em;
//        white-space: nowrap;
//        overflow: hidden;
//        text-overflow: ellipsis;
//        padding: 25px 25px 25px 25px;
//    }
//    .cs-placeholder {
//        border: 1px solid @table-border;
//        background-image: linear-gradient(45deg, transparent 50%, @mainblue 50%), linear-gradient(135deg, @mainblue 50%, transparent 50%);
//        background-position: ~"calc(100% - 20px)" ~"calc(1em + 12px)", ~"calc(100% - 12px)" ~"calc(1em + 12px)";
//        background-size: 8px 8px, 8px 8px;
//        background-repeat: no-repeat;
//        font-family: Montserrat;
//        font-size: 16px;
//        font-weight: 700;
//        line-height: normal;
//        padding: 25px 25px 25px 25px;
//        &.selected {
//            color: @white;
//            background-color: @mainblue;
//            background-image: linear-gradient(45deg, transparent 50%, @white 50%), linear-gradient(135deg, @white 50%, transparent 50%);
//            background-position: ~"calc(100% - 20px)" ~"calc(1em + 12px)", ~"calc(100% - 12px)" ~"calc(1em + 12px)";
//            background-size: 8px 8px, 8px 8px;
//            background-repeat: no-repeat;
//        }
//    }
//    &.cs-active {
//        z-index: 2;
//        .cs-placeholder {
//            background-color: @mainblue;
//            color: @white;
//            background-image: linear-gradient(135deg, transparent 50%, @white 50%), linear-gradient(45deg, @white 50%, transparent 50%);
//            background-position: ~"calc(100% - 20px)" ~"calc(1em + 12px)", ~"calc(100% - 12px)" ~"calc(1em + 12px)";
//            background-size: 8px 8px, 8px 8px;
//            background-repeat: no-repeat;
//        }
//        .cs-options {
//            visibility: visible;
//            box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
//            pointer-events: auto;
//            animation-name: fadeInUpSD;
//            animation-duration: 0.3s;
//            ul {
//                li {
//                    -webkit-transform: translate3d(0, 0, 0);
//                    transform: translate3d(0, 0, 0);
//                    opacity: 1;
//                    -webkit-transition: none;
//                    transition: none;
//                    font-family: Montserrat;
//                    font-size: 16px;
//                    font-weight: 700;
//                    line-height: normal;
//                    &:hover {
//                        span {
//                            color: @bgheaderdark;
//                        }
//                    }
//                }
//            }
//        }
//    }
//    .cs-options {
//        position: absolute;
//        overflow: hidden;
//        width: 108%;
//        background: #ffffff;
//        visibility: hidden;
//        padding: 25px 30px 25px 30px;
//
//        color: @select-text;
//        ul {
//            li {
//                opacity: 0;
//                -webkit-transform: translate3d(0, -25px, 0);
//                transform: translate3d(0, -25px, 0);
//                -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
//                transition: opacity 0.15s, transform 0.15s;
//            }
//        }
//    }
//    ul {
//        list-style: none;
//        margin: 0;
//        padding: 0;
//        width: 100%;
//        span {
//            padding: 10px 0;
//            .font(@font1, 16px, bold, 43px);
//            color: @select-text;
//            .trans(all 0.2s);
//        }
//        li {
//            &.cs-focus {
//                span {
//                    background-color: #ddd;
//                }
//            }
//            &.cs-optgroup {
//                ul {
//                    padding-left: 1em;
//                }
//                span {
//                    cursor: default;
//                }
//            }
//        }
//    }
//}