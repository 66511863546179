.products {
    .breadcrumb {
        z-index: 1;
        position: relative;
        width: 100%;
        background: @lightgrey;
        padding: 25px 0;
        //.flex-container(row,nowrap);
        .flex-container(row, nowrap);
        .align-items(center);

        .hierarchy {
            width: 84.6%;
            margin: 0 auto;
            letter-spacing: 1px;
            .font(@font1, 16px, bold, normal);

            a {
                .font(@font1, 16px, bold, normal);
                color: @product-head !important;
            }

            span {
                .font(@font1, 16px, bold, normal);

                &.active {
                    color: @mainblue;
                }

                &.arrow {
                    padding: 0 1%;
                }
            }
        }
    }

    .product {
        .flex-container(row, nowrap);

        &.container {
            width: 84.6%;
            margin: 0 auto;
        }

        .products_menu {
            width: 25%;
            margin: 60px 0;

            h2 {
                .font(@head-font, 45px, bold, 43px);
                margin: 0 0 40px;
            }

        }
        .category_menu {        
            width: 21.3%;    
            margin: 60px 0;
            padding-right: 20px;
            @media screen and (max-width: 1290px) {
                width: 300px;
            }
            @media screen and (max-width: 768px) {
                padding-right: 0;
                width: 100%;
                margin: 0;
            }

            h2 {
                .font(@head-font, 35px, bold, 40px);
                margin: 0 0 40px;
                @media screen and (max-width: 1290px) {
                    display: none;
                }
            }
        }

        .product_section {
            width: 69.5%;
            margin: 30px auto 20px;
            .category-item {
                margin: 40px 0 50px;
                .flex-container(column, nowrap);
                @media screen and (max-width: 1290px) {
                    margin: 40px 0 0;
                }
                &:not(:first-child) {
                    @media screen and (max-width: 768px) {
                        margin: 60px 0 0;
                    }
                }
                .item-top {
                    background: @lightgrey;
                    padding-bottom: 20px;
                    .flex-container(column, nowrap);
                    .item-heading {
                        margin: -25px 0 0 0;
                        padding: 0 40px 0 40px;
                        @media screen and (max-width: 1290px) {
                            margin: -25px 0 0 0;
                            padding: 0 35px 0 35px;
                        }

                        .header {
                            .font(@head-font, 40px, bold, 35px);
                            @media screen and (max-width: 1735px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1624px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1505px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1440px) {
                                .font(@head-font, 40px, bold, 35px);
                            }
                            @media screen and (max-width: 1290px) {
                                .font(@head-font, 38px, bold, 35px);
                            }
                        }

                        .blue_border {
                            border-bottom: 13px solid @mainblue;
                            width: 145px;
                            margin: 20px 0 10px;
                            @media screen and (max-width: 1330px) {
                                border-bottom: 10px solid #65b9e7;
                                width: 100px;
                                margin: 10px 0 10px 0;
                            }
                        }
                        .item-description{
                            @media screen and (max-width: 1290px) {
                                padding-top: 10px;
                            }
                        }

                        .head-description{
                            padding-top: 5px;
                            font-size: 14px;
                            font-weight: 300;
                            font-family: 'Roboto', sans-serif;
                            line-height: 22px;
                            color: #000000;
                            b{
                                font-family: 'Roboto', sans-serif;
                            }
                            h2{
                                font-size: 17px;
                                font-weight: 500;
                                line-height: 22px;
                                color: @mainblue;
                                font-family: 'Roboto', sans-serif;
                                margin: 15px 0 10px 0;
                                padding: 0;
                            }
                            p{
                                font-size: 14px;
                                font-weight: 300;
                                font-family: 'Roboto', sans-serif;
                                line-height: 22px;
                                color: #000000;
                                padding-top:0px;
                                padding-bottom: 0px;
                            }
                            ul{
                                li{
                                    font-size: 14px;
                                    font-weight: 300;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 22px;
                                    color: #000000;
                                }
                            }
                        }

                        p {
                            padding-top: 20px;
                            padding-bottom: 5px;
                            line-height: 24px;
                        }
                    }
                    &.product-description-top{
                        background: white;
                        .item-heading{
                            padding-left: 0;
                        }
                    }
                }

                .item-bottom {
                    //.flex-container(row, wrap);
                    margin: 20px 0 30px 0;
                    //.align-items(flex-start);
                    display: flex; //for 2-column if we want it
                    flex-wrap: wrap; //for 2-column if we want it
                    width: 100%;                                        
                    @media screen and (max-width: 1040px) {
                        //.flex-container(row, wrap);
                        margin: 20px 0;
                    }
                    .subcategory {
                        padding: 20px 0 20px 0;
                        //position: relative;
                        //width: 100%;
                        flex: 0 0 48%; //for 2-column if we want it
                        @media screen and (max-width: 1440px) {
                            padding: 0 0 20px 0;                            
                        }
                        @media screen and (max-width: 1390px) {
                            //width: 23%;
                        }
                        @media screen and (max-width: 1040px) {
                            padding: 20px 0 0 0;
                            min-height: 250px;
                        }
                        @media screen and (max-width: 1024px) {
                            //margin-top: 90px;
                            //width: 30.3%;
                        }
                        @media screen and (max-width: 768px) {
                            //width: 48%;
                            margin: 20px 0 0 0;
                            flex: 0 0 100%; //for 1-column if we want it
                        }
                        @media screen and (max-width: 499px) {                            
                            padding: 0px 0 0 0;
                            margin-top: 70px !important;
                        }
                        &:not(:last-child) {
                            //margin-right: 4%;
                            @media screen and (max-width: 1390px) {
                                margin-right: 2%;
                            }
                            @media screen and (max-width: 1024px) {
                                margin-right: 4%;
                            }
                            @media screen and (max-width: 768px) {
                                margin: 0px 0 0px 0;
                            }
                        }
                        &:nth-child(odd) {
                            @media screen and (max-width: 768px) {
                                margin-right: 4% !important;
                            }
                        }

                        &:nth-child(4n + 0) {
                            margin-right: 0;
                            @media screen and (max-width: 1024px) {
                                margin-right: 3%;
                            }
                            @media screen and (max-width: 768px) {
                                margin-right: 0;
                            }
                        }
                        &:nth-child(3n + 0) {
                            @media screen and (max-width: 1024px) {
                                margin-right: 0;
                            }
                            @media screen and (max-width: 768px) {
                                margin-right: 0;
                            }
                        }
                        .category-action {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 10;
                        }
                        .expand-item{
                            position: absolute;
                            bottom: 20px;
                            right: 0px;
                            z-index: 15;
                            cursor: pointer;
                            padding: 10px;
                            i{
                                color: @mainblue;
                            }
                            @media screen and (max-width: 1040px) {
                                bottom: -12px;
                            }
                        }
                        .collapse-item{
                            position: absolute;
                            bottom: 26px;
                            right: 0px;
                            color: @mainblue;
                            opacity: 0;
                            visibility: hidden;
                            padding: 10px;
                            @media screen and (max-width: 1040px) {
                                bottom: 0px;
                            }
                        }

                        .subcategory-container {
                            a {
                                color: @dark !important;
                            }
                            //.flex-container(column, nowrap);
                            position: relative;
                            display: -webkit-box;
                            display: -webkit-flex;
                            display: -ms-flexbox;
                            display: flex;
                            .justify-content(start);
                            min-height: 200px;
                            .box-overlay {
                                min-height: 90px;
                                .trans(min-height .5s ease .3s);
                                position: absolute;
                                width: 100%;
                                z-index: -9999;
                                overflow: hidden;
                                @media screen and (max-width: 1040px) {
                                    min-height: 70px;
                                }
                                @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                                    background: transparent;
                                    top: 30px;
                                }
                            }

                            .subcategory-container-wrapper {
                                //width: 100%;
                                //min-height: 190px;
                                //max-height: 200px;
                                //max-width: 300px;
                                //display: flex;
                                //margin-left: 20px;
                                //.align-self(center);
                                //.justify-content(start);
                                //padding: 10px 0 0 0;
                                @media screen and (max-width: 1040px) {
                                    min-height: 170px;
                                    max-height: 170px;
                                }
                                @media screen and (max-width: 768px) {
                                    min-height: 200px;
                                    max-height: none;
                                }
                                @media screen and (max-width: 420px) {
                                    //min-height: 180px;
                                    //max-height: 180px;
                                    padding: 10px 0 0 0;
                                    min-height: 0;
                                    max-height: none;
                                }

                                img {
                                    //position: absolute;
                                    //top: 5px;
                                    max-width: 170px;
                                    @media screen and (max-width: 1680px) {
                                        //top: 45px;
                                    }
                                    @media screen and (max-width: 1580px) {
                                        //top: 55px;
                                    }
                                    @media screen and (max-width: 1440px) {
                                        //top: 60px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        //top: 22px;
                                    }
                                    @media screen and (max-width: 920px) {
                                        //top: 0px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        position: relative;
                                        //top: 5px;
                                        opacity: 0.7;
                                    }
                                    @media screen and (max-width: 420px) {
                                        //top: 0;  
                                        max-width: 130px;                                      
                                    }
                                }
                            }

                            .overlay {
                                visibility: visible;
                                width: 100%;
                            }

                            .text {
                                color: @bgheaderdark;
                                //position: absolute;
                                //top: -20%;
                                left: 20%;
                                //-webkit-transform: translate(-30%, -150%);
                                //-ms-transform: translate(-30%, -150%);
                                //transform: translate(-30%, -150%);
                                padding: 25px 25px 25px 25px;
                                width: 90%;
                                @media screen and (max-width: 1735px) {
                                    //top: -15%;
                                }
                                @media screen and (max-width: 1505px) {
                                    //top: 12%;
                                }
                                @media screen and (max-width: 1440px) {
                                    //top: 35%;
                                }
                                @media screen and (max-width: 1330px) {
                                    //top: 50%;
                                    padding: 25px 18px;
                                }
                                @media screen and (max-width: 1290px) {
                                    //top: 35%;
                                    padding: 20px 15px 20px;
                                }
                                @media screen and (max-width: 1160px) {
                                    //top: 45%;
                                }
                                @media screen and (max-width: 1070px) {
                                    //top: 54%;
                                    width: 70%;
                                }
                                @media screen and (max-width: 1040px) {
                                    color: @bgheaderdark;
                                    //-webkit-transform: translate(-20%, -150%);
                                    //-ms-transform: translate(-20%, -150%);
                                    //transform: translate(-20%, -150%);                                    
                                    width: 100%;                                    
                                    left: 40%;
                                }
                                @media screen and (max-width: 850px) {                                    
                                    padding: 20px 12px 30px;
                                }
                                @media screen and (max-width: 768px) {                                    
                                    //-webkit-transform: translate(-50%, -150%);
                                    //-ms-transform: translate(-50%, -150%);
                                    //transform: translate(-50%, -150%);                                    
                                    width: 100%;
                                    padding: 20px;    
                                }
                                @media screen and (max-width: 420px) {                                    
                                    padding: 20px 10px;                                    
                                    margin-top: -20px; 
                                }

                                p {
                                    .font(@font1, 14px, 600, 22px);
                                    padding-bottom: 0;
                                    @media screen and (max-width: 1580px) {
                                        font-size: 14px;
                                        line-height: 30px;
                                        //margin-top: 40px;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        font-size: 14px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        font-size: 14px;
                                        line-height: 22px;
                                    }
                                    @media screen and (max-width: 920px) {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                    @media screen and (max-width: 420px) {
                                        font-size: 12px;
                                    }
                                }

                                .btn-container {
                                    @media screen and (max-width: 920px) {
                                        display: flex;
                                        flex-wrap: wrap;
                                    }
                                    @media screen and (max-width:768) {
                                        justify-content: space-between;
                                    }
                                    .btn {
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: @mainblue !important;
                                        @media screen and (max-width: 1440px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 1330px) {
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 1290px) {
                                            font-size: 16px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            font-size: 13px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            font-size: 16px;
                                            line-height: 30px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 850px) {
                                            font-size: 14px;
                                        }
                                        span {
                                            font-size: 16px;
                                            font-weight: 800;
                                        }
                                    }  
                                }                              
                            }

                            .sub-heading {
                                //padding: 0 25px 0 25px;
                                margin: -35px 0 0 0;
                                position: relative;
                                @media screen and (max-width: 1290px) {
                                    //padding: 0 0 0 15px;
                                    margin: -70px 0 0 0;
                                }
                                @media screen and (max-width: 1040px) {
                                    //padding: 0 0 0 15px;
                                    margin: -55px 0 0 0;
                                }
                                @media screen and (max-width: 768px) {
                                    margin: -75px 0 0 0;
                                }
                                @media screen and (max-width: 420px) {
                                    margin: 0;
                                }

                                .head {
                                    min-height: 40px;
                                    .flex-container();
                                    .align-items(flex-end);
                                    color: @product-head;
                                    .font(@head-font, 26px, bold, 26px);

                                    @media screen and (max-width: 1735px) {
                                        font-size: 24px;
                                        line-height: 24px;
                                    }

                                    span {
                                        .font(@head-font, 26px, bold, 26px);
                                        @media screen and (max-width: 1735px) {
                                            font-size: 24px;
                                            line-height: 24px;
                                        }
                                        @media screen and (max-width: 1505px) {
                                            font-size: 21px;
                                            line-height: 21px;
                                        }
                                        @media screen and (max-width: 1440px) {
                                            font-size: 18px;
                                            line-height: 18px;
                                        }
                                    }
                                }

                                .blue_border {
                                    border-bottom: 16px solid @mainblue;
                                    width: 116px;
                                    margin: 15px 0 0 0;
                                    @media screen and (max-width: 1290px) {
                                        border-bottom: 10px solid @mainblue;
                                        width: 80px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        width: 116px;
                                        border-bottom: 16px solid @mainblue;
                                    }
                                }
                            }
                        }

                        &.active {
                            cursor: pointer;
                            padding-top: 50px;
                            .expand-item{
                                opacity: 0;
                                visibility: hidden;
                            }
                            .collapse-item{
                                opacity: 1;
                                visibility: visible;
                                z-index: 15;
                            }
                            .subcategory-container {
                                .text {
                                    color: @bgheaderdark;
                                    //position: absolute;
                                    //top: -20%;
                                    left: 20%;
                                    //-webkit-transform: translate(-30%, -150%);
                                    //-ms-transform: translate(-30%, -150%);
                                    //transform: translate(-30%, -150%);
                                    padding: 25px 25px 25px 25px;
                                    width: 90%;
                                    @media screen and (max-width: 1735px) {
                                        //top: -15%;
                                    }
                                    @media screen and (max-width: 1505px) {
                                        //top: 12%;
                                    }
                                    @media screen and (max-width: 1440px) {
                                        //top: 35%;
                                    }
                                    @media screen and (max-width: 1330px) {
                                        //top: 50%;
                                        padding: 25px 18px;
                                    }
                                    @media screen and (max-width: 1290px) {
                                        //top: 35%;
                                        padding: 20px 15px 20px;
                                    }
                                    @media screen and (max-width: 1160px) {
                                        //top: 45%;
                                    }
                                    @media screen and (max-width: 1070px) {
                                        //top: 54%;
                                        width: 70%;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        color: @bgheaderdark;
                                        //-webkit-transform: translate(-20%, -150%);
                                        //-ms-transform: translate(-20%, -150%);
                                        //transform: translate(-20%, -150%);                                    
                                        width: 100%;                                    
                                        left: 40%;
                                    }
                                    @media screen and (max-width: 850px) {                                    
                                        padding: 20px 12px 30px;
                                    }
                                    @media screen and (max-width: 768px) {                                    
                                        //-webkit-transform: translate(-50%, -150%);
                                        //-ms-transform: translate(-50%, -150%);
                                        //transform: translate(-50%, -150%);                                    
                                        width: 100%;
                                        padding: 20px;    
                                    }
                                    @media screen and (max-width: 420px) {                                    
                                        padding: 20px 10px;                                    
                                        margin-top: -20px; 
                                    }
    
                                    p {
                                        .font(@font1, 14px, 600, 22px);
                                        padding-bottom: 0;
                                        @media screen and (max-width: 1580px) {
                                            font-size: 14px;
                                            line-height: 30px;
                                            //margin-top: 40px;
                                        }
                                        @media screen and (max-width: 1040px) {
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            font-size: 14px;
                                            line-height: 20px;
                                        }
                                        @media screen and (max-width: 420px) {
                                            font-size: 12px;
                                        }
                                    }
    
                                    .btn-container {
                                        @media screen and (max-width: 920px) {
                                            display: flex;
                                            flex-wrap: wrap;
                                        }
                                        @media screen and (max-width:768) {
                                            justify-content: space-between;
                                        }
                                        .btn {
                                            font-size: 16px;
                                            line-height: 30px;
                                            color: @mainblue !important;
                                            @media screen and (max-width: 1440px) {
                                                font-size: 14px;
                                                line-height: 22px;
                                            }
                                            @media screen and (max-width: 1330px) {
                                                font-size: 14px;
                                            }
                                            @media screen and (max-width: 1290px) {
                                                font-size: 16px;
                                            }
                                            @media screen and (max-width: 1040px) {
                                                font-size: 13px;
                                            }
                                            @media screen and (max-width: 1024px) {
                                                font-size: 16px;
                                                line-height: 30px;
                                            }
                                            @media screen and (max-width: 920px) {
                                                font-size: 14px;
                                                line-height: 22px;
                                            }
                                            @media screen and (max-width: 850px) {
                                                font-size: 14px;
                                            }
                                            span {
                                                font-size: 16px;
                                                font-weight: 800;
                                            }
                                        }  
                                    }                              
                                }

                                .box{
                                    @media screen and (max-width: 768px) {
                                        position: absolute;
                                        top: -69px;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .box-overlay {
                                    min-height: 220px;
                                    .trans(min-height .1s ease .1s);
                                    @media screen and (max-width: 1735px) {
                                        min-height: 217px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        //min-height: 160px;
                                        min-height: 100%;
                                    }
                                }

                                .subcategory-container-wrapper {
                                    //width: 100%;
                                    //min-height: 190px;
                                    //max-height: 200px;
                                    //max-width: 300px;
                                    //display: flex;
                                    //margin-left: 20px;
                                    //.align-self(center);
                                    //.justify-content(start);
                                    //padding: 10px 0 0 0;
                                    @media screen and (max-width: 1040px) {
                                        min-height: 170px;
                                        max-height: 170px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        min-height: 200px;
                                        max-height: none;
                                    }
                                    @media screen and (max-width: 420px) {
                                        //min-height: 180px;
                                        //max-height: 180px;
                                        padding: 10px 0 0 0;
                                        min-height: 0;
                                        max-height: none;
                                    }

                                    img {
                                        //position: absolute;
                                        //top: 5px;
                                        max-width: 170px;
                                        @media screen and (max-width: 1680px) {
                                            //top: 45px;
                                        }
                                        @media screen and (max-width: 1580px) {
                                            //top: 55px;
                                        }
                                        @media screen and (max-width: 1440px) {
                                            //top: 60px;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            //top: 22px;
                                        }
                                        @media screen and (max-width: 920px) {
                                            //top: 0px;
                                        }
                                        @media screen and (max-width: 768px) {
                                            position: relative;
                                            //top: 5px;
                                            opacity: 0.7;
                                        }
                                        @media screen and (max-width: 420px) {
                                            //top: 0;  
                                            max-width: 130px;                                      
                                        }
                                    }
                                }

                                .overlay {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInText;
                                    animation-name: fadeInText;
                                    height: auto;
                                    width: auto;
                                    animation-fill-mode: both;
                                    min-height: 100px;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0s;
                                    //    animation-duration: 0s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    height: auto;
                                    //    width: auto;
                                    //    animation-fill-mode: none;
                                    //    min-height: 0;
                                    //    visibility: hidden;
                                    //}
                                }

                                .sub-heading {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryHead;
                                    animation-name: fadeInUpCategoryHead;
                                    animation-fill-mode: forwards;
                                    position: relative;
                                    bottom: -100px;
                                    @media screen and (max-width: 1680px) {
                                        padding: 0 10px;
                                    }
                                    @media screen and (max-width: 1290px) {
                                        bottom: -91px;
                                    }
                                    @media screen and (max-width: 1040px) {
                                        bottom: -84px;
                                    }
                                    @media screen and (max-width: 1024px) {
                                        bottom: -101px;
                                    }
                                    @media screen and (max-width: 768px) {
                                        bottom: 0;
                                        margin: 0;
                                        padding: 0 20px;
                                        animation-delay: 0s;
                                        animation-duration: 0s;
                                        -webkit-animation-name: none;
                                        animation-name: none;
                                        height: auto;
                                        width: auto;
                                        animation-fill-mode: none;
                                    }
                                    &.multiline {
                                        bottom: -158px;
                                        @media screen and (max-width: 1290px) {
                                            bottom: -91px;
                                        }
                                        @media screen and (max-width: 768px) {

                                        }
                                        &.x {
                                            bottom: -214px;
                                        }
                                    }
                                }

                                img {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryImg;
                                    animation-name: fadeInUpCategoryImg;
                                    animation-fill-mode: forwards;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-duration: 0s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //    position: unset;
                                    //}
                                }

                            }
                        }

                        &.inactive {
                            .expand-item{
                                opacity: 1;
                                visibility: visible;
                                z-index: 15;
                            }
                            .subcategory-container {
                                .box-overlay {
                                    min-height: 90px;
                                    .trans(min-height .3s ease .3s);
                                    @media screen and (max-width: 1040px) {
                                        min-height: 70px;
                                        .trans(min-height 0s ease 0s);
                                    }
                                }

                                img {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategoryImg;
                                    animation-name: fadeInDownCategoryImg;
                                    animation-fill-mode: both;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0.5s;
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //}
                                }

                                .sub-heading {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategory;
                                    animation-name: fadeInDownCategory;
                                    animation-fill-mode: both;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-delay: 0.3s;
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: none;
                                    //}

                                }

                                .overlay {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeOut;
                                    animation-name: fadeOut;
                                    animation-fill-mode: forwards;
                                    //@media screen and (max-width: 1040px) {
                                    //    animation-duration: 0.3s;
                                    //    -webkit-animation-name: none;
                                    //    animation-name: none;
                                    //    animation-fill-mode: forwards;
                                    //}
                                    a {
                                        transition: none;
                                    }
                                }

                            }
                        }
                    }
                }
            }
            .products-header-top {
                .flex-container(row, nowrap);
                //.align-items(center);
                margin-bottom: 50px;

                .left {
                    width: 20%;
                    .zoom-box{
                        position: relative;
                    }
                    .zoom-box img{
                        vertical-align: bottom;
                    }
                    .zoom-box .zoom-selector{
                        position: absolute;
                        background-image: url("/assets/images/selector.png");
                        background-repeat: repeat;
                        display: none;
                        cursor: none;
                    }
                    .zoom-box .viewer-box{
                        position: absolute;
                        border: 1px solid rgb(239,237,240);
                        display: none;
                        overflow: hidden;
                        background-color: @white;
                        z-index: 99999;
                    }
                    .zoom-box .viewer-box>img{
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .right {
                    width: 88%;
                    padding-left:10px;

                    .heading {
                        margin-top: 30px;
                        h3 {
                            .font(@head-font, 40px, bold, 48px);
                            margin: 0;
                            @media screen and (min-width: 700px) and (max-width: 950px) {
                                .font(@head-font, 40px, bold, 78px);
                            }
                        }

                        .blue_border {
                            width: 145px;
                            border-bottom: 13px solid @mainblue;
                            margin: 10px 0;
                        }
                    }
                    .top-description{
                        font-size: 14px;
                        font-weight: 300;
                        font-family: 'Roboto', sans-serif;
                        line-height: 20px;
                        color: @dark;                        
                        display: flex;
                        justify-content: space-evenly;
                        h2{
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20px;
                            color: @mainblue;
                            font-family: 'Roboto', sans-serif;
                            margin: 15px 0 10px 0;
                            padding: 0;
                            text-transform: uppercase;
                            @media screen and (max-width: 900px) {
                                margin:30px 0 10px 0;
                            }
                        }
                        p{
                            font-size: 14px;
                            font-weight: 300;
                            font-family: 'Roboto', sans-serif;
                            line-height: 20px;
                            color: @dark;
                        }
                        ul{
                            li{
                                font-size: 14px;
                                font-weight: 300;
                                font-family: 'Roboto', sans-serif;
                                line-height: 22px;
                                color: @dark;
                                padding-left: 5px;
                                &::marker {
                                    content: "▶";
                                    font-size: 11px;
                                    color:@mainblue;
                                }
                            }
                            &.material-list {
                                li {
                                    &.brass {
                                        &::marker {
                                            content: url(/assets/images/bullets/brass.png);
                                        }
                                    }
                                    &.steel {
                                        &::marker {
                                            content: url(/assets/images/bullets/steel.png);
                                        }
                                    }
                                    &.ss316 {
                                        &::marker {
                                            content: url(/assets/images/bullets/316.png);
                                        }
                                    }
                                    &.ss303 {
                                        &::marker {
                                            content: url(/assets/images/bullets/303.png);
                                        }
                                    }
                                    &.col-polypro {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.col-pvdf {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.col-cpvc {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.col-iron {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.iron {
                                        &::marker {
                                            content: url(/assets/images/bullets/iron.png);
                                        }
                                    }
                                    &.nat-polypro {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.nat-pvdf {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.polypro {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.ptfe {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.pvc {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.pvdf {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &.abs {
                                        &::marker {
                                            content: url(/assets/images/bullets/bex.png);
                                        }
                                    }
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                            }
                        }
                        div {
                            &.left {
                                width: 100%;
                            }
                            &.right {
                                width: 100%;
                                @media screen and (max-width: 1290px) 
                                {
                                    margin-top:0;
                                }
                                @media screen and (max-width: 700px) 
                                {
                                    padding-left: 0;
                                    align-items: flex-start;
                                }
                            }
                        }
                        br{
                            display: block;
                        }
                        b{
                            font-family: 'Roboto', sans-serif;
                        }
                    }

                    .item-description {
                        .font(@font1, 20px, bold, 30px);
                    }

                    p {
                        .font(@font1, 20px, bold, 30px);
                        margin-top: 0;
                    }

                    ul {
                        li {
                            .font(@font1, 20px, bold, 30px);
                        }
                    }
                }
            }

            #product-filters{
                &.borders {
                    border-radius:5px;
                    -webkit-border-radius:5px;
                    -moz-border-radius:5px;
                    border: 1px solid @grey2;
                    box-shadow: 0 0 10px @grey2;
                }
                .spray-angle-variation{
                    min-width: 200px;
                    width: 30%;
                    @media screen and (max-width: 1290px) {
                        width:100%;
                    }
                }
                form{
                    .trigger{
                        display: none;
                    }
                }
            }

            #usage-filters{
                .industries-applications{
                    min-width: 200px;
                    width: 50%;
                    @media screen and (max-width: 1018px) {
                        width: 100%;
                        margin: 10px 0;
                    }
                }
                form{
                    .trigger{
                        display: none;
                    }
                }
            }

            .products-header-bottom {
                .flex-container(row, nowrap);
                .align-items(end);
                .justify-content(center);
                & > div{
                    margin-left: 15px;
                    @media screen and (max-width: 1290px) {
                        margin-left: 0px;
                    }
                    @media screen and (max-width: 1018px) {
                        margin: 10px 0;
                    }
                    &.spray-angle-variation {
                        margin-left:0;
                    }
                    &.product-table {
                        margin: auto;
                        width: 80%;
                    }    
                }

                .secondary-filters {
                    &.hide {
                        display: none;
                    }
                    &.show {
                        width: calc(50% - 5px);
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: end;
                        justify-content: center;
                        @media screen and (max-width: 1290px) {
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: row;
                            flex-flow: row wrap;
                            width: 100%;
                        }
                    }
                }

                .variation {
                    width: calc(50% - 5px);
                }
                .psi {
                    position: relative;
                    z-index: 1;
                    .trans(all 0.2s);

                    .select-btn {
                        cursor: pointer;
                        color: @select-text;
                        background: @lightgrey;
                        padding: 25px 25px 25px 25px;
                        appearance: none;
                        -moz-appearance: none; /* Firefox */
                        -webkit-appearance: none; /* Safari and Chrome */
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: normal;
                        background-image: linear-gradient(45deg, transparent 50%, @mainblue 50%), linear-gradient(135deg, @mainblue 50%, transparent 50%);
                        background-position: ~"calc(100% - 20px)" ~"calc(1.2em + 12px)", ~"calc(100% - 12px)" ~"calc(1.2em + 12px)";
                        background-size: 8px 8px, 8px 8px;
                        background-repeat: no-repeat;
                        width: 100%;
                        border: none;
                        margin-left:20px;

                        &.active {
                            color: @white;
                            background-color: @mainblue;
                            background-image: linear-gradient(135deg, transparent 50%, @white 50%), linear-gradient(45deg, @white 50%, transparent 50%);
                            background-size: 8px 8px, 8px 8px;
                            background-repeat: no-repeat;
                        }

                        @media screen and (max-width: 1290px) {
                            margin-left: 0px;
                        }
                    }

                    .psi-modal {
                        display: none; /* Hidden by default */
                        position: absolute;
                        z-index: 99;
                        padding: 20px 20px 10px 20px;
                        width: 164%;
                        //overflow: auto;
                        background-color: #000000;
                        background-color: #FFFFFF;
                        box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
                        animation-name: fadeInUpSD;
                        animation-duration: 0.3s;
                        @media screen and (max-width: 1290px) {
                            width: 110%;
                        }
                        @media screen and (max-width: 768px) {
                            width: 100%;
                        }

                        .psi-modal-content {
                            .form-top {
                                .flex-container(row, nowrap);
                                .justify-content(space-between);

                                .select-box {
                                    width: 38%;
                                    margin: 0 0 10px 0;
                                }

                                .input {
                                    .font(@font1, 16px, bold, 43px);
                                    width: 58%;
                                    border: 1px solid @table-border;
                                    text-align: center;
                                    color: @select-text;
                                    margin: 0 0 10px 0;

                                    &.placeholder-fix {
                                        &:focus::-webkit-input-placeholder {
                                            color: transparent;
                                        }

                                        &:focus::-moz-placeholder {
                                            color: transparent;
                                        }

                                        &:-moz-placeholder {
                                            color: transparent;
                                        }
                                    }
                                }
                                .custom-select-variation{
                                    .cs-options{
                                        padding: 25px 18px 25px 15px;
                                    }
                                }
                            }

                            .form-bottom {
                                &.buttons {
                                    .flex-container(row, nowrap);
                                    .justify-content(space-between);
                                    .align-items(baseline);
                                    margin: 15px 0;

                                    a {
                                        .font(@font1, 18px, bold, 20px);

                                        &.reset {
                                            color: @light-a;

                                            .material-icons {
                                                font-size: 18px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .product-table {
                .font(@font1, 16px, bold, 27px);
                margin: 10px 0;
                max-width: 100%;
                text-align: center;
                @media screen and (max-width: 992px) {
                    padding: 15px;
                }
                //overflow-x: auto;
                
                table {
                    width: 100%;
                    max-width: 100%;
                    border: 1px solid @table-border;
                    border-bottom: 0;
                    border-left: 0;

                    td {
                        border: 1px solid @table-border;
                        // padding: 12px 40px;
                        vertical-align: middle;
                        .font(@font1, 11px, bold, 57px);
                        text-align: center;
                        border-top: 0;
                        border-right: 0;
                    }

                    thead {
                        th {
                            border: 1px solid @table-border;
                            font-size: 11px;
                            font-weight: bold;
                            border-top: 0;
                            border-right: 0;
                            padding: 5px 10px;
                            line-height: 18px;
                            strong {
                                font-size: 11px;
                            }
                        }

                        tr {
                            background-color: @lightgrey;
                        }
                    }

                    tbody {
                        th {
                            border: 1px solid @table-border;
                            font-size: 13px;
                            font-weight: bold;
                            border-top: 0;
                            border-right: 0;
                            padding: 5px 10px;
                            line-height: 18px;
                            strong {
                                font-size: 13px;
                            }
                        }
                    }

                    &.table-striped {
                        tbody {
                            tr {
                                .trans(all 0.2s);

                                &:nth-of-type(even) {
                                    background-color: @lightgrey;
                                }

                                td {
                                    top: 0;
                                    left: 6px;
                                    width: 5%;
                                    padding-right: 10px;
                                    white-space: nowrap;
                                    content: attr(data-heading);
                                    &.primary-col{
                                        text-decoration: underline;
                                        text-underline-position: under;
                                        color: @mainblue;
                                        width: 15%;
                                    }
                                    &:first-child{
                                        text-decoration: underline;
                                        text-underline-position: under;
                                        color: @mainblue;
                                        width: 15%;
                                    }
                                    a {
                                        color: @mainblue;
                                        padding: 0 1px;
                                        .font(@font1, 11px, bold, 57px);
                                        text-decoration: underline;
                                        text-underline-position: under;
                                        strong {
                                            .font(@font1, 11px, bold, 57px);
                                        }
                                    }
                                    &:after {
                                        display: block;
                                        height: 2px;
                                        background: blue;
                                        margin-top: 20px;
                                    }

                                    padding: 0 10px;
                                }

                                &:hover {
                                    box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
                                    td {
                                        border: none;
                                        background: @white;
                                        cursor: pointer;
                                        .scale(1.1);
                                    }
                                }
                                &.active {
                                    box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
                                    td {
                                        border: none;
                                        background: @mainblue;
                                        cursor: pointer;
                                        .scale(1.1);
                                        a {
                                            color: @white !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mobile-table {
                        tr {
                            td {
                                line-height: 37px;
                                &:last-child {
                                    min-width: 35%;
                                }
                                a {
                                    color: @mainblue;
                                    padding: 0 15px;
                                    .font(@font1, 12px, bold, 57px);
                                    text-decoration: underline;
                                    text-underline-position: under;
                                }
                                &:after {
                                    display: block;
                                    height: 2px;
                                    background: blue;
                                    margin-top: 20px;
                                }
                            }
                            &.even {
                                background-color: @lightgrey;
                            }
                        }
                    }
                }
            }
        }
    }
}
.empty-alert{
    text-align: center;
    a{
        font-size: inherit;
    }
    &.hide {
        display: none;
    }
}