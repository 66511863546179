@fr_blue: #65B9E7;

@fr_900: 900px;
@fr_500: 500px;

// SVG Map only
svg.find-a-rep-svg{
    background: transparent;
    .region-labels{
        fill: white;
        cursor: default !important;
    }
    .region{
        cursor: pointer;
        fill: lighten(black, 10%);
        stroke: white;
        stroke-width: 0.5;
        // stroke-miterlimit: 10;
        fill-rule: evenodd;
        clip-rule: evenodd;
        &[data-url], &.has-url{
            cursor: pointer;
        }
        &:hover, &.active{
            &, .sub-region{
                fill: @fr_blue;
            }
        }
    }
}

// Map / Info Box
.find-a-rep-wrapper{
    // holds hidden data
    .fr-infos{
        display: none !important;
    }
    &.find-a-rep-wrapper-world{
        margin-top: 50px;
    }
    .fr-info-box{
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: @white;
        padding: 26px;
        .box-shadow(0 11px 67px 0 rgba(0, 0, 0, 0.2));
        .translate(-50%, 20px);
        &.active{
            display: block;
        }
        &.left-1{
            .translate(-10%, 20px);
        }
        &.right-1{
            .translate(-90%, 20px);
        }
        @media screen and (max-width: @fr_900){
            padding: 22px;
        }
        @media screen and (max-width: @fr_500){
            padding: 18px;
        }
        .fr-info{
            //width: 310px;
            @media screen and (max-width: @fr_900){
                width: 260px;
            }
            @media screen and (max-width: @fr_500){
                width: 240px;
            }
            &.fr-not-found{
                p{
                    margin: 0;
                    padding: 0;
                }
            }
            &.show-max-one{
                .counties .county{
                    display: none;
                    &.active{
                        display: block;
                    }
                }
            }
            &.show-multiple.count-many{
                width: 550px;
                padding: 15px;
                @media screen and (max-width: @fr_900){
                    width: 500px;
                    padding: 5px;
                }
                @media screen and (max-width: 700px){
                    width: 235px;
                }
                .counties{
                    width: 100%;
                    .flex-between(flex-start, wrap);
                    margin-bottom: -30px;
                    .county{
                        width: 45%;
                        margin-bottom: 30px;
                        @media screen and (max-width: 640px) {
                            width: 100%;
                        }
                    }
                }
            }
            .select-wrapper{
                margin-bottom: 22px;
                .custom-select-variation-styles{
                    .cs-options{
                        //overflow-y: auto;
                        ul{
                            max-height:220px;
                            @media screen and (max-width: 640px) {
                                max-height:120px;
                            }
                        }
                    }
                }
            }
            .counties{
                // min-height: 50px;
                .county{
                    p, h1, h2, h3, h4, h5, h6{
                        padding: 0;
                        margin: 0 0 5px 0;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    *{
                        font-weight: 300;
                        font-size: 14px;
                        // need large line height for <br>
                        line-height: 1.7em;
                    }
                    strong, .bold{
                        font-weight: 700;
                    }
                    .xl{
                        font-size: 18px;
                    }
                    .lg{
                        font-size: 16px;
                    }
                    .md{
                        font-size: 14px;
                    }
                    .spacer{
                        padding: 0;
                        margin: 0;
                        display: block;
                        width: 100%;
                        background: transparent;
                        border: none;
                        height: 10px;
                        &.spacer-lg{
                            height: 15px;
                        }
                    }
                    a{
                        color: @fr_blue;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    @media screen and (max-width: @fr_900){
                        .xl{
                            font-size: 16px;
                        }

                        .lg{
                            font-size: 14px;
                        }

                        .md{
                            font-size: 12px;
                        }
                    }
                    @media screen and (max-width: @fr_500){
                        .xl{
                            font-size: 14px;
                        }

                        .lg{
                            font-size: 12px;
                        }

                        .md{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

// Find a Rep Page Layout
body.find_a_rep{
    .page-outer-wrapper{
        margin-bottom: 0;
    }
    .inner-page{
        padding: 80px 0;
        width: 88%;
        max-width: 1125px;
        margin: 0 auto;
        .inner-content{
            width: 100%;
            max-width: 100%;
            .rep-text{
                text-align: center;
                p{
                    padding: 0;
                    font-size: 26px;
                    line-height: 1.25em;
                    margin: 0 0 12px 0;
                    *{
                        display: inline-block;
                        vertical-align: middle;
                    }
                    a{
                        color: @fr_blue;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            .find-a-rep{
                padding: 70px 0 120px;
                &.us{
                    cursor: pointer;
                    padding: 0 0 90px;
                }
            }
        }
    }
    #rep-ca {
        .fr-info {
            &.show-multiple-counties {
                &.count-many {
                    .counties {
                        display: flex;                        
                        width: 650px;
                        .county {
                            width: 33%;
                        }
                    }
                }
            }
        }
    }
}

