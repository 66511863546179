
//////**DASHBOARD************/
.inner-page{
    &#dashboard{
        padding:60px 0 130px 0;
        .inner-content{
            width: 80.5%;
            margin: 0 auto;
            .flex-container(row,nowrap);
            .justify-content(space-between);
            .sidebar{
                width:22%;
                .accordion
                {
                    margin: 0;
                    padding: 0;
                    ul, li, a, span {
                        margin: 0;
                        padding: 0;
                        border: none;
                        outline: none;
                    }
                    li {
                        margin: 0;

                        list-style: none;
                        &.has-sub {
                            a{

                                &:before {
                                    content: none;
                                }
                            }
                            &.open {
                                a{
                                    //color: @mainblue;
                                    &:before {
                                        content: none;
                                    }

                                }
                                .sub-menu {
                                    padding: 0 0 0 40px;
                                    li{
                                        &.has-sub {
                                            a {

                                                &:before {
                                                    content: none;
                                                }
                                            }
                                        }
                                        &.no-sub{
                                            a {

                                                &:before {
                                                    content: '';
                                                }
                                                padding: 0 0 0 15px;
                                            }
                                        }
                                        &.open {
                                            a{
                                                &:before {
                                                    content: none;
                                                }
                                            }
                                            .sub-menu {
                                                padding: 0 0 0 40px;
                                                li{
                                                    a{

                                                        &:before {
                                                            //   content: '+';
                                                        }

                                                    }
                                                }

                                            }
                                        }
                                    }

                                }
                            }
                        }

                        &.not-active {
                            a{color: @bgheaderdark;
                                &:before {
                                    //content: '+';
                                    color: @mainblue;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    .font(@font1, 25px, 700, 63px);
                                    //margin: 4px 8px;
                                    margin: -5px 0 0 0;
                                }
                            }
                        }
                        a {
                            display: block;
                            position: relative;
                            padding: 0 0 0 10px;
                            color:@bgheaderdark;
                            .font(@font1, 16px, 700, 66px);
                            .trans(all 0.2s);
                            &:before {
                                //content: '+';
                                color: @mainblue;
                                position: absolute;
                                top: 0;
                                left: 0;
                                .font(@font1, 25px, 700, 63px);
                                margin: -5px 0 0 0;
                            }
                            &:hover{
                                  color:@mainblue;
                              }

                        }

                    }
                    .sub-menu {
                        display: none;
                        li{
                            &.plus-icon {
                                a {

                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                            &.has-sub {
                                a {
                                    &:before {
                                        content: none;
                                    }
                                    font-size: 14px;
                                }

                                &.open {
                                    a{
                                        //color: @mainblue;
                                        &:before {
                                            content: none;
                                        }

                                    }
                                    .sub-menu {
                                        padding: 0 0 0 40px;
                                        li{
                                            &.has-sub {
                                                a {

                                                    &:before {
                                                        content: none;
                                                    }
                                                }

                                            }
                                            &.no-sub {
                                                a {

                                                    &:before {
                                                        content: '';
                                                    }
                                                }

                                            }
                                            &.plus-icon {
                                                a {

                                                    &:before {
                                                        content: none;
                                                    }
                                                }
                                            }

                                            &.open {
                                                a{
                                                    &:before {
                                                        content: none;
                                                    }
                                                }
                                                .sub-menu {
                                                    padding: 0 0 0 40px;

                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }

                }
            }
            .main-content{
                width: 78%;
                h2{font-family:@font1;font-size:26px;line-height: 32px;
                    span,a{
                        font-size:18px;
                    }
                }
                h3{font-family:@font1;font-size:20px;line-height: 30px;margin:0;
                }
                a{font-size:15px;line-height: 30px;font-weight: 600;}
                p{font-size:15px;line-height: 30px;font-weight: 600;}
                table {
                    &.order{
                        width: 100%;
                        max-width: 100%;
                        border: 1px solid @table-border;
                        td {
                            vertical-align: middle;
                            .font(@font1, 16px, bold, 30px);
                            text-align: left;
                            color: @darkcolor1;
                        }
                        thead {
                            th {
                                font-size: 12px;
                                font-weight: bold;
                                border-top: 0;
                                border-right: 0;
                                padding: 35px 0;
                                line-height: 18px;
                                text-align: left;
                                border-bottom: 1px solid @table-border;
                                width: 16%;
                                &:first-child {
                                    padding: 35px 0 35px 35px;
                                }
                            }
                            tr {
                                background-color: @lightgrey;
                            }
                        }
                        &.table-striped {
                            tbody {
                                tr {
                                    .trans(all 0.2s);
                                    &:nth-of-type(even) {
                                        background-color: @bg-table;
                                    }
                                    td {
                                        &:first-child {
                                            color: @mainblue;
                                            padding: 0 0 0 35px;
                                            text-decoration: underline;
                                            .font(@font1, 16px, bold, 57px);
                                            text-underline-position: under;
                                            a {
                                                .font(@font1, 16px, bold, 57px);
                                                text-decoration: underline;
                                                text-underline-position: under;
                                                color: @mainblue;
                                                .trans(all 0.2s);
                                                &:hover {
                                                    color: @darkblue;
                                                }
                                            }
                                            &:after {
                                                display: block;
                                                height: 2px;
                                                background: blue;
                                                margin-top: 20px;
                                            }
                                        }
                                        a {
                                            .font(@font1, 16px, bold, 57px);
                                            color: @darkcolor1;
                                            .trans(all 0.2s);
                                            &:hover {
                                                color: @select-text;
                                            }
                                        }
                                        padding: 2px 0px;
                                    }
                                }
                            }
                        }
                    }
                    &.order-details{
                        width: 100%;
                        max-width: 100%;
                        margin: 20px 0 30px 0;
                        tr {
                            th {
                                border-bottom: 2px solid @darkcolor1;
                                font-size: 18px;
                                font-weight: bold;
                                border-top: 0;
                                border-right: 0;
                                padding: 15px 0px;
                                line-height: 18px;
                                text-align: left;
                                &:nth-child(2){
                                    text-align: right;
                                }
                            }
                            td{
                                padding: 8px 0px;
                                    vertical-align: middle;
                                    text-align: left;
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 50px;
                                &:nth-child(2){
                                    text-align: right;
                                }
                            }
                        }
                    }
                    &.order_total{
                        margin: 0 0 35px 0;
                        width: 100%;
                        max-width: 100%;
                        tr{
                            th{
                                text-align: left;
                                strong {
                                    font-weight: bold;
                                    font-size: 18px;
                                    line-height: 18px;
                                }

                            }

                            td {
                                padding: 24px 0;
                                text-align: right;
                            }
                        }

                    }
                }
                a{
                    &.order-again{
                        .flex-container(row,nowrap);
                        .justify-content(flex-start);
                        font-size: 20px;
                        padding: 0 0 35px 0;
                        i {
                            &.fa-plus {
                                font-size: 16px;
                                padding: 5px;
                            }
                        }
                    }
                }
                .address{
                    width: 65%;
                    .flex-container(row,nowrap);
                    .justify-content(space-between);
                    h3{
                        padding: 20px 0 10px 0;
                    }
                    a{
                        text-decoration: underline;
                        text-underline-position: under;
                        font-size: 18px;
                    }
                    p{
                        &.btn-edit{
                            padding: 0;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        .flex-container(column,nowrap);
                    }
                }
                form{
                    //.custom-select-variation {
                    //    width: 100%;
                    //    &:focus {
                    //        outline: none;
                    //    }
                    //    select {
                    //        display: none;
                    //    }
                    //    span {
                    //        display: block;
                    //        position: relative;
                    //        cursor: pointer;
                    //        padding: 1em;
                    //        white-space: nowrap;
                    //        overflow: hidden;
                    //        text-overflow: ellipsis;
                    //        padding: 25px 25px 25px 25px;
                    //
                    //    }
                    //    .cs-placeholder {
                    //        border: 1px solid @table-border;
                    //        background-image: linear-gradient(45deg, transparent 50%, @mainblue 50%), linear-gradient(135deg, @mainblue 50%, transparent 50%);
                    //        background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                    //        background-size: 8px 8px, 8px 8px;
                    //        background-repeat: no-repeat;
                    //        font-family: Montserrat;
                    //        font-size: 16px;
                    //        font-weight: 600;
                    //        line-height: normal;
                    //        padding: 25px 25px 25px 25px;
                    //        &.selected {
                    //            color: @white;
                    //            background-color: @mainblue;
                    //            background-image: linear-gradient(45deg, transparent 50%, @white 50%), linear-gradient(135deg, @white 50%, transparent 50%);
                    //            background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                    //            background-size: 8px 8px, 8px 8px;
                    //            background-repeat: no-repeat;
                    //        }
                    //    }
                    //    &.cs-active {
                    //        z-index: 2;
                    //        .cs-placeholder {
                    //            background-color: @mainblue;
                    //            color: @white;
                    //            background-image: linear-gradient(135deg, transparent 50%, @white 50%), linear-gradient(45deg, @white 50%, transparent 50%);
                    //            background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                    //            background-size: 8px 8px, 8px 8px;
                    //            background-repeat: no-repeat;
                    //        }
                    //        .cs-options {
                    //            visibility: visible;
                    //            box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
                    //            pointer-events: auto;
                    //            animation-name: fadeInUpSD;
                    //            animation-duration: 0.3s;
                    //            ul {
                    //                li {
                    //                    -webkit-transform: translate3d(0, 0, 0);
                    //                    transform: translate3d(0, 0, 0);
                    //                    opacity: 1;
                    //                    -webkit-transition: none;
                    //                    transition: none;
                    //                    font-family: Montserrat;
                    //                    font-size: 16px;
                    //                    font-weight: 700;
                    //                    line-height: normal;
                    //                    &:hover {
                    //                        span {
                    //                            color: @bgheaderdark;
                    //                        }
                    //                    }
                    //                }
                    //            }
                    //        }
                    //    }
                    //    .cs-options {
                    //        position: absolute;
                    //        overflow: hidden;
                    //        width: 105%;
                    //        background: #ffffff;
                    //        visibility: hidden;
                    //        padding: 25px 30px 25px 30px;
                    //
                    //        color: @select-text;
                    //        ul {
                    //            li {
                    //                opacity: 0;
                    //                -webkit-transform: translate3d(0, -25px, 0);
                    //                transform: translate3d(0, -25px, 0);
                    //                -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
                    //                transition: opacity 0.15s, transform 0.15s;
                    //            }
                    //        }
                    //    }
                    //    ul {
                    //        list-style: none;
                    //        margin: 0;
                    //        padding: 0;
                    //        width: 100%;
                    //        span {
                    //            padding: 10px 0;
                    //            .font(@font1, 16px, bold, 43px);
                    //            color: @select-text;
                    //            .trans(all 0.2s);
                    //        }
                    //        li {
                    //            &.cs-focus {
                    //                span {
                    //                    background-color: #ddd;
                    //                }
                    //            }
                    //            &.cs-optgroup {
                    //                ul {
                    //                    padding-left: 1em;
                    //                }
                    //                span {
                    //                    cursor: default;
                    //                }
                    //            }
                    //        }
                    //    }
                    //
                    //}
                    //.form-row{
                    //    .flex-container(row,nowrap);
                    //    .justify-content(space-between);
                    //    padding: 0 0 20px 0;
                    //    .input-field{
                    //        .flex-container(column,nowrap);
                    //        .flex-grow(1);
                    //        &.two-section{
                    //          &:first-child{
                    //              margin: 0 20px 0 0;
                    //          }
                    //        }
                    //        label{
                    //            padding: 10px 0;
                    //             span{
                    //                 &.required{
                    //                     color:red;
                    //                 }
                    //             }
                    //        }
                    //        input[type=text] {
                    //            padding:25px 25px;
                    //            border: 1px solid @table-border;
                    //            color: @select-text;
                    //            font-family: Montserrat;
                    //            font-size: 16px;
                    //            font-weight: 600;
                    //            line-height: normal;
                    //            //width: 98%;
                    //        }
                    //        input[type=password] {
                    //            padding:25px 25px;
                    //            border: 1px solid @table-border;
                    //            color: @select-text;
                    //            font-family: Montserrat;
                    //            font-size: 16px;
                    //            font-weight: 600;
                    //            line-height: normal;
                    //            //width: 98%;
                    //        }
                    //        textarea{
                    //            padding:25px 25px;
                    //            border: 1px solid @table-border;
                    //            color: @select-text;
                    //            font-family: Montserrat;
                    //            font-size: 16px;
                    //            font-weight: 600;
                    //            line-height: normal;
                    //        }
                    //        ::placeholder {
                    //            color: @select-text;
                    //        }
                    //
                    //        :-ms-input-placeholder {
                    //            color: @select-text;
                    //        }
                    //
                    //        ::-ms-input-placeholder {
                    //            color: @select-text;
                    //        }
                    //    }
                    //    &.btn{
                    //        .justify-content(flex-end);
                    //        a{
                    //            font-size: 23px;
                    //            line-height: 22px;
                    //        }
                    //        padding: 20px 0;
                    //    }
                    //    @media screen and (max-width: 640px) {
                    //        .flex-container(column,nowrap);
                    //        .input-field{
                    //            &.two-section{
                    //                &:first-child{
                    //                    margin: 0;
                    //                }
                    //            }
                    //        }
                    //        &.btn{
                    //            .align-items(flex-end);
                    //        }
                    //    }
                    //}
                }
            }
        }
        @media screen and (max-width: 1700px) {
            .inner-content{
                width:84.5%;
            }
        }
        @media screen and (max-width: 1480px) {
            .inner-content{
                width:90%;
            }
        }
        @media screen and (max-width: 1400px) {
            .inner-content{
                width:100%;
            }
        }
        @media screen and (max-width: 950px) {
            .inner-content{
                .sidebar{
                    width: 35%;
                }
                .main-content{
                    width: 65%;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .inner-content{
                .main-content{
                    table{
                        &.order{
                            display: block;
                            td{
                                display: block;

                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }
                            thead {
                                th {
                                    &:first-child {

                                    }
                                }
                                tr {
                                    position: absolute;
                                    top: -9999px;
                                    left: -9999px;	display: block;
                                }
                            }
                            &.table-striped {
                                tbody {

                                    display: block;
                                    tr {
                                        display: block;
                                        border-bottom: 1px solid @lightgrey;padding: 20px 30px;
                                        td {
                                            padding: 2px 0 2px 50%;
                                            border-bottom: none;

                                            &:before {
                                                position: absolute;
                                                top: 6px;
                                                left: 6px;
                                                width: 5%;
                                                padding-right: 10px;
                                                white-space: nowrap;
                                            }
                                            &:nth-of-type(1){
                                                padding: 2px 0 2px 50%;
                                                text-align: right;
                                                &:before { content: "Order #";color:@darkcolor1;   text-align: left;
                                                }
                                            }
                                            &:nth-of-type(2){  text-align: right;
                                                &:before { content: "Status";text-align: left;
                                                }
                                            }
                                            &:nth-of-type(3){ text-align: right;
                                                &:before { content: "Date";text-align: left;
                                                }
                                            }
                                            &:nth-of-type(4){ text-align: right;
                                                &:before { content: "Total";text-align: left;
                                                }
                                            }
                                            &:nth-of-type(5){ text-align: right;
                                                &:before { content: "Actions";text-align: left;
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.admin {
            width: 100%;
            .inner-content {
                width: 100%;
                margin: 0;
                .sidebar{
                    ul{
                        background:@lightgrey;
                        padding: 0 30px;
                        margin: 0 60px 0 0;
                        min-height: 700px;
                        &.admin-sidebar{
                                    li{
                                        .trans(all 0.2s);
                                        .circle{
                                            border-radius: 50%;
                                            //behavior: url(PIE.htc);
                                            ///* remove if you don't care about IE8 */
                                            width: 36px;
                                            height: 36px;
                                            padding: 8px;
                                            background: red;
                                            color:@white;
                                            text-align: center;
                                            opacity: 0.8;
                                            margin: 0 0 0 10px;
                                        }
                                        .flex-container(row,nowrap);
                                        .align-items(center);
                                        padding:15px 0;
                                        .material-icons{margin:0 10px 0 0;

                                        }
                                        a{

                                            padding: 0;
                                        }
                                        &:hover{
                                            .material-icons,a{
                                                color:@mainblue;
                                            }
                                        }
                                    }
                        }
                    }


                }
                .pages{
                    width:70%;
                    padding: 30px 0;
                    .flex-container(column,nowrap);
                    .page-item{
                        .flex-container(row,nowrap);
                        .justify-content(space-between);
                        background:@lightgrey;
                        padding: 30px;
                        margin:0 0 20px 0;
                        a{
                            text-underline-position: under;
                            text-decoration: underline;
                        }
                    }
                }
                .inner-content-admin{
                    width:90%;
                    position: relative;
                    form{
                        margin:50px 0;
                        input[type=file]{
                            position: absolute;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            height: 100%;
                            outline: none;
                            opacity: 0;
                        }
                        input[type=checkbox]{
                           margin: 0 10px 0 0;
                        }
                        .form-row{
                            position: relative;
                            .input-field{

                                .uploader{
                                    p{
                                        width: 100%;
                                        height: 100%;
                                        text-align: center;
                                        line-height: 100px;
                                        font-size: 18px;
                                            //background:@lightgrey url('../images/drop.png') no-repeat center center;
                                    }
                                    position: relative;
                                    top:0;
                                    background:@lightgrey;
                                    width: 100%;
                                    height: 250px;
                                }
                            }
                        }
                    }
                    .row-item{
                        .flex-container(row,nowrap);
                        .align-items(center);
                        margin: 50px 0;
                        .success{
                            color:green;
                            padding: 0;
                        }
                        .first-item{
                            margin: 0 50px 0 0;
                        }
                        a{
                            font-size: 25px;
                        }
                        .search{
                            input[type=text] {
                                padding:25px 25px;
                                border: 1px solid @table-border;
                                color: @select-text;
                                font-family: Montserrat;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: normal;
                                margin:0 10px 0 0;
                            }
                        }
                        .status{
                            width:18%;
                        }
                        //.custom-select-variation {
                        //    width: 100%;
                        //    &:focus {
                        //        outline: none;
                        //    }
                        //    select {
                        //        display: none;
                        //    }
                        //    span {
                        //        display: block;
                        //        position: relative;
                        //        cursor: pointer;
                        //        padding: 1em;
                        //        white-space: nowrap;
                        //        overflow: hidden;
                        //        text-overflow: ellipsis;
                        //        padding: 25px 25px 25px 25px;
                        //
                        //    }
                        //    .cs-placeholder {
                        //        border: 1px solid @table-border;
                        //        background-image: linear-gradient(45deg, transparent 50%, @mainblue 50%), linear-gradient(135deg, @mainblue 50%, transparent 50%);
                        //        background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                        //        background-size: 8px 8px, 8px 8px;
                        //        background-repeat: no-repeat;
                        //        font-family: Montserrat;
                        //        font-size: 16px;
                        //        font-weight: 600;
                        //        line-height: normal;
                        //        padding: 25px 25px 25px 25px;
                        //        &.selected {
                        //            color: @white;
                        //            background-color: @mainblue;
                        //            background-image: linear-gradient(45deg, transparent 50%, @white 50%), linear-gradient(135deg, @white 50%, transparent 50%);
                        //            background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                        //            background-size: 8px 8px, 8px 8px;
                        //            background-repeat: no-repeat;
                        //        }
                        //    }
                        //    &.cs-active {
                        //        z-index: 2;
                        //        .cs-placeholder {
                        //            background-color: @mainblue;
                        //            color: @white;
                        //            background-image: linear-gradient(135deg, transparent 50%, @white 50%), linear-gradient(45deg, @white 50%, transparent 50%);
                        //            background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 12px) calc(1em + 12px);
                        //            background-size: 8px 8px, 8px 8px;
                        //            background-repeat: no-repeat;
                        //        }
                        //        .cs-options {
                        //            visibility: visible;
                        //            box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
                        //            pointer-events: auto;
                        //            animation-name: fadeInUpSD;
                        //            animation-duration: 0.3s;
                        //            ul {
                        //                li {
                        //                    -webkit-transform: translate3d(0, 0, 0);
                        //                    transform: translate3d(0, 0, 0);
                        //                    opacity: 1;
                        //                    -webkit-transition: none;
                        //                    transition: none;
                        //                    font-family: Montserrat;
                        //                    font-size: 16px;
                        //                    font-weight: 700;
                        //                    line-height: normal;
                        //                    &:hover {
                        //                        span {
                        //                            color: @bgheaderdark;
                        //                        }
                        //                    }
                        //                }
                        //            }
                        //        }
                        //    }
                        //    .cs-options {
                        //        position: absolute;
                        //        overflow: hidden;
                        //        width: 105%;
                        //        background: #ffffff;
                        //        visibility: hidden;
                        //        padding: 25px 30px 25px 30px;
                        //
                        //        color: @select-text;
                        //        ul {
                        //            li {
                        //                opacity: 0;
                        //                -webkit-transform: translate3d(0, -25px, 0);
                        //                transform: translate3d(0, -25px, 0);
                        //                -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
                        //                transition: opacity 0.15s, transform 0.15s;
                        //            }
                        //        }
                        //    }
                        //    ul {
                        //        list-style: none;
                        //        margin: 0;
                        //        padding: 0;
                        //        width: 100%;
                        //        span {
                        //            padding: 10px 0;
                        //            .font(@font1, 16px, bold, 43px);
                        //            color: @select-text;
                        //            .trans(all 0.2s);
                        //        }
                        //        li {
                        //            &.cs-focus {
                        //                span {
                        //                    background-color: #ddd;
                        //                }
                        //            }
                        //            &.cs-optgroup {
                        //                ul {
                        //                    padding-left: 1em;
                        //                }
                        //                span {
                        //                    cursor: default;
                        //                }
                        //            }
                        //        }
                        //    }
                        //
                        //}
                    }
                    table{
                        &.users{
                            width: 100%;
                            max-width: 100%;
                            border: 1px solid @table-border;
                            margin:50px 0;
                            td {
                                vertical-align: middle;
                                .font(@font1, 14px, bold, 57px);
                                text-align: left;
                                color: @darkcolor1;
                            }
                            thead {
                                th {
                                    font-size: 13px;
                                    font-weight: bold;
                                    border-top: 0;
                                    border-right: 0;
                                    padding: 30px 10px;
                                    line-height: 18px;
                                    text-align: left;
                                    border-bottom: 1px solid @table-border;
                                }
                                tr {
                                    background-color: @lightgrey;
                                }
                            }
                            &.table-striped {
                                tbody {
                                    tr {
                                        .trans(all 0.2s);
                                        &:nth-of-type(even) {
                                            background-color: @bg-table;
                                        }
                                        td {
                                            a {
                                                .font(@font1, 16px, bold, 57px);
                                                color: @darkcolor1;
                                                .trans(all 0.2s);
                                                &:hover {
                                                    color: @select-text;
                                                }
                                            }
                                            span{
                                                &.delete-btn{
                                                    .font(@font1, 16px, bold, 57px);
                                                    color: @darkcolor1;
                                                    .trans(all 0.2s);
                                                    cursor: pointer;
                                                    &:hover {
                                                        color: @select-text;
                                                    }
                                                }

                                            }
                                            padding: 2px 10px;
                                        }
                                    }
                                }
                            }
                        }
                        &.order-details{
                            &.admin-order-details{
                                padding: 50px 0;
                                tr{
                                    th{
                                        border: none;
                                        &:nth-child(1){
                                            padding: 0 20px;
                                        }
                                    }
                                    td{
                                        background:@lightgrey;
                                        width:10%;
                                        &:nth-child(1){
                                            width:10%;
                                            .product-outer{max-height: 85px;
                                                width: auto;
                                                img{margin: 0 0 0 -20px}
                                            }
                                        }
                                        &:nth-child(2){
                                            width:60%;
                                            text-align: left;
                                        }

                                    }
                                }
                            }

                        }
                        &.order_total{
                            &.order_total_admin{
                                width:15%;
                                padding: 50px 0;
                                tr{
                                    td{
                                        padding: 10px 0;
                                    }
                                }
                            }
                        }
                    }
                    .order-section-top{
                        padding: 40px;
                        background: @lightgrey;
                        h3{
                            font-size: 18px;
                        }
                        .three-section{
                            padding: 40px 0 0 0;
                            .flex-container(row,nowrap);
                            .justify-content(space-between);
                            .section-item{
                                .flex-container(column,nowrap);

                                p{
                                    &.head{
                                        color:@select-text;
                                        margin: 0;
                                    }
                                    &.text{
                                        font-size: 18px;
                                        font-weight: bold;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                    .delete-modal{
                        display: none;
                        position: absolute;
                        z-index: 1;
                        left: 25%;
                        width: 60%;
                        height:auto;
                        overflow: auto;
                        .box-shadow(0 11px 67px 0 rgba(0, 0, 0, 0.2));
                        padding: 50px 30px;
                        p{
                            &.alert{
                                color:red;
                            }
                        }
                        .row-item{
                            .justify-content(flex-end);
                            span{
                                &.cancel{
                                    color:@darkcolor1;
                                    font-size:18px;
                                    margin: 0 20px 0 0;
                                    cursor: pointer;
                                }
                            }


                        }
                    }
                    ul{
                        padding: 50px 0;
                        margin: 0;
                        &.pagination{
                            list-style: none;
                            .flex-container(row,nowrap);
                            a{    padding: 8px 16px;
                                color: @darkcolor1;
                                &:hover{
                                    color: @select-text;
                                }
                                &.active{
                                    color: @mainblue;
                                }
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1290px) {
                    .inner-content{
                        .sidebar{
                            width:30%;
                        }
                        .main-content{
                            width:70%;
                        }
                        .inner-content-admin {
                            table{
                                &.order{
                                    display: block;
                                    td{
                                        display: block;

                                        border: none;
                                        border-bottom: 1px solid #eee;
                                        position: relative;
                                        padding-left: 50%;
                                    }
                                    thead {
                                        th {
                                            &:first-child {

                                            }
                                        }
                                        tr {
                                            position: absolute;
                                            top: -9999px;
                                            left: -9999px;	display: block;
                                        }
                                    }
                                    &.table-striped {
                                        tbody {

                                            display: block;
                                            tr {
                                                display: block;
                                                border-bottom: 1px solid @lightgrey;padding: 20px 30px;
                                                td {
                                                    padding: 2px 0 2px 50%;
                                                    border-bottom: none;

                                                    &:before {
                                                        position: absolute;
                                                        top: 6px;
                                                        left: 6px;
                                                        width: 5%;
                                                        padding-right: 10px;
                                                        white-space: nowrap;
                                                    }
                                                    &:nth-of-type(1){
                                                        padding: 2px 0 2px 50%;
                                                        text-align: right;
                                                        &:before { content: "Order #";color:@darkcolor1;   text-align: left;
                                                        }
                                                    }
                                                    &:nth-of-type(2){  text-align: right;
                                                        &:before { content: "Status";text-align: left;
                                                        }
                                                    }
                                                    &:nth-of-type(3){ text-align: right;
                                                        &:before { content: "Date";text-align: left;
                                                        }
                                                    }
                                                    &:nth-of-type(4){ text-align: right;
                                                        &:before { content: "Total";text-align: left;
                                                        }
                                                    }
                                                    &:nth-of-type(5){ text-align: right;
                                                        &:before { content: "Actions";text-align: left;
                                                        }
                                                    }
                                                    &:nth-of-type(6){
                                                        text-align: right;
                                                        &:before{
                                                            content: "View";
                                                            text-align: left;
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
            }
            @media screen and (max-width: 950px) {
                .inner-content{
                    .sidebar{
                        width:88%;
                        margin: 0 auto;
                        ul{
                            margin: 0;
                        }
                    }
                    .main-content{
                        width:88%;
                        margin: 0 auto;
                        padding: 50px 0 0;
                    }
                    .pages{
                        width:100%;
                    }
                    .inner-content-admin {
                        width:100%;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                .inner-content{
                    .inner-content-admin{
                        .row-item{
                            .flex-container(column,wrap);
                            .align-items(flex-start);
                            .first-item{
                                margin:0 0 40px 0;
                            }
                            .status{
                                width: 80%;
                            }
                        }
                        .delete-modal{
                            position:relative;
                            left: 0;
                            width: 100%;
                            .row-item{
                                .flex-container(row,wrap);
                                .align-items(center);
                            }
                        }
                        form{
                            .row-item{
                                .flex-container(row,wrap);
                                .align-items(center);
                            }
                        }
                        table{
                            &.order{
                                &.table-striped{
                                    tbody{
                                        tr{
                                            td{
                                                padding: 2px 0;
                                            }
                                        }
                                    }
                                }
                            }
                            &.manage-users{
                                display: block;
                                td{
                                    display: block;

                                    border: none;
                                    border-bottom: 1px solid #eee;
                                    position: relative;
                                    padding-left: 50%;
                                }
                                thead {
                                    tr {
                                        position: absolute;
                                        top: -9999px;
                                        left: -9999px;	display: block;
                                    }
                                }
                                &.table-striped {
                                    tbody {

                                        display: block;
                                        tr {
                                            display: block;
                                            border-bottom: 1px solid @lightgrey;
                                            padding: 20px 10px;
                                            td {
                                                border-bottom: none;

                                                &:before {
                                                    position: absolute;
                                                    top: 6px;
                                                    left: 6px;
                                                    width: 5%;
                                                    padding-right: 10px;
                                                    white-space: nowrap;
                                                }
                                                &:nth-of-type(1){
                                                    padding: 2px 0 2px 50%;
                                                    text-align: right;
                                                    &:before { content: "Username";color:@darkcolor1;   text-align: left;
                                                    }
                                                }
                                                &:nth-of-type(2){  text-align: right;
                                                    &:before { content: "Email";text-align: left;
                                                    }
                                                }
                                                &:nth-of-type(3){ text-align: right;
                                                    &:before { content: "Status";text-align: left;
                                                    }
                                                }
                                                &:nth-of-type(4){ text-align: right;
                                                    &:before { content: "Action";text-align: left;
                                                    }
                                                }

                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 640px) {
                .inner-content{
                    .main-content{
                        form{
                            .form-row{
                                .input-field{
                                    &.two-section{
                                        &:first-child{
                                            margin: 0 0 20px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .inner-content-admin{
                        .row-item{
                            .search{
                                input[type=text] {
                                    padding: 25px 15px;
                                }
                            }
                            .status{
                                width:70%;
                                .custom-select-variation{
                                    .cs-placeholder{
                                        padding: 25px 15px;
                                    }
                                }
                            }
                        }
                        .order-section-top{
                            .three-section{
                                .flex-container(column,wrap);
                            }
                        }
                        table{
                            &.order_total{
                                &.order_total_admin{
                                    width:50%;
                                }
                            }

                                &.order{
                                    &.table-striped{
                                        tbody{
                                            tr{
                                                padding: 20px 10px;
                                                td{
                                                    font-size: 14px;
                                                    padding: 2px 0;
                                                    &:nth-of-type(5){
                                                        a{
                                                            font-size: 10px;
                                                        }
                                                    }
                                                    &:before{
                                                        font-size: 14px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.users{

                                }

                        }
                        ul{
                            &.pagination{
                               li {
                                   a {
                                       padding: 8px 5px;
                                   }
                               }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1700px) {
    section{
        &.dashboard{
            .page-outer-wrapper{
                .page-title-wrapper{
                    .page-title{
                        width:84.5%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1480px) {
    section{
        &.dashboard{
            .page-outer-wrapper{
                .page-title-wrapper{
                    .page-title{
                        width:90%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1400px) {
    section{
        &.dashboard{
            .page-outer-wrapper{
                .page-title-wrapper{
                    .page-title{
                        width:100%;
                    }
                }
            }
        }
    }
}
