#technical-item {
    & .inner-page {
        & .inner-content {
            width: 80.5%;
            margin: 0 auto;
            @media screen and (max-width: 1700px) {
                width: 84.5%;
            }
            @media screen and (max-width: 1480px) {
                width: 90%;
            }
            @media screen and (max-width: 1400px) {
                width: 100%;
            }
            .search-section {
                h3 {
                    font-size: 31px;
                    margin: 0;
                    line-height: normal;
                    padding: 20px 0 0 0;
                }
                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 30px;
                }
                .search-keywords {
                    width: 78%;
                    .flex-container(row, nowrap);
                    .justify-content(space-between);
                    .align-items(center);
                    .material-icons {
                        font-size: 25px;
                        line-height: 33px;
                    }
                    .custom-select-variation {
                        .cs-placeholder {
                            border: none;
                            background-color: @lightgrey;
                            width: 400px;
                            @media screen and (max-width: 1735px) {
                                width: 360px;
                            }
                            @media screen and (max-width: 1700px) {
                                width: 340px;
                            }
                            &.selected {
                                color: @white;
                                background-color: @mainblue;
                            }
                        }
                        &.cs-active {
                            .cs-placeholder {
                                background-color: @mainblue;
                                color: @white;
                            }
                        }
                    }
                    & .buttons {
                        & .form-row {
                            padding: 0;
                        }
                    }
                }
            }
            .product-category {
                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 30px;
                }
                .item-bottom {
                    .flex-container(row, wrap);
                    margin: 40px 0;
                    .subcategory {
                        padding: 60px 0 30px 0;
                        position: relative;
                        width: 21.3%;
                        &:not(:last-child) {
                            margin-right: 4%;
                        }
                        .subcategory-container {
                            .flex-container(column, nowrap);
                            position: relative;
                            .justify-content(center);
                            .box-overlay {
                                min-height: 90px;
                                .trans(min-height .5s ease .3s);
                                position: absolute;
                                width: 100%;
                                z-index: -9999;
                                overflow: hidden;
                            }
                            .subcategory-container-wrapper {
                                width: 100%;
                                min-height: 170px;
                                max-height: 200px;
                                .align-self(center);
                                .justify-content(center);
                                padding: 10px 0 0 0;

                            }
                            .overlay {
                                visibility: hidden;
                                width: 100%;
                            }
                            .text {
                                color: @bgheaderdark;
                                position: absolute;
                                top: -20%;
                                left: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                padding: 25px 25px 25px 25px;
                                width: 100%;
                                @media screen and (max-width: 1735px) {
                                    top: -15%;
                                }
                                @media screen and (max-width: 1600px) {
                                    top: -5%;
                                }
                                p {
                                    .font(@font1, 16px, 600, 22px);
                                }
                                .btn {
                                    font-size: 16px;
                                    line-height: 30px;
                                }
                            }
                            & .box {
                                @media screen and (max-width: 1600px) {
                                    .trans(margin 0.3s ease-in-out 0.3s);
                                    margin: 0;
                                }
                            }
                            .sub-heading {
                                padding: 0 0 0 25px;
                                margin: -55px 0 0 0;
                                .head {
                                    color: @product-head;
                                    .font(@head-font, 26px, bold, 26px);
                                    @media screen and (max-width: 1735px) {
                                        font-size: 24px;
                                        line-height: 24px;
                                    }
                                    span {
                                        .font(@head-font, 26px, bold, 26px);
                                        @media screen and (max-width: 1735px) {
                                            font-size: 24px;
                                            line-height: 24px;
                                        }
                                    }
                                }
                                .blue_border {
                                    border-bottom: 16px solid @mainblue;
                                    width: 116px;
                                    margin: 15px 0 0 0;
                                }
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            .subcategory-container {
                                .box-overlay {
                                    min-height: 220px;
                                    .trans(min-height .3s ease .3s);
                                    @media screen and (max-width: 1735px) {
                                        min-height: 217px;
                                    }
                                    @media screen and (max-width: 1600px) {
                                        min-height: 235px;
                                    }
                                }
                                .subcategory-container-wrapper {
                                    max-height: unset;
                                    min-height: 100px;
                                    position: relative;
                                }
                                .overlay {
                                    animation-delay: 0.8s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInText;
                                    animation-name: fadeInText;
                                    height: auto;
                                    width: auto;
                                    animation-fill-mode: both;
                                    min-height: 100px;
                                }
                                & .box {
                                    @media screen and (max-width: 1600px) {
                                        margin: -20px 0 0 0;
                                        transition: margin 0.3s ease 0.4s;
                                    }
                                }
                                .sub-heading {
                                    animation-delay: 0.3s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryHead;
                                    animation-name: fadeInUpCategoryHead;
                                    animation-fill-mode: forwards;
                                    position: relative;
                                    bottom: -100px;
                                }
                                img {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInUpCategoryImg;
                                    animation-name: fadeInUpCategoryImg;
                                    animation-fill-mode: forwards;
                                    position: absolute;
                                }

                            }
                        }
                        &.inactive {
                            .subcategory-container {
                                .box-overlay {
                                    min-height: 90px;
                                    .trans(min-height .3s ease .3s);
                                }
                                img {
                                    animation-delay: 0.5s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategoryImg;
                                    animation-name: fadeInDownCategoryImg;
                                    animation-fill-mode: both;
                                }
                                .sub-heading {
                                    animation-delay: 0.3s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeInDownCategory;
                                    animation-name: fadeInDownCategory;
                                    animation-fill-mode: both;

                                }
                                .overlay {
                                    //   animation-delay: 0s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: fadeOut;
                                    animation-name: fadeOut;
                                    animation-fill-mode: forwards;
                                    a {
                                        .trans(none);
                                    }
                                }
                            }
                        }
                    }
                }
                .product-img {
                    padding: 20px 0 90px 0;
                    img {
                        .box-shadow(-37px 9px 59px 0px rgba(0, 0, 0, 0.13));
                    }
                }
            }
            & .content-section {
                & p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 30px;
                    & a {
                        font-size: 14px;
                        line-height: 30px;
                    }
                }
                & h2 {
                    font-size: 26px;
                    line-height: 32px;
                    font-weight: bold;
                    font-style: italic;
                    font-family: @font1;
                }
            }
            & ul {
                padding-bottom: 10px;
                & li {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 10px;
                    & a {
                        line-height: 22px;
                    }
                    & ul{
                        padding-top: 20px;
                    }
                }
            }
            & p {
                font-size: 14px;
                line-height: 26px;
                font-weight: 400;
                & i{
                    font-weight: inherit;
                }
                & a {
                    font-size: inherit;
                }
                &.center{
                    text-align: center;
                }
            }
            .tabs {
                .flex-container(column, nowrap);
                padding: 0 0 50px 0;
                .ui-accordion {
                    width: 100%;
                    position: relative;
                    h3 {
                        background: none;
                        border: none;
                        border-radius: 0;
                        margin: 0 0 30px 0;
                        .box-shadow(1px 1px 20px -7px rgba(0, 0, 0, 0.2));
                        color: @product-head;
                        cursor: pointer;
                        width: 100%;
                        .font(@head-font, 31px, bold, 56px);
                        padding: 35px 20% 35px 4%;
                        background-repeat: no-repeat;
                        background-image: url(../images/baseline-expand_more-24px.svg);
                        background-position: 96% 50%;
                        position: relative;
                        @media screen and (max-width: 639px) {
                            line-height: 1.5em;
                            padding-top: 20px;
                            padding-bottom: 20px;
                        }
                        @media screen and (max-width: 420px) {
                            font-size: 24px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                        &.ui-accordion-header-active {
                            background-repeat: no-repeat;
                            background-image: url(../images/baseline-chevron_right-24px.svg);
                            background-position: 96% 50%;
                        }
                        .ui-icon {
                            background: none;
                        }
                    }
                    .ui-accordion-content {
                        padding: 20px 7% 70px;;
                        position: relative;
                        top: -40px;

                        &.ui-accordion-content-active {
                            .box-shadow(0px 13px 20px -10px rgba(0, 0, 0, 0.2));
                            border: none;
                        }
                    }
                    & ul {
                        & li {
                            font-weight: 400;
                        }
                    }
                }
                & .centered{
                    text-align: center;
                }
                & .thumb-cols {
                    .flex-container(row, wrap);
                    .justify-content(space-around);
                    margin-bottom: 40px;
                    list-style: none;
                    padding: 0;
                    & li {
                        margin-top: 20px;
                        & .col-title {
                            font-size: 24px;
                            line-height: 30px;
                            text-align: center;
                            padding: 5px;
                            margin: 10px 0;
                            @media screen and (max-width: 639px) {
                                font-size: 18px;
                                line-height: 24px;
                                margin: 0;
                            }
                        }
                    }
                    &.twice{
                        & li{
                            width: 45%;
                            @media screen and (max-width: 639px) {
                                width: 100%;
                            }
                        }
                    }
                    &.thrice {
                        & li {
                            width: 30%;
                            @media screen and (max-width: 960px) {
                                width: 45%;
                            }
                            @media screen and (max-width: 639px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            & .sub-item {
                & .sub-title {
                    color: @mainblue;
                }
                & h2 {
                    font-size: 30px;
                    line-height: 1.5em;
                    @media screen and (max-width: 639px) {
                        font-size: 24px;
                    }
                }
                & h4 {
                    font-size: 26px;
                    line-height: 1.5em;
                    @media screen and (max-width: 639px) {
                        font-size: 20px;
                    }
                    &.item-title{
                        margin: 10px 0;
                    }
                }
            }
            & .sweeping-section{
                & h4{
                    font-size: 24px;
                    line-height: 1.5em;
                    text-align: center;
                    padding: 10px 0;
                    margin: 0;
                    color: @mainblue;
                }
            }
            & table {
                width: 100%;
                border: 1px solid;
                border-collapse: collapse;
                & thead {
                    & th {
                        font-weight: 700;
                        text-align: center;
                        border-left: 1px solid;
                        &:first-child {
                            border-left: none;
                        }
                    }
                }
                & tbody {
                    & td {
                        border-top: 1px solid;
                        border-left: 1px solid;
                        &:first-child {
                            border-left: none;
                        }
                    }
                }
                & tr {
                    & td,
                    & th {
                        padding: 5px 10px;
                    }
                }
            }
        }
        & img {
            max-width: 100%;
            height: auto;
        }
        & .cols {
            .flex-container(row);
            .justify-content(space-between);
            margin-bottom: 40px;
            &.nopadding{
                margin-bottom: 0px;
            }
            @media screen and (max-width: 960px) {
                .flex-container(column, wrap);
            }
            @media screen and (max-width: 639px) {
                margin-bottom: 20px;
                &.nopadding{
                    margin-bottom: 0px;
                }
            }
            &.column-reverse{
                .flex-container(row-reverse);
                @media screen and (max-width: 960px) {
                    .flex-container(column, wrap);
                }
            }
            &.center {
                .align-items(center);
            }
            & .col {
                width: 48%;
                @media screen and (max-width: 960px) {
                    width: 100%;
                }
            }
            &.three-cols {
                & .col {
                    width: 30%;
                    @media screen and (max-width: 960px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}