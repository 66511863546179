html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var,
b, u, i, center, hr, input, textarea,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, main,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, select{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    outline: 0;
    font-family: @font1;
}

a{
    text-decoration: none;
    color: @mainblue !important;
    .font(@font1bold, 16px, bold, 20px);
    .trans(all 0.2s);
    &:hover{
        cursor: pointer;
        color: @darkblue;

    }
}

img{
    max-width: 100%;
}

p{
    font-family: @font1;
    font-weight: normal;
    padding-bottom: 10px;
}

fieldset {
    border: none;
}

.font-face(@font-family, @file-path){
    @font-face{
        font-family: @font-family;
        src: url('@{file-path}.eot');
        src: url('@{file-path}.eot?#iefix') format('embedded-opentype'),
        url('@{file-path}.woff') format('woff'),
        url('@{file-path}.otf') format('truetype'),
        url('@{file-path}.svg#@{file-path}') format('svg');
    }
}

/**
 * @font-face LESS Mixin
 * use: .font-face(
 *   @font-family, // name
 *   @file-path,   // absolute/relative URL to font files
 *   @font-weight, // light/normal/bold/inherit | 300/400/700
 *   @font-style   // italic/normal/inherit
 * )
 */
.font(@font-family, @font-size, @font-weight, @line-height){
    font-family: @font-family;
    font-size: @font-size;
    font-weight: @font-weight;
    line-height: @line-height;
}

// ************************************************
//         Transform, Scale, Transition etc.
// ************************************************

.scale(@amount){
    -webkit-transform: scale(@amount);
    -moz-transform: scale(@amount);
    -o-transform: scale(@amount);
    -ms-transform: scale(@amount);
    transform: scale(@amount);
}

.trans(@arguments){
    -webkit-transition: @arguments;
    -moz-transition: @arguments;
    -ms-transition: @arguments;
    -o-transition: @arguments;
    -o-transition: @arguments;
    transition: @arguments;
}

.transition-timing-function(@arguments){
    -webkit-transition-timing-function: @arguments;
    -moz-transition-timing-function: @arguments;
    -ms-transition-timing-function: @arguments;
    -o-transition-timing-function: @arguments;
    -o-transition-timing-function: @arguments;
    transition: @arguments;
}

.translate(@x-amount: 0, @y-amount: 0){
    -webkit-transform: translate(@x-amount, @y-amount);
    -moz-transform: translate(@x-amount, @y-amount);
    -ms-transform: translate(@x-amount, @y-amount);
    transform: translate(@x-amount, @y-amount);
}

.translateRotate(@x: 0, @y: 0, @rotate: 0deg){
    -webkit-transform: translateX(@x) translateY(@y) rotate(@rotate);
    -ms-transform: translateX(@x) translateY(@y) rotate(@rotate);
    transform: translateX(@x) translateY(@y) rotate(@rotate);
}

.translateSkewY(@amount1: 1.5, @amount2: -50%, @amount3: -50%, @amount4: 0deg){
    -webkit-transform: scale(@amount1) translateX(@amount2) translateY(@amount3) skewY(@amount4);
    -ms-transform: scale(@amount1) translateX(@amount2) translateY(@amount3) skewY(@amount4);
    transform: scale(@amount1) translateX(@amount2) translateY(@amount3) skewY(@amount4);
}

.translateX(@amount: 0){

    -webkit-transform: translateX(@amount);
    -ms-transform: translateX(@amount);
    transform: translateX(@amount);
}

.rotate(@amount: 0deg){
    -webkit-transform: rotate(@amount);
    -ms-transform: rotate(@amount);
    transform: rotate(@amount);
}

.translateY(@amount: 0){
    -webkit-transform: translateY(@amount);
    -ms-transform: translateY(@amount);
    transform: translateY(@amount);
}

.translateZ(@amount: 0){
    -webkit-transform: translateZ(@amount);
    -ms-transform: translateZ(@amount);
    transform: translateZ(@amount);
}

.transform(...){
    -webkit-transform: @arguments;
    -ms-transform: @arguments;
    transform: @arguments;
}

.filter(...){
    -webkit-filter: @arguments;
    filter: @arguments;
}

.backface-visibility(@visibility){
    -webkit-backface-visibility: @visibility;
    backface-visibility: @visibility;
}

.animation(@animation; @delay: 0){
    -webkit-animation: @animation;
    animation: @animation;
    -webkit-animation-delay: @delay;
    animation-delay: @delay;
}

.origin(...){
    -webkit-transform-origin: @arguments; /* Chrome, Safari, Opera */
    -ms-transform-origin: @arguments; /* IE 9 */
    transform-origin: @arguments;
}

// ************************************************
//               Flex
// ************************************************

.flex-container(@flow: row, @wrap: nowrap){
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: @flow;
    -webkit-flex-flow: @flow @wrap;
    flex-flow: @flow @wrap;
}

.flex-wrap(@wrap){
    -webkit-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

.flex-order(@order){
    -webkit-box-ordinal-group: @order;
    -ms-box-ordinal-group: @order;
    -ms-flex-order: @order;
    -webkit-order: @order;
    order: @order;
}

.flex(...){
    -webkit-box-flex: @arguments;
    -moz-box-flex: @arguments;
    -webkit-flex: @arguments;
    -ms-flex: @arguments;
    flex: @arguments;
}

.align-items(@param){
    -webkit-align-items: @param;
    align-items: @param;
}

.align-self(@param){

    -webkit-align-self: @param;
    align-self: @param;
}

.justify-content(@param){
    -webkit-justify-content: @param;
    justify-content: @param;
}

// ************************************************
//              Flex extended
// ************************************************

.flex-between( @align: stretch, @wrap: nowrap ){
    .flex-container(row, @wrap);
    .justify-content(space-between);
    .align-items(@align);
}

.flex-around( @align: stretch, @wrap: nowrap ){
    .flex-container(row, @wrap);
    .justify-content(space-around);
    .align-items(@align);
}

.flex-left( @align: stretch, @wrap: nowrap ){
    .flex-container(row, @wrap);
    .justify-content(flex-start);
    .align-items(@align);
}

.flex-center( @align: stretch, @wrap: nowrap ){
    .flex-container(row, @wrap);
    .justify-content(center);
    .align-items(@align);
}

.flex-right( @align: stretch, @wrap: nowrap ){
    .flex-container(row, @wrap);
    .justify-content(flex-end);
    .align-items(@align);
}

.col-flex-between( @align: stretch ){
    .flex-container(column);
    .justify-content(space-between);
    .align-items(@align);
}

.col-flex-around( @align: stretch ){
    .flex-container(column);
    .justify-content(space-around);
    .align-items(@align);
}

.col-flex-start( @align: stretch ){
    .flex-container(column);
    .justify-content(flex-start);
    .align-items(@align);
}

.col-flex-center( @align: stretch ){
    .flex-container(column);
    .justify-content(center);
    .align-items(@align);
}

.col-flex-end( @align: stretch ){
    .flex-container(column);
    .justify-content(flex-end);
    .align-items(@align);
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow){
    -webkit-flex-grow: @grow;
    -moz-flex-grow: @grow;
    -ms-flex-grow: @grow;
    flex-grow: @grow;
}

//box-shadow mixin
.box-shadow (@string){
    -webkit-box-shadow: @string;
    -moz-box-shadow: @string;
    box-shadow: @string;
}

//background size
.background-size(@string){
    -webkit-background-size: @string;
    -moz-background-size: @string;
    -o-background-size: @string;
    background-size: @string;
}

@responsive-1366: 1366px;
@responsive-1024: 1024px;
@responsive-411: 411px;

// not sure if this mixin is the correct name so before you change
// it, make sure it makes sense to do so in the contexts that its
// currently being used in.
.reset-select(){
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**
 * Material icons mixin
 */
.material-icon(@size: 24px){
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: @size;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}