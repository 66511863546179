
#single-product {

    @media screen and (max-width: 1700px) {
        width: 80%;
        .product_section {
            width: 100%;
        }
    }
    @media screen and (max-width: 1670px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        .modal {
                            .modal-content {
                                .cart-table {
                                    table {
                                        &.cart_total {
                                            tr {
                                                &.total {
                                                    td {
                                                        &.total {
                                                            font-size: 40px;

                                                            span {
                                                                font-size: 40px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .product_section {
            .products-header-top {
                .right {
                    .product-attributes {
                        .tooltip {
                            .tooltiptext {
                                width: 560px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1560px) {
        width: 85%;
        .product_section {
            .products-header-bottom{
                #accordion{
                    .dimensions{padding: 60px 7% 70px 0;}
                }
            }
        }

    }
    @media screen and (max-width: 1510px) {
        width: 90%;

    }
    @media screen and (max-width: 1360px) {
        .product_section {
            .products-header-top {
                .right{
                    .text{
                        width: 100%;
                    }
                    .product-attributes {
                        .tooltip {
                            .tooltiptext {
                                width: 520px;
                            }
                        }
                    }
                }
            }
        }

    }
    @media screen and (max-width: 1390px) {
        width: 95.5%;
        .product_section {
            .products-header-top {
                margin: -300px 0 0;
                .left {
                    .image-gallery {
                        .thumbnails {
                            .thumbnail-image {
                                min-width: 150px;
                                min-height: 100px;
                                margin: 0px 5px 20px;
                                background-size: contain;
                            }
                        }
                    }
                }
                .right {
                    .heading {
                        margin: 0;
                        align-self:flex-start;
                    }
                    .text {
                        width: 100%;
                        .bulk {
                            .modal{
                                .modal-content{
                                    width: 50%;
                                }
                            }
                        }
                    }
                    .product-attributes {
                        width: 90%;
                        .tooltip{
                            .tooltiptext {
                                width:520px;
                            }
                        }
                    }                    
                    #single-product-cart {
                        .modal {
                            .modal-content {
                                width: 50%;
                            }
                        }
                    }
                }
                .measures {
                    form {
                        a {
                            width: 38%;
                        }
                    }
                }
                .specs {
                    width: 100%;
                }
            }
            .products-header-bottom
            {
                width:100%;
            }
        }
    }
    @media screen and (max-width: 1333px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        #part-description{
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1320px) {
        .product_section {
            width: 89.4%;
            .products-header-top {
                 .flex-container(row,wrap);
                margin: -300px 0 0;
                .left {
                    z-index: 1;
                    .image-gallery {
                        .thumbnails {
                            .thumbnail-image {
                                min-width: 130px;
                                min-height: 130px;                          
                                background-size: contain;
                            }
                        }
                        .primary {
                            min-width: 450px;
                            margin-top: 45px;
                            min-height: 410px;
                            background-size: contain;
                        }
                    }
                }
                .right {
                    width:55%;
                    /*margin-top: -480px;*/
                    .flex-container(column,wrap);
                    .text{
                        width:50%;
                        align-self: flex-end;
                        .flex-container(column,wrap);

                        h4{font-size:45px;align-self: flex-start;}
                        .or{    align-self: center;
                            margin-top: -95px;
                            padding-left: 0px;}
                        .bulk{
                            align-self: flex-start;
                            .top{font-size:16px;}
                        }

                    }
                    .heading {
                        margin: 0;
                        //width:50%;
                        align-self: flex-end;
                    }
                    .product-attributes{
                        margin: 0;
                        width:90%;
                        align-self: flex-end;
                        .tooltip{
                            &.material{
                                .tooltiptext {
                                    width: 330px;
                                }
                            }
                            .tooltiptext {
                                width: 390px;
                            }
                        }
                    }
                    .measures {

                        margin: 0px 0 30px;
                        width: 100%;                        
                        form {
                            input[type=text] {
                                width: 9%;
                                padding: 18px 10px;
                            }
                            a {
                                width: 32%;
                            }
                        }
                    }
                    .specs {
                        width: 70%;
                        align-self: flex-start;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1125px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        .modal {
                            .modal-content {
                                .cart-table {
                                    table {
                                        &.cart_total {
                                            tr {
                                                &.total {
                                                    td {
                                                        &.total {
                                                            width: 40%;
                                                            font-size: 34px;

                                                            span {
                                                                font-size: 34px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        width: 100%;
        .product_section {
            width: 94%;
            margin: 60px 0 60px auto;
            .products-header-top {
                margin: -280px 6% 0 0;
                .left {
                    .image-gallery {
                        .thumbnails {
                            .thumbnail-image {
                                min-width: 124px;
                                min-height: 120px;
                                background-size: contain;
                            }
                        }
                        .primary {
                            min-width: 370px;
                            min-height: 360px;
                            background-size: contain;
                        }
                    }
                }
                .right {
                    /*width:100%;*/
                    margin-top: 20px;
                    .text{
                        width:100%;
                        align-self: flex-end;
                        .flex-container(column,wrap);

                        h4{font-size:45px;align-self: flex-start;}
                        .or{    align-self: center;
                            margin-top: -95px;
                            padding-left: 100px;}
                        .bulk{
                            align-self: flex-start;
                            margin-bottom: 20px;
                            .top{font-size:16px;}
                        }

                    }
                    .heading {
                        margin: 0;
                        width:45%;
                        align-self: flex-end;
                    }
                    .product-attributes{
                        margin: 0;
                        width:90%;
                        align-self: flex-end;
                        margin-top: 15px;
                        .tooltip{
                            display: none;
                            &.material {
                                display: block;
                            }
                        }
                    }
                    .measures {
                        margin: 0px 0 30px;
                        width: 100%;
                        form {
                            input[type=text] {
                                width: 9%;
                                padding: 18px 10px;
                            }
                            a {
                                width: 28%;
                            }
                        }
                        margin: 0;
                    }
                    .specs {
                        width:96%;
                    }
                }

            }
            .products-header-bottom{
                #accordion{
                    h3{
                        padding: 20px 5%;
                        margin-right: 6%;
                        width: 94%;
                        &.ui-accordion-header-active{
                            margin-right: 0%;
                            width: 100%;
                        }
                    }
                   #ui-accordion-content{
                        &.ui-accordion-content-active{
                            padding: 20px 5%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        .product_section {
            .products-header-top {
                .flex-container(column,nowrap);
               margin:  -360px 6% 0 0;
                .left{
                    width: 100%;
                    margin-left: -20px;
                    .image-gallery {
                        .align-items(center);
                    }
                }
                .right {
                    margin-top: 0;                    
                    width: 100%;
                    .heading{
                        width: auto;
                        text-align: center;
                        align-self: center;
                        .blue_border{
                            margin: 10px auto 0px auto;
                        }
                    }
                    .text {
                        width: 100%;
                        text-align: center;
                        h4{
                            align-self: center;
                            font-size:31px;
                            span{
                                font-size:31px;
                            }
                        }
                      .or{
                          padding-left: 0;
                          align-self: center;
                          margin-top: -62px;
                      }
                        .bulk
                        {
                            align-self: center;
                            margin: -30px 0 0;
                            .modal{
                                .modal-content {
                                    left: 20%;
                                    right: 20%;
                                    top: 25%;
                                    width: 60%;
                                    .modal-top {
                                        h6 {
                                            font-size: 22px;
                                            line-height: 50px;
                                        }
                                    }
                                    .close{
                                        .material-icons {   font-size: 22px;
                                            line-height: 50px;
                                        }
                                    }
                                }
                            }
                        }
                    }                    
                    #single-product-cart {
                        align-self: center;
                        margin: -30px 0 0;
                        .modal{
                            .modal-content {
                                left: 20%;
                                right: 20%;
                                top: 25%;
                                width: 60%;
                                .modal-top {
                                    h6 {
                                        font-size: 22px;
                                        line-height: 50px;
                                    }
                                }
                                .close{
                                    .material-icons {   font-size: 22px;
                                        line-height: 50px;
                                    }
                                }
                            }
                        }
                    }
                    .product-attributes{
                        width: 90%;
                        text-align: center;
                        .tooltip{
                            display: none;
                            &.material {
                                display: block;
                                .tooltiptext {
                                    right:-40px;
                                }
                            }
                        }
                    }
                    .measures{
                        form{
                            a{
                                width: 38%;
                            }
                            .shipping{
                                width: 100%;
                                margin: 20px 0 0;
                            }
                        }
                    }
                    .specs{
                        .flex-container(column,nowrap);
                        .video{
                            margin: 20px 0 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        .modal {
                            .modal-content {
                                .cart-table {
                                    table {
                                        display: block;
                                        min-width: auto;

                                        thead {
                                            display: block;

                                            tr {
                                                position: absolute;
                                                top: -9999px;
                                                left: -9999px;
                                            }
                                        }

                                        th {
                                            display: block;
                                        }

                                        tbody {
                                            display: block;

                                            tr {
                                                border-bottom: 1px solid @border-table;
                                                display: block;

                                                td {
                                                    display: block;
                                                    border: none;
                                                    border-bottom: none;
                                                    position: relative;
                                                    padding-left: 50%;
                                                    text-align: right;

                                                    &:before {

                                                        /* Now like a table header */
                                                        position: absolute;
                                                        /* Top/left values mimic padding */
                                                        top: 20px;
                                                        left: 0;
                                                        width: 45%;
                                                        padding-right: 10px;
                                                        white-space: nowrap;
                                                        text-align: left;

                                                    }
                                                    &:first-child{
                                                        padding: 10px;
                                                    }

                                                    &:nth-of-type(1) {
                                                        width: 100%;
                                                        text-align: center;

                                                        .product-outer {
                                                            max-height: 75px;
                                                            img {
                                                                max-width: 50%;
                                                            }
                                                        }
                                                    }

                                                    &:nth-of-type(2) {
                                                        padding-right: 0;
                                                        &:before {
                                                            content: "Product";
                                                        }
                                                    }

                                                    &:nth-of-type(3) {
                                                        &:before {
                                                            content: "Price";
                                                        }
                                                    }

                                                    &:nth-of-type(4) {
                                                        padding-right: 0;

                                                        &:before {
                                                            content: "QTY";
                                                        }
                                                    }

                                                    &:nth-of-type(5) {
                                                        text-align: right;

                                                        &:before {
                                                            content: "Total";
                                                        }
                                                    }

                                                    &:nth-of-type(6) {
                                                        padding-right: 0;
                                                        text-align: right;

                                                        &:before {
                                                            content: "";
                                                        }
                                                    }

                                                    input[type=text] {
                                                        width: 50px;
                                                        height: 50px;
                                                    }
                                                }

                                                &.dark-border {
                                                    border-bottom: 2px solid @darkcolor1;

                                                    td {
                                                        border: none;
                                                    }
                                                }

                                            }

                                        }

                                        &.cart_total {

                                            tr {
                                                .flex-container(row, nowrap);
                                                .justify-content(space-between);
                                                .align-items(center);
                                                border-bottom: 1px solid @border-table;

                                                th {
                                                    border: none;
                                                }

                                                td {
                                                    border: none;
                                                }

                                                &:nth-of-type(1) {
                                                    td {
                                                        width: auto;

                                                        &:before {
                                                            content: "";
                                                        }
                                                    }
                                                }

                                                &:nth-of-type(2) {
                                                    td {
                                                        width: auto;

                                                        &:before {
                                                            content: "";
                                                        }
                                                    }
                                                }

                                                &:nth-of-type(3) {
                                                    td {
                                                        width: auto;

                                                        &:before {
                                                            content: "";
                                                        }
                                                    }
                                                }

                                                &:nth-of-type(4) {
                                                    td {
                                                        width: auto !important;

                                                        &:before {
                                                            content: "";
                                                        }
                                                    }

                                                    border-bottom: none;
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }   

    @media screen and (max-width: 700px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        #part-description {
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .product_section {
            .products-header-top {
                    .left{
                    .image-gallery{
                        .primary{
                            margin-top: 89px;
                        }
                      }
                    }
                .right{
                    width: 100%;
                    .measures{
                        form{
                            a{
                                width: 70%;
                            }
                        }
                    }
                    .text{
                        .bulk{
                            .modal{
                                .modal-content {
                                    left: 10%;
                                    right: 10%;
                                    top: 20%;
                                    width: 80%;
                                }
                            }
                        }
                    }
                }                        
                #single-product-cart {
                    .modal{
                        .modal-content {
                            left: 10%;
                            right: 10%;
                            top: 20%;
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        .product_section {
            .products-header-top {
                .right {
                    #single-product-cart {
                        .modal {
                            .modal-content {
                                .cart-table {
                                    table {
                                        &.cart_total {
                                            tr {
                                                th{
                                                    strong{
                                                        font-size: 16px;
                                                    }
                                                }
                                                &.total {
                                                    td {
                                                        &.total {
                                                            font-size: 30px;

                                                            span {
                                                                font-size: 30px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 460px) {
        .product_section {
            .products-header-top {
                .left{
                    .image-gallery{
                        .primary{
                            min-width: 320px;
                            min-height: 190px;
                        }
                        .thumbnails {
                           .thumbnail-image{
                               min-width: 85px;
                               min-height: 64px;
                               background-size: contain;
                           }
                        }
                    }
                }
                .right{
                    width: 100%;
                    .product-attributes {
                        .flex-container(row,wrap);
                        .variation {
                            width: 90%;
                        }
                        select{
                            padding: 25px 18px 25px 18px;
                            font-size: 13px;
                        }
                    }
                    .specs{

                            a{
                                font-size: 20px;
                            }

                    }
                    .measures{
                        h3{    margin: 10px 0 0;}
                        form{
                            input[type=text]{
                                width: 20%;
                            }
                            a{
                                width: 60%;
                            }
                        }
                    }
                    .text{
                        .bulk{
                            .modal{
                                .modal-content {
                                    left: 5%;
                                    right: 5%;
                                    top: 20%;
                                    width: 90%;
                                    .modal-top {
                                        h6 {
                                            font-size: 20px;
                                        }
                                    }
                                    .close{
                                        .material-icons {   font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }                                             
                    #single-product-cart {
                        .modal{
                            .modal-content {
                                left: 5%;
                                right: 5%;
                                top: 20%;
                                width: 90%;
                                .modal-top {
                                    h6 {
                                        font-size: 20px;
                                    }
                                }
                                .close{
                                    .material-icons {   font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                    p{    margin: 40px 3% 10px 0;}
                }
            }
        }

    }
    }
@media screen and (max-width: 1080px) {
    .product-outer-wrapper {
        height: 200px;
    }
}
@media screen and (max-width: 600px) {
    .product-outer-wrapper {
        height: 215px;
    }
}
