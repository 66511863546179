.font-face(Titillium-BoldUpright, '../fonts/Titillium/Titillium-BoldUpright');
.font-face(Montserrat-Bold, '../fonts/montserrat/Montserrat-Bold');
.font-face(Montserrat-SemiBold, '../fonts/montserrat/Montserrat-SemiBold');

body {
    p {
        .font(@font1, 15px, 600, 30px);
        color: @darkcolor1;
    }
    h1, h2, h3, h4, h5, h6 {
        .font(@head-font, 1.7em, bold, normal);
    }
    div {
        &.btn {
            a {
                i {
                    &.fa-plus, &.fa-minus {
                        font-size: 1.5rem;
                        color: @mainblue;
                        font-weight: 900;
                        padding-top: 8px;
                        padding-right: 5px;
                    }
                }
            }
        }        
        a {
            &.btn {
                i {
                    &.fa-plus {
                        font-size: 1.3rem;
                        color: @mainblue;
                        font-weight: 900;
                        padding-right: 10px;
                        padding-top:0;
                    }
                }
            }
        }
        &.specs, &.form-row {
            a {
                i {
                    &.fa-plus, &.fa-minus {
                        font-size: 1.3rem;
                        padding-left: 1.3rem;
                        color: @mainblue;
                        font-weight: 900;
                        padding-top: 0;
                        padding-right: 5px;
                    }
                }
            }
        }
        &.bulk {
            a {
                i {
                    &.fa-plus, &.fa-minus {
                        font-size: 1.3rem;
                        color: @mainblue;
                        font-weight: 900;
                        padding-right: 7px;
                        padding-top: 0px;
                    }
                }
            }
        }
    }
    i {
        &.fa-plus, &.fa-minus {
            font-size: 1.3rem;
            color: @mainblue;
            font-weight: 900;
            padding-top: 7px;
        }
    }
    ol {
        li {
            color: @darkcolor1;
        }
        &.terms-list {
            list-style-type: none;
        }
    }
    ul {
        li {
            color: @darkcolor1;
        }
    }
    // ************************************************
    //              Services
    // ************************************************

    .service_divider {
        height: 90px;
        clear: both;
        @media screen and (max-width: 1100px) {
            height: 40px;
        }
    }

    .services {
        &.container {
            width: 100%;
            max-width: 1520px;
            margin: 0 auto;
            .flex-container(row, wrap);
            .align-items(flex-start);
            .justify-content(space-between);
        }

        .single_service {
            width: 29%;
            .flex-container(column, wrap);
            .justify-content(space-between);
            .align-items(stretch);
            position: relative;
            @media screen and (max-width: 640px) {
                width: 100% !important;
            }
            a {
                text-decoration: none;
                color: @darkcolor1;
                .service_bottom {
                    margin-top: -42px;
                    padding: 0 32px 0 45px;
                    @media screen and (max-width: @responsive-1366) {
                        padding: 0 15px 0 19px;
                    }
                    @media screen and (max-width: 1250px) {
                        padding: 0 15px 0 15px;
                    }

                }
                .service_image {
                    text-align: center;
                    width: 100%;
                    background: #eeeeee;
                    padding: 90px 0;
                    .trans(all 0.2s);
                }
                .blue_border {
                    border-bottom: 10px solid @mainblue;
                    width: 145px;
                    padding-top: 15px;
                }
                .service_heading {
                    width: 100%;
                    color: @dark;
                    .align-self(flex-end);
                    .font(@head-font, 40px, bold, 38px);
                }
                &:hover {
                    & .service_image{
                        background: @mainblue;
                        cursor: pointer;
                        .trans(all 0.2s);
                        .service_icon {
                            fill: @white;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1770px) {
            &.container {
                width: 80%;
            }

            .single_service {
                width: 32%;
            }
        }
        @media screen and (max-width: @responsive-1366) {
            &.container {
                width: 80%;
            }

            .single_service {
                & a{
                    .service_heading {
                        font-size: 40px;
                        line-height: 38px;
                    }
                }
            }
        }

        @media screen and (max-width: 1250px) {
            .single_service {
                //width:260px;
                .service_image {
                    padding: 50px 0;
                }
            }
        }
        @media screen and (max-width: 1100px) {
            &.container {
                width: 88%;
            }

            .single_service {
                width: 31%;
            }
        }
        @media screen and (max-width: 910px) {
            .single_service {
                width: 32.5%;
                & a{
                    .service_heading {
                        font-size: 40px;
                        line-height: 38px;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            &.container {
                .flex-container(row, nowrap);
                max-width: 88%;
            }

            .single_service {
                & a{
                    .service_heading {
                        font-size: 40px;
                        line-height: 38px;
                    }                    
                }
            }
        }

        @media screen and (max-width: 640px) {
            .single_service {
                & a{
                    .service_heading{
                        font-size: 40px;
                        line-height: 38px;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            &.container {
                max-width: 88%;
                .flex-container(column, nowrap);
            }

            .single_service {
                width: 70%;
                margin-bottom: 60px;
            }
        }

    }

    // ************************************************
    //              About
    // ************************************************

    .about_divider {
        height: 90px;
        clear: both;
        @media screen and (max-width: 640px) {
            height: 120px;
        }
    }
    .about {
        &.container {
            width: 100%;
            max-width: 1520px;
            margin: 0 auto;
            .flex-container(row, wrap);
            .align-items(flex-start);
            .justify-content(flex-end);
        }
        .blue_border {
            border-bottom: 10px solid #65b9e7;
            width: 145px;
        }
        .about_left {
            width: 46%;
            align-self: flex-start;
            margin-top: -20px;
            padding-right: 128px;
            padding-left: 8%;
            //flex-basis: 450px;
            h3 {
                .font(@head-font, 55px, bold, 65px);
                margin: 0;
            }
            h4 {
                .font(@head-font, 31px, bold, 36px);
            }
            p {
                margin-top: 0;
                span {
                    .font(@font1bold, 15px, bold, 30px);
                }
            }
            a {
                color: @mainblue;
                .font(@font1bold, 18px, bold, 33px);
                .trans(all 0.2s);
                &:hover {
                    cursor: pointer;
                    color: @darkblue;

                }
            }
            &.sec {
                transition-duration: 0.3s;
            }
            &.sec1 {
                transition-duration: 6s;
            }
        }
        .about_right {
            width: 54%;
            img {
                width: 100%;
            }
        }
        @media screen and (max-width: 1700px) {
            &.container {
                max-width: 80%;
                .justify-content(space-between);
            }

            .about_left {
                padding-left: 0;
                padding-right: 80px;
            }
        }

        @media screen and (max-width: 1440px) {
            &.container {
                max-width: 80%;
                .justify-content(space-between);
            }

            .about_left {
                padding-left: 0;
                padding-right: 60px;
            }
        }
        @media screen and (max-width: @responsive-1366) {
            &.container {
                max-width: 80%;
            }
        }
        @media screen and (max-width: 1100px) {
            &.container {
                max-width: 88%;
                .justify-content(flex-end);
            }

            .about_left {
                padding-right: 30px;
            }

            .about_right {
                img {
                    height: 450px;
                }
            ;
            }
        }
        @media screen and (max-width: 768px) {
            &.container {
                max-width: 88%;
                .flex-container(column-reverse, nowrap);
                .justify-content(flex-end);
            }

            .about_left {
                //padding-left:25px;
                //padding-right:25px;
                padding: 0;
                width: 100%;
                h3 {
                    font-size: 50px;
                    line-height: 55px;
                    padding-top: 70px;
                    margin-bottom: 10px;
                }
            }

            .about_right {
                width: 100%;
                img {
                    height: auto;
                }
            ;
            }
        }
        @media screen and (max-width: 640px) {
            &.container {
                max-width: 100%;
            }

            .about_left {
                margin: 0 auto;
                padding: 0;
                width: 74%;
                h3 {
                    font-size: 50px;
                    line-height: 55px;
                    padding-top: 70px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .overlay{
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
        background: rgba(25,124,178,0.3);
        .spinner {
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            height:60px;
            width:60px;
            margin:0px auto;
            z-index: 99999;
            -webkit-animation: rotation .6s infinite linear;
            -moz-animation: rotation .6s infinite linear;
            -o-animation: rotation .6s infinite linear;
            animation: rotation .6s infinite linear;
            border-left:6px solid rgba(0,174,239,.15);
            border-right:6px solid rgba(0,174,239,.15);
            border-bottom:6px solid rgba(0,174,239,.15);
            border-top:6px solid rgba(0,174,239,.8);
            border-radius:100%;            
            &.active {
                display: block;
            }
        }
         
        @-webkit-keyframes rotation {
            from {-webkit-transform: rotate(0deg);}
            to {-webkit-transform: rotate(359deg);}
        }
        @-moz-keyframes rotation {
            from {-moz-transform: rotate(0deg);}
            to {-moz-transform: rotate(359deg);}
        }
        @-o-keyframes rotation {
            from {-o-transform: rotate(0deg);}
            to {-o-transform: rotate(359deg);}
        }
        @keyframes rotation {
            from {transform: rotate(0deg);}
            to {transform: rotate(359deg);}
        }
        &:hover {
            cursor: pointer;
            color: @darkblue;
        }
        &.active{
            & span.process{
                & .base{
                    display: none;
                }
                & .lds-ring{
                    display: inline-block;
                }
            }
        }
        &:disabled {
            color: @darkblue;
        }
    }
    &.loading {
        overflow: hidden;
        .overlay {
            display: block;
        }
    }
}
