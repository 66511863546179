
.custom-select-variation-styles,
.custom-select-variation{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: left;
    background: #fff;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    color: @select-text;
    &:focus {
        outline: none; /* For better accessibility add a style for this in your skin */
    }
    select {
        display: none;
    }
    span {
        display: block;
        position: relative;
        cursor: pointer;
        padding: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 25px;

    }
    .cs-placeholder{
        background-color:@lightgrey;
        background-image: linear-gradient(45deg, transparent 50%, @mainblue 50%), linear-gradient(135deg, @mainblue 50%, transparent 50%);
        background-position: ~"calc(100% - 20px)" ~"calc(1.2em + 12px)", ~"calc(100% - 12px)" ~"calc(1.2em + 12px)";
        background-size: 8px 8px, 8px 8px;
        background-repeat: no-repeat;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
    }
    &.cs-active {
        z-index: 2;
        .cs-placeholder {
            background-color:@mainblue;
            color:@white;
            background-image: linear-gradient(135deg, transparent 50%, @white  50%), linear-gradient(45deg, @white 50%, transparent 50%);
            background-size: 8px 8px, 8px 8px;
            background-repeat: no-repeat;

        }
        .cs-options {
            visibility: visible;
            box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
            pointer-events: auto;
            animation-name: fadeInUpSD;
            animation-duration: 0.3s;
            ul{
                max-height:220px;
                @media screen and (max-width: 640px) {
                    max-height: 120px;
                }
                li{
                    -webkit-transform: translate3d(0,0,0);
                    transform: translate3d(0,0,0);
                    opacity: 1;
                    -webkit-transition: none;
                    transition: none;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: normal;
                    &:hover{
                        span{
                            color:@bgheaderdark;
                        }
                    }
                }
            }
        }
    }
    .cs-options {
        position: absolute;
        overflow: hidden;
        width: 100%;
        background: #ffffff;
        visibility: hidden;
        padding: 25px 25px 25px 30px;
        color: @select-text;
        .trans(all 0.3s);
        max-height: 200px;
        overflow: auto;
        ul{
            li{
                opacity: 0;
                -webkit-transform: translate3d(0,-25px,0);
                transform: translate3d(0,-25px,0);
                -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
                transition: opacity 0.15s, transform 0.15s;
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        span {
            padding: 10px 0;
            .font(@font1, 16px, bold, 43px);
            color:@select-text ;
            .trans(all 0.2s);
        }
        li{
            &.cs-focus{
                span {
                    background-color: #ddd;
                }
            }
            &.cs-optgroup{
                ul {
                    padding-left: 1em;
                }
                span {
                    cursor: default;
                }
            }
        }
    }
}
#usage-filters-form {
    .custom-select-variation-styles,
    .custom-select-variation{
        &.cs-active {
            z-index: 99;
            .cs-options {
                ul {
                    max-height:420px;
                }
            }
        }
        .cs-options {
            max-height: 400px;
        }
    }
}
.part-material {
    &.custom-select-variation-styles {
        margin-top: 20px;
    }
}
.part-connection {
    &.custom-select-variation-styles {
        margin-top: 20px;
        margin-left: 10px;
    }
}
@media screen and (max-width: 460px) {
    .part-connection {
        &.custom-select-variation-styles {
            margin-top: 20px;
            margin-left: 0;
        }
    }
}