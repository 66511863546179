/////***********TECHNICAL LIST**********//////////////////
#technical-assistance {
    .page-outer-wrapper {
        background: @lightgrey no-repeat center center;
        .background-size(cover);
        min-height: 271px;
        .flex-container(row, wrap);
        .align-items(center);
        .justify-content(center);
        margin: 0 0 55px 0;
        .page-title-wrapper {
            width: 68%;
            margin: 0 auto;
            .flex-container(row, wrap);
            .align-items(center);
            .justify-content(center);
            @media screen and (max-width: 1290px) {
                .flex-container(row, wrap);
            }
            .page-title {
                .flex-container(column, wrap);
                &:first-child {
                    width: 30%;
                }
                &:nth-of-type(2) {
                    width: 65%;
                    margin-left: 15px;
                }
                h1 {
                    font-size: 40px;
                    line-height: 48px;
                    margin: 0;
                }
                p {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 22px;
                }
                .blue_border {
                    width: 145px;
                    border-bottom: 13px solid @mainblue;
                    margin: 10px 0 0 0;
                }
            }
        }
    }
    & .inner-page{
        padding-bottom: 50px;
        .technical-item {
            .flex-container(row, wrap);
            position: relative;
            margin: 20px 0;
            padding-bottom: 110px;
            &:nth-of-type(even) {
                margin: 0 0 0 5%;
            }
            &:nth-of-type(odd) {
                img {
                    margin-left: -25px;
                }
            }
            .item-left {
                position: relative;
                min-height: 220px;
                width: 269px;
                .box-shadow(9px 20px 40px -4px rgba(0, 0, 0, 0.31));
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
            .item-right {
                .flex-container(column, wrap);
                position: absolute;
                width: 80%;
                left: 18%;
                top: 20%;
                position: absolute;
                padding: 20px 30px 70px 30px;
                background: @white;
                h1 {
                    font-size: 40px;
                    line-height: 48px;
                    margin: 0;
                }
                p {
                    padding-bottom: 0;
                }
                .blue_border {
                    width: 145px;
                    border-bottom: 13px solid @mainblue;
                    margin: 10px 0 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1505px) {
    #technical-item{
        .inner-page {
            .inner-content {
                .product-category {
                    .item-bottom {
                        margin: 80px 0;
                        .subcategory {
                            .subcategory-container {
                                .text {
                                    top: 12%;
                                }
                                .sub-heading {
                                    .head {
                                        font-size: 21px;
                                        line-height: 21px;
                                        span {
                                            font-size: 21px;
                                            line-height: 21px;
                                        }
                                    }
                                }
                            }
                            &:hover {

                                .subcategory-container {
                                    .box {
                                        margin: -60px 0 0 0;
                                    }
                                    .box-overlay {
                                        min-height: 245px;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    #technical-item{
        .inner-page {
            .inner-content {
                .product-category {
                    .item-bottom {
                        .subcategory {
                            padding: 100px 0 30px 0;
                            .subcategory-container {
                                .sub-heading {
                                    .head {
                                        font-size: 18px;
                                        line-height: 18px;
                                        span {
                                            font-size: 18px;
                                            line-height: 18px;
                                        }
                                    }
                                }
                                .text {
                                    top: 35%;
                                }
                            }
                            &:hover {

                                .subcategory-container {
                                    .box {
                                        margin: -95px 0 0 0;
                                    }
                                    .box-overlay {
                                        min-height: 260px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1330px) {
    #technical-item{
        .inner-page {
            .inner-content {
                .product-category {
                    .item-bottom {
                        .subcategory {
                            .subcategory-container {
                                .text {
                                    top: 50%;
                                    .btn {
                                        font-size: 14px;
                                    }
                                }
                            }
                            &:hover {
                                .subcategory-container {
                                    .box {
                                        margin: -112px 0 0 0;
                                    }
                                    .box-overlay {
                                        min-height: 280px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1290px) {
    .inner-page {
        &#technical-list {
            .technical-item {
                .flex-container(row, wrap);
                margin: 20px 0 40px;
                &:nth-of-type(even) {
                    margin: 0;
                }
                .item-right {
                    padding: 40px 0px 80px 30px;
                    h1 {
                        font-size: 35px;
                        line-height: 44px;
                    }
                }
            }
        }
        .inner-content {
            .search-section {
                .search-keywords {
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 310px;
                        }
                    }
                }
            }
            .product-category {
                .item-bottom {
                    .subcategory {
                        .subcategory-container {
                            .text {
                                top: 35%;
                                padding: 20px 15px 20px;
                                .btn {
                                    font-size: 16px;
                                }
                            }
                            .sub-heading {
                                padding: 0 0 0 15px;
                                margin: -70px 0 0 0;
                                .blue_border {
                                    border-bottom: 10px solid #65b9e7;
                                    width: 80px;
                                }
                            }
                        }
                        &:hover {
                            .subcategory-container {
                                .box {
                                    margin: -75px 0 0 0;
                                }
                                .box-overlay {
                                    min-height: 220px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1160px) {
    .inner-page {
        .inner-content {
            .search-section {
                .search-keywords {
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 270px;
                        }
                    }
                }
            }
            .product-category {
                .item-bottom {
                    .subcategory {
                        .subcategory-container {
                            .text {
                                top: 45%;
                            }
                        }
                        &:hover {
                            .subcategory-container {
                                .box {
                                    margin: -95px 0 0 0;
                                }
                                .box-overlay {
                                    min-height: 240px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1070px) {
    .inner-page {
        .inner-content {
            .product-category {
                .item-bottom {
                    .subcategory {
                        .subcategory-container {
                            .text {
                                top: 54%;
                            }
                        }
                        &:hover {
                            .subcategory-container {
                                .box {
                                    margin: -124px 0 0 0;
                                }
                                .box-overlay {
                                    min-height: 270px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .inner-page {
        .inner-content {
            .search-section {
                .search-keywords {
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 250px;
                        }
                    }
                }
            }
            .product-category {
                .item-bottom {
                    .subcategory {
                        padding: 30px 0 0px 0;
                        .subcategory-container {
                            .text {
                                p {
                                    font-size: 14px;
                                }
                                .btn {
                                    font-size: 13px;
                                }
                            }
                            .subcategory-container-wrapper {
                                min-height: 170px;
                                max-height: 170px;
                            }
                            .text {
                                color: @bgheaderdark;
                            }
                            .sub-heading {
                                padding: 0 0 0 15px;
                                margin: -55px 0 0 0;
                            }
                            .box-overlay {
                                min-height: 70px;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            .subcategory-container {
                                .box {
                                    margin: 0;
                                }

                                .box-overlay {
                                    min-height: 70px;
                                    .trans(none);
                                }
                                .subcategory-container-wrapper {
                                    max-height: 170px;
                                    min-height: 170px;
                                    position: unset;
                                }
                                .overlay {
                                    animation-delay: 0s;
                                    animation-duration: 0s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    height: auto;
                                    width: auto;
                                    animation-fill-mode: none;
                                    min-height: 0;
                                    visibility: hidden;
                                }
                                .sub-heading {

                                    animation-delay: 0s;
                                    animation-duration: 0s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    animation-fill-mode: none;
                                    position: relative;
                                    bottom: 0px;
                                    margin: -55PX 0 0 0;
                                }
                                img {
                                    animation-duration: 0s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    animation-fill-mode: none;
                                    position: unset;
                                }

                            }
                        }
                        &.inactive {
                            .subcategory-container {
                                .box-overlay {
                                    min-height: 70px;
                                    .trans(min-height 0s ease 0s);
                                }
                                img {
                                    animation-delay: 0.5s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    animation-fill-mode: none;
                                }
                                .sub-heading {
                                    animation-delay: 0.3s;
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    animation-fill-mode: none;

                                }
                                .overlay {
                                    animation-duration: 0.3s;
                                    -webkit-animation-name: none;
                                    animation-name: none;
                                    animation-fill-mode: forwards;
                                    a {
                                        transition: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 940px) {
    .inner-page {
        .inner-content {
            .search-section {
                .search-keywords {
                    width: 85%;
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 230px;
                        }
                    }
                }
            }
            .tabs {
                .ui-accordion {
                    h3 {
                        font-size: 20px;
                        line-height: normal;
                        padding: 25px 3%;
                        .ui-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .inner-page {
        .inner-content {
            .search-section {

                .search-keywords {
                    width: 85%;
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 210px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #technical-assistance{
        .page-outer-wrapper {
            background: @lightgrey;
            margin: 0 0 65px 0;
            background-size: cover;
            background-image: linear-gradient(rgba(238,238,238,.4),rgba(238,238,238,.4)), url('/assets/images/technical-bg.png') !important;
            .page-title-wrapper {
                width: 88%;
                .flex-container(column, wrap);
                .page-title {
                    &:first-child {
                        width: 0%;
                    }
                    &:nth-of-type(2) {
                        width: 96%;
                        margin-left: 0;
                    }
                    h1 {
                        padding-top: 50px;
                    }
                }
            }
        }
    }

    .inner-page {
        &.container {
            width: 82%;
        }
        &#technical-list {
            padding-bottom: 60px;

            .technical-item {
                margin: 20px 0;
                .flex-container(column, wrap);
                padding-bottom: 50px;
                &:nth-of-type(even) {
                    margin: 0;
                }
                &:nth-of-type(odd) {
                    img {
                        margin-left: 0;

                    }
                }
                .item-left {
                    width: 100%;
                    min-height: 270px;
                }
                .item-right {
                    width: 100%;
                    left: 0;
                    position: relative;
                    background: none;
                    padding: 45px 0 0;
                    h1 {
                        width: 90%;
                    }
                }
            }

        }
        .inner-content {
            .content-section {
                width: 100%;
                padding: 0 0 20px 0;
                h2 {
                    width: 100%;
                }
            }
            .search-section {
                .search-keywords {
                    width: 95%;
                    .custom-select-variation {
                        .cs-placeholder {
                            width: 175px;
                        }
                    }
                }
            }

            .product-category {
                .item-bottom {
                    margin: 0 0 90px 0;
                    .subcategory {
                        width: 48%;
                        margin: 0px 0 0px 0;
                        padding: 0 0 0 0;
                        &:not(:last-child) {
                            margin: 0px 0 0px 0;
                        }
                        &:nth-child(odd) {
                            margin-right: 4%;
                        }
                        .subcategory-container {
                            .subcategory-container-wrapper {
                                min-height: 170px;
                                max-height: 200px;
                                padding: 0;
                                text-align: center;
                                img {
                                    padding: 20px 0 0 0;
                                }
                            }
                            .text {
                                padding: 20px;
                            }
                            .sub-heading {
                                margin: -75px 0 0 0;
                            }
                        }
                        &:hover {
                            .subcategory-container {
                                .subcategory-container-wrapper {
                                    min-height: 170px;
                                    max-height: 200px;
                                    padding: 0;
                                }
                                .sub-heading {
                                    margin: -75px 0 0 0;
                                }
                            }
                        }
                    }
                }
                .product-img {
                    padding: 20px 0 70px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .inner-page {
        .inner-content {
            .search-section {
                .search-keywords {
                    width: 100%;
                    .flex-container(column, nowrap);
                    .align-items(flex-start);
                    .custom-select-variation {
                        margin: 10px 0;
                        .cs-placeholder {
                            width: 350px;

                        }
                        .cs-options {
                            width: 105%;
                        }
                    }
                    a {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    #technical-item {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 84.5%;
                }
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    #technical-item {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 90%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    #technical-item {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #technical-item {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #technical-item {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    h1 {
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

