i.inherit{
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    vertical-align: middle;
}

body{
    & .notifications-container{
        padding-bottom: 20px;
        & .notification{
            padding: 10px 25px 10px 38px;
            border-radius: 5px;
            position: relative;
            background-color: #fdfdfd;
            margin-bottom: 10px;
            .translateY(-15px);
            opacity: 0;
            transition: opacity 0.4s, transform 0.4s;
            & p{
                padding: 0;
                margin: 0;
                line-height: 1.5em;
            }
            & .close-notification{
                position: absolute;
                top: 50%;
                right: 5px;
                .translateY(-50%);
                cursor: pointer;
            }
            &.show{
                opacity: 1;
                .translateY(0px);
            }
            &.hide{
                opacity: 0;
                .translateY(-15px);
            }
            &:after{
                font-size: 24px;
                position: absolute;
                top: 50%;
                left: 19px;
            }
        }
        &.fixed{
            position: fixed;
            top: 95px;
            left: 50%;
            .translateX(-50%);
            width: 50vw;
            z-index: 1001;
            padding-bottom: 0px;
            @media screen and (max-width: 767px) {
                width: 80vw;
            }
            @media screen and (max-width: 639px) {
                width: 90vw;
            }
            & .notification{
                @media screen and (max-width: 639px) {
                    width: 100%;
                }
            }
        }
    }
    & .notification{
        max-width: 1024px;
        margin: 0px auto;
        width: 75%;
        position: relative;
        padding: 20px 5px 20px 80px;
        color: #fff;
        box-shadow: 0px 0px 5px #888;
        background-color: #fff;
        & .title{
            font-size: 25px;
            line-height: 32px;
            font-weight: 700;
            margin: 0;
        }
        & p{
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 5px;
            color: #fff;
            margin: 0;
        }
        & a{
            color: #efefef;
        }
        &.success{
            background-color: @darkblue;
            &:after{
                content: '\E5CA';
                font-weight: 700;
            }
        }
        &.info{
            background-color: @mainblue;

            &:after{
                content: '\E88E';

            }
        }
        &.warning{
            background-color: #ffb238;

            &:after{
                content: '\E002';
            }
        }
        &.error{
            background-color: @red2;
            &:after{
                content: '\E000';

            }
        }
        &:after{
            font-weight: 300;
            .material-icon(40px);
            position: absolute;
            top: 50%;
            left: 40px;
            .translate(-50%, -50%);
        }
    }
    & .pagination{
        padding: 20px 0;
        .flex-container(row, wrap);
        .justify-content(center);
        & ul{
            list-style: none;
            padding: 0;
            margin: 0;
            .flex-container();
            .align-items(center);
            & li{
                margin: 0 5px;
                & a,
                & span{
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}