/* *********************************************************************************************

How to Create CSS3 + jQuery Accordion Menu [Tutorial]
"How to Create CSS3 + jQuery Accordion Menu" that was specially made for DesignModo by our friend Valeriu Timbuc.

Links:
http://vtimbuc.net/
https://twitter.com/vtimbuc
http://designmodo.com/futurico
http://vladimirkudinov.com
http://rockablethemes.com

********************************************************************************************* */

/* Reset */

.accordion
{
  margin: 0;
  padding: 0;
  ul, li, a, span {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  li {
    margin: 0;
    list-style: none;    
    line-height: 35px;
      &.has-sub {
          a{

              &:before {
                  content: none;
              }
          }
          .sub-menu {
            padding: 0 0 0 40px;
          }
          &.open {
              a{
                  //color: @mainblue;
                  &:before {
                     content: none;
                  }

              }
              .sub-menu {
                  padding: 0 0 0 40px;
                  display: block;
                  li{
                      &.has-sub {
                          a {

                              &:before {
                                  content: none;
                              }
                          }
                      }
                      &.no-sub{
                          a {

                              &:before {
                                  content: none;
                              }
                              padding: 0 0 0 15px;
                          }
                      }
                      &.open {
                          a{
                              &:before {
                                  content: none;
                              }
                          }
                          .sub-menu {
                              padding: 0 0 0 20px;
                              li{
                                  a{

                                      &:before {
                                       //   content: '+';
                                      }

                                  }
                              }

                          }
                      }
                  }

              }
          }
      }

      &.not-active {
          a{color: @dark !important;
              &:before {
                  //content: '+';
                  color: @mainblue;
                  position: absolute;
                  top: 0;
                  left: 0;
                  .font(@font1, 25px, 700, 63px);
                  //margin: 4px 8px;
                  margin: -5px 0 0 0;
              }
          }
      }
        a {
            //display: block;
            //position: relative;
            padding: 0 0 0 10px;
            color:@dark !important;
            .font(@font1, 16px, 700, 25px);
            margin-bottom: 15px;
            &:before {
                //content: '+';
                color: @mainblue;
                position: absolute;
                top: 0;
                left: 0;
                .font(@font1, 25px, 700, 38px);
                //margin: 4px 8px;
                margin: -5px 0 0 0;
            }
            &.active {
                color:@mainblue !important;
            }
        }

    }
  .sub-menu {
    display: none;
      li{
          &.plus-icon {
              a {

                  &:before {
                   content: none;
                  }
              }
          }
          &.has-sub {
              a {
                  &:before {
                      content: none;
                  }
                  font-size: 14px;
              }

              &.open {
                  a{
                      //color: @mainblue;
                      &:before {
                          content: none;
                      }

                  }
                  .sub-menu {
                      padding: 0 0 0 40px;
                      li{
                          &.has-sub {
                              a {

                                  &:before {
                                      content: none;
                                  }
                              }

                          }
                          &.no-sub {
                              a {

                                  &:before {
                                      content: '';
                                  }
                              }

                          }
                          &.plus-icon {
                              a {

                                  &:before {
                                      content: none;
                                  }
                              }
                          }

                          &.open {
                              a{
                                  &:before {
                                      content: none;
                                  }
                              }
                              .sub-menu {
                                  padding: 0 0 0 20px;
                                  li{
                                      a{

                                          &:before {
                                              //   content: '+';
                                          }

                                      }
                                  }

                              }
                          }
                      }

                  }
              }
          }
      }
  }

  }

.moby-button{display: none;}
@media screen and (max-width: 1290px) {
    #main-sidebar{display: none;}

    .accordion
    {
        li
       {
            &.has-sub {
                a{
                    &:before {
                        content: none;
                    }
                }
            }
        }
        .sub-menu {
            li{
                &.plus-icon {
                    a {
                        &:before {
                            content: none;
                        }
                    }
                }
                &.has-sub {
                    a {
                        &:before {
                            content: none;
                        }
                    }
                    &.open {
                        a{
                            &:before {
                                content: none;
                            }
                        }
                        .sub-menu {
                            padding: 0 0 0 40px;
                            li{
                                &.has-sub {
                                    a {
                                        &:before {
                                            content: none;
                                        }
                                    }
                                }
                                &.plus-icon {
                                    a {
                                        &:before {
                                            content: none;
                                        }
                                    }
                                }
                                &.open {
                                    a{
                                        &:before {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    .moby-button{display: inline-block;
        background: @mainblue;
        width:270px;
        height: 100px;
        display: flex;
        color:@white;
        .justify-content(center);
        &:hover{
            cursor: pointer;
        }
        .material-icons{font-size:32px;
            line-height: 95px;}
    }
}
@media screen and (max-width: 768px) {
    #main-sidebar {
        display: none;
    }

    .moby-button {
       display: inline-flex;
        width: 100%;
        height: auto;
        color: @white;
        .justify-content(flex-start);
        .align-items(center);
        padding: 0 5.6%;
        h2 {
            display: inline-flex !important;
           font-size: 26px !important;;
            line-height: 30px !important;
            margin: 0 !important;
            padding-left: 20px;
        }

    }
}






