.product-outer-wrapper {
    background: @lightgrey;
    height: 180px;
}

#single-product {
    width: 1520px;
    margin: 0 auto;

    #overlay {
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
        cursor: pointer;

        #text {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 50px;
            color: white;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
        }
    }

    .product_section {
        width: 1340px;
        margin: 60px auto;

        .products-header-top {
            margin: -270px 0 0;
            .justify-content(space-between);

            .left {
                width:40%;
                margin-right: 15px;
                .zoom-box{
                    position: relative;
                }
                .zoom-box img{
                    vertical-align: bottom;
                }
                .zoom-box .zoom-selector{
                    position: absolute;
                    background-image: url("/assets/images/selector.png");
                    background-repeat: repeat;
                    display: none;
                    cursor: none;
                }
                .zoom-box .viewer-box{
                    position: absolute;
                    border: 1px solid rgb(239,237,240);
                    display: none;
                    overflow: hidden;
                    background-color: @white;
                    z-index: 99999;
                }
                .zoom-box .viewer-box>img{
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .image-gallery {
                    .flex-container(column-reverse, nowrap);
                    .align-items(flex-start);

                    .thumbnails {
                        .flex-container(row, wrap);
                        .justify-content(space-between);
                        margin: 40px 0 0;
                        width: 100%;
                        .thumb-container{
                            width: calc(~"33% - 9px");
                            display: block;
                            margin: 0px 0 15px;
                        }

                        .thumbnail-image {
                            width: 100%;
                            min-height: 150px;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-size: contain;
                        }
                    }

                    .primary {
                        min-width: 500px;
                        //margin-top: -60px;
                        min-height: 400px;
                        //background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        width: 100%;
                        background-size: 400px;
                        //margin-top: 50px;
                    }

                }

                .products-thumbnails {
                    .flex-container(row, nowrap);

                    img {
                        width: 20%;
                    }
                }

                .dimensions {
                    text-align: center;                    
                    img {
                        max-width: 65% !important;
                    }
                }
            }

            .right {
                .sku {
                    font-weight: 700;
                    line-height: 30px;
                    margin: 0;
                    a {
                        font-size: 18px;
                    }
                    .product-login-message {
                        padding: 20px 0;
                    }
                }

                .price-text {
                    .flex-container(row, nowrap);
                    .justify-content(flex-start);
                    .align-items(center);
                    width: 85%;
                    @media screen and (max-width: 700px) {
                        display: block;
                        text-align: center;
                    }                    

                    h4 {
                        font-size: 40px;
                        line-height: 66px;
                        color: @mainblue;;
                        margin: 0;

                        span {
                            font-size: 40px;
                            line-height: 66px;
                            color: @mainblue;;
                            margin: 0;
                            &.currency{
                                .font(@head-font, 40px, bold, 66px);
                                color: @product-head;
                                margin: 0;
                            }
                        }
                    }

                    .or {
                        .font(@head-font, 29px, bold, 66px);
                        color: @product-head;
                        margin: 0;
                        @media screen and (max-width: 700px) {
                            .font(@head-font, 29px, bold, 46px);
                        }
                    }

                    .bulk {
                        margin-left: 15px;
                        .top {
                            font-weight: 700;
                            line-height: 30px;
                            margin: 0;
                        }

                        .modal {
                            display: none;
                            position: fixed;
                            z-index: 13000;
                            padding-top: 100px;
                            left: 0;
                            overflow-y: auto;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.8);

                            .modal-content {
                                background-color: @white;
                                margin: 0 auto;
                                padding: 15px 40px 30px;
                                width: 650px;
                                top: 32%;
                                position: absolute;
                                left: 25%;
                                right: 25%;

                                .modal-top {
                                    .flex-container(row, nowrap);
                                    .justify-content(space-between);
                                    .align-items(center);

                                    .close {
                                        color: @mainblue;

                                        &:hover {
                                            cursor: pointer;
                                        }

                                        .material-icons {
                                            font-size: 37px;
                                            line-height: 96px
                                        }
                                    }

                                    h6 {
                                        font-size: 37px;
                                        line-height: 96px;
                                        margin: 0;
                                    }
                                }

                                .quantity-table {
                                    .font(@font1, 12px, bold, 27px);

                                    table {
                                        width: 100%;
                                        max-width: 100%;
                                        border: 1px solid @table-border;
                                        border-bottom: 0;
                                        border-left: 0;

                                        td {
                                            border: 1px solid @table-border;
                                            // padding: 12px 40px;
                                            vertical-align: middle;
                                            .font(@font1, 12px, bold, 66px);
                                            text-align: center;
                                            border-top: 0;
                                            border-right: 0;
                                        }

                                        thead {
                                            th {
                                                border: 1px solid @table-border;
                                                font-size: 13px;
                                                font-weight: bold;
                                                border-top: 0;
                                                border-right: 0;
                                                padding: 5px 10px;
                                                line-height: 57px;
                                            }

                                            tr {
                                                background-color: @lightgrey;
                                            }
                                        }

                                        &.table-striped {
                                            tbody {
                                                tr {
                                                    .trans(all 0.2s);

                                                    &:nth-of-type(even) {
                                                        background-color: @lightgrey;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }                    
                }

                .product-attributes {
                    width: 92%;
                    margin: 0;
                    .flex-container(row, nowrap);
                    .justify-content(space-between);
                    .align-items(center);

                    .tooltip {
                        position: relative;
                        .flex-container(column, nowrap);
                        color: @mainblue;
                        margin: 20px 0 0;
                        .trans(all 0.5s);

                        &.material{
                            font-size: 22px;
                            margin-left: 10px;
                            padding-left: 10px;
                            .tooltiptext {                                
                                background-color: @mainblue;
                                color: @white;
                                padding: 15px;
                                border-radius: 6px;
                                z-index: 999;
                                font-size: 13px;
                                font-weight:500;
                                text-align: left;
                            }
                        }

                        .tooltiptext {
                            display: none;
                            width: 600px;
                            color: @select-text;
                            top: 65px;
                            right: 0;
                            /* Position the tooltip */
                            position: absolute;
                            z-index: 1;
                            color: @mainblue;
                            font-size: 14px;
                        }

                        &:hover {
                            .tooltiptext {
                                .flex-container(row, nowrap);
                                animation-name: fadeInLeft;
                                animation-duration: 0.5s;
                            }
                        }
                    }

                    .variation {
                        width: 100%;
                        /*margin: 25px 0 0;*/

                        #display {
                            margin: -20px 0 0 0;
                            padding: 0px 0 6px 0;
                            display: none;
                            animation-duration: 0.5s;
                            -webkit-animation-name: fadeInUpSD;
                            animation-name: fadeInUpSD;
                            animation-fill-mode: both;
                        }

                        #display-material {
                            margin: -20px 0 0 0;
                            padding: 0px 0 6px 0;
                            display: none;
                            animation-duration: 0.5s;
                            -webkit-animation-name: fadeInUpSD;
                            animation-name: fadeInUpSD;
                            animation-fill-mode: both;
                        }
                    }
                }

                .measures {
                    margin: 0 0 30px;

                    h3 {
                        width: 100%;
                        font-size: 35px;
                        line-height: 96px;
                        margin: 15px 0 0;
                    }

                    & .fields-container {
                        .align-items(center);
                        .justify-content(flex-start);
                        &.has-shipping{
                            .justify-content(flex-start);
                        }

                        & .form-row {
                            padding: 0;

                            & .input-field {
                                & .input {
                                    padding: 0;
                                }
                            }

                            & input#quantity {
                                border: 2px solid @select_border;
                                padding: 18px 10px;
                                text-align: center;
                            }

                            &:first-child {
                                width: 15%;
                                @media screen and (max-width: 900px) {
                                    width: 50%;
                                }
                                @media screen and (max-width: 460px) {
                                    width: 25%;
                                }
                            }

                            &.final-row {
                                padding: 0;
                                width: 35%;
                                text-align: center;
                                margin-left: 20px;                                
                                @media screen and (max-width: 460px) {
                                    width: 65%;
                                    margin-bottom: 35px;
                                }
                            }
                        }

                        .local-shipping, .out-of-region-shipping, .out-of-stock-shipping, .outcast-shipping, .call-for-shipping {
                            .flex-container(row, nowrap);
                            .align-items(center);

                            i {
                                color: #00cd00;
                                font-size: 20px;
                                margin-right: 5px;
                            }

                            span {
                                font-weight: 700;
                                line-height: 30px;                            
                            }
                        }
                        .no-shipping {                            
                            .flex-container(row, nowrap);
                            .align-items(center);

                            i {
                                color: #ff0000;
                                font-size: 20px;
                                margin-right: 5px;
                            }

                            span {
                                font-weight: 700;
                                line-height: 30px;
                                a {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 30px;
                    font-weight: 600;
                }

                .specs {
                    .flex-container(row, nowrap);
                    .justify-content(start);
                    margin: 40px 0 10px;
                    a {
                        padding-right: 15px;
                    }
                    .video {
                        .flex-container(row, nowrap);
                        .align-items(center);

                        i {
                            padding: 0 10px 0 0px;
                        }
                    }
                }

                #part-description{
                    font-size: 14px;
                    font-weight: 300;
                    font-family: 'Roboto', sans-serif;
                    line-height: 20px;
                    color: @dark;
                    display: flex;
                    justify-content: space-evenly;
                    h2{
                        font-size: 17px;
                        font-weight: 500;
                        line-height: 22px;
                        color: @mainblue;
                        font-family: 'Roboto', sans-serif;
                        margin: 15px 0 10px 0;
                        padding: 0;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 300;
                        font-family: 'Roboto', sans-serif;
                        line-height: 22px;
                        color: @dark;
                        &.bottom {
                            width: 100%;
                        }
                    }
                    ul{
                        li{
                            font-size: 14px;
                            font-weight: 300;
                            font-family: 'Roboto', sans-serif;
                            line-height: 22px;
                            color: @dark;
                            padding-left: 5px;
                            &::marker {
                                content: "▶";
                                font-size: 11px;
                                color:@mainblue;
                            }
                        }
                        &.material-list {
                            li {
                                &.brass {
                                    &::marker {
                                        content: url(/assets/images/bullets/brass.png);
                                    }
                                }
                                &.steel {
                                    &::marker {
                                        content: url(/assets/images/bullets/steel.png);
                                    }
                                }
                                &.ss316 {
                                    &::marker {
                                        content: url(/assets/images/bullets/316.png);
                                    }
                                }
                                &.ss303 {
                                    &::marker {
                                        content: url(/assets/images/bullets/303.png);
                                    }
                                }
                                &.col-polypro {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.col-pvdf {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.col-cpvc {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.col-iron {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.iron {
                                    &::marker {
                                        content: url(/assets/images/bullets/iron.png);
                                    }
                                }
                                &.nat-polypro {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.nat-pvdf {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.polypro {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.ptfe {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.pvc {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.pvdf {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &.abs {
                                    &::marker {
                                        content: url(/assets/images/bullets/bex.png);
                                    }
                                }
                                &::marker {
                                    content: url(/assets/images/bullets/bex.png);
                                }
                            }
                        }
                    }
                    div {
                        &.left {
                            width: 100%;
                        }
                        &.right {
                            width: 100%;
                            @media screen and (max-width: 1290px) {
                                margin-top:0;
                            }
                            @media screen and (max-width: 700px) {
                                padding-left: 0;
                                align-items: flex-start;
                            }
                        }       
                    }
                    br{
                        display: block;
                    }
                    b{
                        font-family: 'Roboto', sans-serif;
                    }
                    .model-number {
                        width: 98%;
                        text-align: left;
                    }
                }
                #single-product-cart {
                    form {
                        min-height: 50px;
                    }
                    
                    .top {
                        font-weight: 700;
                        line-height: 30px;
                        margin: 0;
                    }

                    .modal {
                        display: none;
                        position: fixed;
                        z-index: 13000;
                        padding-top: 100px;
                        left: 0;
                        overflow-y: auto;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.8);

                        .modal-content {
                            background-color: @white;
                            margin: 0 auto;
                            padding: 15px 40px 30px;
                            width: 650px;
                            top: 32%;
                            position: absolute;
                            left: 25%;
                            right: 25%;

                            .modal-top {
                                .flex-container(row, nowrap);
                                .justify-content(space-between);
                                .align-items(center);

                                .close {
                                    color: @mainblue;

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    .material-icons {
                                        font-size: 37px;
                                        line-height: 96px
                                    }
                                }

                                h6 {
                                    font-size: 37px;
                                    line-height: 96px;
                                    margin: 0;
                                }
                            }

                            .cart-table {
                                table {
                                    width: 100%;
                                    max-width: 100%;
                                    margin: 30px 0 0px 0;
                
                                    thead {
                                        th {
                                            border-bottom: 2px solid @darkcolor1;
                                            font-size: 18px;
                                            font-weight: bold;
                                            border-top: 0;
                                            border-right: 0;
                                            padding: 15px 0px;
                                            line-height: 18px;
                                            text-align: left;
                
                                            &:nth-child(5) {
                                                text-align: right;
                                            }
                                        }
                                    }
                
                                    tr {
                                        td {
                                            vertical-align: middle;
                                            text-align: left;
                                            font-size: 16px;
                                            font-weight: 600;
                                            border-bottom: 1px solid @border-table;
                                            padding: 20px 10px;
                
                                            &:first-child {
                                                width: 100px;
                
                                                .product-outer {
                                                    max-height: 150px;
                                                    width: 100%;
                                                }
                                            }
                
                                            .item-info {
                                                a {
                                                    display: block;
                                                    text-decoration: none;
                                                    font-size: 16px;
                                                }
                                            }
                
                                            &:last-child {
                                                a {
                                                    color: red;
                                                    font-weight: bold;
                                                    font-size: 14px;
                                                }
                                            }
                
                                            &:nth-child(5) {
                                                text-align: right;
                                            }
                
                                            &:nth-child(6) {
                                                text-align: right;
                                                padding-right: 30px;
                                            }
                
                                            input[type=text] {
                                                border: 2px solid @select_border;
                                                width: 65px;
                                                padding: 14px 10px;
                                                text-align: center;
                
                                                &.placeholder-fix {
                                                    &:focus::-webkit-input-placeholder {
                                                        color: transparent;
                                                    }
                
                                                    &:focus::-moz-placeholder {
                                                        color: transparent;
                                                    }
                
                                                    &:-moz-placeholder {
                                                        color: transparent;
                                                    }
                                                }
                                            }
                
                                            form{
                                                .trigger{
                                                    display: none;
                                                }
                                            }
                                        }
                
                                        &.dark-border {
                                            td {
                                                border-bottom: 2px solid @darkcolor1;;
                                            }
                                        }
                
                                    }
                
                                    &.cart_total {
                                        margin: 0 0 35px 0;
                
                                        tr {
                                            th {
                                                text-align: left;
                                                strong {
                                                    font-weight: bold;
                                                    font-size: 24px;
                                                    line-height: 50px;
                                                }
                
                                                border-bottom: 1px solid @border-table;
                
                                            }
                
                                            td {
                                                padding: 30px 0;
                                                text-align: right;
                                                span{
                                                    font-size: 16px;
                                                }
                                            }
                
                                            &.total {
                                                th {
                                                    border-bottom: none;
                                                    strong {
                                                        font-weight: bold;
                                                        font-size: 33px;
                                                        line-height: 50px;
                                                    }
                                                }
                
                                                td {
                                                    font-weight: bold;
                                                    font-size: 33px;
                                                    border-bottom: none;
                
                                                    &.total {
                                                        font-family: @head-font;
                                                        font-size: 45px;
                
                                                        span {
                                                            color: @mainblue;
                                                            font-family: @head-font;
                                                            font-size: 45px;
                                                            &.currency{
                                                                color: @dark;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                
                                    & .cart-actions {
                                        & td {
                                            width: 100%;
                                            border: none;
                                            padding: 10px 0;
                                            &:first-child{
                                                width: auto;
                                            }
                
                                            & .form-row {
                                                padding: 0;
                                                .flex-container();
                                                .justify-content(flex-end);
                                            }
                                        }
                                        .text {
                                            a {
                                                font-size:25px;
                                                margin-left: 10px;
                                            }
                                            i {
                                                &.fa-plus {
                                                    font-size: 1.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .products-header-bottom {
            margin-top: 20px;
            .flex-container(column, nowrap);

            .ui-accordion {
                width: 100%;
                position: relative;

                h3 {
                    background: none;
                    border: none;
                    border-radius: 0;
                    margin: 0 0 30px 0;
                    .box-shadow(1px 1px 20px -7px rgba(0, 0, 0, 0.2));
                    color: @product-head;
                    cursor: pointer;
                    width: 100%;
                    .font(@head-font, 30px, bold, 46px);
                    padding: 20px 7%;
                    background-repeat: no-repeat;
                    background-image: url(../images/baseline-expand_more-24px.svg);
                    background-position: 96% 50%;
                    position: relative;

                    &.ui-accordion-header-active {
                        background-repeat: no-repeat;
                        background-image: url(../images/baseline-chevron_right-24px.svg);
                        background-position: 96% 50%;
                    }

                    .ui-icon {
                        background: none;
                    }
                }

                .ui-accordion-content {
                    padding: 20px 7% 70px;;
                    position: relative;
                    top: -40px;

                    &.ui-accordion-content-active {
                        .box-shadow(0px 13px 20px -10px rgba(0, 0, 0, 0.2));
                        border: none;
                    }
                    .dimensions{
                        img{
                            height: auto;
                        }
                    }
                }

                .product-table {
                    margin: 0;

                    table {
                        tbody {
                            tr {
                                &:hover {
                                    box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
                                    td {
                                        border: none;
                                        background: @white;
                                        cursor: pointer;
                                        .scale(1.1);
                                    }
                                }
                                &.active {
                                    box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
                                    td {
                                        border: none;
                                        background: @mainblue;
                                        cursor: pointer;
                                        .scale(1.1);
                                        a {
                                            color: @white !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .panel {
                    padding: 0 18px;
                    display: none;
                    background-color: white;
                    overflow: hidden;
                }
            }

            .panel {
                padding: 0 18px;
                display: none;
                background-color: white;
                overflow: hidden;
            }
        }


    }
}