/////***********ot Found**********//////////////////
.not-found {
    .inner-page {
        padding-bottom: 120px;
        .inner-content {
            width: 80.5%;
            margin: 0 auto;
            .three-section {
                .flex-container(row, nowrap);
                .justify-content(space-between);
                padding: 20px 0 65px;
                h6 {
                    .font(@font1, 18px, bold, 29px);
                    margin: 0;
                }
                p {
                    font-size: 14px;
                    line-height: 19px;
                    padding-bottom: 0;
                    font-weight: 500;
                    strong {
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: bold;
                    }
                }
                a {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }
    }
}