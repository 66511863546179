/////***********Contact us**********//////////////////
#contact-wrap {
    .inner-page {
        padding-bottom: 120px;
        .inner-content {
            width: 80.5%;
            margin: 0 auto;
            .three-section {
                .flex-container(row, nowrap);
                .justify-content(space-between);
                padding: 20px 0 65px;
                h6 {
                    .font(@font1, 18px, bold, 29px);
                    margin: 0;
                }
                p {
                    font-size: 14px;
                    line-height: 19px;
                    padding-bottom: 0;
                    font-weight: 500;
                    strong {
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: bold;
                    }
                }
                a {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
            .contact-form {
                .head {
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 22px;
                }
                form {
                    padding: 20px 0;
                    .flex-container(column, nowrap);
                    color: @select-text;
                    .form-row {
                        //.flex-container(row, nowrap);
                        //.justify-content(space-between);
                        //padding: 10px 0;
                        //width: 100%;
                        &.btn {
                            .justify-content(flex-end);
                            a {
                                font-size: 23px;
                                line-height: 22px;
                            }
                        }
                        //&.second {
                        //    width: 94%;
                        //}
                        //& .country {
                        //    width: 242px;
                        //}
                        input[type=text] {
                            //padding: 25px 30px 25px 30px;
                            border: 1px solid @table-border;
                            color: @select-text;
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: normal;
                            //&#zip {
                            //    width: 225px;
                            //}
                        }
                        //input[type=submit] {
                        //    padding: 25px 10px 25px 25px;
                        //    border: none;
                        //    color: @mainblue;
                        //    font-family: Montserrat;
                        //    font-size: 16px;
                        //    font-weight: 700;
                        //    line-height: normal;
                        //    background: none;
                        //}
                        textarea {
                            padding: 25px 30px 25px 30px;
                            border: 1px solid @table-border;
                            color: @select-text;
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: normal;
                            width: 100%;
                        }
                        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: @select-text;
                        }

                        :-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: @select-text;
                        }

                        ::-ms-input-placeholder { /* Microsoft Edge */
                            color: @select-text;
                        }
                    }
                }

            }
        }
        @media screen and (max-width: 1700px) {
            .inner-content {
                width: 84.5%;
            }
        }
        @media screen and (max-width: 1480px) {
            .inner-content {
                width: 90%;
            }
        }
        @media screen and (max-width: 1400px) {
            .inner-content {
                width: 100%;
            }
        }
        @media screen and (max-width: 1220px) {
            .inner-content {
                .three-section {
                    h6 {
                        padding-right: 10px;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .inner-content {
                width: 100%;
                margin: 0;
                .three-section {
                    h6 {
                        padding-right: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .inner-content {
                .three-section {
                    .flex-container(column, nowrap);
                    h6 {
                        font-size: 18px;
                        padding-right: 0;
                    }
                    p {
                        strong {
                            font-size: 14px;
                        }
                    }
                    .contact {
                        padding: 0 0 20px 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    #contact-wrap {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 84.5%;
                }
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    #contact-wrap {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 90%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    #contact-wrap {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #contact-wrap {
        .page-outer-wrapper {
            .page-title-wrapper {
                .page-title {
                    width: 100%;
                }
            }
        }
    }
}