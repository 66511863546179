@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
hr,
input,
textarea,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
main,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  font-family: Montserrat;
}
a {
  text-decoration: none;
  color: #61b9e7 !important;
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
a:hover {
  cursor: pointer;
  color: #197cb2;
}
img {
  max-width: 100%;
}
p {
  font-family: Montserrat;
  font-weight: normal;
  padding-bottom: 10px;
}
fieldset {
  border: none;
}
/**
 * @font-face LESS Mixin
 * use: .font-face(
 *   @font-family, // name
 *   @file-path,   // absolute/relative URL to font files
 *   @font-weight, // light/normal/bold/inherit | 300/400/700
 *   @font-style   // italic/normal/inherit
 * )
 */
/**
 * Material icons mixin
 */
i.inherit {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  vertical-align: middle;
}
body .notifications-container {
  padding-bottom: 20px;
}
body .notifications-container .notification {
  padding: 10px 25px 10px 38px;
  border-radius: 5px;
  position: relative;
  background-color: #fdfdfd;
  margin-bottom: 10px;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
}
body .notifications-container .notification p {
  padding: 0;
  margin: 0;
  line-height: 1.5em;
}
body .notifications-container .notification .close-notification {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
body .notifications-container .notification.show {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
body .notifications-container .notification.hide {
  opacity: 0;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}
body .notifications-container .notification:after {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 19px;
}
body .notifications-container.fixed {
  position: fixed;
  top: 95px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50vw;
  z-index: 1001;
  padding-bottom: 0px;
}
@media screen and (max-width: 767px) {
  body .notifications-container.fixed {
    width: 80vw;
  }
}
@media screen and (max-width: 639px) {
  body .notifications-container.fixed {
    width: 90vw;
  }
}
@media screen and (max-width: 639px) {
  body .notifications-container.fixed .notification {
    width: 100%;
  }
}
body .notification {
  max-width: 1024px;
  margin: 0px auto;
  width: 75%;
  position: relative;
  padding: 20px 5px 20px 80px;
  color: #fff;
  box-shadow: 0px 0px 5px #888;
  background-color: #fff;
}
body .notification .title {
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
}
body .notification p {
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 5px;
  color: #fff;
  margin: 0;
}
body .notification a {
  color: #efefef;
}
body .notification.success {
  background-color: #197cb2;
}
body .notification.success:after {
  content: '\E5CA';
  font-weight: 700;
}
body .notification.info {
  background-color: #61b9e7;
}
body .notification.info:after {
  content: '\E88E';
}
body .notification.warning {
  background-color: #ffb238;
}
body .notification.warning:after {
  content: '\E002';
}
body .notification.error {
  background-color: #f73737;
}
body .notification.error:after {
  content: '\E000';
}
body .notification:after {
  font-weight: 300;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  position: absolute;
  top: 50%;
  left: 40px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body .pagination {
  padding: 20px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
body .pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
body .pagination ul li {
  margin: 0 5px;
}
body .pagination ul li a,
body .pagination ul li span {
  font-size: 18px;
  line-height: 22px;
}
/* 
 * MOBY.LESS VERSION 2.0.7
 */
.moby-overlay {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.moby-overlay.light {
  background: #289DCC;
}
.moby-overlay.moby-overlay-active {
  visibility: visible !important;
  opacity: 0.2 !important;
  background: #000000;
}
.moby-body-fixed {
  overflow: hidden !important;
}
.moby {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  overflow: auto;
}
.moby * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.moby.moby-hidden {
  display: none;
}
.moby.moby-demo {
  color: #ffffff;
  background: blue;
  position: fixed;
  z-index: 3000;
  top: 0;
  height: 100%;
  width: 30%;
  right: 0;
  min-width: 333px;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  padding: 60px 0 20px 0;
  overflow: auto;
}
.moby.moby-demo.moby-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.moby.moby-demo .moby-inner {
  height: 100%;
  min-height: 520px;
}
.moby.moby-demo .moby-inner > p {
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0 30px;
  font-size: 13px;
  text-align: center;
}
.moby.moby-demo .moby-close {
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 12px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.moby.moby-demo .moby-close .moby-close-icon {
  margin-right: 0;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
}
.moby.moby-demo .moby-close .moby-close-icon::before,
.moby.moby-demo .moby-close .moby-close-icon::after {
  background: #ffffff;
}
.moby.moby-demo .moby-wrap {
  height: calc(100% - 40px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.moby.moby-demo .moby-wrap > div {
  width: 100%;
}
.moby.moby-demo .moby-wrap img {
  margin: 0 auto 40px auto;
  display: block;
  width: 100px;
  height: auto;
}
.moby.moby-demo .moby-wrap .moby-menu {
  width: 100%;
}
.moby.moby-demo .moby-wrap .moby-menu > * {
  width: 100%;
}
.moby.moby-demo .moby-wrap .moby-menu {
  width: 80%;
}
.moby.moby-demo ul li ul a {
  padding: 20px 70px 20px 70px;
  font-size: 16px;
  background: #0000cc;
}
.moby.moby-demo ul li a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  padding: 20px 70px 20px 70px;
  text-align: center;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -ms-transition: color 0.3s;
  transition: color 0.3s;
}
.moby.moby-demo ul li a:hover {
  color: #e0e0e0;
}
.moby.moby-demo ul li a .moby-expand {
  display: none;
}
.moby.moby-demo ul li a .moby-expand:hover {
  color: #e0e0e0;
}
.moby.moby-demo ul li a .moby-expand i,
.moby.moby-demo ul li a .moby-expand span {
  font-size: 20px;
}
.moby.right-side {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: auto;
  width: 28%;
  right: 0;
  min-width: 333px;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.moby.right-side .material-icons {
  color: #ffffff;
  font-size: 22px;
}
@media screen and (max-width: 400px) {
  .moby.right-side {
    min-width: 300px;
  }
}
.moby.right-side.moby-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .moby.right-side {
    width: 100%;
    height: 100%;
  }
}
.moby.fade {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
}
.moby.fade.moby-active {
  opacity: 1;
  visibility: visible;
}
.moby.fade ul {
  width: 100%;
}
.moby.left-side {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 30%;
  min-width: 333px;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.moby.left-side.moby-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.moby.top-full {
  position: fixed;
  z-index: 13000;
  top: 0;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  height: 100%;
  left: 0px;
  width: 100%;
}
.moby.top-full ul {
  width: 100%;
}
.moby.top-full.moby-active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.moby.bottom-full {
  position: fixed;
  z-index: 13000;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  height: 100%;
  left: 0px;
  width: 100%;
}
.moby.bottom-full ul {
  width: 100%;
}
.moby.bottom-full.moby-active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.moby.grow-out {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  -ms-transform: scale(.7);
  transform: scale(.7);
  visibility: hidden;
  opacity: 0;
}
.moby.grow-out ul {
  width: 100%;
}
.moby.grow-out.moby-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.moby.shrink-in {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
}
.moby.shrink-in ul {
  width: 100%;
}
.moby.shrink-in.moby-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.moby .menu {
  list-style: none;
  margin: 30px 0 80px;
  padding: 0;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
@media screen and (max-width: 900px) {
  .moby .menu {
    margin: 30px 0 -30px;
  }
}
.moby .menu ul {
  display: none;
}
.moby ul li ul a {
  padding: 20px 20px 20px 34px;
  font-size: 14px;
}
.moby ul li ul li ul li a {
  padding-left: 42px;
}
.moby ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 15px;
  display: inline-block;
  padding: 27px 10%;
  text-align: left;
  position: relative;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 27px;
  font-weight: normal;
  line-height: normal;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.moby ul li a:hover {
  color: #61b9e7;
}
.moby ul li a:hover::after {
  width: 100%;
}
.moby ul li a .moby-expand {
  display: none;
}
.moby ul li a .moby-expand i,
.moby ul li a .moby-expand span {
  font-size: 30px;
  color: #ffffff;
}
.moby ul li .dropdown-toggle {
  display: none;
}
.moby .moby-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  -webkit-flex-flow: row-reverse nowrap;
  flex-flow: row-reverse nowrap;
  height: 100%;
}
@media screen and (max-width: 640px) {
  .moby .moby-wrap {
    height: auto;
  }
}
.moby .moby-menu {
  width: 80%;
}
.moby .moby-close {
  width: 20%;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 40px 0;
  background: #000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.moby .moby-close:hover {
  color: #ffffff;
}
.moby .moby-close .moby-close-icon {
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  height: 20px;
  width: 20px;
}
.moby .moby-close .moby-close-icon:hover::before {
  background: #ffffff;
}
.moby .moby-close .moby-close-icon:hover::after {
  background: #ffffff;
}
.moby .mob-top {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 0 10%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: none;
}
@media screen and (max-width: 1024px) {
  .moby .mob-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
.moby .mob-top .unit-select {
  margin: 15px 0 0;
}
.moby .mob-top .language {
  margin: 15px 15px 0 0;
  color: #000000;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
  align-self: center;
}
.moby .mob-top .language select {
  margin: 0;
}
.moby .mob-top .country_icon {
  margin: 15px 15px 0 0;
  color: #000000;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding: 40px 0 0;
  align-self: center;
}
.moby .mob-top .country_icon img {
  margin: 10px 0 0 0;
}
@media screen and (max-width: 640px) {
}
.moby .mob-top .location_sel {
  list-style: none;
  padding: 0;
  margin: 0;
}
.moby .mob-top .location_sel li {
  position: relative;
}
.moby .mob-top .location_sel li > span,
.moby .mob-top .location_sel li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  color: #61b9e7;
  padding: 0 10px;
}
.moby .mob-top .location_sel li img {
  max-width: 20px;
  margin: 0;
  margin-right: 5px;
}
.moby .mob-top .location_sel li > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 110%;
  left: 0;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background: #ffffff;
  padding-top: 30px;
}
.moby .mob-top .location_sel li > ul.active {
  opacity: 1;
  visibility: visible;
  z-index: 10;
  top: 100%;
}
.moby .mob-top .location_sel li > ul li {
  margin: 5px 0;
}
.moby .mob-top .location_sel li:hover > ul {
  opacity: 1;
  visibility: visible;
  z-index: 10;
  top: 100%;
}
.moby .mob-top .register,
.moby .mob-top .login {
  margin: 0 55px 0 0;
  align-self: center;
  padding: 80px 0;
}
.moby .mob-top .register a,
.moby .mob-top .login a {
  text-decoration: none;
  color: #61b9e7;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.moby .mob-top .register a:hover,
.moby .mob-top .login a:hover {
  cursor: pointer;
  color: #197cb2;
}
.moby .mob-top.usermenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.moby .mob-top.usermenu .dashboard.header_item_right a {
  text-decoration: none;
  display: block;
  padding: 55px 0%;
  text-align: left;
  position: relative;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 27px;
  font-weight: 400;
  line-height: normal;
  transition: all .2s;
}
@media screen and (max-width: 1200px) {
  .moby .mob-top.usermenu {
    padding: 0 0 0 10%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
@media screen and (max-width: 900px) {
  .moby .mob-top.usermenu .register a,
  .moby .mob-top.usermenu .login a {
    font-size: 20px;
  }
}
body {
  margin: 0;
}
.fixed-header {
  width: 100%;
  min-height: 96px;
}
@media screen and (max-width: 640px) {
  .fixed-header {
    min-height: 85px;
  }
}
header .header_top {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #ffffff;
  height: 96px;
}
header .header_top .container {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
header .header_top .container .header_left {
  margin-left: 2%;
}
header .header_top .container .header_right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: stretch;
  align-items: stretch;
}
header .header_top .container .header_right .location_sel {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .header_top .container .header_right .location_sel li {
  position: relative;
}
header .header_top .container .header_right .location_sel li > span,
header .header_top .container .header_right .location_sel li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  color: #61b9e7;
  padding: 0 10px;
}
header .header_top .container .header_right .location_sel li img {
  max-width: 30px;
  margin: 0;
  margin-right: 5px;
}
header .header_top .container .header_right .location_sel li > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 110%;
  left: 0;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background: #ffffff;
  padding-top: 30px;
}
header .header_top .container .header_right .location_sel li > ul li {
  margin: 5px 0;
}
header .header_top .container .header_right .location_sel li:hover > ul {
  opacity: 1;
  visibility: visible;
  z-index: 10;
  top: 100%;
}
header .header_top .container .header_right .header_item_right.language,
header .header_top .container .header_right .header_item_right.country_icon {
  margin-right: 20px;
}
header .header_top .container .header_right .header_item_right.language select,
header .header_top .container .header_right .header_item_right.country_icon select {
  border: none;
  margin-right: 0;
}
@media screen and (min-width: 1025px) {
  header .header_top .container .header_right .header_item_right.menu {
    display: none;
  }
}
header .header_top .container .header_right .header_item_right .cart-quantity {
  background-color: #61b9e7;
  color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  position: relative;
  top: -15px;
  left: -10px;
  font-weight: 700;
  width: 26px;
  height: 26px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
header .header_top .container .header_right .material-icons {
  color: #ffffff;
  font-size: 22px;
  line-height: inherit;
}
header .header_top .container .header_right .language,
header .header_top .container .header_right .country_icon {
  margin: 0 40px 0 0;
  color: #000000;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  align-self: center;
}
header .header_top .container .header_right .language img,
header .header_top .container .header_right .country_icon img {
  margin: 10px 0 0 0;
}
header .header_top .container .header_right .register,
header .header_top .container .header_right .login {
  margin: 0 40px 0 0;
  align-self: center;
}
@media screen and (max-width: 640px) {
  header .header_top .container .header_right .register,
  header .header_top .container .header_right .login {
    margin: 0 10px 0 0;
    display: none;
  }
}
header .header_top .container .header_right .register a,
header .header_top .container .header_right .login a {
  text-decoration: none;
  color: #61b9e7;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
header .header_top .container .header_right .register a:hover,
header .header_top .container .header_right .login a:hover {
  cursor: pointer;
  color: #197cb2;
}
header .header_top .container .header_right ul.login-link {
  list-style: none;
}
header .header_top .container .header_right ul.login-link li {
  display: inline-block;
  position: relative;
  line-height: 21px;
  text-align: center;
}
header .header_top .container .header_right ul.login-link li ul.logout-link {
  display: none;
  min-width: 125px;
  position: absolute;
  z-index: 999;
  right: 5px;
  list-style: none;
  top: 10px;
}
header .header_top .container .header_right ul.login-link li ul.logout-link li {
  background-color: #65b9e7;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  width: 99px;
}
header .header_top .container .header_right ul.login-link li ul.logout-link li a {
  text-decoration: none;
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
header .header_top .container .header_right ul.login-link li ul.logout-link li a:hover {
  cursor: pointer;
  color: #197cb2;
}
header .header_top .container .header_right ul.login-link:hover ul.logout-link {
  display: block;
}
header .header_top .container .header_right ul.login-link:hover ul.logout-link li {
  display: block;
}
header .header_top .container .header_right .search {
  background: #1f1f1f;
  margin: 0 -1px 0 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  height: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
header .header_top .container .header_right .search a {
  padding: 30px 36px;
  line-height: 30px;
}
@media screen and (max-width: 640px) {
  header .header_top .container .header_right .search a {
    padding: 30px 10px;
  }
}
header .header_top .container .header_right .search:hover {
  cursor: pointer;
  background: #101010;
}
header .header_top .container .header_right .search .search-box {
  position: absolute;
  top: 100%;
  width: 350px;
  right: 0;
  display: none;
}
@media screen and (max-width: 500px) {
  header .header_top .container .header_right .search .search-box {
    position: fixed;
    top: 96px;
    width: 100%;
    left: 0;
    right: auto;
  }
}
header .header_top .container .header_right .search .search-box form.form .form-row {
  padding: 0;
}
header .header_top .container .header_right .search .search-box form.form .form-row .input-field .input {
  position: relative;
  background: white;
  padding: 0;
}
header .header_top .container .header_right .search .search-box form.form .form-row .input-field .input .input__field {
  padding: 20px 30px 20px 15px;
}
header .header_top .container .header_right .search .search-box form.form .form-row .input-field .input .form-button {
  position: absolute;
  top: 15px;
  right: 8px;
}
header .header_top .container .header_right .search .search-box form.form .form-row .input-field .input .form-button i {
  color: #197cb2;
}
header .header_top .container .header_right .cart {
  background: #1f1f1f;
  height: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
header .header_top .container .header_right .cart a {
  padding: 30px 36px;
  line-height: 30px;
}
@media screen and (max-width: 640px) {
  header .header_top .container .header_right .cart a {
    padding: 30px 10px;
  }
}
header .header_top .container .header_right .cart:hover {
  cursor: pointer;
  background: #101010;
}
header .header_top .container .header_right .catalog {
  background: #61b9e7;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  height: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
header .header_top .container .header_right .catalog a {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 75px;
  color: #ffffff !important;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
}
@media screen and (max-width: 1200px) {
  header .header_top .container .header_right .catalog a {
    padding: 0 45px;
  }
}
header .header_top .container .header_right .catalog:hover {
  cursor: pointer;
  background: #197cb2;
}
header .header_top .container .header_right .menu {
  background: #1f1f1f;
}
header .header_top .container .header_right .menu .header-menu {
  display: none;
}
header .header_top .container .header_right .menu .navbars {
  display: inline-block;
  padding: 30px 30px;
  height: 96px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
@media screen and (max-width: 640px) {
  header .header_top .container .header_right .menu .navbars {
    padding: 30px 15px;
  }
}
header .header_top .container .header_right .menu .navbars .material-icons {
  font-size: 32px;
}
header .header_top .container .header_right .menu:hover {
  cursor: pointer;
  background: #101010;
}
header .header_top .container .header_right .fa {
  color: #ffffff;
  font-size: 18px;
}
@media screen and (max-width: 1366px) {
  header .header_top .container .header_left {
    margin-left: 32px;
  }
}
@media screen and (max-width: 1300px) {
  header .header_top .container .header_left {
    margin-left: 2%;
  }
  header .header_top .container .header_right select {
    margin: 0 20px 0 0;
  }
  header .header_top .container .header_right .language,
  header .header_top .container .header_right .country_icon {
    margin: 0 20px 0 0;
  }
  header .header_top .container .header_right .register,
  header .header_top .container .header_right login {
    margin: 0 20px 0 0;
  }
}
@media screen and (max-width: 1200px) {
  header .header_top .container {
    width: 100%;
    margin: 0;
  }
  header .header_top .container .header_left {
    margin-left: 22px;
  }
  header .header_top .container .header_right .catalog a {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  header .header_top .container .header_right .country_icon {
    display: none;
  }
  header .header_top .container .header_right select {
    display: none;
  }
  header .header_top .container .header_right .language {
    display: none;
  }
  header .header_top .container .header_right .catalog a a {
    font-size: 12px;
  }
}
@media screen and (max-width: 900px) {
  header .header_top .container .header_right .catalog {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  header .header_top .container .header_left {
    margin-left: 20px;
  }
  header .header_top .container .header_left img {
    width: 80%;
  }
  header .header_top .container .header_right .search {
    padding: 10px;
  }
  header .header_top .container .header_right .menu .navbars {
    padding: 30px;
  }
  header .header_top .container .header_right .cart {
    padding: 10px;
  }
  header .header_top .container .header_right .mob-top {
    display: inline-block;
  }
}
header .header_top .container.add-shadow {
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
}
header .header_top .container.add-shadow .header_right .header_item_right.menu {
  display: block;
  height: 96px;
}
@media screen and (max-width: 1024px) {
  header .header-menu {
    display: none;
  }
}
header .header-menu .container .menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 20px;
  background: #1f1f1f;
  list-style: none;
  height: 55px;
  margin: 0;
}
header .header-menu .container .menu li a {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff !important;
  padding: 10px 30px;
  font-family: 'Montserrat', sans-serif;
  height: 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
header .header-menu .container .menu li:hover a {
  color: #65B9E7;
}
footer {
  width: 100%;
  background: #1f1f1f;
}
footer .container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  padding: 40px 0 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  color: #ffffff;
  list-style: none;
}
@media screen and (max-width: 411px) {
  footer .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}
footer .container .footer_left {
  width: 20%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}
@media screen and (max-width: 411px) {
  footer .container .footer_left {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
footer .container .footer_left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
footer .container .footer_left ul.social_links img {
  max-width: 60%;
  margin-bottom: 20px;
}
footer .container .footer_center {
  width: 65%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
footer .container .footer_center .footer_center_top {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  footer .container .footer_center .footer_center_top {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  footer .container .footer_center .footer_center_top {
    width: 100%;
  }
}
footer .container .footer_center .footer_center_top ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 1200px) {
  footer .container .footer_center .footer_center_top ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    height: 160px;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 640px) {
  footer .container .footer_center .footer_center_top ul {
    height: auto;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  footer .container .footer_center .footer_center_top ul li {
    flex: 1 1 40px;
    width: 45%;
  }
}
@media screen and (max-width: 640px) {
  footer .container .footer_center .footer_center_top ul li {
    width: auto;
  }
}
footer .container .footer_center .footer_center_top ul li a {
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
footer .container .footer_center .footer_center_bottom {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 1200px) {
  footer .container .footer_center .footer_center_bottom {
    width: 45%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
}
@media screen and (max-width: 768px) {
  footer .container .footer_center .footer_center_bottom {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  footer .container .footer_center .footer_center_bottom {
    width: 100%;
  }
}
footer .container .footer_center .footer_center_bottom p {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
footer .container .footer_center .footer_center_bottom .left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 40%;
}
footer .container .footer_center .footer_center_bottom .left .support {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
  font-weight: bold;
  line-height: 40px;
}
footer .container .footer_center .footer_center_bottom .left .support .support_mail {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
  font-weight: bold;
  line-height: normal;
  color: #61b9e7;
}
footer .container .footer_center .footer_center_bottom .left .support .support_mail:hover {
  cursor: pointer;
  color: #197cb2;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
footer .container .footer_center .footer_center_bottom .center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 30%;
  padding: 30px 0;
}
footer .container .footer_center .footer_center_bottom .center p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}
footer .container .footer_center .footer_center_bottom .right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 30%;
  padding: 30px 0;
}
footer .container .footer_center .footer_center_bottom .right p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
footer .container .footer_right {
  width: 15%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
footer .container .footer_right img.iso-logo {
  width: 100px;
}
footer .container .footer_bottom {
  width: 100%;
  text-align: center;
}
footer .container .footer_bottom p {
  color: #ffffff;
}
footer .container .footer_bottom p.copyright {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0;
  margin: 0;
}
@media screen and (max-width: 1520px) {
  footer .container {
    max-width: 80%;
  }
}
@media screen and (max-width: 1440px) {
  footer .container {
    max-width: 80%;
  }
  footer .container .footer_center .footer_center_bottom .left .support {
    font-size: 35px;
  }
  footer .container .footer_center .footer_center_bottom .left .support .support_mail {
    font-size: 35px;
  }
}
@media screen and (max-width: 1366px) {
  footer .container .footer_center .footer_center_bottom .left .support {
    font-size: 35px;
  }
  footer .container .footer_center .footer_center_bottom .left .support .support_mail {
    font-size: 35px;
  }
  footer .container .footer_center .footer_center_bottom .center p {
    font-size: 14px;
  }
  footer .container .footer_right p.website {
    font-size: 8px;
  }
}
@media screen and (max-width: 1200px) {
  footer .container {
    max-width: 88%;
    -webkit-align-items: baseline;
    align-items: baseline;
  }
  footer .container .footer_center {
    width: 90%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
  footer .container .footer_center .footer_center_bottom .left {
    margin-top: -50px;
    width: 100%;
  }
  footer .container .footer_center .footer_center_bottom .left .support {
    font-size: 39px;
    line-height: 42px;
    text-align: right;
  }
  footer .container .footer_center .footer_center_bottom .left .support .support_mail {
    font-size: 39px;
    line-height: 42px;
  }
  footer .container .footer_center .footer_center_bottom .center {
    width: 100%;
    margin-top: -70px;
    text-align: right;
  }
  footer .container .footer_center .footer_center_bottom .right {
    display: none;
  }
  footer .container .footer_right {
    width: 100%;
    -webkit-align-items: center;
    align-items: center;
  }
  footer .container .footer_right p.copyright {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  footer .container {
    max-width: 100%;
    padding: 20px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  footer .container .footer_left {
    width: 100%;
  }
  footer .container .footer_left ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
  footer .container .footer_left ul li {
    padding: 0 10px;
  }
  footer .container .footer_center {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: center;
    align-items: center;
  }
  footer .container .footer_center .footer_center_bottom .left {
    margin-top: 10px;
  }
  footer .container .footer_center .footer_center_bottom .left .support {
    text-align: center;
  }
  footer .container .footer_center .footer_center_bottom .left .support .support_mail {
    margin-top: 10px;
  }
  footer .container .footer_center .footer_center_bottom .center {
    text-align: center;
    padding: 0;
    margin-top: -10px;
  }
  footer .container .footer_center .footer_center_bottom .right {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0;
  }
}
@media screen and (max-width: 411px) {
  footer .container {
    padding: 20px;
  }
  footer .container .footer_right p.website {
    margin-bottom: 5px;
  }
}
footer .modal {
  display: none;
  position: fixed;
  z-index: 13000;
  padding-top: 100px;
  left: 0;
  overflow-y: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
footer .modal .modal-content {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 15px 40px 30px;
  width: 650px;
  top: 32%;
  position: absolute;
  left: 25%;
  right: 25%;
}
@media screen and (max-width: 700px) {
  footer .modal .modal-content {
    width: 325px;
    left: 15%;
  }
}
footer .modal .modal-content .modal-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
footer .modal .modal-content .modal-top .close {
  color: #61b9e7;
}
footer .modal .modal-content .modal-top .close:hover {
  cursor: pointer;
}
footer .modal .modal-content .modal-top .close .material-icons {
  font-size: 37px;
  line-height: 96px;
}
footer .modal .modal-content .modal-top h6 {
  font-size: 37px;
  line-height: 56px;
  margin: 0;
  color: #000000;
}
@-webkit-keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpSD {
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
}
/* SLIDER  */
.hero-slider .hero-slide {
  height: 625px;
  display: flex !important;
  align-items: flex-end;
  position: relative;
  background-image: url('../images/hero.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide {
    height: 900px;
  }
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide {
    height: 820px;
  }
}
.hero-slider .hero-slide .container {
  z-index: 1;
  position: relative;
  width: 1920px;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
  .hero-slider .hero-slide .container {
    width: 100%;
  }
}
.hero-slider .hero-slide .slide-content {
  width: 1220px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin: 0 auto;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1600px) {
  .hero-slider .hero-slide .slide-content {
    width: 77%;
  }
}
@media screen and (max-width: 1370px) {
  .hero-slider .hero-slide .slide-content {
    width: 72%;
  }
}
@media screen and (max-width: 1300px) {
  .hero-slider .hero-slide .slide-content {
    width: 70%;
  }
}
@media screen and (max-width: 1024px) {
  .hero-slider .hero-slide .slide-content {
    width: 68%;
  }
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content {
    width: 74%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-flex-flow: column-reverse nowrap;
    flex-flow: column-reverse nowrap;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left {
  width: 60%;
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-left {
    width: 100%;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left span {
  color: #61b9e7;
  font-size: 55px;
  font-family: Titillium-BoldUpright, Titillium Web;
}
@media screen and (max-width: 1440px) {
  .hero-slider .hero-slide .slide-content .slide-content-left span {
    font-size: 55px;
    line-height: 65px;
  }
}
@media screen and (max-width: 1370px) {
  .hero-slider .hero-slide .slide-content .slide-content-left span {
    font-size: 50px;
    line-height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-slider .hero-slide .slide-content .slide-content-left span {
    width: 55%;
  }
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-left span {
    font-size: 38px;
    line-height: 41px;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left .h1 {
  font-weight: bold;
  font-size: 55px;
  line-height: 65px;
  margin-bottom: 30px;
  margin-top: 5px;
}
@media screen and (max-width: 1440px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    font-size: 71px;
    line-height: 75px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1370px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    font-size: 50px;
    line-height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    font-size: 47px;
  }
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    margin-top: 0;
    font-size: 38px;
    line-height: 41px;
  }
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    margin-top: -20px;
  }
}
@media screen and (max-width: 420px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 {
    font-size: 26px;
    line-height: 30px;
  }
}
@media screen and (max-width: 420px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1 span {
    font-size: 26px;
    line-height: 30px;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
  font-size: 55px;
  line-height: 65px;
}
@media screen and (max-width: 1370px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
    font-size: 50px;
    line-height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
    font-size: 47px;
  }
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
    font-size: 38px;
    line-height: 41px;
  }
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 420px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title {
    font-size: 26px;
    line-height: 30px;
  }
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title span {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 420px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .h1.slide1-title span {
    font-size: 26px;
    line-height: 30px;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left p {
  text-align: left;
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide .slide-content .slide-content-left p {
    line-height: 22px;
    padding-bottom: 20px;
    margin: 0;
  }
}
@media screen and (max-width: 360px) {
  .hero-slider .hero-slide .slide-content .slide-content-left p {
    padding-bottom: 10px;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left .slider_button {
  color: #61b9e7;
  text-decoration: none;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: normal;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
@media screen and (max-width: 420px) {
  .hero-slider .hero-slide .slide-content .slide-content-left .slider_button {
    font-size: 20px;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-left .slider_button:hover {
  cursor: pointer;
  color: #197cb2;
}
.hero-slider .hero-slide .slide-content .slide-content-left .slider_button i.fa-plus {
  font-size: 1.5rem;
}
.hero-slider .hero-slide .slide-content .slide-content-right {
  width: 40%;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .hero-slider .hero-slide .slide-content .slide-content-right {
    width: 45%;
  }
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-right {
    width: 100%;
  }
}
.hero-slider .hero-slide .slide-content .slide-content-right img {
  display: inline-block;
  padding: 10px 0;
}
@media screen and (max-width: 1020px) {
  .hero-slider .hero-slide .slide-content .slide-content-right img {
    margin: 0 auto;
    max-height: 500px;
    padding: 40px 0;
  }
}
@media screen and (max-width: 640px) {
  .hero-slider .hero-slide .slide-content .slide-content-right img {
    max-height: 400px;
    padding-top: 20px;
  }
}
.hero-slider .hero-slide .slide-btn {
  position: relative;
  padding-left: 0;
}
.hero-slider .hero-slide .slide-btn :after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-bottom: 3px solid #d2282e;
  transition: width 0.3s ease-in-out;
}
.hero-slider .hero-slide .slide-btn:hover:after {
  width: 90%;
}
.hero-slider .hero-slide.slick-active .slide-content .slide-content-left {
  animation-name: fadeInUpSD;
  animation-duration: 1s;
}
.hero-slider .hero-slide.slick-active .slide-content .slide-content-right img {
  animation-name: fadeInUpSD;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}
.hero-slider .slick-prev {
  cursor: pointer;
  position: absolute;
  top: 300px;
  width: auto;
  padding: 40px;
  color: #ffffff;
  font-weight: normal;
  font-size: 0px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #1f1f1f;
  left: 0px;
  z-index: 1;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  outline: none;
  border: none;
}
@media screen and (max-width: 1020px) {
  .hero-slider .slick-prev {
    display: none !important;
  }
}
.hero-slider .slick-prev .material-icons {
  color: #ffffff;
  font-size: 28px;
}
.hero-slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 300px;
  width: auto;
  padding: 40px;
  font-weight: normal;
  font-size: 0px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #1f1f1f;
  z-index: 1;
  right: 0px;
}
@media screen and (max-width: 1020px) {
  .hero-slider .slick-next {
    display: none !important;
  }
}
.hero-slider .slick-next .material-icons {
  color: #ffffff;
  font-size: 28px;
}
.hero-slider .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: 35px;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  list-style: none;
  padding: 0;
}
.hero-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0 10px;
  cursor: pointer;
}
.hero-slider .slick-dots li.slick-active button:before {
  background: #61b9e7;
  border: 2px solid #61b9e7;
  box-shadow: inset 0px 0px 0px 3px #ffffff;
}
.hero-slider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.hero-slider .slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: "";
  text-align: center;
  border: 2px solid #292929;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: Titillium-BoldUpright;
  src: url('../fonts/Titillium/Titillium-BoldUpright.eot');
  src: url('../fonts/Titillium/Titillium-BoldUpright.eot?#iefix') format('embedded-opentype'), url('../fonts/Titillium/Titillium-BoldUpright.woff') format('woff'), url('../fonts/Titillium/Titillium-BoldUpright.otf') format('truetype'), url('../fonts/Titillium/Titillium-BoldUpright.svg#../fonts/Titillium/Titillium-BoldUpright') format('svg');
}
@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/montserrat/Montserrat-Bold.eot');
  src: url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'), url('../fonts/montserrat/Montserrat-Bold.otf') format('truetype'), url('../fonts/montserrat/Montserrat-Bold.svg#../fonts/montserrat/Montserrat-Bold') format('svg');
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot');
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'), url('../fonts/montserrat/Montserrat-SemiBold.otf') format('truetype'), url('../fonts/montserrat/Montserrat-SemiBold.svg#../fonts/montserrat/Montserrat-SemiBold') format('svg');
}
body p {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  color: #292929;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 1.7em;
  font-weight: bold;
  line-height: normal;
}
body div.btn a i.fa-plus,
body div.btn a i.fa-minus {
  font-size: 1.5rem;
  color: #61b9e7;
  font-weight: 900;
  padding-top: 8px;
  padding-right: 5px;
}
body div a.btn i.fa-plus {
  font-size: 1.3rem;
  color: #61b9e7;
  font-weight: 900;
  padding-right: 10px;
  padding-top: 0;
}
body div.specs a i.fa-plus,
body div.form-row a i.fa-plus,
body div.specs a i.fa-minus,
body div.form-row a i.fa-minus {
  font-size: 1.3rem;
  padding-left: 1.3rem;
  color: #61b9e7;
  font-weight: 900;
  padding-top: 0;
  padding-right: 5px;
}
body div.bulk a i.fa-plus,
body div.bulk a i.fa-minus {
  font-size: 1.3rem;
  color: #61b9e7;
  font-weight: 900;
  padding-right: 7px;
  padding-top: 0px;
}
body i.fa-plus,
body i.fa-minus {
  font-size: 1.3rem;
  color: #61b9e7;
  font-weight: 900;
  padding-top: 7px;
}
body ol li {
  color: #292929;
}
body ol.terms-list {
  list-style-type: none;
}
body ul li {
  color: #292929;
}
body .service_divider {
  height: 90px;
  clear: both;
}
@media screen and (max-width: 1100px) {
  body .service_divider {
    height: 40px;
  }
}
body .services.container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
body .services .single_service {
  width: 29%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  position: relative;
}
@media screen and (max-width: 640px) {
  body .services .single_service {
    width: 100% !important;
  }
}
body .services .single_service a {
  text-decoration: none;
  color: #292929;
}
body .services .single_service a .service_bottom {
  margin-top: -42px;
  padding: 0 32px 0 45px;
}
@media screen and (max-width: 1366px) {
  body .services .single_service a .service_bottom {
    padding: 0 15px 0 19px;
  }
}
@media screen and (max-width: 1250px) {
  body .services .single_service a .service_bottom {
    padding: 0 15px 0 15px;
  }
}
body .services .single_service a .service_image {
  text-align: center;
  width: 100%;
  background: #eeeeee;
  padding: 90px 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
body .services .single_service a .blue_border {
  border-bottom: 10px solid #61b9e7;
  width: 145px;
  padding-top: 15px;
}
body .services .single_service a .service_heading {
  width: 100%;
  color: #000000;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 38px;
}
body .services .single_service a:hover .service_image {
  background: #61b9e7;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
body .services .single_service a:hover .service_image .service_icon {
  fill: #ffffff;
}
@media screen and (max-width: 1770px) {
  body .services.container {
    width: 80%;
  }
  body .services .single_service {
    width: 32%;
  }
}
@media screen and (max-width: 1366px) {
  body .services.container {
    width: 80%;
  }
  body .services .single_service a .service_heading {
    font-size: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 1250px) {
  body .services .single_service .service_image {
    padding: 50px 0;
  }
}
@media screen and (max-width: 1100px) {
  body .services.container {
    width: 88%;
  }
  body .services .single_service {
    width: 31%;
  }
}
@media screen and (max-width: 910px) {
  body .services .single_service {
    width: 32.5%;
  }
  body .services .single_service a .service_heading {
    font-size: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 768px) {
  body .services.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    max-width: 88%;
  }
  body .services .single_service a .service_heading {
    font-size: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 640px) {
  body .services .single_service a .service_heading {
    font-size: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 768px) {
  body .services.container {
    max-width: 88%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  body .services .single_service {
    width: 70%;
    margin-bottom: 60px;
  }
}
body .about_divider {
  height: 90px;
  clear: both;
}
@media screen and (max-width: 640px) {
  body .about_divider {
    height: 120px;
  }
}
body .about.container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
body .about .blue_border {
  border-bottom: 10px solid #65b9e7;
  width: 145px;
}
body .about .about_left {
  width: 46%;
  align-self: flex-start;
  margin-top: -20px;
  padding-right: 128px;
  padding-left: 8%;
}
body .about .about_left h3 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 55px;
  font-weight: bold;
  line-height: 65px;
  margin: 0;
}
body .about .about_left h4 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 31px;
  font-weight: bold;
  line-height: 36px;
}
body .about .about_left p {
  margin-top: 0;
}
body .about .about_left p span {
  font-family: Montserrat-Bold;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}
body .about .about_left a {
  color: #61b9e7;
  font-family: Montserrat-Bold;
  font-size: 18px;
  font-weight: bold;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
body .about .about_left a:hover {
  cursor: pointer;
  color: #197cb2;
}
body .about .about_left.sec {
  transition-duration: 0.3s;
}
body .about .about_left.sec1 {
  transition-duration: 6s;
}
body .about .about_right {
  width: 54%;
}
body .about .about_right img {
  width: 100%;
}
@media screen and (max-width: 1700px) {
  body .about.container {
    max-width: 80%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  body .about .about_left {
    padding-left: 0;
    padding-right: 80px;
  }
}
@media screen and (max-width: 1440px) {
  body .about.container {
    max-width: 80%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  body .about .about_left {
    padding-left: 0;
    padding-right: 60px;
  }
}
@media screen and (max-width: 1366px) {
  body .about.container {
    max-width: 80%;
  }
}
@media screen and (max-width: 1100px) {
  body .about.container {
    max-width: 88%;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  body .about .about_left {
    padding-right: 30px;
  }
  body .about .about_right img {
    height: 450px;
  }
}
@media screen and (max-width: 768px) {
  body .about.container {
    max-width: 88%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-flex-flow: column-reverse nowrap;
    flex-flow: column-reverse nowrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  body .about .about_left {
    padding: 0;
    width: 100%;
  }
  body .about .about_left h3 {
    font-size: 50px;
    line-height: 55px;
    padding-top: 70px;
    margin-bottom: 10px;
  }
  body .about .about_right {
    width: 100%;
  }
  body .about .about_right img {
    height: auto;
  }
}
@media screen and (max-width: 640px) {
  body .about.container {
    max-width: 100%;
  }
  body .about .about_left {
    margin: 0 auto;
    padding: 0;
    width: 74%;
  }
  body .about .about_left h3 {
    font-size: 50px;
    line-height: 55px;
    padding-top: 70px;
    margin-bottom: 10px;
  }
}
body .overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(25, 124, 178, 0.3);
}
body .overlay .spinner {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  z-index: 99999;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}
body .overlay .spinner.active {
  display: block;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
body .overlay:hover {
  cursor: pointer;
  color: #197cb2;
}
body .overlay.active span.process .base {
  display: none;
}
body .overlay.active span.process .lds-ring {
  display: inline-block;
}
body .overlay:disabled {
  color: #197cb2;
}
body.loading {
  overflow: hidden;
}
body.loading .overlay {
  display: block;
}
section.categories .breadcrumb {
  z-index: 1;
  position: relative;
  width: 100%;
  background: #eeeeee;
  padding: 25px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
section.categories .breadcrumb .hierarchy {
  width: 84.6%;
  margin: 0 auto;
  letter-spacing: 1px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}
@media screen and (max-width: 1290px) {
  section.categories .breadcrumb .hierarchy {
    width: 95.8%;
  }
}
@media screen and (max-width: 850px) {
  section.categories .breadcrumb .hierarchy {
    width: 88.8%;
  }
}
@media screen and (max-width: 768px) {
  section.categories .breadcrumb .hierarchy {
    width: 88.8%;
  }
}
section.categories .breadcrumb .hierarchy a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #292929 !important;
}
section.categories .breadcrumb .hierarchy span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}
section.categories .breadcrumb .hierarchy span.active {
  color: #61b9e7;
}
section.categories .breadcrumb .hierarchy span.arrow {
  padding: 0 1%;
}
section.categories .category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
section.categories .category.container {
  width: 1520px;
  margin: 0 auto;
}
@media screen and (max-width: 1735px) {
  section.categories .category.container {
    width: 84.6%;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category.container {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}
section.categories .category .category_menu {
  width: 21.3%;
  margin: 60px 0;
  padding-right: 20px;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_menu {
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_menu {
    padding-right: 0;
    width: 100%;
    margin: 0;
  }
}
section.categories .category .category_menu h2 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 35px;
  font-weight: bold;
  line-height: 40px;
  margin: 0 0 40px;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_menu h2 {
    display: none;
  }
}
section.categories .category .category_section {
  width: 78.7%;
  margin: 60px 0 40px 0;
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section {
    margin: 60px 0 40px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section {
    width: 79.5%;
    margin: 30px auto 40px auto;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section {
    width: 88.80%;
    margin: 30px auto 60px auto;
  }
}
section.categories .category .category_section .category-item {
  margin: 40px 0 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item {
    margin: 40px 0 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item:not(:first-child) {
    margin: 60px 0 0;
  }
}
section.categories .category .category_section .category-item .item-top {
  background: #eeeeee;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
section.categories .category .category_section .category-item .item-top .item-heading {
  margin: -25px 0 0 0;
  padding: 0 40px 0 40px;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-top .item-heading {
    margin: -25px 0 0 0;
    padding: 0 35px 0 35px;
  }
}
section.categories .category .category_section .category-item .item-top .item-heading .header {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 35px;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1624px) {
  section.categories .category .category_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1505px) {
  section.categories .category .category_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 38px;
    font-weight: bold;
    line-height: 35px;
  }
}
section.categories .category .category_section .category-item .item-top .item-heading .blue_border {
  border-bottom: 13px solid #61b9e7;
  width: 145px;
  margin: 20px 0 10px;
}
@media screen and (max-width: 1330px) {
  section.categories .category .category_section .category-item .item-top .item-heading .blue_border {
    border-bottom: 10px solid #65b9e7;
    width: 100px;
    margin: 10px 0 10px 0;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-top .item-heading .item-description {
    padding-top: 10px;
  }
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description b {
  font-family: 'Roboto', sans-serif;
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description h2 {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  color: #61b9e7;
  font-family: 'Roboto', sans-serif;
  margin: 15px 0 10px 0;
  padding: 0;
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description p {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
  padding-top: 0px;
  padding-bottom: 0px;
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description ul li {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
}
section.categories .category .category_section .category-item .item-top .item-heading .head-description br {
  display: block;
}
section.categories .category .category_section .category-item .item-top .item-heading p {
  padding-top: 20px;
  padding-bottom: 5px;
  line-height: 24px;
}
section.categories .category .category_section .category-item .item-top.product-description-top {
  background: white;
}
section.categories .category .category_section .category-item .item-top.product-description-top .item-heading {
  padding-left: 0;
}
section.categories .category .category_section .category-item .item-bottom {
  margin: 20px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom {
    margin: 50px 0 30px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom {
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory {
  padding: 20px 0 20px 0;
  flex: 0 0 48%;
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 0;
  }
}
@media screen and (max-width: 1390px) {
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 20px 0 0 0;
    min-height: 250px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    margin: 20px 0 0 0;
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 499px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 0;
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 1390px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 2%;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 4%;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin: 0px 0 0px 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(odd) {
    margin-right: 4% !important;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 3%;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:first-child {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:first-child {
    margin-top: 0 !important;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .category-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item {
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 15;
  cursor: pointer;
  padding: 10px;
  display: none;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item i {
  color: #61b9e7;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item {
    bottom: -12px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .collapse-item {
  position: absolute;
  bottom: 26px;
  right: 0px;
  color: #61b9e7;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  display: none;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .collapse-item {
    bottom: 0px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: start;
  justify-content: start;
  min-height: 200px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container a {
  color: #000000 !important;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.5s ease 0.3s;
  -moz-transition: min-height 0.5s ease 0.3s;
  -ms-transition: min-height 0.5s ease 0.3s;
  -o-transition: min-height 0.5s ease 0.3s;
  transition: min-height 0.5s ease 0.3s;
  position: absolute;
  width: 100%;
  z-index: -9999;
  overflow: hidden;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    min-height: 70px;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    background: transparent;
    top: 30px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
  display: flex;
  -webkit-align-self: center;
  align-self: center;
  -webkit-justify-content: start;
  justify-content: start;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 170px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    max-height: none;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
  max-width: 170px;
  max-height: 120px;
}
@media screen and (max-width: 1680px) {
}
@media screen and (max-width: 1580px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    max-width: 130px;
    max-height: 130px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .overlay {
  visibility: visible;
  width: 100%;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
  color: #1f1f1f;
  left: 20%;
  padding: 25px 25px 25px 25px;
  width: 90%;
}
@media screen and (max-width: 1735px) {
}
@media screen and (max-width: 1505px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1330px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 25px 18px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 15px 20px;
  }
}
@media screen and (max-width: 1160px) {
}
@media screen and (max-width: 1070px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 70%;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    color: #1f1f1f;
    width: 100%;
    left: 40%;
  }
}
@media screen and (max-width: 850px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 12px 30px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 10px;
    margin-top: -20px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 0;
}
@media screen and (max-width: 1580px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 12px;
  }
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container {
    justify-content: space-between;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
  font-size: 16px;
  line-height: 30px;
  color: #61b9e7 !important;
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1330px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 850px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn span {
  font-size: 16px;
  font-weight: 800;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
  margin: -35px 0 0 0;
  position: relative;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -70px 0 0 0;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -55px 0 0 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -75px 0 0 0;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: 0;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  color: #292929;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 24px;
    line-height: 24px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1505px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 21px;
    line-height: 21px;
  }
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 18px;
    line-height: 18px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
  border-bottom: 16px solid #61b9e7;
  width: 116px;
  margin: 15px 0 0 0;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    border-bottom: 10px solid #61b9e7;
    width: 80px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    width: 116px;
    border-bottom: 16px solid #61b9e7;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active {
  cursor: pointer;
  padding-top: 50px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .expand-item {
  opacity: 0;
  visibility: hidden;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .collapse-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    transform: none;
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
    top: auto;
    left: auto;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box {
    position: absolute;
    top: -69px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
  min-height: 220px;
  -webkit-transition: min-height 0.1s ease 0.1s;
  -moz-transition: min-height 0.1s ease 0.1s;
  -ms-transition: min-height 0.1s ease 0.1s;
  -o-transition: min-height 0.1s ease 0.1s;
  transition: min-height 0.1s ease 0.1s;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 217px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 100%;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
  max-height: unset;
  min-height: 100px;
  position: relative;
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    min-height: 200px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInText;
  animation-name: fadeInText;
  height: auto;
  width: auto;
  animation-fill-mode: both;
  min-height: 100px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryHead;
  animation-name: fadeInUpCategoryHead;
  animation-fill-mode: forwards;
  position: relative;
  bottom: -100px;
}
@media screen and (max-width: 1680px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -91px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -84px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -101px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: 0;
    margin: 0;
    padding: 0 20px;
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    height: auto;
    width: auto;
    animation-fill-mode: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
  bottom: -158px;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
    bottom: -91px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline.x {
  bottom: -214px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryImg;
  animation-name: fadeInUpCategoryImg;
  animation-fill-mode: forwards;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .expand-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.3s ease 0.3s;
  -moz-transition: min-height 0.3s ease 0.3s;
  -ms-transition: min-height 0.3s ease 0.3s;
  -o-transition: min-height 0.3s ease 0.3s;
  transition: min-height 0.3s ease 0.3s;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
    min-height: 70px;
    -webkit-transition: min-height 0s ease 0s;
    -moz-transition: min-height 0s ease 0s;
    -ms-transition: min-height 0s ease 0s;
    -o-transition: min-height 0s ease 0s;
    transition: min-height 0s ease 0s;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategoryImg;
  animation-name: fadeInDownCategoryImg;
  animation-fill-mode: both;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategory;
  animation-name: fadeInDownCategory;
  animation-fill-mode: both;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay a {
  transition: none;
}
section.categories .category .category_section .category-item .item-bottom .product-listing {
  display: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 27px;
  margin: 0 0 50px 0;
  max-width: 100%;
}
@media screen and (max-width: 992px) {
  section.categories .category .category_section .category-item .item-bottom .product-listing {
    padding-right: 5%;
  }
}
section.categories .category .category_section .category-item .item-bottom .product-listing table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b7b7b7;
  border-bottom: 0;
  border-left: 0;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table td {
  border: 1px solid #b7b7b7;
  vertical-align: middle;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 57px;
  text-align: center;
  border-top: 0;
  border-right: 0;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table thead th {
  border: 1px solid #b7b7b7;
  font-size: 13px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 5px 10px;
  line-height: 18px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table thead th strong {
  font-size: 13px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table thead tr {
  background-color: #eeeeee;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table tbody th {
  border: 1px solid #b7b7b7;
  font-size: 13px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 5px 10px;
  line-height: 18px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table tbody th strong {
  font-size: 13px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr:nth-of-type(even) {
  background-color: #eeeeee;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td {
  top: 0;
  left: 6px;
  width: 5%;
  padding-right: 10px;
  white-space: nowrap;
  content: attr(data-heading);
  padding: 0 10px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td.primary-col {
  text-decoration: underline;
  text-underline-position: under;
  color: #61b9e7;
  width: 15%;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td:first-child {
  text-decoration: underline;
  text-underline-position: under;
  color: #61b9e7;
  width: 15%;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td a {
  color: #61b9e7 !important;
  padding: 0 1px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 57px;
  text-decoration: underline;
  text-underline-position: under;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td a strong {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 57px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td:after {
  display: block;
  height: 2px;
  background: blue;
  margin-top: 20px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr td i {
  font-size: 22px;
  color: #61b9e7;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr:hover {
  box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.table-striped tbody tr:hover td {
  border: none;
  background: #ffffff;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.mobile-table tr td {
  line-height: 37px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.mobile-table tr td:last-child {
  min-width: 35%;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.mobile-table tr td a {
  color: #61b9e7;
  padding: 0 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 57px;
  text-decoration: underline;
  text-underline-position: under;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.mobile-table tr td:after {
  display: block;
  height: 2px;
  background: blue;
  margin-top: 20px;
}
section.categories .category .category_section .category-item .item-bottom .product-listing table.mobile-table tr.even {
  background-color: #eeeeee;
}
section.categories .category .category_section .category-item .item-bottom .subcategory {
  padding: 20px 0 20px 0;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 0 0 20px 0;
  }
}
@media screen and (max-width: 1390px) {
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 20px 0 0 0;
    min-height: 250px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    margin: 20px 0 0 0;
  }
}
@media screen and (max-width: 499px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory {
    padding: 0;
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 1390px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 2%;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 4%;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin: 0px 0 0px 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(odd) {
    margin-right: 4% !important;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 3%;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:first-child {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory:first-child {
    margin-top: 0 !important;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .category-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item {
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 15;
  cursor: pointer;
  padding: 10px;
  display: none;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item i {
  color: #61b9e7;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .expand-item {
    bottom: -12px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .collapse-item {
  position: absolute;
  bottom: 26px;
  right: 0px;
  color: #61b9e7;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  display: none;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .collapse-item {
    bottom: 0px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: start;
  justify-content: start;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container a {
  color: #000000 !important;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.5s ease 0.3s;
  -moz-transition: min-height 0.5s ease 0.3s;
  -ms-transition: min-height 0.5s ease 0.3s;
  -o-transition: min-height 0.5s ease 0.3s;
  transition: min-height 0.5s ease 0.3s;
  position: absolute;
  width: 100%;
  z-index: -9999;
  overflow: hidden;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    min-height: 70px;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    background: transparent;
    top: 30px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 170px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 200px;
    max-height: none;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
  max-width: 170px;
}
@media screen and (max-width: 1680px) {
}
@media screen and (max-width: 1580px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    max-width: 130px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .overlay {
  visibility: visible;
  width: 100%;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
  color: #1f1f1f;
  padding: 25px 25px 25px 25px;
  width: 90%;
}
@media screen and (max-width: 1735px) {
}
@media screen and (max-width: 1505px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1330px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 25px 18px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 15px 20px;
  }
}
@media screen and (max-width: 1160px) {
}
@media screen and (max-width: 1070px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 70%;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    color: #1f1f1f;
    width: 100%;
    left: 40%;
  }
}
@media screen and (max-width: 850px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 12px 30px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 10px;
    margin-top: -20px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 0;
}
@media screen and (max-width: 1580px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 12px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
  font-size: 16px;
  line-height: 30px;
  color: #61b9e7 !important;
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn.capacity-table {
  margin-left: 25px;
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn.capacity-table {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1330px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 920px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 850px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 14px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
  margin: -35px 0 0 0;
  position: relative;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -70px 0 0 0;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -55px 0 0 0;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -75px 0 0 0;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: 0;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  color: #292929;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 24px;
    line-height: 24px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1505px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 21px;
    line-height: 21px;
  }
}
@media screen and (max-width: 1440px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 18px;
    line-height: 18px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
  border-bottom: 16px solid #61b9e7;
  width: 116px;
  margin: 15px 0 0 0;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    border-bottom: 10px solid #61b9e7;
    width: 80px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    width: 116px;
    border-bottom: 16px solid #61b9e7;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active {
  cursor: pointer;
  padding-top: 50px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .expand-item {
  opacity: 0;
  visibility: hidden;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .collapse-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    transform: none;
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
    top: auto;
    left: auto;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box {
    position: absolute;
    top: -69px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
  min-height: 220px;
  -webkit-transition: min-height 0.1s ease 0.1s;
  -moz-transition: min-height 0.1s ease 0.1s;
  -ms-transition: min-height 0.1s ease 0.1s;
  -o-transition: min-height 0.1s ease 0.1s;
  transition: min-height 0.1s ease 0.1s;
}
@media screen and (max-width: 1735px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 217px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 100%;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
  max-height: unset;
  min-height: 100px;
  position: relative;
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    min-height: 200px;
  }
}
@media screen and (max-width: 420px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInText;
  animation-name: fadeInText;
  height: auto;
  width: auto;
  animation-fill-mode: both;
  min-height: 100px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryHead;
  animation-name: fadeInUpCategoryHead;
  animation-fill-mode: forwards;
  position: relative;
  bottom: -100px;
}
@media screen and (max-width: 1680px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -91px;
  }
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -84px;
  }
}
@media screen and (max-width: 1024px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -101px;
  }
}
@media screen and (max-width: 768px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: 0;
    margin: 0;
    padding: 0 20px;
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    height: auto;
    width: auto;
    animation-fill-mode: none;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
  bottom: -158px;
}
@media screen and (max-width: 1290px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
    bottom: -91px;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline.x {
  bottom: -214px;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.active .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryImg;
  animation-name: fadeInUpCategoryImg;
  animation-fill-mode: forwards;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .expand-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.3s ease 0.3s;
  -moz-transition: min-height 0.3s ease 0.3s;
  -ms-transition: min-height 0.3s ease 0.3s;
  -o-transition: min-height 0.3s ease 0.3s;
  transition: min-height 0.3s ease 0.3s;
}
@media screen and (max-width: 1040px) {
  section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
    min-height: 70px;
    -webkit-transition: min-height 0s ease 0s;
    -moz-transition: min-height 0s ease 0s;
    -ms-transition: min-height 0s ease 0s;
    -o-transition: min-height 0s ease 0s;
    transition: min-height 0s ease 0s;
  }
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategoryImg;
  animation-name: fadeInDownCategoryImg;
  animation-fill-mode: both;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategory;
  animation-name: fadeInDownCategory;
  animation-fill-mode: both;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}
section.categories .category .category_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay a {
  transition: none;
}
/* *********************************************************************************************

How to Create CSS3 + jQuery Accordion Menu [Tutorial]
"How to Create CSS3 + jQuery Accordion Menu" that was specially made for DesignModo by our friend Valeriu Timbuc.

Links:
http://vtimbuc.net/
https://twitter.com/vtimbuc
http://designmodo.com/futurico
http://vladimirkudinov.com
http://rockablethemes.com

********************************************************************************************* */
/* Reset */
.accordion {
  margin: 0;
  padding: 0;
}
.accordion ul,
.accordion li,
.accordion a,
.accordion span {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
.accordion li {
  margin: 0;
  list-style: none;
  line-height: 35px;
}
.accordion li.has-sub a:before {
  content: none;
}
.accordion li.has-sub .sub-menu {
  padding: 0 0 0 40px;
}
.accordion li.has-sub.open a:before {
  content: none;
}
.accordion li.has-sub.open .sub-menu {
  padding: 0 0 0 40px;
  display: block;
}
.accordion li.has-sub.open .sub-menu li.has-sub a:before {
  content: none;
}
.accordion li.has-sub.open .sub-menu li.no-sub a {
  padding: 0 0 0 15px;
}
.accordion li.has-sub.open .sub-menu li.no-sub a:before {
  content: none;
}
.accordion li.has-sub.open .sub-menu li.open a:before {
  content: none;
}
.accordion li.has-sub.open .sub-menu li.open .sub-menu {
  padding: 0 0 0 20px;
}
.accordion li.not-active a {
  color: #000000 !important;
}
.accordion li.not-active a:before {
  color: #61b9e7;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  line-height: 63px;
  margin: -5px 0 0 0;
}
.accordion li a {
  padding: 0 0 0 10px;
  color: #000000 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 15px;
}
.accordion li a:before {
  color: #61b9e7;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  line-height: 38px;
  margin: -5px 0 0 0;
}
.accordion li a.active {
  color: #61b9e7 !important;
}
.accordion .sub-menu {
  display: none;
}
.accordion .sub-menu li.plus-icon a:before {
  content: none;
}
.accordion .sub-menu li.has-sub a {
  font-size: 14px;
}
.accordion .sub-menu li.has-sub a:before {
  content: none;
}
.accordion .sub-menu li.has-sub.open a:before {
  content: none;
}
.accordion .sub-menu li.has-sub.open .sub-menu {
  padding: 0 0 0 40px;
}
.accordion .sub-menu li.has-sub.open .sub-menu li.has-sub a:before {
  content: none;
}
.accordion .sub-menu li.has-sub.open .sub-menu li.no-sub a:before {
  content: '';
}
.accordion .sub-menu li.has-sub.open .sub-menu li.plus-icon a:before {
  content: none;
}
.accordion .sub-menu li.has-sub.open .sub-menu li.open a:before {
  content: none;
}
.accordion .sub-menu li.has-sub.open .sub-menu li.open .sub-menu {
  padding: 0 0 0 20px;
}
.moby-button {
  display: none;
}
@media screen and (max-width: 1290px) {
  #main-sidebar {
    display: none;
  }
  .accordion li.has-sub a:before {
    content: none;
  }
  .accordion .sub-menu li.plus-icon a:before {
    content: none;
  }
  .accordion .sub-menu li.has-sub a:before {
    content: none;
  }
  .accordion .sub-menu li.has-sub.open a:before {
    content: none;
  }
  .accordion .sub-menu li.has-sub.open .sub-menu {
    padding: 0 0 0 40px;
  }
  .accordion .sub-menu li.has-sub.open .sub-menu li.has-sub a:before {
    content: none;
  }
  .accordion .sub-menu li.has-sub.open .sub-menu li.plus-icon a:before {
    content: none;
  }
  .accordion .sub-menu li.has-sub.open .sub-menu li.open a:before {
    content: none;
  }
  .moby-button {
    display: inline-block;
    background: #61b9e7;
    width: 270px;
    height: 100px;
    display: flex;
    color: #ffffff;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .moby-button:hover {
    cursor: pointer;
  }
  .moby-button .material-icons {
    font-size: 32px;
    line-height: 95px;
  }
}
@media screen and (max-width: 768px) {
  #main-sidebar {
    display: none;
  }
  .moby-button {
    display: inline-flex;
    width: 100%;
    height: auto;
    color: #ffffff;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 5.6%;
  }
  .moby-button h2 {
    display: inline-flex !important;
    font-size: 26px !important;
    line-height: 30px !important;
    margin: 0 !important;
    padding-left: 20px;
  }
}
.assistance_divider {
  height: 90px;
  clear: both;
}
@media screen and (max-width: 640px) {
  .assistance_divider {
    height: 80px;
  }
}
.assistance_container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}
.assistance_container .assistance {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}
.assistance_container .assistance .assistance_subcontainer {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.assistance_container .assistance .assistance_subcontainer .assistance_left {
  position: relative;
  width: 27%;
}
.assistance_container .assistance .assistance_subcontainer .assistance_left img {
  position: absolute;
  left: -58px;
  z-index: -1;
  width: auto;
  max-width: unset;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right {
  width: 73%;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  margin-left: 25%;
  margin-bottom: -117px;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top h6 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 55px;
  font-weight: bold;
  line-height: 65px;
  width: 19%;
  margin: 0;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top .blue_border {
  border-bottom: 10px solid #61b9e7;
  width: 145px;
  margin: 20px 0 10px;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  -webkit-flex-flow: column-reverse wrap;
  flex-flow: column-reverse wrap;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer {
  background: #ffffff;
  width: 100%;
  padding: 50px 45px 100px;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: baseline;
  align-items: baseline;
  width: 100%;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item.active:nth-child(3) {
  margin-right: 0px !important;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
  width: 75%;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item .head {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 25px;
  font-weight: 600;
  line-height: 33px;
  color: #292929;
  min-height: 65px;
  width: 86%;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item .btn a {
  color: #61b9e7;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: normal;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item .btn a:hover {
  cursor: pointer;
  color: #197cb2;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
  display: block;
  width: 100%;
  height: auto;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav {
  margin: 0;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-prev {
  background: #1f1f1f;
  width: auto;
  padding: 40px !important;
  font-size: 15px;
  border-radius: 0;
  color: #ffffff;
  margin: 0;
  outline: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-prev .material-icons {
  font-size: 28px;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-prev:hover svg {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  transform: scale(1.5);
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-next {
  background: #1f1f1f;
  width: auto;
  padding: 40px !important;
  font-size: 15px;
  border-radius: 0;
  color: #ffffff;
  margin: 0;
  outline: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-next .material-icons {
  font-size: 28px;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-next:hover {
  cursor: pointer;
}
.assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav .owl-next:hover svg {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  transform: scale(1.5);
}
.assistance_container .assistance .technical_right {
  position: absolute;
  right: 4%;
}
.assistance_container .assistance .technical_right a {
  text-decoration: none;
  color: #61b9e7;
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  text-orientation: mixed;
  padding-bottom: 115px;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.assistance_container .assistance .technical_right a:hover {
  cursor: pointer;
  color: #197cb2;
}
.assistance_container .assistance .technical_right a i.fa-plus {
  font-size: 1.5rem;
}
@media screen and (max-width: 1770px) {
  .assistance_container {
    max-width: 80%;
    margin: 0 auto;
  }
  .assistance_container .assistance .assistance_subcontainer {
    max-width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left img {
    left: -55px;
  }
}
@media screen and (max-width: 1670px) {
  .assistance_container .assistance .assistance_subcontainer .assistance_left {
    width: 10%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left img {
    left: -35px;
    width: 455px;
    height: 456px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right {
    width: 90%;
    margin-top: 70px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
    margin-left: 34%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top h6 {
    width: 31%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
    width: 90%;
  }
}
@media screen and (max-width: 1350px) {
  .assistance_container {
    max-width: 80%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
    margin-left: 35%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer {
    padding: 50px 50px 50px;
  }
  .assistance_container .assistance .technical_right {
    right: 0;
    position: relative;
    width: 100%;
    text-align: center;
    padding-bottom: 90px;
  }
  .assistance_container .assistance .technical_right a {
    writing-mode: horizontal-tb;
  }
}
@media screen and (max-width: 1280px) {
  .assistance_container .assistance {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right {
    width: 100%;
    margin-top: 0px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
    margin: 0 0 -117px 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top h6 {
    margin-left: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top .blue_border {
    margin-left: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer {
    width: 100%;
    padding: 50px 0 0 0px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item:nth-child(3) {
    margin-right: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div.btn {
    width: 100%;
  }
  .assistance_container .assistance .technical_right {
    margin: 80px 0 0;
  }
  .assistance_container .assistance .technical_right a {
    writing-mode: horizontal-tb;
  }
}
@media screen and (max-width: 1100px) {
  .assistance_container {
    max-width: 88%;
  }
  .assistance_container .assistance {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right {
    width: 100%;
    margin-top: 0px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
    margin: 0 0 -117px 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top h6 {
    margin-left: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top .blue_border {
    margin-left: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer {
    width: 100%;
    padding: 50px 0 0 0px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item:nth-child(3) {
    margin-right: 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div.btn {
    width: 100%;
  }
  .assistance_container .assistance .technical_right {
    margin: 80px 0 0;
  }
  .assistance_container .assistance .technical_right a {
    writing-mode: horizontal-tb;
  }
}
@media screen and (max-width: 768px) {
  .assistance_container .assistance .assistance_subcontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    margin: 0 auto;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top {
    margin: 0 0 0 0;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top h6 {
    margin-left: 0px;
    width: 60%;
    font-size: 50px;
    line-height: 55px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_top .blue_border {
    margin-left: 0px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer {
    padding: 50px 0px 0 ;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    transform: none !important;
    transition: none !important;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item {
    display: none;
    width: 100% !important;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item.active {
    display: block;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item.cloned {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    width: 100%;
    margin-bottom: 60px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
    width: 52%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item .head {
    min-height: auto;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-nav {
    display: none;
  }
  .assistance_container .assistance .technical_right {
    right: 0;
    margin: 35px auto 125px AUTO;
    bottom: 0px;
    text-align: left;
    PADDING: 0;
  }
}
@media screen and (max-width: 640px) {
  .assistance_container {
    width: 74%;
  }
  .assistance_container .assistance .assistance_subcontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    max-width: 100%;
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_left {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right {
    width: 100%;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    transform: none !important;
    transition: none !important;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item {
    width: 80% !important;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item.cloned {
    display: none;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    margin-bottom: 60px;
  }
  .assistance_container .assistance .assistance_subcontainer .assistance_right .assistance_right_bottom .aowl-carousel .owl-stage-outer .owl-stage .owl-item .item div {
    width: 58%;
  }
}
.custom-select-variation-styles,
.custom-select-variation {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  color: #585858;
}
.custom-select-variation-styles:focus,
.custom-select-variation:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.custom-select-variation-styles select,
.custom-select-variation select {
  display: none;
}
.custom-select-variation-styles span,
.custom-select-variation span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 25px;
}
.custom-select-variation-styles .cs-placeholder,
.custom-select-variation .cs-placeholder {
  background-color: #eeeeee;
  background-image: linear-gradient(45deg, transparent 50%, #61b9e7 50%), linear-gradient(135deg, #61b9e7 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1.2em + 12px), calc(100% - 12px) calc(1.2em + 12px);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
.custom-select-variation-styles.cs-active,
.custom-select-variation.cs-active {
  z-index: 2;
}
.custom-select-variation-styles.cs-active .cs-placeholder,
.custom-select-variation.cs-active .cs-placeholder {
  background-color: #61b9e7;
  color: #ffffff;
  background-image: linear-gradient(135deg, transparent 50%, #ffffff 50%), linear-gradient(45deg, #ffffff 50%, transparent 50%);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
}
.custom-select-variation-styles.cs-active .cs-options,
.custom-select-variation.cs-active .cs-options {
  visibility: visible;
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  animation-name: fadeInUpSD;
  animation-duration: 0.3s;
}
.custom-select-variation-styles.cs-active .cs-options ul,
.custom-select-variation.cs-active .cs-options ul {
  max-height: 220px;
}
@media screen and (max-width: 640px) {
  .custom-select-variation-styles.cs-active .cs-options ul,
  .custom-select-variation.cs-active .cs-options ul {
    max-height: 120px;
  }
}
.custom-select-variation-styles.cs-active .cs-options ul li,
.custom-select-variation.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
.custom-select-variation-styles.cs-active .cs-options ul li:hover span,
.custom-select-variation.cs-active .cs-options ul li:hover span {
  color: #1f1f1f;
}
.custom-select-variation-styles .cs-options,
.custom-select-variation .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  padding: 25px 25px 25px 30px;
  color: #585858;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 200px;
  overflow: auto;
}
.custom-select-variation-styles .cs-options ul li,
.custom-select-variation .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
}
.custom-select-variation-styles ul,
.custom-select-variation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.custom-select-variation-styles ul span,
.custom-select-variation ul span {
  padding: 10px 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 43px;
  color: #585858;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.custom-select-variation-styles ul li.cs-focus span,
.custom-select-variation ul li.cs-focus span {
  background-color: #ddd;
}
.custom-select-variation-styles ul li.cs-optgroup ul,
.custom-select-variation ul li.cs-optgroup ul {
  padding-left: 1em;
}
.custom-select-variation-styles ul li.cs-optgroup span,
.custom-select-variation ul li.cs-optgroup span {
  cursor: default;
}
#usage-filters-form .custom-select-variation-styles.cs-active,
#usage-filters-form .custom-select-variation.cs-active {
  z-index: 99;
}
#usage-filters-form .custom-select-variation-styles.cs-active .cs-options ul,
#usage-filters-form .custom-select-variation.cs-active .cs-options ul {
  max-height: 420px;
}
#usage-filters-form .custom-select-variation-styles .cs-options,
#usage-filters-form .custom-select-variation .cs-options {
  max-height: 400px;
}
.part-material.custom-select-variation-styles {
  margin-top: 20px;
}
.part-connection.custom-select-variation-styles {
  margin-top: 20px;
  margin-left: 10px;
}
@media screen and (max-width: 460px) {
  .part-connection.custom-select-variation-styles {
    margin-top: 20px;
    margin-left: 0;
  }
}
.products_divider {
  height: 90px;
  clear: both;
}
@media screen and (max-width: 1100px) {
  .products_divider {
    height: 100px;
  }
}
@media screen and (max-width: 640px) {
  .products_divider {
    height: 40px;
  }
}
.our_product_conatainer {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}
.our_product_conatainer .products {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.our_product_conatainer .products .p_sub_container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.our_product_conatainer .products .p_sub_container h2 {
  color: #292929;
  font-size: 40px;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 55px;
  font-weight: bold;
  line-height: 65px;
  margin: 0;
  margin-left: 12%;
}
.our_product_conatainer .products .p_sub_container .blue_border {
  border-bottom: 10px solid #61b9e7;
  width: 30%;
  width: 145px;
  position: relative;
  top: 10px;
  margin-top: -10px;
  z-index: 555;
}
.our_product_conatainer .products .product_right_button {
  position: absolute;
  right: 4%;
  margin: -90px 0;
}
.our_product_conatainer .products .product_right_button a {
  text-decoration: none;
  color: #61b9e7;
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  text-orientation: mixed;
  padding-bottom: 115px;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.our_product_conatainer .products .product_right_button a:hover {
  cursor: pointer;
  color: #197cb2;
}
.our_product_conatainer .products .product_right_button a i.fa-plus {
  font-size: 1.5rem;
}
.our_product_conatainer .products .phero-slider {
  background-image: url('../images/bgfinal.png') !important;
  background-position: center center !important;
  background-size: cover;
  background-repeat: no-repeat;
  height: 595px;
}
.our_product_conatainer .products .phero-slider .phero-slide {
  display: flex !important;
  align-items: flex-end;
  position: relative;
  align-items: center;
}
.our_product_conatainer .products .phero-slider .phero-slide.slick-active .slide-content .slide-content-top {
  animation-name: fadeInUpSD;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;
}
.our_product_conatainer .products .phero-slider .phero-slide.slick-active .slide-content .slide-content-bottom h3 {
  animation-name: fadeInUpSD;
  animation-duration: 0.5s;
}
.our_product_conatainer .products .phero-slider .phero-slide.slick-active .slide-content .slide-content-bottom .slide-content-bottom_wht {
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
}
.our_product_conatainer .products .phero-slider .phero-slide .container {
  width: 100%;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content {
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  margin: 0 auto;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top {
  align-self: center;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top img {
  padding-top: 70px;
  height: 506px;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom {
  position: relative;
  bottom: 140px;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 55px;
  font-weight: bold;
  line-height: 63px;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  padding-left: 12%;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 span {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 55px;
  font-weight: bold;
  line-height: 63px;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3.split {
  margin-top: -10px;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht {
  padding-left: 12%;
  width: 55%;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht .text {
  text-align: left;
  padding: 0 30% 0 0;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht .btn a {
  color: #61b9e7;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: normal;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht .btn a:hover {
  cursor: pointer;
  color: #197cb2;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-btn {
  position: relative;
  padding-left: 0;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-btn :after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-bottom: 3px solid #d2282e;
  transition: width 0.3s ease-in-out;
}
.our_product_conatainer .products .phero-slider .phero-slide .slide-btn:hover:after {
  width: 90%;
}
.our_product_conatainer .products .phero-slider .slick-dots {
  position: absolute;
  right: 14%;
  bottom: 0px;
  transform: translate(-50%, 0%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: 35px;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  list-style: none;
}
.our_product_conatainer .products .phero-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0 10px;
  cursor: pointer;
}
.our_product_conatainer .products .phero-slider .slick-dots li.slick-active button:before {
  background: #61b9e7;
  box-shadow: inset 0px 0px 0px 3px #ffffff;
  border: 2px solid #61b9e7;
}
.our_product_conatainer .products .phero-slider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.our_product_conatainer .products .phero-slider .slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: "";
  text-align: center;
  border: 2px solid #292929;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
}
.our_product_conatainer .products .phero-slider .slick-prev {
  cursor: pointer;
  position: absolute;
  bottom: -50px;
  width: auto;
  padding: 34px 40px;
  color: #ffffff;
  font-weight: normal;
  font-size: 0px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #1f1f1f;
  z-index: 1;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: none;
  left: 55%;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.our_product_conatainer .products .phero-slider .slick-prev .material-icons {
  font-size: 28px;
}
.our_product_conatainer .products .phero-slider .slick-prev:hover svg {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  transform: scale(1.5);
}
.our_product_conatainer .products .phero-slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: -50px;
  width: auto;
  padding: 34px 40px;
  font-weight: normal;
  font-size: 0px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #1f1f1f;
  z-index: 1;
  right: 31%;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.our_product_conatainer .products .phero-slider .slick-next .material-icons {
  font-size: 28px;
}
.our_product_conatainer .products .phero-slider .slick-next:hover svg {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  transform: scale(1.5);
}
@media screen and (max-width: 1770px) {
  .our_product_conatainer {
    max-width: 80%;
    margin: 0 auto;
  }
  .our_product_conatainer .products .p_sub_container {
    max-width: 100%;
  }
}
@media screen and (max-width: 1680px) {
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top img {
    height: 500px;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: 11%;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    right: 29%;
  }
}
@media screen and (max-width: 1440px) {
  .our_product_conatainer .products .p_sub_container h2 {
    margin-left: 75px;
  }
  .our_product_conatainer .products .phero-slider .slick-prev {
    left: 56%;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    right: 27%;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: 5%;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 {
    padding-left: 75px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht {
    padding-left: 75px;
  }
}
@media screen and (max-width: 1380px) {
  .our_product_conatainer {
    max-width: 80%;
    margin: 0 auto;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top img {
    height: 450px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom {
    bottom: 90px;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: 3%;
    right: 5%;
  }
}
@media screen and (max-width: 1300px) {
  .our_product_conatainer .products .product_right_button {
    right: 17%;
    margin: 50px 0;
    position: relative;
    width: 100%;
    text-align: right;
  }
  .our_product_conatainer .products .product_right_button a {
    writing-mode: horizontal-tb;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    right: 23%;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: -1%;
  }
}
@media screen and (max-width: 1100px) {
  .our_product_conatainer {
    max-width: 88%;
    margin: 0 auto;
  }
  .our_product_conatainer .products {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .our_product_conatainer .products .p_sub_container h2 {
    margin-left: 90px;
  }
  .our_product_conatainer .products .product_right_button {
    right: 125px;
    margin: 50px 0;
    position: relative;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    right: 22%;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 {
    padding-left: 35px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht {
    padding-left: 35px;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: -4%;
  }
}
@media screen and (max-width: 900px) {
  .our_product_conatainer .products {
    -webkit-align-items: center;
    align-items: center;
  }
  .our_product_conatainer .products .p_sub_container h2 {
    margin-left: 42px;
    font-size: 50px;
    line-height: 55px;
  }
  .our_product_conatainer .products .p_sub_container .blue_border {
    width: 140px;
  }
  .our_product_conatainer .products .product_right_button {
    right: 0;
    position: relative;
    width: 100%;
    text-align: left;
    margin: 0 auto 160px;
    padding-left: 25px;
    bottom: -360px;
  }
  .our_product_conatainer .products .product_right_button a {
    writing-mode: horizontal-tb;
  }
  .our_product_conatainer .products .phero-slider {
    height: 410px;
    background: #eeeeee !important;
  }
  .our_product_conatainer .products .phero-slider .slick-prev {
    left: 0%;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    left: 14%;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top img {
    height: 340px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom {
    bottom: 115px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 {
    padding-left: 25px;
    margin: 200px 0 0PX 0;
    width: 100%;
    min-height: inherit;
    font-size: 45px;
    line-height: 50px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3.split {
    margin: 200px 0 0 0;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom h3 span {
    font-size: 45px;
    line-height: 50px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht {
    padding-left: 25px;
    width: 95%;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom .slide-content-bottom_wht .text {
    padding: 20px 6% 0 0;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: 0px;
  }
}
@media screen and (max-width: 640px) {
  .our_product_conatainer .products .product_right_button {
    bottom: -435px;
    margin: 0 auto 340px;
  }
  .our_product_conatainer .products .phero-slider {
    height: 415px;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    left: 19%;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-top img {
    height: 250px;
    margin-bottom: 70px;
  }
  .our_product_conatainer .products .phero-slider .phero-slide .slide-content .slide-content-bottom {
    bottom: 85px;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: -50px;
  }
}
@media screen and (max-width: 420px) {
  .our_product_conatainer .products .product_right_button {
    bottom: -468px;
    margin: 0 auto 355px;
  }
  .our_product_conatainer .products .phero-slider .slick-dots {
    right: 65px;
  }
  .our_product_conatainer .products .phero-slider .slick-prev {
    display: none !important;
  }
  .our_product_conatainer .products .phero-slider .slick-next {
    display: none !important;
  }
}
.products .breadcrumb {
  z-index: 1;
  position: relative;
  width: 100%;
  background: #eeeeee;
  padding: 25px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
.products .breadcrumb .hierarchy {
  width: 84.6%;
  margin: 0 auto;
  letter-spacing: 1px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}
.products .breadcrumb .hierarchy a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #292929 !important;
}
.products .breadcrumb .hierarchy span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}
.products .breadcrumb .hierarchy span.active {
  color: #61b9e7;
}
.products .breadcrumb .hierarchy span.arrow {
  padding: 0 1%;
}
.products .product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.products .product.container {
  width: 84.6%;
  margin: 0 auto;
}
.products .product .products_menu {
  width: 25%;
  margin: 60px 0;
}
.products .product .products_menu h2 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
  font-weight: bold;
  line-height: 43px;
  margin: 0 0 40px;
}
.products .product .category_menu {
  width: 21.3%;
  margin: 60px 0;
  padding-right: 20px;
}
@media screen and (max-width: 1290px) {
  .products .product .category_menu {
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .category_menu {
    padding-right: 0;
    width: 100%;
    margin: 0;
  }
}
.products .product .category_menu h2 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 35px;
  font-weight: bold;
  line-height: 40px;
  margin: 0 0 40px;
}
@media screen and (max-width: 1290px) {
  .products .product .category_menu h2 {
    display: none;
  }
}
.products .product .product_section {
  width: 69.5%;
  margin: 30px auto 20px;
}
.products .product .product_section .category-item {
  margin: 40px 0 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item {
    margin: 40px 0 0;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item:not(:first-child) {
    margin: 60px 0 0;
  }
}
.products .product .product_section .category-item .item-top {
  background: #eeeeee;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.products .product .product_section .category-item .item-top .item-heading {
  margin: -25px 0 0 0;
  padding: 0 40px 0 40px;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-top .item-heading {
    margin: -25px 0 0 0;
    padding: 0 35px 0 35px;
  }
}
.products .product .product_section .category-item .item-top .item-heading .header {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 35px;
}
@media screen and (max-width: 1735px) {
  .products .product .product_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1624px) {
  .products .product .product_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1505px) {
  .products .product .product_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1440px) {
  .products .product .product_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-top .item-heading .header {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 38px;
    font-weight: bold;
    line-height: 35px;
  }
}
.products .product .product_section .category-item .item-top .item-heading .blue_border {
  border-bottom: 13px solid #61b9e7;
  width: 145px;
  margin: 20px 0 10px;
}
@media screen and (max-width: 1330px) {
  .products .product .product_section .category-item .item-top .item-heading .blue_border {
    border-bottom: 10px solid #65b9e7;
    width: 100px;
    margin: 10px 0 10px 0;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-top .item-heading .item-description {
    padding-top: 10px;
  }
}
.products .product .product_section .category-item .item-top .item-heading .head-description {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
}
.products .product .product_section .category-item .item-top .item-heading .head-description b {
  font-family: 'Roboto', sans-serif;
}
.products .product .product_section .category-item .item-top .item-heading .head-description h2 {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  color: #61b9e7;
  font-family: 'Roboto', sans-serif;
  margin: 15px 0 10px 0;
  padding: 0;
}
.products .product .product_section .category-item .item-top .item-heading .head-description p {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
  padding-top: 0px;
  padding-bottom: 0px;
}
.products .product .product_section .category-item .item-top .item-heading .head-description ul li {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
}
.products .product .product_section .category-item .item-top .item-heading p {
  padding-top: 20px;
  padding-bottom: 5px;
  line-height: 24px;
}
.products .product .product_section .category-item .item-top.product-description-top {
  background: white;
}
.products .product .product_section .category-item .item-top.product-description-top .item-heading {
  padding-left: 0;
}
.products .product .product_section .category-item .item-bottom {
  margin: 20px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom {
    margin: 20px 0;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory {
  padding: 20px 0 20px 0;
  flex: 0 0 48%;
}
@media screen and (max-width: 1440px) {
  .products .product .product_section .category-item .item-bottom .subcategory {
    padding: 0 0 20px 0;
  }
}
@media screen and (max-width: 1390px) {
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory {
    padding: 20px 0 0 0;
    min-height: 250px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory {
    margin: 20px 0 0 0;
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 499px) {
  .products .product .product_section .category-item .item-bottom .subcategory {
    padding: 0px 0 0 0;
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1390px) {
  .products .product .product_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 2%;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin-right: 4%;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory:not(:last-child) {
    margin: 0px 0 0px 0;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory:nth-child(odd) {
    margin-right: 4% !important;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 3%;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory:nth-child(4n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory:nth-child(3n + 0) {
    margin-right: 0;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .category-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.products .product .product_section .category-item .item-bottom .subcategory .expand-item {
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 15;
  cursor: pointer;
  padding: 10px;
}
.products .product .product_section .category-item .item-bottom .subcategory .expand-item i {
  color: #61b9e7;
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .expand-item {
    bottom: -12px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .collapse-item {
  position: absolute;
  bottom: 26px;
  right: 0px;
  color: #61b9e7;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .collapse-item {
    bottom: 0px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: start;
  justify-content: start;
  min-height: 200px;
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container a {
  color: #000000 !important;
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.5s ease 0.3s;
  -moz-transition: min-height 0.5s ease 0.3s;
  -ms-transition: min-height 0.5s ease 0.3s;
  -o-transition: min-height 0.5s ease 0.3s;
  transition: min-height 0.5s ease 0.3s;
  position: absolute;
  width: 100%;
  z-index: -9999;
  overflow: hidden;
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    min-height: 70px;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .box-overlay {
    background: transparent;
    top: 30px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 170px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 200px;
    max-height: none;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
  max-width: 170px;
}
@media screen and (max-width: 1680px) {
}
@media screen and (max-width: 1580px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    position: relative;
    opacity: 0.7;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    max-width: 130px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .overlay {
  visibility: visible;
  width: 100%;
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
  color: #1f1f1f;
  left: 20%;
  padding: 25px 25px 25px 25px;
  width: 90%;
}
@media screen and (max-width: 1735px) {
}
@media screen and (max-width: 1505px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1330px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 25px 18px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 15px 20px;
  }
}
@media screen and (max-width: 1160px) {
}
@media screen and (max-width: 1070px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 70%;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    color: #1f1f1f;
    width: 100%;
    left: 40%;
  }
}
@media screen and (max-width: 850px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 12px 30px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text {
    padding: 20px 10px;
    margin-top: -20px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 0;
}
@media screen and (max-width: 1580px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text p {
    font-size: 12px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container {
    justify-content: space-between;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
  font-size: 16px;
  line-height: 30px;
  color: #61b9e7 !important;
}
@media screen and (max-width: 1440px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1330px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 850px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .text .btn-container .btn span {
  font-size: 16px;
  font-weight: 800;
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
  margin: -35px 0 0 0;
  position: relative;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -70px 0 0 0;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -55px 0 0 0;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -75px 0 0 0;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: 0;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  color: #292929;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 24px;
    line-height: 24px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1505px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 21px;
    line-height: 21px;
  }
}
@media screen and (max-width: 1440px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 18px;
    line-height: 18px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
  border-bottom: 16px solid #61b9e7;
  width: 116px;
  margin: 15px 0 0 0;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    border-bottom: 10px solid #61b9e7;
    width: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    width: 116px;
    border-bottom: 16px solid #61b9e7;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active {
  cursor: pointer;
  padding-top: 50px;
}
.products .product .product_section .category-item .item-bottom .subcategory.active .expand-item {
  opacity: 0;
  visibility: hidden;
}
.products .product .product_section .category-item .item-bottom .subcategory.active .collapse-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
  color: #1f1f1f;
  left: 20%;
  padding: 25px 25px 25px 25px;
  width: 90%;
}
@media screen and (max-width: 1735px) {
}
@media screen and (max-width: 1505px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1330px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    padding: 25px 18px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    padding: 20px 15px 20px;
  }
}
@media screen and (max-width: 1160px) {
}
@media screen and (max-width: 1070px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    width: 70%;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    color: #1f1f1f;
    width: 100%;
    left: 40%;
  }
}
@media screen and (max-width: 850px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    padding: 20px 12px 30px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text {
    padding: 20px 10px;
    margin-top: -20px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 0;
}
@media screen and (max-width: 1580px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
    font-size: 14px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text p {
    font-size: 12px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container {
    justify-content: space-between;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
  font-size: 16px;
  line-height: 30px;
  color: #61b9e7 !important;
}
@media screen and (max-width: 1440px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1330px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 920px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (max-width: 850px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn {
    font-size: 14px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .text .btn-container .btn span {
  font-size: 16px;
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .box {
    position: absolute;
    top: -69px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
  min-height: 220px;
  -webkit-transition: min-height 0.1s ease 0.1s;
  -moz-transition: min-height 0.1s ease 0.1s;
  -ms-transition: min-height 0.1s ease 0.1s;
  -o-transition: min-height 0.1s ease 0.1s;
  transition: min-height 0.1s ease 0.1s;
}
@media screen and (max-width: 1735px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 217px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .box-overlay {
    min-height: 100%;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 170px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    min-height: 200px;
    max-height: none;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper {
    padding: 10px 0 0 0;
    min-height: 0;
    max-height: none;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper img {
  max-width: 170px;
}
@media screen and (max-width: 1680px) {
}
@media screen and (max-width: 1580px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper img {
    position: relative;
    opacity: 0.7;
  }
}
@media screen and (max-width: 420px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .subcategory-container-wrapper img {
    max-width: 130px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInText;
  animation-name: fadeInText;
  height: auto;
  width: auto;
  animation-fill-mode: both;
  min-height: 100px;
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryHead;
  animation-name: fadeInUpCategoryHead;
  animation-fill-mode: forwards;
  position: relative;
  bottom: -100px;
}
@media screen and (max-width: 1680px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -91px;
  }
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -84px;
  }
}
@media screen and (max-width: 1024px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: -101px;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading {
    bottom: 0;
    margin: 0;
    padding: 0 20px;
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    height: auto;
    width: auto;
    animation-fill-mode: none;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
  bottom: -158px;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline {
    bottom: -91px;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container .sub-heading.multiline.x {
  bottom: -214px;
}
.products .product .product_section .category-item .item-bottom .subcategory.active .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryImg;
  animation-name: fadeInUpCategoryImg;
  animation-fill-mode: forwards;
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .expand-item {
  opacity: 1;
  visibility: visible;
  z-index: 15;
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.3s ease 0.3s;
  -moz-transition: min-height 0.3s ease 0.3s;
  -ms-transition: min-height 0.3s ease 0.3s;
  -o-transition: min-height 0.3s ease 0.3s;
  transition: min-height 0.3s ease 0.3s;
}
@media screen and (max-width: 1040px) {
  .products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
    min-height: 70px;
    -webkit-transition: min-height 0s ease 0s;
    -moz-transition: min-height 0s ease 0s;
    -ms-transition: min-height 0s ease 0s;
    -o-transition: min-height 0s ease 0s;
    transition: min-height 0s ease 0s;
  }
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategoryImg;
  animation-name: fadeInDownCategoryImg;
  animation-fill-mode: both;
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container .sub-heading {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategory;
  animation-name: fadeInDownCategory;
  animation-fill-mode: both;
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}
.products .product .product_section .category-item .item-bottom .subcategory.inactive .subcategory-container .overlay a {
  transition: none;
}
.products .product .product_section .products-header-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-bottom: 50px;
}
.products .product .product_section .products-header-top .left {
  width: 20%;
}
.products .product .product_section .products-header-top .left .zoom-box {
  position: relative;
}
.products .product .product_section .products-header-top .left .zoom-box img {
  vertical-align: bottom;
}
.products .product .product_section .products-header-top .left .zoom-box .zoom-selector {
  position: absolute;
  background-image: url("/assets/images/selector.png");
  background-repeat: repeat;
  display: none;
  cursor: none;
}
.products .product .product_section .products-header-top .left .zoom-box .viewer-box {
  position: absolute;
  border: 1px solid #efedf0;
  display: none;
  overflow: hidden;
  background-color: #ffffff;
  z-index: 99999;
}
.products .product .product_section .products-header-top .left .zoom-box .viewer-box > img {
  position: absolute;
  left: 0;
  top: 0;
}
.products .product .product_section .products-header-top .right {
  width: 88%;
  padding-left: 10px;
}
.products .product .product_section .products-header-top .right .heading {
  margin-top: 30px;
}
.products .product .product_section .products-header-top .right .heading h3 {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  margin: 0;
}
@media screen and (min-width: 700px) and (max-width: 950px) {
  .products .product .product_section .products-header-top .right .heading h3 {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 40px;
    font-weight: bold;
    line-height: 78px;
  }
}
.products .product .product_section .products-header-top .right .heading .blue_border {
  width: 145px;
  border-bottom: 13px solid #61b9e7;
  margin: 10px 0;
}
.products .product .product_section .products-header-top .right .top-description {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  color: #000000;
  display: flex;
  justify-content: space-evenly;
}
.products .product .product_section .products-header-top .right .top-description h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #61b9e7;
  font-family: 'Roboto', sans-serif;
  margin: 15px 0 10px 0;
  padding: 0;
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .products .product .product_section .products-header-top .right .top-description h2 {
    margin: 30px 0 10px 0;
  }
}
.products .product .product_section .products-header-top .right .top-description p {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  color: #000000;
}
.products .product .product_section .products-header-top .right .top-description ul li {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
  padding-left: 5px;
}
.products .product .product_section .products-header-top .right .top-description ul li::marker {
  content: "▶";
  font-size: 11px;
  color: #61b9e7;
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.brass::marker {
  content: url(/assets/images/bullets/brass.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.steel::marker {
  content: url(/assets/images/bullets/steel.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.ss316::marker {
  content: url(/assets/images/bullets/316.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.ss303::marker {
  content: url(/assets/images/bullets/303.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.col-polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.col-pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.col-cpvc::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.col-iron::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.iron::marker {
  content: url(/assets/images/bullets/iron.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.nat-polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.nat-pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.ptfe::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.pvc::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li.abs::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description ul.material-list li::marker {
  content: url(/assets/images/bullets/bex.png);
}
.products .product .product_section .products-header-top .right .top-description div.left {
  width: 100%;
}
.products .product .product_section .products-header-top .right .top-description div.right {
  width: 100%;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .products-header-top .right .top-description div.right {
    margin-top: 0;
  }
}
@media screen and (max-width: 700px) {
  .products .product .product_section .products-header-top .right .top-description div.right {
    padding-left: 0;
    align-items: flex-start;
  }
}
.products .product .product_section .products-header-top .right .top-description br {
  display: block;
}
.products .product .product_section .products-header-top .right .top-description b {
  font-family: 'Roboto', sans-serif;
}
.products .product .product_section .products-header-top .right .item-description {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.products .product .product_section .products-header-top .right p {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 0;
}
.products .product .product_section .products-header-top .right ul li {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.products .product .product_section #product-filters.borders {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid #262626;
  box-shadow: 0 0 10px #262626;
}
.products .product .product_section #product-filters .spray-angle-variation {
  min-width: 200px;
  width: 30%;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section #product-filters .spray-angle-variation {
    width: 100%;
  }
}
.products .product .product_section #product-filters form .trigger {
  display: none;
}
.products .product .product_section #usage-filters .industries-applications {
  min-width: 200px;
  width: 50%;
}
@media screen and (max-width: 1018px) {
  .products .product .product_section #usage-filters .industries-applications {
    width: 100%;
    margin: 10px 0;
  }
}
.products .product .product_section #usage-filters form .trigger {
  display: none;
}
.products .product .product_section .products-header-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: end;
  align-items: end;
  -webkit-justify-content: center;
  justify-content: center;
}
.products .product .product_section .products-header-bottom > div {
  margin-left: 15px;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .products-header-bottom > div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1018px) {
  .products .product .product_section .products-header-bottom > div {
    margin: 10px 0;
  }
}
.products .product .product_section .products-header-bottom > div.spray-angle-variation {
  margin-left: 0;
}
.products .product .product_section .products-header-bottom > div.product-table {
  margin: auto;
  width: 80%;
}
.products .product .product_section .products-header-bottom .secondary-filters.hide {
  display: none;
}
.products .product .product_section .products-header-bottom .secondary-filters.show {
  width: calc(45%);
  display: flex;
  flex-flow: row nowrap;
  align-items: end;
  justify-content: center;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .products-header-bottom .secondary-filters.show {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-flow: row wrap;
    width: 100%;
  }
}
.products .product .product_section .products-header-bottom .variation {
  width: calc(45%);
}
.products .product .product_section .products-header-bottom .psi {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.products .product .product_section .products-header-bottom .psi .select-btn {
  cursor: pointer;
  color: #585858;
  background: #eeeeee;
  padding: 25px 25px 25px 25px;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  background-image: linear-gradient(45deg, transparent 50%, #61b9e7 50%), linear-gradient(135deg, #61b9e7 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1.2em + 12px), calc(100% - 12px) calc(1.2em + 12px);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
  width: 100%;
  border: none;
  margin-left: 20px;
}
.products .product .product_section .products-header-bottom .psi .select-btn.active {
  color: #ffffff;
  background-color: #61b9e7;
  background-image: linear-gradient(135deg, transparent 50%, #ffffff 50%), linear-gradient(45deg, #ffffff 50%, transparent 50%);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .products-header-bottom .psi .select-btn {
    margin-left: 0px;
  }
}
.products .product .product_section .products-header-bottom .psi .psi-modal {
  display: none;
  /* Hidden by default */
  position: absolute;
  z-index: 99;
  padding: 20px 20px 10px 20px;
  width: 164%;
  background-color: #000000;
  background-color: #FFFFFF;
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  animation-name: fadeInUpSD;
  animation-duration: 0.3s;
}
@media screen and (max-width: 1290px) {
  .products .product .product_section .products-header-bottom .psi .psi-modal {
    width: 110%;
  }
}
@media screen and (max-width: 768px) {
  .products .product .product_section .products-header-bottom .psi .psi-modal {
    width: 100%;
  }
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .select-box {
  width: 38%;
  margin: 0 0 10px 0;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .input {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 43px;
  width: 58%;
  border: 1px solid #b7b7b7;
  text-align: center;
  color: #585858;
  margin: 0 0 10px 0;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .input.placeholder-fix:focus::-webkit-input-placeholder {
  color: transparent;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .input.placeholder-fix:focus::-moz-placeholder {
  color: transparent;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .input.placeholder-fix:-moz-placeholder {
  color: transparent;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-top .custom-select-variation .cs-options {
  padding: 25px 18px 25px 15px;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-bottom.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
  margin: 15px 0;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-bottom.buttons a {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-bottom.buttons a.reset {
  color: #9f9f9f;
}
.products .product .product_section .products-header-bottom .psi .psi-modal .psi-modal-content .form-bottom.buttons a.reset .material-icons {
  font-size: 18px;
  line-height: 20px;
}
.products .product .product_section .product-table {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 27px;
  margin: 10px 0;
  max-width: 100%;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .products .product .product_section .product-table {
    padding: 15px;
  }
}
.products .product .product_section .product-table table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b7b7b7;
  border-bottom: 0;
  border-left: 0;
}
.products .product .product_section .product-table table td {
  border: 1px solid #b7b7b7;
  vertical-align: middle;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 57px;
  text-align: center;
  border-top: 0;
  border-right: 0;
}
.products .product .product_section .product-table table thead th {
  border: 1px solid #b7b7b7;
  font-size: 11px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 5px 10px;
  line-height: 18px;
}
.products .product .product_section .product-table table thead th strong {
  font-size: 11px;
}
.products .product .product_section .product-table table thead tr {
  background-color: #eeeeee;
}
.products .product .product_section .product-table table tbody th {
  border: 1px solid #b7b7b7;
  font-size: 13px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 5px 10px;
  line-height: 18px;
}
.products .product .product_section .product-table table tbody th strong {
  font-size: 13px;
}
.products .product .product_section .product-table table.table-striped tbody tr {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.products .product .product_section .product-table table.table-striped tbody tr:nth-of-type(even) {
  background-color: #eeeeee;
}
.products .product .product_section .product-table table.table-striped tbody tr td {
  top: 0;
  left: 6px;
  width: 5%;
  padding-right: 10px;
  white-space: nowrap;
  content: attr(data-heading);
  padding: 0 10px;
}
.products .product .product_section .product-table table.table-striped tbody tr td.primary-col {
  text-decoration: underline;
  text-underline-position: under;
  color: #61b9e7;
  width: 15%;
}
.products .product .product_section .product-table table.table-striped tbody tr td:first-child {
  text-decoration: underline;
  text-underline-position: under;
  color: #61b9e7;
  width: 15%;
}
.products .product .product_section .product-table table.table-striped tbody tr td a {
  color: #61b9e7;
  padding: 0 1px;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 57px;
  text-decoration: underline;
  text-underline-position: under;
}
.products .product .product_section .product-table table.table-striped tbody tr td a strong {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 57px;
}
.products .product .product_section .product-table table.table-striped tbody tr td:after {
  display: block;
  height: 2px;
  background: blue;
  margin-top: 20px;
}
.products .product .product_section .product-table table.table-striped tbody tr:hover {
  box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
}
.products .product .product_section .product-table table.table-striped tbody tr:hover td {
  border: none;
  background: #ffffff;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.products .product .product_section .product-table table.table-striped tbody tr.active {
  box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
}
.products .product .product_section .product-table table.table-striped tbody tr.active td {
  border: none;
  background: #61b9e7;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.products .product .product_section .product-table table.table-striped tbody tr.active td a {
  color: #ffffff !important;
}
.products .product .product_section .product-table table.mobile-table tr td {
  line-height: 37px;
}
.products .product .product_section .product-table table.mobile-table tr td:last-child {
  min-width: 35%;
}
.products .product .product_section .product-table table.mobile-table tr td a {
  color: #61b9e7;
  padding: 0 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 57px;
  text-decoration: underline;
  text-underline-position: under;
}
.products .product .product_section .product-table table.mobile-table tr td:after {
  display: block;
  height: 2px;
  background: blue;
  margin-top: 20px;
}
.products .product .product_section .product-table table.mobile-table tr.even {
  background-color: #eeeeee;
}
.empty-alert {
  text-align: center;
}
.empty-alert a {
  font-size: inherit;
}
.empty-alert.hide {
  display: none;
}
@media screen and (max-width: 1700px) {
  .products .product .product_section .products-header-bottom select {
    padding: 25px 25px 25px 15px;
  }
  .products .product .product_section .products-header-bottom .psi .select-btn {
    padding: 25px 25px 25px 15px;
  }
}
@media screen and (max-width: 1690px) {
  .products .product .product_section .products-header-bottom select {
    font-size: 14px;
  }
  .products .product .product_section .products-header-bottom .psi .select-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 1500px) {
  .products .product .product_section {
    margin: 60px auto 40px;
  }
  .products .product .product_section .products-header-bottom select {
    font-size: 12px;
    padding: 25px 25px 25px 10px;
  }
  .products .product .product_section .products-header-bottom .psi .select-btn {
    font-size: 14px;
    padding: 25px 25px 25px 10px;
  }
}
@media screen and (max-width: 1290px) {
  .products .breadcrumb .hierarchy {
    width: 95.8%;
  }
  .products .product.container {
    width: 100%;
    margin: 0;
  }
  .products .product .products_menu {
    width: 100px;
    margin: 0;
  }
  .products .product .products_menu h2 {
    display: none;
  }
  .products .product .product_section {
    width: 75.5%;
    margin: 30px 0 40px auto;
  }
  .products .product .product_section .products-header-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .products .product .product_section .products-header-top .left {
    width: 100%;
    text-align: center;
  }
  .products .product .product_section .products-header-top .right {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .products .product .product_section .products-header-top .right p {
    margin-top: 15px;
  }
  .products .product .product_section .products-header-top .right .top-description .left {
    text-align: left;
  }
  .products .product .product_section .products-header-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 85%;
    margin: auto;
  }
  .products .product .product_section .products-header-bottom .variation {
    width: 100%;
    margin: 10px 0;
  }
  .products .product .product_section .products-header-bottom select {
    font-size: 14px;
  }
  .products .product .product_section .products-header-bottom .psi .select-btn {
    padding: 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1080px) {
  .products .product .product_section {
    width: 70.5%;
  }
}
@media screen and (max-width: 768px) {
  .products .product.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .products .product .products_menu {
    width: 100%;
    margin: 0;
  }
  .products .product .product_section {
    width: 100%;
  }
  .products .product .product_section .products-header-top .right {
    width: 100%;
  }
  .products .product .product_section .products-header-top .right .heading {
    margin: 0;
  }
}
@media screen and (max-width: 700px) {
  .products #main-sidebar {
    display: none;
  }
  .products .moby-button {
    display: inline-flex;
    width: 100%;
    height: auto;
    color: #ffffff;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 5.6%;
  }
  .products .moby-button h2 {
    display: inline-flex !important;
    font-size: 26px !important;
    line-height: 30px !important;
    margin: 0 !important;
    padding-left: 20px;
  }
  .products .breadcrumb .hierarchy {
    width: 88.8%;
  }
  .products .breadcrumb .hierarchy span.arrow {
    padding: 0 1%;
  }
  .products .product .product_section {
    margin: 30px auto 40px;
  }
  .products .product .product_section .products-header-top .right {
    width: 100%;
    margin-top: 65px;
  }
  .products .product .product_section .products-header-top .right .heading {
    align-self: center;
  }
  .products .product .product_section .products-header-top .right .heading h3 {
    font-size: 45px;
  }
  .products .product .product_section .products-header-top .right .heading .blue_border {
    margin: 5px 0 10px 0;
  }
  .products .product .product_section .products-header-top .right p {
    font-size: 18px;
    line-height: 23px;
    margin: 40px 15% 10px 0;
  }
  .products .product .product_section .products-header-top .right .top-description {
    flex-wrap: wrap;
  }
  .products .product .product_section .products-header-bottom {
    width: 100%;
  }
  .products .product .product_section .products-header-bottom .variation.angle {
    width: 100%;
  }
  .products .product .product_section .products-header-bottom .variation.psi {
    width: 100%;
  }
  .products .product .product_section .products-header-bottom select {
    font-size: 14px;
  }
  .products .product .product_section .products-header-bottom .psi .select-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .products .breadcrumb .hierarchy {
    width: 88.8%;
  }
}
@media screen and (max-width: 375px) {
  .products .product .product_section .products-header-bottom .variation {
    width: 100%;
  }
}
/*
 * MOBY.LESS VERSION 2.0.7
 */
.moby-overlay {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.moby-overlay.light {
  background: #289DCC;
}
.moby-overlay.moby-overlay-active {
  visibility: visible !important;
  opacity: 0.2 !important;
}
.moby-overlay.moby-overlay-active#moby-overlay2 {
  background: none;
}
.moby-body-fixed {
  overflow: hidden !important;
}
.moby.left-side {
  position: fixed;
  z-index: 13000;
  top: 25%;
  left: 0px;
  width: 40%;
  max-height: 100%;
  height: auto;
  min-width: 333px;
  background: transparent;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.moby.left-side.moby-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
}
.moby.left-side.moby-demo {
  color: #ffffff;
  background: blue;
  position: fixed;
  z-index: 3000;
  top: 0;
  height: 100%;
  width: 30%;
  right: 0;
  min-width: 333px;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  padding: 60px 0 20px 0;
  overflow: auto;
}
.moby.left-side.moby-demo.moby-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.moby.left-side.moby-demo .moby-inner {
  height: 100%;
  min-height: 520px;
}
.moby.left-side.moby-demo .moby-inner > p {
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0 30px;
  font-size: 13px;
  text-align: center;
}
.moby.left-side.moby-demo .moby-close {
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 12px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.moby.left-side.moby-demo .moby-close .moby-close-icon {
  margin-right: 0;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
}
.moby.left-side.moby-demo .moby-close .moby-close-icon::before,
.moby.left-side.moby-demo .moby-close .moby-close-icon::after {
  background: #ffffff;
}
.moby.left-side.moby-demo .moby-wrap {
  height: calc(100% - 40px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #ffffff;
}
.moby.left-side.moby-demo .moby-wrap > div {
  width: 100%;
}
.moby.left-side.moby-demo .moby-wrap img {
  margin: 0 auto 40px auto;
  display: block;
  width: 100px;
  height: auto;
}
.moby.left-side.moby-demo .moby-wrap .moby-menu {
  width: 100%;
}
.moby.left-side.moby-demo .moby-wrap .moby-menu > * {
  width: 100%;
}
.moby.left-side.moby-demo .moby-wrap .moby-menu {
  width: 80%;
  background: #ffffff;
  height: auto;
}
.moby.left-side.moby-demo ul li ul a {
  padding: 20px 70px 20px 70px;
  font-size: 16px;
  background: #0000cc;
}
.moby.left-side.moby-demo ul li a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  padding: 20px 70px 20px 70px;
  text-align: center;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -ms-transition: color 0.3s;
  transition: color 0.3s;
}
.moby.left-side.moby-demo ul li a:hover {
  color: #e0e0e0;
}
.moby.left-side.moby-demo ul li a .moby-expand {
  display: none;
}
.moby.left-side.moby-demo ul li a .moby-expand:hover {
  color: #e0e0e0;
}
.moby.left-side.moby-demo ul li a .moby-expand i,
.moby.left-side.moby-demo ul li a .moby-expand span {
  font-size: 20px;
}
.moby.left-side.fade {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
}
.moby.left-side.fade.moby-active {
  opacity: 1;
  visibility: visible;
}
.moby.left-side.fade ul {
  width: 100%;
}
.moby.left-side.top-full {
  position: fixed;
  z-index: 13000;
  top: 0;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  height: 100%;
  left: 0px;
  width: 100%;
}
.moby.left-side.top-full ul {
  width: 100%;
}
.moby.left-side.top-full.moby-active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.moby.left-side.bottom-full {
  position: fixed;
  z-index: 13000;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  height: 100%;
  left: 0px;
  width: 100%;
}
.moby.left-side.bottom-full ul {
  width: 100%;
}
.moby.left-side.bottom-full.moby-active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.moby.left-side.grow-out {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  -ms-transform: scale(.7);
  transform: scale(.7);
  visibility: hidden;
  opacity: 0;
}
.moby.left-side.grow-out ul {
  width: 100%;
}
.moby.left-side.grow-out.moby-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.moby.left-side.shrink-in {
  position: fixed;
  z-index: 13000;
  top: 0;
  height: 100%;
  left: 0px;
  width: 100%;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
}
.moby.left-side.shrink-in ul {
  width: 100%;
}
.moby.left-side.shrink-in.moby-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.moby.left-side ul {
  list-style: none;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  padding: 40px 0 30px 40px;
}
.moby.left-side ul ul {
  display: none;
  padding: 0 0 0 25px;
}
.moby.left-side ul ul ul {
  display: none;
  padding: 0 0 0 25px;
}
.moby.left-side ul li ul li ul li a {
  padding-left: 42px;
}
.moby.left-side ul li a {
  color: #000000;
  text-decoration: none;
  font-weight: normal;
  display: inline-block;
  padding: 20px 10% 20px 5%;
  text-align: left;
  position: relative;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.moby.left-side ul li a:before {
  content: none;
}
.moby.left-side ul li a:hover {
  color: #61b9e7;
}
.moby.left-side ul li a:hover::after {
  width: 100%;
}
.moby.left-side ul li a .moby-expand {
  display: none;
  margin: 0px 0 0 0;
}
.moby.left-side ul li a .moby-expand i,
.moby.left-side ul li a .moby-expand span {
  font-size: 30px;
  color: #61b9e7;
}
.moby.left-side ul li .dropdown-toggle {
  display: none;
}
.moby.left-side .moby-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  -webkit-flex-flow: row-reverse nowrap;
  flex-flow: row-reverse nowrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 25%;
  background: #ffffff;
}
@media screen and (max-width: 640px) {
  .moby.left-side .moby-wrap {
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}
.moby.left-side .moby-menu {
  width: 80%;
  background: #ffffff;
}
@media screen and (max-width: 640px) {
  .moby.left-side .moby-menu {
    width: 100%;
  }
}
.moby.left-side .moby-close {
  width: 22%;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 30px 0;
  background: #61b9e7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
@media screen and (max-width: 640px) {
  .moby.left-side .moby-close {
    width: 100%;
  }
}
.moby.left-side .moby-close:hover {
  color: #ffffff;
}
.moby.left-side .moby-close .moby-close-icon {
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  height: auto;
  width: auto;
}
.moby.left-side .moby-close .moby-close-icon .material-icons {
  font-size: 32px;
}
.moby.left-side .moby-close .moby-close-icon:hover::before {
  background: #ffffff;
}
.moby.left-side .moby-close .moby-close-icon:hover::after {
  background: #ffffff;
}
@media screen and (max-width: 1024px) {
  .moby.left-side {
    top: 16%;
    width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .moby.left-side {
    width: 100%;
  }
}
.product-outer-wrapper {
  background: #eeeeee;
  height: 180px;
}
#single-product {
  width: 1520px;
  margin: 0 auto;
}
#single-product #overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  cursor: pointer;
}
#single-product #overlay #text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
#single-product .product_section {
  width: 1340px;
  margin: 60px auto;
}
#single-product .product_section .products-header-top {
  margin: -270px 0 0;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#single-product .product_section .products-header-top .left {
  width: 40%;
  margin-right: 15px;
}
#single-product .product_section .products-header-top .left .zoom-box {
  position: relative;
}
#single-product .product_section .products-header-top .left .zoom-box img {
  vertical-align: bottom;
}
#single-product .product_section .products-header-top .left .zoom-box .zoom-selector {
  position: absolute;
  background-image: url("/assets/images/selector.png");
  background-repeat: repeat;
  display: none;
  cursor: none;
}
#single-product .product_section .products-header-top .left .zoom-box .viewer-box {
  position: absolute;
  border: 1px solid #efedf0;
  display: none;
  overflow: hidden;
  background-color: #ffffff;
  z-index: 99999;
}
#single-product .product_section .products-header-top .left .zoom-box .viewer-box > img {
  position: absolute;
  left: 0;
  top: 0;
}
#single-product .product_section .products-header-top .left .image-gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  -webkit-flex-flow: column-reverse nowrap;
  flex-flow: column-reverse nowrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
#single-product .product_section .products-header-top .left .image-gallery .thumbnails {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 40px 0 0;
  width: 100%;
}
#single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumb-container {
  width: calc(33% - 9px);
  display: block;
  margin: 0px 0 15px;
}
#single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumbnail-image {
  width: 100%;
  min-height: 150px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
#single-product .product_section .products-header-top .left .image-gallery .primary {
  min-width: 500px;
  min-height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 400px;
}
#single-product .product_section .products-header-top .left .products-thumbnails {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
#single-product .product_section .products-header-top .left .products-thumbnails img {
  width: 20%;
}
#single-product .product_section .products-header-top .left .dimensions {
  text-align: center;
}
#single-product .product_section .products-header-top .left .dimensions img {
  max-width: 65% !important;
}
#single-product .product_section .products-header-top .right .sku {
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}
#single-product .product_section .products-header-top .right .sku a {
  font-size: 18px;
}
#single-product .product_section .products-header-top .right .sku .product-login-message {
  padding: 20px 0;
}
#single-product .product_section .products-header-top .right .price-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  width: 85%;
}
@media screen and (max-width: 700px) {
  #single-product .product_section .products-header-top .right .price-text {
    display: block;
    text-align: center;
  }
}
#single-product .product_section .products-header-top .right .price-text h4 {
  font-size: 40px;
  line-height: 66px;
  color: #61b9e7;
  margin: 0;
}
#single-product .product_section .products-header-top .right .price-text h4 span {
  font-size: 40px;
  line-height: 66px;
  color: #61b9e7;
  margin: 0;
}
#single-product .product_section .products-header-top .right .price-text h4 span.currency {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 66px;
  color: #292929;
  margin: 0;
}
#single-product .product_section .products-header-top .right .price-text .or {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 29px;
  font-weight: bold;
  line-height: 66px;
  color: #292929;
  margin: 0;
}
@media screen and (max-width: 700px) {
  #single-product .product_section .products-header-top .right .price-text .or {
    font-family: Titillium-BoldUpright, Titillium Web;
    font-size: 29px;
    font-weight: bold;
    line-height: 46px;
  }
}
#single-product .product_section .products-header-top .right .price-text .bulk {
  margin-left: 15px;
}
#single-product .product_section .products-header-top .right .price-text .bulk .top {
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal {
  display: none;
  position: fixed;
  z-index: 13000;
  padding-top: 100px;
  left: 0;
  overflow-y: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 15px 40px 30px;
  width: 650px;
  top: 32%;
  position: absolute;
  left: 25%;
  right: 25%;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .modal-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .modal-top .close {
  color: #61b9e7;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .modal-top .close:hover {
  cursor: pointer;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .modal-top .close .material-icons {
  font-size: 37px;
  line-height: 96px;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .modal-top h6 {
  font-size: 37px;
  line-height: 96px;
  margin: 0;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 27px;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b7b7b7;
  border-bottom: 0;
  border-left: 0;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table td {
  border: 1px solid #b7b7b7;
  vertical-align: middle;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 66px;
  text-align: center;
  border-top: 0;
  border-right: 0;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table thead th {
  border: 1px solid #b7b7b7;
  font-size: 13px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 5px 10px;
  line-height: 57px;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table thead tr {
  background-color: #eeeeee;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table.table-striped tbody tr {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
#single-product .product_section .products-header-top .right .price-text .bulk .modal .modal-content .quantity-table table.table-striped tbody tr:nth-of-type(even) {
  background-color: #eeeeee;
}
#single-product .product_section .products-header-top .right .product-attributes {
  width: 92%;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right .product-attributes .tooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  color: #61b9e7;
  margin: 20px 0 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#single-product .product_section .products-header-top .right .product-attributes .tooltip.material {
  font-size: 22px;
  margin-left: 10px;
  padding-left: 10px;
}
#single-product .product_section .products-header-top .right .product-attributes .tooltip.material .tooltiptext {
  background-color: #61b9e7;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  z-index: 999;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}
#single-product .product_section .products-header-top .right .product-attributes .tooltip .tooltiptext {
  display: none;
  width: 600px;
  color: #585858;
  top: 65px;
  right: 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  color: #61b9e7;
  font-size: 14px;
}
#single-product .product_section .products-header-top .right .product-attributes .tooltip:hover .tooltiptext {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
}
#single-product .product_section .products-header-top .right .product-attributes .variation {
  width: 100%;
  /*margin: 25px 0 0;*/
}
#single-product .product_section .products-header-top .right .product-attributes .variation #display {
  margin: -20px 0 0 0;
  padding: 0px 0 6px 0;
  display: none;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
  animation-fill-mode: both;
}
#single-product .product_section .products-header-top .right .product-attributes .variation #display-material {
  margin: -20px 0 0 0;
  padding: 0px 0 6px 0;
  display: none;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
  animation-fill-mode: both;
}
#single-product .product_section .products-header-top .right .measures {
  margin: 0 0 30px;
}
#single-product .product_section .products-header-top .right .measures h3 {
  width: 100%;
  font-size: 35px;
  line-height: 96px;
  margin: 15px 0 0;
}
#single-product .product_section .products-header-top .right .measures .fields-container {
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
#single-product .product_section .products-header-top .right .measures .fields-container.has-shipping {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
#single-product .product_section .products-header-top .right .measures .fields-container .form-row {
  padding: 0;
}
#single-product .product_section .products-header-top .right .measures .fields-container .form-row .input-field .input {
  padding: 0;
}
#single-product .product_section .products-header-top .right .measures .fields-container .form-row input#quantity {
  border: 2px solid #d7d7d7;
  padding: 18px 10px;
  text-align: center;
}
#single-product .product_section .products-header-top .right .measures .fields-container .form-row:first-child {
  width: 15%;
}
@media screen and (max-width: 900px) {
  #single-product .product_section .products-header-top .right .measures .fields-container .form-row:first-child {
    width: 50%;
  }
}
@media screen and (max-width: 460px) {
  #single-product .product_section .products-header-top .right .measures .fields-container .form-row:first-child {
    width: 25%;
  }
}
#single-product .product_section .products-header-top .right .measures .fields-container .form-row.final-row {
  padding: 0;
  width: 35%;
  text-align: center;
  margin-left: 20px;
}
@media screen and (max-width: 460px) {
  #single-product .product_section .products-header-top .right .measures .fields-container .form-row.final-row {
    width: 65%;
    margin-bottom: 35px;
  }
}
#single-product .product_section .products-header-top .right .measures .fields-container .local-shipping,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-region-shipping,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-stock-shipping,
#single-product .product_section .products-header-top .right .measures .fields-container .outcast-shipping,
#single-product .product_section .products-header-top .right .measures .fields-container .call-for-shipping {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right .measures .fields-container .local-shipping i,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-region-shipping i,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-stock-shipping i,
#single-product .product_section .products-header-top .right .measures .fields-container .outcast-shipping i,
#single-product .product_section .products-header-top .right .measures .fields-container .call-for-shipping i {
  color: #00cd00;
  font-size: 20px;
  margin-right: 5px;
}
#single-product .product_section .products-header-top .right .measures .fields-container .local-shipping span,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-region-shipping span,
#single-product .product_section .products-header-top .right .measures .fields-container .out-of-stock-shipping span,
#single-product .product_section .products-header-top .right .measures .fields-container .outcast-shipping span,
#single-product .product_section .products-header-top .right .measures .fields-container .call-for-shipping span {
  font-weight: 700;
  line-height: 30px;
}
#single-product .product_section .products-header-top .right .measures .fields-container .no-shipping {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right .measures .fields-container .no-shipping i {
  color: #ff0000;
  font-size: 20px;
  margin-right: 5px;
}
#single-product .product_section .products-header-top .right .measures .fields-container .no-shipping span {
  font-weight: 700;
  line-height: 30px;
}
#single-product .product_section .products-header-top .right .measures .fields-container .no-shipping span a {
  font-size: 18px;
}
#single-product .product_section .products-header-top .right p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
}
#single-product .product_section .products-header-top .right .specs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: start;
  justify-content: start;
  margin: 40px 0 10px;
}
#single-product .product_section .products-header-top .right .specs a {
  padding-right: 15px;
}
#single-product .product_section .products-header-top .right .specs .video {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right .specs .video i {
  padding: 0 10px 0 0px;
}
#single-product .product_section .products-header-top .right #part-description {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  color: #000000;
  display: flex;
  justify-content: space-evenly;
}
#single-product .product_section .products-header-top .right #part-description h2 {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  color: #61b9e7;
  font-family: 'Roboto', sans-serif;
  margin: 15px 0 10px 0;
  padding: 0;
}
#single-product .product_section .products-header-top .right #part-description p {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
}
#single-product .product_section .products-header-top .right #part-description p.bottom {
  width: 100%;
}
#single-product .product_section .products-header-top .right #part-description ul li {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  color: #000000;
  padding-left: 5px;
}
#single-product .product_section .products-header-top .right #part-description ul li::marker {
  content: "▶";
  font-size: 11px;
  color: #61b9e7;
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.brass::marker {
  content: url(/assets/images/bullets/brass.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.steel::marker {
  content: url(/assets/images/bullets/steel.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.ss316::marker {
  content: url(/assets/images/bullets/316.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.ss303::marker {
  content: url(/assets/images/bullets/303.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.col-polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.col-pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.col-cpvc::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.col-iron::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.iron::marker {
  content: url(/assets/images/bullets/iron.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.nat-polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.nat-pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.polypro::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.ptfe::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.pvc::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.pvdf::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li.abs::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description ul.material-list li::marker {
  content: url(/assets/images/bullets/bex.png);
}
#single-product .product_section .products-header-top .right #part-description div.left {
  width: 100%;
}
#single-product .product_section .products-header-top .right #part-description div.right {
  width: 100%;
}
@media screen and (max-width: 1290px) {
  #single-product .product_section .products-header-top .right #part-description div.right {
    margin-top: 0;
  }
}
@media screen and (max-width: 700px) {
  #single-product .product_section .products-header-top .right #part-description div.right {
    padding-left: 0;
    align-items: flex-start;
  }
}
#single-product .product_section .products-header-top .right #part-description br {
  display: block;
}
#single-product .product_section .products-header-top .right #part-description b {
  font-family: 'Roboto', sans-serif;
}
#single-product .product_section .products-header-top .right #part-description .model-number {
  width: 98%;
  text-align: left;
}
#single-product .product_section .products-header-top .right #single-product-cart form {
  min-height: 50px;
}
#single-product .product_section .products-header-top .right #single-product-cart .top {
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal {
  display: none;
  position: fixed;
  z-index: 13000;
  padding-top: 100px;
  left: 0;
  overflow-y: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 15px 40px 30px;
  width: 650px;
  top: 32%;
  position: absolute;
  left: 25%;
  right: 25%;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top .close {
  color: #61b9e7;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top .close:hover {
  cursor: pointer;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top .close .material-icons {
  font-size: 37px;
  line-height: 96px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top h6 {
  font-size: 37px;
  line-height: 96px;
  margin: 0;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table {
  width: 100%;
  max-width: 100%;
  margin: 30px 0 0px 0;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table thead th {
  border-bottom: 2px solid #292929;
  font-size: 18px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 15px 0px;
  line-height: 18px;
  text-align: left;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table thead th:nth-child(5) {
  text-align: right;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td {
  vertical-align: middle;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 10px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td:first-child {
  width: 100px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td:first-child .product-outer {
  max-height: 150px;
  width: 100%;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td .item-info a {
  display: block;
  text-decoration: none;
  font-size: 16px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td:last-child a {
  color: red;
  font-weight: bold;
  font-size: 14px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td:nth-child(5) {
  text-align: right;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td:nth-child(6) {
  text-align: right;
  padding-right: 30px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td input[type=text] {
  border: 2px solid #d7d7d7;
  width: 65px;
  padding: 14px 10px;
  text-align: center;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td input[type=text].placeholder-fix:focus::-webkit-input-placeholder {
  color: transparent;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td input[type=text].placeholder-fix:focus::-moz-placeholder {
  color: transparent;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td input[type=text].placeholder-fix:-moz-placeholder {
  color: transparent;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr td form .trigger {
  display: none;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tr.dark-border td {
  border-bottom: 2px solid #292929;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total {
  margin: 0 0 35px 0;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr th {
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr th strong {
  font-weight: bold;
  font-size: 24px;
  line-height: 50px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr td {
  padding: 30px 0;
  text-align: right;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr td span {
  font-size: 16px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total th {
  border-bottom: none;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total th strong {
  font-weight: bold;
  font-size: 33px;
  line-height: 50px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td {
  font-weight: bold;
  font-size: 33px;
  border-bottom: none;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total span {
  color: #61b9e7;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total span.currency {
  color: #000000;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table .cart-actions td {
  width: 100%;
  border: none;
  padding: 10px 0;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table .cart-actions td:first-child {
  width: auto;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table .cart-actions td .form-row {
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table .cart-actions .text a {
  font-size: 25px;
  margin-left: 10px;
}
#single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table .cart-actions .text i.fa-plus {
  font-size: 1.5rem;
}
#single-product .product_section .products-header-bottom {
  margin-top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
#single-product .product_section .products-header-bottom .ui-accordion {
  width: 100%;
  position: relative;
}
#single-product .product_section .products-header-bottom .ui-accordion h3 {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0 0 30px 0;
  -webkit-box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  color: #292929;
  cursor: pointer;
  width: 100%;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
  padding: 20px 7%;
  background-repeat: no-repeat;
  background-image: url(../images/baseline-expand_more-24px.svg);
  background-position: 96% 50%;
  position: relative;
}
#single-product .product_section .products-header-bottom .ui-accordion h3.ui-accordion-header-active {
  background-repeat: no-repeat;
  background-image: url(../images/baseline-chevron_right-24px.svg);
  background-position: 96% 50%;
}
#single-product .product_section .products-header-bottom .ui-accordion h3 .ui-icon {
  background: none;
}
#single-product .product_section .products-header-bottom .ui-accordion .ui-accordion-content {
  padding: 20px 7% 70px;
  position: relative;
  top: -40px;
}
#single-product .product_section .products-header-bottom .ui-accordion .ui-accordion-content.ui-accordion-content-active {
  -webkit-box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  border: none;
}
#single-product .product_section .products-header-bottom .ui-accordion .ui-accordion-content .dimensions img {
  height: auto;
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table {
  margin: 0;
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table table tbody tr:hover {
  box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table table tbody tr:hover td {
  border: none;
  background: #ffffff;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table table tbody tr.active {
  box-shadow: 0 1px 31px 0 rgba(0, 0, 0, 0.2);
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table table tbody tr.active td {
  border: none;
  background: #61b9e7;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
#single-product .product_section .products-header-bottom .ui-accordion .product-table table tbody tr.active td a {
  color: #ffffff !important;
}
#single-product .product_section .products-header-bottom .ui-accordion .panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
#single-product .product_section .products-header-bottom .panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
@media screen and (max-width: 1700px) {
  #single-product {
    width: 80%;
  }
  #single-product .product_section {
    width: 100%;
  }
}
@media screen and (max-width: 1670px) {
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total {
    font-size: 40px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total span {
    font-size: 40px;
  }
}
@media screen and (max-width: 1600px) {
  #single-product .product_section .products-header-top .right .product-attributes .tooltip .tooltiptext {
    width: 560px;
  }
}
@media screen and (max-width: 1560px) {
  #single-product {
    width: 85%;
  }
  #single-product .product_section .products-header-bottom #accordion .dimensions {
    padding: 60px 7% 70px 0;
  }
}
@media screen and (max-width: 1510px) {
  #single-product {
    width: 90%;
  }
}
@media screen and (max-width: 1360px) {
  #single-product .product_section .products-header-top .right .text {
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip .tooltiptext {
    width: 520px;
  }
}
@media screen and (max-width: 1390px) {
  #single-product {
    width: 95.5%;
  }
  #single-product .product_section .products-header-top {
    margin: -300px 0 0;
  }
  #single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumbnail-image {
    min-width: 150px;
    min-height: 100px;
    margin: 0px 5px 20px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .right .heading {
    margin: 0;
    align-self: flex-start;
  }
  #single-product .product_section .products-header-top .right .text {
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content {
    width: 50%;
  }
  #single-product .product_section .products-header-top .right .product-attributes {
    width: 90%;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip .tooltiptext {
    width: 520px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content {
    width: 50%;
  }
  #single-product .product_section .products-header-top .measures form a {
    width: 38%;
  }
  #single-product .product_section .products-header-top .specs {
    width: 100%;
  }
  #single-product .product_section .products-header-bottom {
    width: 100%;
  }
}
@media screen and (max-width: 1333px) {
  #single-product .product_section .products-header-top .right #single-product-cart #part-description {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1320px) {
  #single-product .product_section {
    width: 89.4%;
  }
  #single-product .product_section .products-header-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: -300px 0 0;
  }
  #single-product .product_section .products-header-top .left {
    z-index: 1;
  }
  #single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumbnail-image {
    min-width: 130px;
    min-height: 130px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .left .image-gallery .primary {
    min-width: 450px;
    margin-top: 45px;
    min-height: 410px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .right {
    width: 55%;
    /*margin-top: -480px;*/
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  #single-product .product_section .products-header-top .right .text {
    width: 50%;
    align-self: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  #single-product .product_section .products-header-top .right .text h4 {
    font-size: 45px;
    align-self: flex-start;
  }
  #single-product .product_section .products-header-top .right .text .or {
    align-self: center;
    margin-top: -95px;
    padding-left: 0px;
  }
  #single-product .product_section .products-header-top .right .text .bulk {
    align-self: flex-start;
  }
  #single-product .product_section .products-header-top .right .text .bulk .top {
    font-size: 16px;
  }
  #single-product .product_section .products-header-top .right .heading {
    margin: 0;
    align-self: flex-end;
  }
  #single-product .product_section .products-header-top .right .product-attributes {
    margin: 0;
    width: 90%;
    align-self: flex-end;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip.material .tooltiptext {
    width: 330px;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip .tooltiptext {
    width: 390px;
  }
  #single-product .product_section .products-header-top .right .measures {
    margin: 0px 0 30px;
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .measures form input[type=text] {
    width: 9%;
    padding: 18px 10px;
  }
  #single-product .product_section .products-header-top .right .measures form a {
    width: 32%;
  }
  #single-product .product_section .products-header-top .right .specs {
    width: 70%;
    align-self: flex-start;
  }
}
@media screen and (max-width: 1125px) {
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total {
    width: 40%;
    font-size: 34px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total span {
    font-size: 34px;
  }
}
@media screen and (max-width: 1080px) {
  #single-product {
    width: 100%;
  }
  #single-product .product_section {
    width: 94%;
    margin: 60px 0 60px auto;
  }
  #single-product .product_section .products-header-top {
    margin: -280px 6% 0 0;
  }
  #single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumbnail-image {
    min-width: 124px;
    min-height: 120px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .left .image-gallery .primary {
    min-width: 370px;
    min-height: 360px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .right {
    /*width:100%;*/
    margin-top: 20px;
  }
  #single-product .product_section .products-header-top .right .text {
    width: 100%;
    align-self: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  #single-product .product_section .products-header-top .right .text h4 {
    font-size: 45px;
    align-self: flex-start;
  }
  #single-product .product_section .products-header-top .right .text .or {
    align-self: center;
    margin-top: -95px;
    padding-left: 100px;
  }
  #single-product .product_section .products-header-top .right .text .bulk {
    align-self: flex-start;
    margin-bottom: 20px;
  }
  #single-product .product_section .products-header-top .right .text .bulk .top {
    font-size: 16px;
  }
  #single-product .product_section .products-header-top .right .heading {
    margin: 0;
    width: 45%;
    align-self: flex-end;
  }
  #single-product .product_section .products-header-top .right .product-attributes {
    margin: 0;
    width: 90%;
    align-self: flex-end;
    margin-top: 15px;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip {
    display: none;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip.material {
    display: block;
  }
  #single-product .product_section .products-header-top .right .measures {
    margin: 0px 0 30px;
    width: 100%;
    margin: 0;
  }
  #single-product .product_section .products-header-top .right .measures form input[type=text] {
    width: 9%;
    padding: 18px 10px;
  }
  #single-product .product_section .products-header-top .right .measures form a {
    width: 28%;
  }
  #single-product .product_section .products-header-top .right .specs {
    width: 96%;
  }
  #single-product .product_section .products-header-bottom #accordion h3 {
    padding: 20px 5%;
    margin-right: 6%;
    width: 94%;
  }
  #single-product .product_section .products-header-bottom #accordion h3.ui-accordion-header-active {
    margin-right: 0%;
    width: 100%;
  }
  #single-product .product_section .products-header-bottom #accordion #ui-accordion-content.ui-accordion-content-active {
    padding: 20px 5%;
  }
}
@media screen and (max-width: 950px) {
  #single-product .product_section .products-header-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    margin: -360px 6% 0 0;
  }
  #single-product .product_section .products-header-top .left {
    width: 100%;
    margin-left: -20px;
  }
  #single-product .product_section .products-header-top .left .image-gallery {
    -webkit-align-items: center;
    align-items: center;
  }
  #single-product .product_section .products-header-top .right {
    margin-top: 0;
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .heading {
    width: auto;
    text-align: center;
    align-self: center;
  }
  #single-product .product_section .products-header-top .right .heading .blue_border {
    margin: 10px auto 0px auto;
  }
  #single-product .product_section .products-header-top .right .text {
    width: 100%;
    text-align: center;
  }
  #single-product .product_section .products-header-top .right .text h4 {
    align-self: center;
    font-size: 31px;
  }
  #single-product .product_section .products-header-top .right .text h4 span {
    font-size: 31px;
  }
  #single-product .product_section .products-header-top .right .text .or {
    padding-left: 0;
    align-self: center;
    margin-top: -62px;
  }
  #single-product .product_section .products-header-top .right .text .bulk {
    align-self: center;
    margin: -30px 0 0;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content {
    left: 20%;
    right: 20%;
    top: 25%;
    width: 60%;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content .modal-top h6 {
    font-size: 22px;
    line-height: 50px;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content .close .material-icons {
    font-size: 22px;
    line-height: 50px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart {
    align-self: center;
    margin: -30px 0 0;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content {
    left: 20%;
    right: 20%;
    top: 25%;
    width: 60%;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top h6 {
    font-size: 22px;
    line-height: 50px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .close .material-icons {
    font-size: 22px;
    line-height: 50px;
  }
  #single-product .product_section .products-header-top .right .product-attributes {
    width: 90%;
    text-align: center;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip {
    display: none;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip.material {
    display: block;
  }
  #single-product .product_section .products-header-top .right .product-attributes .tooltip.material .tooltiptext {
    right: -40px;
  }
  #single-product .product_section .products-header-top .right .measures form a {
    width: 38%;
  }
  #single-product .product_section .products-header-top .right .measures form .shipping {
    width: 100%;
    margin: 20px 0 0;
  }
  #single-product .product_section .products-header-top .right .specs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  #single-product .product_section .products-header-top .right .specs .video {
    margin: 20px 0 0;
  }
}
@media screen and (max-width: 768px) {
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table {
    display: block;
    min-width: auto;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table thead {
    display: block;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table th {
    display: block;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody {
    display: block;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr {
    border-bottom: 1px solid #e1e1e1;
    display: block;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td {
    display: block;
    border: none;
    border-bottom: none;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 20px;
    left: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:first-child {
    padding: 10px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(1) {
    width: 100%;
    text-align: center;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(1) .product-outer {
    max-height: 75px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(1) .product-outer img {
    max-width: 50%;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(2) {
    padding-right: 0;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(2):before {
    content: "Product";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(3):before {
    content: "Price";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(4) {
    padding-right: 0;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(4):before {
    content: "QTY";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(5) {
    text-align: right;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(5):before {
    content: "Total";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(6) {
    padding-right: 0;
    text-align: right;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td:nth-of-type(6):before {
    content: "";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr td input[type=text] {
    width: 50px;
    height: 50px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr.dark-border {
    border-bottom: 2px solid #292929;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table tbody tr.dark-border td {
    border: none;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr th {
    border: none;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr td {
    border: none;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(1) td {
    width: auto;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(1) td:before {
    content: "";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(2) td {
    width: auto;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(2) td:before {
    content: "";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(3) td {
    width: auto;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(3) td:before {
    content: "";
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(4) {
    border-bottom: none;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(4) td {
    width: auto !important;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr:nth-of-type(4) td:before {
    content: "";
  }
}
@media screen and (max-width: 700px) {
  #single-product .product_section .products-header-top .right #single-product-cart #part-description {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  #single-product .product_section .products-header-top .left .image-gallery .primary {
    margin-top: 89px;
  }
  #single-product .product_section .products-header-top .right {
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .measures form a {
    width: 70%;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content {
    left: 10%;
    right: 10%;
    top: 20%;
    width: 80%;
  }
  #single-product .product_section .products-header-top #single-product-cart .modal .modal-content {
    left: 10%;
    right: 10%;
    top: 20%;
    width: 80%;
  }
}
@media screen and (max-width: 575px) {
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr th strong {
    font-size: 16px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total {
    font-size: 30px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .cart-table table.cart_total tr.total td.total span {
    font-size: 30px;
  }
}
@media screen and (max-width: 460px) {
  #single-product .product_section .products-header-top .left .image-gallery .primary {
    min-width: 320px;
    min-height: 190px;
  }
  #single-product .product_section .products-header-top .left .image-gallery .thumbnails .thumbnail-image {
    min-width: 85px;
    min-height: 64px;
    background-size: contain;
  }
  #single-product .product_section .products-header-top .right {
    width: 100%;
  }
  #single-product .product_section .products-header-top .right .product-attributes {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  #single-product .product_section .products-header-top .right .product-attributes .variation {
    width: 90%;
  }
  #single-product .product_section .products-header-top .right .product-attributes select {
    padding: 25px 18px 25px 18px;
    font-size: 13px;
  }
  #single-product .product_section .products-header-top .right .specs a {
    font-size: 20px;
  }
  #single-product .product_section .products-header-top .right .measures h3 {
    margin: 10px 0 0;
  }
  #single-product .product_section .products-header-top .right .measures form input[type=text] {
    width: 20%;
  }
  #single-product .product_section .products-header-top .right .measures form a {
    width: 60%;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content {
    left: 5%;
    right: 5%;
    top: 20%;
    width: 90%;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content .modal-top h6 {
    font-size: 20px;
  }
  #single-product .product_section .products-header-top .right .text .bulk .modal .modal-content .close .material-icons {
    font-size: 20px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content {
    left: 5%;
    right: 5%;
    top: 20%;
    width: 90%;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .modal-top h6 {
    font-size: 20px;
  }
  #single-product .product_section .products-header-top .right #single-product-cart .modal .modal-content .close .material-icons {
    font-size: 20px;
  }
  #single-product .product_section .products-header-top .right p {
    margin: 40px 3% 10px 0;
  }
}
@media screen and (max-width: 1080px) {
  .product-outer-wrapper {
    height: 200px;
  }
}
@media screen and (max-width: 600px) {
  .product-outer-wrapper {
    height: 215px;
  }
}
/***INNER PAGE***/
.inner-page h1 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}
.inner-page .blue_border {
  width: 145px;
  border-bottom: 13px solid #61b9e7;
  margin: 10px 0 0 0;
}
.inner-page.container {
  margin: 0 auto;
  width: 79.2%;
}
@media screen and (min-width: 1700px) {
  .inner-page.container {
    width: 1520px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1600px) {
  .inner-page.container {
    width: 84.6%;
  }
}
@media screen and (max-width: 1290px) {
  .inner-page.container {
    width: 88%;
  }
}
.inner-page p {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
.inner-page .inner-content a {
  font-size: inherit;
}
.inner-page .inner-content a.action-btn {
  font-size: 25px;
}
.inner-page .inner-content .alignleft {
  float: left;
  padding-right: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 639px) {
  .inner-page .inner-content .alignleft {
    float: none;
    padding-right: 0;
    padding-bottom: 10px;
  }
}
.inner-page .inner-content .alignright {
  float: right;
  padding-left: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 639px) {
  .inner-page .inner-content .alignright {
    float: none;
    padding-left: 0;
    padding-bottom: 10px;
  }
}
.inner-page .inner-content .cl {
  clear: both;
  display: block;
}
.page-outer-wrapper {
  background: #eeeeee;
  min-height: 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 0 10px 0;
}
.page-outer-wrapper p {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
.page-outer-wrapper .page-title-wrapper {
  width: 79.2%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@media screen and (min-width: 1700px) {
  .page-outer-wrapper .page-title-wrapper {
    width: 1520px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1600px) {
  .page-outer-wrapper .page-title-wrapper {
    width: 84.6%;
  }
}
@media screen and (max-width: 1290px) {
  .page-outer-wrapper .page-title-wrapper {
    width: 88%;
  }
}
.page-outer-wrapper .page-title-wrapper .page-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 80.5%;
  margin: 0 auto;
}
.page-outer-wrapper .page-title-wrapper .page-title h1 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  padding-top: 15px;
}
.page-outer-wrapper .page-title-wrapper .page-title .blue_border {
  width: 145px;
  border-bottom: 13px solid #61b9e7;
  margin: 10px 0 0 0;
}
@media screen and (max-width: 768px) {
  .page-outer-wrapper .page-title-wrapper {
    width: 82%;
  }
}
/**** About Page***/
section.bg-image {
  background: url('../images/hero.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 1100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 40px 0;
}
section.bg-image .login {
  width: 40%;
}
section.bg-image .login .tabs {
  position: relative;
  clear: both;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
section.bg-image .login .tabs .tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
section.bg-image .login .tabs .tab label.tab-label {
  padding: 10px 15px;
  position: relative;
  left: 90px;
  top: 60px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  color: #61b9e7;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
}
section.bg-image .login .tabs .tab label.tab-label:hover {
  color: #197cb2;
}
section.bg-image .login .tabs .tab .content {
  position: absolute;
  top: 0px;
  left: 0;
  opacity: 0;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  padding: 60px 100px;
}
section.bg-image .login .tabs .tab .content.show {
  opacity: 1;
  top: 100px;
}
section.bg-image .login .tabs .tab .content h1 {
  font-size: 40px;
  line-height: 48px;
  margin: 35px 0 30px;
}
section.bg-image .login .tabs .tab .content .register-location {
  display: none;
}
section.bg-image .login .tabs .tab [type=radio] {
  display: none;
}
section.bg-image .login .tabs .tab [type=radio]:checked ~ label {
  z-index: 3;
  color: #bababa;
}
section.bg-image .login .tabs .tab [type=radio]:checked ~ label ~ .content {
  z-index: 1;
  opacity: 1;
}
@media screen and (max-width: 1675px) {
  section.bg-image .login {
    width: 50%;
  }
}
@media screen and (max-width: 1340px) {
  section.bg-image .login {
    width: 60%;
  }
}
@media screen and (max-width: 1140px) {
  section.bg-image .login {
    width: 70%;
  }
}
@media screen and (max-width: 960px) {
  section.bg-image .login {
    width: 80%;
  }
}
@media screen and (max-width: 860px) {
  section.bg-image {
    min-height: 820px;
  }
  section.bg-image .login .tabs .tab .content {
    padding: 60px 80px;
  }
}
@media screen and (max-width: 768px) {
  section.bg-image {
    min-height: 925px;
  }
  section.bg-image .login .tabs .tab label.tab-label {
    left: 52px;
  }
  section.bg-image .login .tabs .tab .content {
    padding: 60px 60px;
  }
  section.bg-image .login .tabs .tab .content h1 {
    font-size: 50px;
  }
  section.bg-image .login .tabs .tab .content form .form-row .row-item {
    padding: 0 0 25px 0;
  }
  section.bg-image .login .tabs .tab .content form .form-row a.btn_forgot_password {
    padding: 0 0 25px 0;
  }
}
@media screen and (max-width: 735px) {
  section.bg-image {
    min-height: 1500px;
  }
}
@media screen and (max-width: 420px) {
  section.bg-image .login {
    width: 92%;
  }
  section.bg-image .login .tabs .tab label.tab-label {
    left: 12px;
    top: 25px;
  }
  section.bg-image .login .tabs .tab .content {
    padding: 35px 20px;
  }
  section.bg-image .login .tabs .tab .content h1 {
    font-size: 38px;
  }
}
#technical-assistance .page-outer-wrapper {
  background: #eeeeee no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 271px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 0 55px 0;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper {
  width: 68%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media screen and (max-width: 1290px) {
  #technical-assistance .page-outer-wrapper .page-title-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title:first-child {
  width: 30%;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title:nth-of-type(2) {
  width: 65%;
  margin-left: 15px;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title h1 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title p {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
#technical-assistance .page-outer-wrapper .page-title-wrapper .page-title .blue_border {
  width: 145px;
  border-bottom: 13px solid #61b9e7;
  margin: 10px 0 0 0;
}
#technical-assistance .inner-page {
  padding-bottom: 50px;
}
#technical-assistance .inner-page .technical-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  position: relative;
  margin: 20px 0;
  padding-bottom: 110px;
}
#technical-assistance .inner-page .technical-item:nth-of-type(even) {
  margin: 0 0 0 5%;
}
#technical-assistance .inner-page .technical-item:nth-of-type(odd) img {
  margin-left: -25px;
}
#technical-assistance .inner-page .technical-item .item-left {
  position: relative;
  min-height: 220px;
  width: 269px;
  -webkit-box-shadow: 9px 20px 40px -4px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 9px 20px 40px -4px rgba(0, 0, 0, 0.31);
  box-shadow: 9px 20px 40px -4px rgba(0, 0, 0, 0.31);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#technical-assistance .inner-page .technical-item .item-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 80%;
  left: 18%;
  top: 20%;
  position: absolute;
  padding: 20px 30px 70px 30px;
  background: #ffffff;
}
#technical-assistance .inner-page .technical-item .item-right h1 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}
#technical-assistance .inner-page .technical-item .item-right p {
  padding-bottom: 0;
}
#technical-assistance .inner-page .technical-item .item-right .blue_border {
  width: 145px;
  border-bottom: 13px solid #61b9e7;
  margin: 10px 0 0 0;
}
@media screen and (max-width: 1505px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom {
    margin: 80px 0;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 12%;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 21px;
    line-height: 21px;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 21px;
    line-height: 21px;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -60px 0 0 0;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 245px;
  }
}
@media screen and (max-width: 1440px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory {
    padding: 100px 0 30px 0;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 18px;
    line-height: 18px;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 18px;
    line-height: 18px;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 35%;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -95px 0 0 0;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 260px;
  }
}
@media screen and (max-width: 1330px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 50%;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 14px;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -112px 0 0 0;
  }
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 280px;
  }
}
@media screen and (max-width: 1290px) {
  .inner-page#technical-list .technical-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 20px 0 40px;
  }
  .inner-page#technical-list .technical-item:nth-of-type(even) {
    margin: 0;
  }
  .inner-page#technical-list .technical-item .item-right {
    padding: 40px 0px 80px 30px;
  }
  .inner-page#technical-list .technical-item .item-right h1 {
    font-size: 35px;
    line-height: 44px;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 310px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 35%;
    padding: 20px 15px 20px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 16px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading {
    padding: 0 0 0 15px;
    margin: -70px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
    border-bottom: 10px solid #65b9e7;
    width: 80px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -75px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 220px;
  }
}
@media screen and (max-width: 1160px) {
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 270px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 45%;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -95px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 240px;
  }
}
@media screen and (max-width: 1070px) {
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: 54%;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -124px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 270px;
  }
}
@media screen and (max-width: 1040px) {
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 250px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory {
    padding: 30px 0 0px 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text p {
    font-size: 14px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text .btn {
    font-size: 13px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 170px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    color: #1f1f1f;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading {
    padding: 0 0 0 15px;
    margin: -55px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .box-overlay {
    min-height: 70px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover {
    cursor: pointer;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 70px;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .subcategory-container-wrapper {
    max-height: 170px;
    min-height: 170px;
    position: unset;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .overlay {
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    height: auto;
    width: auto;
    animation-fill-mode: none;
    min-height: 0;
    visibility: hidden;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .sub-heading {
    animation-delay: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
    position: relative;
    bottom: 0px;
    margin: -55PX 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container img {
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
    position: unset;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
    min-height: 70px;
    -webkit-transition: min-height 0s ease 0s;
    -moz-transition: min-height 0s ease 0s;
    -ms-transition: min-height 0s ease 0s;
    -o-transition: min-height 0s ease 0s;
    transition: min-height 0s ease 0s;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container img {
    animation-delay: 0.5s;
    animation-duration: 0.3s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .sub-heading {
    animation-delay: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: none;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .overlay {
    animation-duration: 0.3s;
    -webkit-animation-name: none;
    animation-name: none;
    animation-fill-mode: forwards;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .overlay a {
    transition: none;
  }
}
@media screen and (max-width: 940px) {
  .inner-page .inner-content .search-section .search-keywords {
    width: 85%;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 230px;
  }
  .inner-page .inner-content .tabs .ui-accordion h3 {
    font-size: 20px;
    line-height: normal;
    padding: 25px 3%;
  }
  .inner-page .inner-content .tabs .ui-accordion h3 .ui-icon {
    display: none;
  }
}
@media screen and (max-width: 820px) {
  .inner-page .inner-content .search-section .search-keywords {
    width: 85%;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 210px;
  }
}
@media screen and (max-width: 768px) {
  #technical-assistance .page-outer-wrapper {
    background: #eeeeee;
    margin: 0 0 65px 0;
    background-size: cover;
    background-image: linear-gradient(rgba(238, 238, 238, 0.4), rgba(238, 238, 238, 0.4)), url('/assets/images/technical-bg.png') !important;
  }
  #technical-assistance .page-outer-wrapper .page-title-wrapper {
    width: 88%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  #technical-assistance .page-outer-wrapper .page-title-wrapper .page-title:first-child {
    width: 0%;
  }
  #technical-assistance .page-outer-wrapper .page-title-wrapper .page-title:nth-of-type(2) {
    width: 96%;
    margin-left: 0;
  }
  #technical-assistance .page-outer-wrapper .page-title-wrapper .page-title h1 {
    padding-top: 50px;
  }
  .inner-page.container {
    width: 82%;
  }
  .inner-page#technical-list {
    padding-bottom: 60px;
  }
  .inner-page#technical-list .technical-item {
    margin: 20px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    padding-bottom: 50px;
  }
  .inner-page#technical-list .technical-item:nth-of-type(even) {
    margin: 0;
  }
  .inner-page#technical-list .technical-item:nth-of-type(odd) img {
    margin-left: 0;
  }
  .inner-page#technical-list .technical-item .item-left {
    width: 100%;
    min-height: 270px;
  }
  .inner-page#technical-list .technical-item .item-right {
    width: 100%;
    left: 0;
    position: relative;
    background: none;
    padding: 45px 0 0;
  }
  .inner-page#technical-list .technical-item .item-right h1 {
    width: 90%;
  }
  .inner-page .inner-content .content-section {
    width: 100%;
    padding: 0 0 20px 0;
  }
  .inner-page .inner-content .content-section h2 {
    width: 100%;
  }
  .inner-page .inner-content .search-section .search-keywords {
    width: 95%;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 175px;
  }
  .inner-page .inner-content .product-category .item-bottom {
    margin: 0 0 90px 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory {
    width: 48%;
    margin: 0px 0 0px 0;
    padding: 0 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:not(:last-child) {
    margin: 0px 0 0px 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:nth-child(odd) {
    margin-right: 4%;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 200px;
    padding: 0;
    text-align: center;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper img {
    padding: 20px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    padding: 20px;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading {
    margin: -75px 0 0 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .subcategory-container-wrapper {
    min-height: 170px;
    max-height: 200px;
    padding: 0;
  }
  .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .sub-heading {
    margin: -75px 0 0 0;
  }
  .inner-page .inner-content .product-category .product-img {
    padding: 20px 0 70px 0;
  }
}
@media screen and (max-width: 640px) {
  .inner-page .inner-content .search-section .search-keywords {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation {
    margin: 10px 0;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 350px;
  }
  .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-options {
    width: 105%;
  }
  .inner-page .inner-content .search-section .search-keywords a {
    margin: 20px 0;
  }
}
@media screen and (max-width: 1700px) {
  #technical-item .page-outer-wrapper .page-title-wrapper .page-title {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  #technical-item .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  #technical-item .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #technical-item .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #technical-item .page-outer-wrapper .page-title-wrapper .page-title h1 {
    font-size: 35px;
  }
}
#technical-item .inner-page .inner-content {
  width: 80.5%;
  margin: 0 auto;
}
@media screen and (max-width: 1700px) {
  #technical-item .inner-page .inner-content {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  #technical-item .inner-page .inner-content {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  #technical-item .inner-page .inner-content {
    width: 100%;
  }
}
#technical-item .inner-page .inner-content .search-section h3 {
  font-size: 31px;
  margin: 0;
  line-height: normal;
  padding: 20px 0 0 0;
}
#technical-item .inner-page .inner-content .search-section p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
#technical-item .inner-page .inner-content .search-section .search-keywords {
  width: 78%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
#technical-item .inner-page .inner-content .search-section .search-keywords .material-icons {
  font-size: 25px;
  line-height: 33px;
}
#technical-item .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
  border: none;
  background-color: #eeeeee;
  width: 400px;
}
@media screen and (max-width: 1735px) {
  #technical-item .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 360px;
  }
}
@media screen and (max-width: 1700px) {
  #technical-item .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder {
    width: 340px;
  }
}
#technical-item .inner-page .inner-content .search-section .search-keywords .custom-select-variation .cs-placeholder.selected {
  color: #ffffff;
  background-color: #61b9e7;
}
#technical-item .inner-page .inner-content .search-section .search-keywords .custom-select-variation.cs-active .cs-placeholder {
  background-color: #61b9e7;
  color: #ffffff;
}
#technical-item .inner-page .inner-content .search-section .search-keywords .buttons .form-row {
  padding: 0;
}
#technical-item .inner-page .inner-content .product-category p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
#technical-item .inner-page .inner-content .product-category .item-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 40px 0;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory {
  padding: 60px 0 30px 0;
  position: relative;
  width: 21.3%;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:not(:last-child) {
  margin-right: 4%;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  position: relative;
  -webkit-justify-content: center;
  justify-content: center;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.5s ease 0.3s;
  -moz-transition: min-height 0.5s ease 0.3s;
  -ms-transition: min-height 0.5s ease 0.3s;
  -o-transition: min-height 0.5s ease 0.3s;
  transition: min-height 0.5s ease 0.3s;
  position: absolute;
  width: 100%;
  z-index: -9999;
  overflow: hidden;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .subcategory-container-wrapper {
  width: 100%;
  min-height: 170px;
  max-height: 200px;
  -webkit-align-self: center;
  align-self: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 10px 0 0 0;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .overlay {
  visibility: hidden;
  width: 100%;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
  color: #1f1f1f;
  position: absolute;
  top: -20%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 25px 25px 25px 25px;
  width: 100%;
}
@media screen and (max-width: 1735px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: -15%;
  }
}
@media screen and (max-width: 1600px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text {
    top: -5%;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .text .btn {
  font-size: 16px;
  line-height: 30px;
}
@media screen and (max-width: 1600px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .box {
    -webkit-transition: margin 0.3s ease-in-out 0.3s;
    -moz-transition: margin 0.3s ease-in-out 0.3s;
    -ms-transition: margin 0.3s ease-in-out 0.3s;
    -o-transition: margin 0.3s ease-in-out 0.3s;
    transition: margin 0.3s ease-in-out 0.3s;
    margin: 0;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading {
  padding: 0 0 0 25px;
  margin: -55px 0 0 0;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head {
  color: #292929;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head {
    font-size: 24px;
    line-height: 24px;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head span {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}
@media screen and (max-width: 1735px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .head span {
    font-size: 24px;
    line-height: 24px;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory .subcategory-container .sub-heading .blue_border {
  border-bottom: 16px solid #61b9e7;
  width: 116px;
  margin: 15px 0 0 0;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover {
  cursor: pointer;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
  min-height: 220px;
  -webkit-transition: min-height 0.3s ease 0.3s;
  -moz-transition: min-height 0.3s ease 0.3s;
  -ms-transition: min-height 0.3s ease 0.3s;
  -o-transition: min-height 0.3s ease 0.3s;
  transition: min-height 0.3s ease 0.3s;
}
@media screen and (max-width: 1735px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 217px;
  }
}
@media screen and (max-width: 1600px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box-overlay {
    min-height: 235px;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .subcategory-container-wrapper {
  max-height: unset;
  min-height: 100px;
  position: relative;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .overlay {
  animation-delay: 0.8s;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInText;
  animation-name: fadeInText;
  height: auto;
  width: auto;
  animation-fill-mode: both;
  min-height: 100px;
}
@media screen and (max-width: 1600px) {
  #technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .box {
    margin: -20px 0 0 0;
    transition: margin 0.3s ease 0.4s;
  }
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container .sub-heading {
  animation-delay: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryHead;
  animation-name: fadeInUpCategoryHead;
  animation-fill-mode: forwards;
  position: relative;
  bottom: -100px;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory:hover .subcategory-container img {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUpCategoryImg;
  animation-name: fadeInUpCategoryImg;
  animation-fill-mode: forwards;
  position: absolute;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .box-overlay {
  min-height: 90px;
  -webkit-transition: min-height 0.3s ease 0.3s;
  -moz-transition: min-height 0.3s ease 0.3s;
  -ms-transition: min-height 0.3s ease 0.3s;
  -o-transition: min-height 0.3s ease 0.3s;
  transition: min-height 0.3s ease 0.3s;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container img {
  animation-delay: 0.5s;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategoryImg;
  animation-name: fadeInDownCategoryImg;
  animation-fill-mode: both;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .sub-heading {
  animation-delay: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInDownCategory;
  animation-name: fadeInDownCategory;
  animation-fill-mode: both;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .overlay {
  animation-duration: 0.3s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}
#technical-item .inner-page .inner-content .product-category .item-bottom .subcategory.inactive .subcategory-container .overlay a {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
#technical-item .inner-page .inner-content .product-category .product-img {
  padding: 20px 0 90px 0;
}
#technical-item .inner-page .inner-content .product-category .product-img img {
  -webkit-box-shadow: -37px 9px 59px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -37px 9px 59px 0px rgba(0, 0, 0, 0.13);
  box-shadow: -37px 9px 59px 0px rgba(0, 0, 0, 0.13);
}
#technical-item .inner-page .inner-content .content-section p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
#technical-item .inner-page .inner-content .content-section p a {
  font-size: 14px;
  line-height: 30px;
}
#technical-item .inner-page .inner-content .content-section h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  font-style: italic;
  font-family: Montserrat;
}
#technical-item .inner-page .inner-content ul {
  padding-bottom: 10px;
}
#technical-item .inner-page .inner-content ul li {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}
#technical-item .inner-page .inner-content ul li a {
  line-height: 22px;
}
#technical-item .inner-page .inner-content ul li ul {
  padding-top: 20px;
}
#technical-item .inner-page .inner-content p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}
#technical-item .inner-page .inner-content p i {
  font-weight: inherit;
}
#technical-item .inner-page .inner-content p a {
  font-size: inherit;
}
#technical-item .inner-page .inner-content p.center {
  text-align: center;
}
#technical-item .inner-page .inner-content .tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  padding: 0 0 50px 0;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion {
  width: 100%;
  position: relative;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion h3 {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0 0 30px 0;
  -webkit-box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 20px -7px rgba(0, 0, 0, 0.2);
  color: #292929;
  cursor: pointer;
  width: 100%;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 31px;
  font-weight: bold;
  line-height: 56px;
  padding: 35px 20% 35px 4%;
  background-repeat: no-repeat;
  background-image: url(../images/baseline-expand_more-24px.svg);
  background-position: 96% 50%;
  position: relative;
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .tabs .ui-accordion h3 {
    line-height: 1.5em;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 420px) {
  #technical-item .inner-page .inner-content .tabs .ui-accordion h3 {
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#technical-item .inner-page .inner-content .tabs .ui-accordion h3.ui-accordion-header-active {
  background-repeat: no-repeat;
  background-image: url(../images/baseline-chevron_right-24px.svg);
  background-position: 96% 50%;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion h3 .ui-icon {
  background: none;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion .ui-accordion-content {
  padding: 20px 7% 70px;
  position: relative;
  top: -40px;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion .ui-accordion-content.ui-accordion-content-active {
  -webkit-box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 13px 20px -10px rgba(0, 0, 0, 0.2);
  border: none;
}
#technical-item .inner-page .inner-content .tabs .ui-accordion ul li {
  font-weight: 400;
}
#technical-item .inner-page .inner-content .tabs .centered {
  text-align: center;
}
#technical-item .inner-page .inner-content .tabs .thumb-cols {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin-bottom: 40px;
  list-style: none;
  padding: 0;
}
#technical-item .inner-page .inner-content .tabs .thumb-cols li {
  margin-top: 20px;
}
#technical-item .inner-page .inner-content .tabs .thumb-cols li .col-title {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  padding: 5px;
  margin: 10px 0;
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .tabs .thumb-cols li .col-title {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
}
#technical-item .inner-page .inner-content .tabs .thumb-cols.twice li {
  width: 45%;
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .tabs .thumb-cols.twice li {
    width: 100%;
  }
}
#technical-item .inner-page .inner-content .tabs .thumb-cols.thrice li {
  width: 30%;
}
@media screen and (max-width: 960px) {
  #technical-item .inner-page .inner-content .tabs .thumb-cols.thrice li {
    width: 45%;
  }
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .tabs .thumb-cols.thrice li {
    width: 100%;
  }
}
#technical-item .inner-page .inner-content .sub-item .sub-title {
  color: #61b9e7;
}
#technical-item .inner-page .inner-content .sub-item h2 {
  font-size: 30px;
  line-height: 1.5em;
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .sub-item h2 {
    font-size: 24px;
  }
}
#technical-item .inner-page .inner-content .sub-item h4 {
  font-size: 26px;
  line-height: 1.5em;
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .inner-content .sub-item h4 {
    font-size: 20px;
  }
}
#technical-item .inner-page .inner-content .sub-item h4.item-title {
  margin: 10px 0;
}
#technical-item .inner-page .inner-content .sweeping-section h4 {
  font-size: 24px;
  line-height: 1.5em;
  text-align: center;
  padding: 10px 0;
  margin: 0;
  color: #61b9e7;
}
#technical-item .inner-page .inner-content table {
  width: 100%;
  border: 1px solid;
  border-collapse: collapse;
}
#technical-item .inner-page .inner-content table thead th {
  font-weight: 700;
  text-align: center;
  border-left: 1px solid;
}
#technical-item .inner-page .inner-content table thead th:first-child {
  border-left: none;
}
#technical-item .inner-page .inner-content table tbody td {
  border-top: 1px solid;
  border-left: 1px solid;
}
#technical-item .inner-page .inner-content table tbody td:first-child {
  border-left: none;
}
#technical-item .inner-page .inner-content table tr td,
#technical-item .inner-page .inner-content table tr th {
  padding: 5px 10px;
}
#technical-item .inner-page img {
  max-width: 100%;
  height: auto;
}
#technical-item .inner-page .cols {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 40px;
}
#technical-item .inner-page .cols.nopadding {
  margin-bottom: 0px;
}
@media screen and (max-width: 960px) {
  #technical-item .inner-page .cols {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
}
@media screen and (max-width: 639px) {
  #technical-item .inner-page .cols {
    margin-bottom: 20px;
  }
  #technical-item .inner-page .cols.nopadding {
    margin-bottom: 0px;
  }
}
#technical-item .inner-page .cols.column-reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  -webkit-flex-flow: row-reverse nowrap;
  flex-flow: row-reverse nowrap;
}
@media screen and (max-width: 960px) {
  #technical-item .inner-page .cols.column-reverse {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
}
#technical-item .inner-page .cols.center {
  -webkit-align-items: center;
  align-items: center;
}
#technical-item .inner-page .cols .col {
  width: 48%;
}
@media screen and (max-width: 960px) {
  #technical-item .inner-page .cols .col {
    width: 100%;
  }
}
#technical-item .inner-page .cols.three-cols .col {
  width: 30%;
}
@media screen and (max-width: 960px) {
  #technical-item .inner-page .cols.three-cols .col {
    width: 100%;
  }
}
svg.find-a-rep-svg {
  background: transparent;
}
svg.find-a-rep-svg .region-labels {
  fill: white;
  cursor: default !important;
}
svg.find-a-rep-svg .region {
  cursor: pointer;
  fill: #1a1a1a;
  stroke: white;
  stroke-width: 0.5;
  fill-rule: evenodd;
  clip-rule: evenodd;
}
svg.find-a-rep-svg .region[data-url],
svg.find-a-rep-svg .region.has-url {
  cursor: pointer;
}
svg.find-a-rep-svg .region:hover,
svg.find-a-rep-svg .region.active,
svg.find-a-rep-svg .region:hover .sub-region,
svg.find-a-rep-svg .region.active .sub-region {
  fill: #65B9E7;
}
.find-a-rep-wrapper .fr-infos {
  display: none !important;
}
.find-a-rep-wrapper.find-a-rep-wrapper-world {
  margin-top: 50px;
}
.find-a-rep-wrapper .fr-info-box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  padding: 26px;
  -webkit-box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-50%, 20px);
  -moz-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
}
.find-a-rep-wrapper .fr-info-box.active {
  display: block;
}
.find-a-rep-wrapper .fr-info-box.left-1 {
  -webkit-transform: translate(-10%, 20px);
  -moz-transform: translate(-10%, 20px);
  -ms-transform: translate(-10%, 20px);
  transform: translate(-10%, 20px);
}
.find-a-rep-wrapper .fr-info-box.right-1 {
  -webkit-transform: translate(-90%, 20px);
  -moz-transform: translate(-90%, 20px);
  -ms-transform: translate(-90%, 20px);
  transform: translate(-90%, 20px);
}
@media screen and (max-width: 900px) {
  .find-a-rep-wrapper .fr-info-box {
    padding: 22px;
  }
}
@media screen and (max-width: 500px) {
  .find-a-rep-wrapper .fr-info-box {
    padding: 18px;
  }
}
@media screen and (max-width: 900px) {
  .find-a-rep-wrapper .fr-info-box .fr-info {
    width: 260px;
  }
}
@media screen and (max-width: 500px) {
  .find-a-rep-wrapper .fr-info-box .fr-info {
    width: 240px;
  }
}
.find-a-rep-wrapper .fr-info-box .fr-info.fr-not-found p {
  margin: 0;
  padding: 0;
}
.find-a-rep-wrapper .fr-info-box .fr-info.show-max-one .counties .county {
  display: none;
}
.find-a-rep-wrapper .fr-info-box .fr-info.show-max-one .counties .county.active {
  display: block;
}
.find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many {
  width: 550px;
  padding: 15px;
}
@media screen and (max-width: 900px) {
  .find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many {
    width: 500px;
    padding: 5px;
  }
}
@media screen and (max-width: 700px) {
  .find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many {
    width: 235px;
  }
}
.find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many .counties {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: -30px;
}
.find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many .counties .county {
  width: 45%;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .find-a-rep-wrapper .fr-info-box .fr-info.show-multiple.count-many .counties .county {
    width: 100%;
  }
}
.find-a-rep-wrapper .fr-info-box .fr-info .select-wrapper {
  margin-bottom: 22px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .select-wrapper .custom-select-variation-styles .cs-options ul {
  max-height: 220px;
}
@media screen and (max-width: 640px) {
  .find-a-rep-wrapper .fr-info-box .fr-info .select-wrapper .custom-select-variation-styles .cs-options ul {
    max-height: 120px;
  }
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county p,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h1,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h2,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h3,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h4,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h5,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h6 {
  padding: 0;
  margin: 0 0 5px 0;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county p:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h1:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h2:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h3:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h4:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h5:last-child,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county h6:last-child {
  margin-bottom: 0;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county * {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.7em;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county strong,
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .bold {
  font-weight: 700;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .xl {
  font-size: 18px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .lg {
  font-size: 16px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .md {
  font-size: 14px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .spacer {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  height: 10px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county .spacer.spacer-lg {
  height: 15px;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county a {
  color: #65B9E7;
}
.find-a-rep-wrapper .fr-info-box .fr-info .counties .county a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 900px) {
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .xl {
    font-size: 16px;
  }
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .lg {
    font-size: 14px;
  }
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .md {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .xl {
    font-size: 14px;
  }
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .lg {
    font-size: 12px;
  }
  .find-a-rep-wrapper .fr-info-box .fr-info .counties .county .md {
    font-size: 10px;
  }
}
body.find_a_rep .page-outer-wrapper {
  margin-bottom: 0;
}
body.find_a_rep .inner-page {
  padding: 80px 0;
  width: 88%;
  max-width: 1125px;
  margin: 0 auto;
}
body.find_a_rep .inner-page .inner-content {
  width: 100%;
  max-width: 100%;
}
body.find_a_rep .inner-page .inner-content .rep-text {
  text-align: center;
}
body.find_a_rep .inner-page .inner-content .rep-text p {
  padding: 0;
  font-size: 26px;
  line-height: 1.25em;
  margin: 0 0 12px 0;
}
body.find_a_rep .inner-page .inner-content .rep-text p * {
  display: inline-block;
  vertical-align: middle;
}
body.find_a_rep .inner-page .inner-content .rep-text p a {
  color: #65B9E7;
}
body.find_a_rep .inner-page .inner-content .rep-text p:last-child {
  margin-bottom: 0;
}
body.find_a_rep .inner-page .inner-content .find-a-rep {
  padding: 70px 0 120px;
}
body.find_a_rep .inner-page .inner-content .find-a-rep.us {
  cursor: pointer;
  padding: 0 0 90px;
}
body.find_a_rep #rep-ca .fr-info.show-multiple-counties.count-many .counties {
  display: flex;
  width: 650px;
}
body.find_a_rep #rep-ca .fr-info.show-multiple-counties.count-many .counties .county {
  width: 33%;
}
#contact-wrap .inner-page {
  padding-bottom: 120px;
}
#contact-wrap .inner-page .inner-content {
  width: 80.5%;
  margin: 0 auto;
}
#contact-wrap .inner-page .inner-content .three-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 0 65px;
}
#contact-wrap .inner-page .inner-content .three-section h6 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 29px;
  margin: 0;
}
#contact-wrap .inner-page .inner-content .three-section p {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 0;
  font-weight: 500;
}
#contact-wrap .inner-page .inner-content .three-section p strong {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
}
#contact-wrap .inner-page .inner-content .three-section a {
  font-size: 14px;
  line-height: 19px;
}
#contact-wrap .inner-page .inner-content .contact-form .head {
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
}
#contact-wrap .inner-page .inner-content .contact-form form {
  padding: 20px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  color: #585858;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row.btn {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row.btn a {
  font-size: 23px;
  line-height: 22px;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row input[type=text] {
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row textarea {
  padding: 25px 30px 25px 30px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #585858;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #585858;
}
#contact-wrap .inner-page .inner-content .contact-form form .form-row ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #585858;
}
@media screen and (max-width: 1700px) {
  #contact-wrap .inner-page .inner-content {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  #contact-wrap .inner-page .inner-content {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  #contact-wrap .inner-page .inner-content {
    width: 100%;
  }
}
@media screen and (max-width: 1220px) {
  #contact-wrap .inner-page .inner-content .three-section h6 {
    padding-right: 10px;
  }
}
@media screen and (max-width: 1024px) {
  #contact-wrap .inner-page .inner-content {
    width: 100%;
    margin: 0;
  }
  #contact-wrap .inner-page .inner-content .three-section h6 {
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  #contact-wrap .inner-page .inner-content .three-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  #contact-wrap .inner-page .inner-content .three-section h6 {
    font-size: 18px;
    padding-right: 0;
  }
  #contact-wrap .inner-page .inner-content .three-section p strong {
    font-size: 14px;
  }
  #contact-wrap .inner-page .inner-content .three-section .contact {
    padding: 0 0 20px 0;
  }
}
@media screen and (max-width: 1700px) {
  #contact-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  #contact-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  #contact-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #contact-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
/**** Cart Page***/
.inner-page#cart {
  padding-bottom: 120px;
}
.inner-page#cart .inner-content {
  width: 80.5%;
  margin: 0 auto;
}
.inner-page#cart .inner-content #empty-address {
  display: none;
}
.inner-page#cart .inner-content .cart-menu ul {
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  position: relative;
  margin: 0;
  padding: 0;
}
.inner-page#cart .inner-content .cart-menu ul li {
  padding: 0 15px;
}
.inner-page#cart .inner-content .cart-menu ul li:not(:last-child)::after {
  content: "|";
  position: absolute;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 26px;
  font-weight: 500;
  line-height: 87px;
  margin: 0 0 0 15px;
}
.inner-page#cart .inner-content .cart-menu ul li:first-child {
  padding: 0 15px 0 0;
}
.inner-page#cart .inner-content .cart-menu ul li a {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 30px;
  font-weight: 500;
  line-height: 87px;
}
.inner-page#cart .inner-content .cart-menu ul li a:not(.active) {
  color: #000000;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#cart .inner-content .cart-menu ul li a:not(.active):hover {
  color: #61b9e7;
}
.inner-page#cart .inner-content .modal .related-list ul li {
  padding-bottom: 10px;
}
.inner-page#cart .inner-content .modal .related-list ul li span {
  font-size: 12px;
}
.inner-page#cart .inner-content .related-notification {
  margin: 10px 0;
  padding: 10px 5px 10px 30px;
  background: #eeeeee;
}
.inner-page#cart .inner-content .related-notification p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  padding: 0;
  margin: 0;
}
.inner-page#cart .inner-content .related-notification p a {
  border-bottom: 2px solid #000000;
  color: #000000;
  -webkit-transition: border 0.3s;
  -moz-transition: border 0.3s;
  -ms-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}
.inner-page#cart .inner-content .related-notification p a:hover {
  border-bottom-color: transparent;
}
.inner-page#cart .inner-content .cart-table table {
  width: 100%;
  max-width: 100%;
  margin: 30px 0 0px 0;
}
.inner-page#cart .inner-content .cart-table table thead th {
  border-bottom: 2px solid #292929;
  font-size: 18px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 15px 0px;
  line-height: 18px;
  text-align: left;
}
.inner-page#cart .inner-content .cart-table table thead th:nth-child(5) {
  text-align: right;
}
.inner-page#cart .inner-content .cart-table table tr td {
  vertical-align: middle;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 10px;
}
.inner-page#cart .inner-content .cart-table table tr td:first-child {
  width: 100px;
}
.inner-page#cart .inner-content .cart-table table tr td:first-child .product-outer {
  max-height: 150px;
  width: 100%;
}
.inner-page#cart .inner-content .cart-table table tr td .item-info a {
  display: block;
  text-decoration: none;
  font-size: 16px;
}
.inner-page#cart .inner-content .cart-table table tr td .item-info .leadtime {
  font-style: italic;
  font-size: 14px;
  display: block;
}
.inner-page#cart .inner-content .cart-table table tr td:last-child a {
  color: red;
  font-weight: bold;
  font-size: 14px;
}
.inner-page#cart .inner-content .cart-table table tr td:nth-child(5) {
  text-align: right;
}
.inner-page#cart .inner-content .cart-table table tr td:nth-child(6) {
  text-align: right;
  padding-right: 30px;
}
.inner-page#cart .inner-content .cart-table table tr td input[type=text] {
  border: 2px solid #d7d7d7;
  width: 65px;
  padding: 14px 10px;
  text-align: center;
}
.inner-page#cart .inner-content .cart-table table tr td input[type=text].placeholder-fix:focus::-webkit-input-placeholder {
  color: transparent;
}
.inner-page#cart .inner-content .cart-table table tr td input[type=text].placeholder-fix:focus::-moz-placeholder {
  color: transparent;
}
.inner-page#cart .inner-content .cart-table table tr td input[type=text].placeholder-fix:-moz-placeholder {
  color: transparent;
}
.inner-page#cart .inner-content .cart-table table tr td form .trigger {
  display: none;
}
.inner-page#cart .inner-content .cart-table table tr.dark-border td {
  border-bottom: 2px solid #292929;
}
.inner-page#cart .inner-content .cart-table table tfoot tr.cart-actions p.order-note {
  text-align: left;
  width: 85%;
}
.inner-page#cart .inner-content .cart-table table.cart_total {
  margin: 0 0 35px 0;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr th {
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr th strong {
  font-weight: bold;
  font-size: 24px;
  line-height: 50px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr td {
  padding: 30px 0;
  text-align: right;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr td span {
  font-size: 16px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total th {
  border-bottom: none;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total th strong {
  font-weight: bold;
  font-size: 33px;
  line-height: 50px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total td {
  font-weight: bold;
  font-size: 33px;
  border-bottom: none;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total {
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total span {
  color: #61b9e7;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 45px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total span.currency {
  color: #000000;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .tooltip {
  position: relative;
  color: #61b9e7;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .tooltip .tooltiptext {
  display: none;
  background-color: #61b9e7;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  z-index: 999;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 600px;
  top: 10px;
  right: 0px;
  position: absolute;
}
@media screen and (max-width: 700px) {
  .inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .tooltip .tooltiptext {
    width: 325px;
  }
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .tooltip .tooltiptext a {
  color: #ffffff !important;
  text-decoration: underline;
  font-weight: 300 !important;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .tooltip:hover .tooltiptext {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .text {
  margin: 0;
  cursor: pointer;
  position: relative;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .text::before {
  content: "";
  background: none;
  border: 2px solid #61b9e7;
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  padding: 0;
  margin-right: 5px;
  text-align: center;
  border-radius: 20%;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .custom {
  opacity: 0;
  position: absolute;
  display: inline-block;
  cursor: pointer;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt .custom:checked + .text:before {
  background: #61b9e7;
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt label.terms {
  font-size: 12px;
}
.inner-page#cart .inner-content .cart-table table.cart_total tr.tax-exempt input#vat_number {
  padding: 3px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 50%;
}
.inner-page#cart .inner-content .cart-table table .cart-actions td {
  width: 100%;
  border: none;
  padding: 10px 0;
}
.inner-page#cart .inner-content .cart-table table .cart-actions td:first-child {
  width: auto;
}
.inner-page#cart .inner-content .cart-table table .cart-actions td .form-row {
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.inner-page#cart .inner-content a.checkout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.inner-page#cart .inner-content .checkout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.inner-page#cart .inner-content .checkout .checkout-form {
  width: 55.5%;
}
.inner-page#cart .inner-content .checkout .checkout-form h3 {
  font-size: 18px;
  line-height: 54px;
  font-family: Montserrat;
  margin: 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item a {
  font-size: 18px;
  line-height: 54px;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item .text {
  margin: 0;
  font-size: 16px;
  color: #585858;
  cursor: pointer;
  position: relative;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item .custom {
  opacity: 0;
  position: absolute;
  display: inline-block;
  cursor: pointer;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item .custom + .text:before {
  content: '';
  background: none;
  border: 2px solid #61b9e7;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 5px;
  text-align: center;
  border-radius: 20%;
}
.inner-page#cart .inner-content .checkout .checkout-form .row-item .custom:checked + .text:before {
  background: #61b9e7;
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 20px 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field.two-section {
  width: 48%;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field span.input {
  padding-top: 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field label {
  padding: 10px 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field label span.required {
  color: red;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field .input__field {
  padding: 15px 15px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field .input-select {
  background-image: linear-gradient(45deg, transparent 50%, #61b9e7 0), linear-gradient(135deg, #61b9e7 50%, transparent 0);
  background-position: calc(100% - 21px) calc(1.4em + 0px), calc(100% - 16px) calc(1.4em + 0px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px,
                                5px 5px,
                                1px 1.5em;
  background-repeat: no-repeat;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field textarea {
  padding: 15px 15px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #585858;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #585858;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #585858;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row .month-selector {
  min-width: 105px;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row.btn {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 20px 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .form-row.btn a {
  font-size: 23px;
  line-height: 22px;
}
@media screen and (max-width: 640px) {
  .inner-page#cart .inner-content .checkout .checkout-form .form-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field.two-section:first-child {
    margin: 0;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .form-row.btn {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
}
.inner-page#cart .inner-content .checkout .checkout-form .checkout-personal {
  border-bottom: 2px solid #eeeeee;
}
.inner-page#cart .inner-content .checkout .checkout-form .address {
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 2px solid #eeeeee;
}
.inner-page#cart .inner-content .checkout .checkout-form .address .address-item {
  width: 48%;
}
.inner-page#cart .inner-content .checkout .checkout-form .address .address-item .row-item .billing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.inner-page#cart .inner-content .checkout .checkout-form ul.payment_methods {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inner-page#cart .inner-content .checkout .checkout-form ul.payment_methods li {
  padding-bottom: 10px;
}
.inner-page#cart .inner-content .checkout .checkout-form .payment {
  width: 72%;
  padding: 20px 0 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .payment h4 {
  font-size: 21px;
  line-height: 54px;
  font-family: Montserrat;
  margin: 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .payment .row-item {
  -webkit-align-items: baseline;
  align-items: baseline;
  margin: 0 0 15px 0;
}
.inner-page#cart .inner-content .checkout .checkout-form .payment .form-row .input-field.three-section {
  width: 30%;
}
.inner-page#cart .inner-content .checkout .cart-sidebar {
  border-left: 2px solid #d5d5d5;
  width: 38%;
  padding-left: 40px;
}
.inner-page#cart .inner-content .checkout .cart-sidebar h4 {
  font-size: 18px;
  line-height: 54px;
  font-family: Montserrat;
  margin: 0;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table {
  margin: 0;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table tr td {
  border-bottom: none;
  padding: 20px 0px 20px 10px;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table tr td:first-child {
  padding-left: 0;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table tr td:nth-of-type(2) a {
  margin: 0;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table tr td:nth-of-type(4) .material-icons {
  color: #f73737;
  font-size: 30px;
  cursor: pointer;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr:first-child td {
  border-top: 1px solid #e1e1e1;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr:first-child th {
  border-top: 1px solid #e1e1e1;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr th strong {
  font-size: 18px;
  line-height: 50px;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr td {
  padding: 30px 0;
  border-bottom: 1px solid #e1e1e1;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr.total th strong {
  font-size: 24px;
  padding: 0 10px;
  font-weight: 600;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr.total td {
  border-bottom: none;
  padding: 30px 0;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr.total td.total {
  line-height: 40px;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr.total td.total span {
  font-size: 35px;
  line-height: 50px;
  font-family: Montserrat;
  font-weight: 700;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr.taxes td {
  border-bottom: none;
}
.inner-page#cart .inner-content .checkout .cart-sidebar.cart-table select.shipping-selector {
  margin: 0;
  text-align: center;
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  background-image: linear-gradient(45deg, transparent 50%, #61b9e7 0), linear-gradient(135deg, #61b9e7 50%, transparent 0);
  background-position: calc(94%) calc(1.4em), calc(95%) calc(1.4em), calc(97.5%) 0.5em;
  background-size: 5px 5px,5px 5px,1px 1.5em;
  background-repeat: no-repeat;
}
.inner-page#cart .inner-content .checkout.action-btn i.fa-plus {
  font-size: 20px;
  padding: 0 5px;
}
.inner-page#cart .rep-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.inner-page#cart .rep-text .head {
  font-size: 25px;
  line-height: 32px;
  font-weight: bold;
}
.inner-page#cart .rep-text a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 0;
}
.inner-page#cart .rep-text a .material-icons {
  font-size: 25px;
  line-height: 33px;
}
.inner-page#cart .rep-text a span {
  font-size: 25px;
  line-height: 33px;
  font-weight: bold;
}
.inner-page#cart .modal {
  display: none;
  position: fixed;
  z-index: 13000;
  padding-top: 100px;
  left: 0;
  overflow-y: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.inner-page#cart .modal .modal-content {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 15px 40px 30px;
  width: 650px;
  top: 32%;
  position: absolute;
  left: 25%;
  right: 25%;
}
@media screen and (max-width: 700px) {
  .inner-page#cart .modal .modal-content {
    width: 325px;
    left: 15%;
  }
}
.inner-page#cart .modal .modal-content .modal-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.inner-page#cart .modal .modal-content .modal-top .close {
  color: #61b9e7;
}
.inner-page#cart .modal .modal-content .modal-top .close:hover {
  cursor: pointer;
}
.inner-page#cart .modal .modal-content .modal-top .close .material-icons {
  font-size: 37px;
  line-height: 96px;
}
.inner-page#cart .modal .modal-content .modal-top h6 {
  font-size: 37px;
  line-height: 56px;
  margin: 0;
}
@media screen and (max-width: 1700px) {
  .inner-page#cart .inner-content {
    width: 84.5%;
  }
  .inner-page#cart .inner-content .checkout .checkout-form {
    width: 58%;
  }
  .inner-page#cart.checkout-page .inner-content {
    width: 90%;
  }
}
@media screen and (max-width: 1670px) {
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total {
    font-size: 40px;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total span {
    font-size: 40px;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address .address-item h3 {
    padding: 0 0 40px 0;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address .address-item.shipping .row-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address .address-item.shipping .row-item h3 {
    padding: 0;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address .address-item.shipping .row-item .text {
    padding: 0 0 20px 0;
  }
}
@media screen and (max-width: 1480px) {
  .inner-page#cart .inner-content {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  .inner-page#cart .inner-content {
    width: 100%;
  }
  .inner-page#cart.checkout-page .inner-content {
    width: 100%;
  }
}
@media screen and (max-width: 1125px) {
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total {
    width: 40%;
    font-size: 34px;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total span {
    font-size: 34px;
  }
  .inner-page#cart .inner-content .checkout {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .inner-page#cart .inner-content .checkout .checkout-form {
    width: 100%;
  }
  .inner-page#cart .inner-content .checkout .cart-sidebar {
    width: 100%;
    border-left: none;
    padding: 40px 0;
  }
}
@media screen and (max-width: 768px) {
  .inner-page#cart .inner-content .cart-table table {
    display: block;
    min-width: auto;
  }
  .inner-page#cart .inner-content .cart-table table thead {
    display: block;
  }
  .inner-page#cart .inner-content .cart-table table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .inner-page#cart .inner-content .cart-table table th {
    display: block;
  }
  .inner-page#cart .inner-content .cart-table table tbody {
    display: block;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr {
    border-bottom: 1px solid #e1e1e1;
    display: block;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td {
    display: block;
    border: none;
    border-bottom: none;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 20px;
    left: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:first-child {
    padding: 10px;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(1) {
    width: 100%;
    text-align: center;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(1) .product-outer {
    max-height: 75px;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(1) .product-outer img {
    max-width: 50%;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(2) {
    padding-right: 0;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(2):before {
    content: "Product";
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(3):before {
    content: "Price";
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(4) {
    padding-right: 0;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(4):before {
    content: "QTY";
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(5) {
    text-align: right;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(5):before {
    content: "Total";
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(6) {
    padding-right: 0;
    text-align: right;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td:nth-of-type(6):before {
    content: "";
  }
  .inner-page#cart .inner-content .cart-table table tbody tr td input[type=text] {
    width: 50px;
    height: 50px;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr.dark-border {
    border-bottom: 2px solid #292929;
  }
  .inner-page#cart .inner-content .cart-table table tbody tr.dark-border td {
    border: none;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr th {
    border: none;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr td {
    border: none;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(1) td {
    width: auto;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(1) td:before {
    content: "";
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(2) td {
    width: auto;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(2) td:before {
    content: "";
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(3) td {
    width: auto;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(3) td:before {
    content: "";
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(4) {
    border-bottom: none;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(4) td {
    width: auto !important;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr:nth-of-type(4) td:before {
    content: "";
  }
  .inner-page#cart .inner-content .checkout .cart-sidebar button.checkout {
    display: block;
    font-size: 25px;
  }
}
@media screen and (max-width: 700px) {
  .inner-page#cart .inner-content .checkout .checkout-form .row-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding: 0 0 20px 0;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .row-item a {
    font-size: 14px;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .form-row {
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field {
    padding: 0 0 20px 0;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .form-row .input-field.two-section {
    width: 100%;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .address .address-item {
    width: 100%;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .payment {
    width: 100%;
  }
  .inner-page#cart .inner-content .checkout .checkout-form .payment .form-row .input-field.three-section {
    width: 100%;
  }
}
@media screen and (max-width: 645px) {
  .inner-page#cart .inner-content .cart-menu ul li a {
    font-size: 26px;
  }
}
@media screen and (max-width: 575px) {
  .inner-page#cart .inner-content .cart-menu ul li:not(:last-child)::after {
    font-size: 20px;
  }
  .inner-page#cart .inner-content .cart-menu ul li a {
    font-size: 20px;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr th strong {
    font-size: 16px;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total {
    font-size: 30px;
  }
  .inner-page#cart .inner-content .cart-table table.cart_total tr.total td.total span {
    font-size: 30px;
  }
}
@media screen and (max-width: 500px) {
  .inner-page#cart .inner-content .cart-menu ul li {
    padding: 0 8px;
  }
  .inner-page#cart .inner-content .cart-menu ul li:not(:last-child)::after {
    margin: 0 0 0 6px;
  }
  .inner-page#cart .inner-content .cart-menu ul li:first-child {
    padding: 0 8px 0 0;
  }
  .inner-page#cart .inner-content .checkout .cart-sidebar.cart-table table.cart_total tr:nth-of-type(4) td {
    padding: 0;
  }
}
@media screen and (max-width: 400px) {
  .inner-page#cart .inner-content .cart-menu ul li a {
    font-size: 14px;
  }
  .inner-page#cart .inner-content button.checkout {
    font-size: 14px;
  }
}
@media screen and (max-width: 1700px) {
  section.cart .page-outer-wrapper .page-title-wrapper .page-title {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  section.cart .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  section.cart .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  section.checkout .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1480px) {
  section.checkout .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  section.checkout .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
.inner-page#dashboard {
  padding: 60px 0 130px 0;
}
.inner-page#dashboard .inner-content {
  width: 80.5%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.inner-page#dashboard .inner-content .sidebar {
  width: 22%;
}
.inner-page#dashboard .inner-content .sidebar .accordion {
  margin: 0;
  padding: 0;
}
.inner-page#dashboard .inner-content .sidebar .accordion ul,
.inner-page#dashboard .inner-content .sidebar .accordion li,
.inner-page#dashboard .inner-content .sidebar .accordion a,
.inner-page#dashboard .inner-content .sidebar .accordion span {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li {
  margin: 0;
  list-style: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu {
  padding: 0 0 0 40px;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu li.has-sub a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu li.no-sub a {
  padding: 0 0 0 15px;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu li.no-sub a:before {
  content: '';
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu li.open a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.has-sub.open .sub-menu li.open .sub-menu {
  padding: 0 0 0 40px;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.not-active a {
  color: #1f1f1f;
}
.inner-page#dashboard .inner-content .sidebar .accordion li.not-active a:before {
  color: #61b9e7;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  line-height: 63px;
  margin: -5px 0 0 0;
}
.inner-page#dashboard .inner-content .sidebar .accordion li a {
  display: block;
  position: relative;
  padding: 0 0 0 10px;
  color: #1f1f1f;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 66px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard .inner-content .sidebar .accordion li a:before {
  color: #61b9e7;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  line-height: 63px;
  margin: -5px 0 0 0;
}
.inner-page#dashboard .inner-content .sidebar .accordion li a:hover {
  color: #61b9e7;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu {
  display: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.plus-icon a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub a {
  font-size: 14px;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu {
  padding: 0 0 0 40px;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu li.has-sub a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu li.no-sub a:before {
  content: '';
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu li.plus-icon a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu li.open a:before {
  content: none;
}
.inner-page#dashboard .inner-content .sidebar .accordion .sub-menu li.has-sub.open .sub-menu li.open .sub-menu {
  padding: 0 0 0 40px;
}
.inner-page#dashboard .inner-content .main-content {
  width: 78%;
}
.inner-page#dashboard .inner-content .main-content h2 {
  font-family: Montserrat;
  font-size: 26px;
  line-height: 32px;
}
.inner-page#dashboard .inner-content .main-content h2 span,
.inner-page#dashboard .inner-content .main-content h2 a {
  font-size: 18px;
}
.inner-page#dashboard .inner-content .main-content h3 {
  font-family: Montserrat;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}
.inner-page#dashboard .inner-content .main-content a {
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
}
.inner-page#dashboard .inner-content .main-content p {
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
}
.inner-page#dashboard .inner-content .main-content table.order {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b7b7b7;
}
.inner-page#dashboard .inner-content .main-content table.order td {
  vertical-align: middle;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  text-align: left;
  color: #292929;
}
.inner-page#dashboard .inner-content .main-content table.order thead th {
  font-size: 12px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 35px 0;
  line-height: 18px;
  text-align: left;
  border-bottom: 1px solid #b7b7b7;
  width: 16%;
}
.inner-page#dashboard .inner-content .main-content table.order thead th:first-child {
  padding: 35px 0 35px 35px;
}
.inner-page#dashboard .inner-content .main-content table.order thead tr {
  background-color: #eeeeee;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr:nth-of-type(even) {
  background-color: #d5d5d5;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td {
  padding: 2px 0px;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:first-child {
  color: #61b9e7;
  padding: 0 0 0 35px;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 57px;
  text-underline-position: under;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:first-child a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 57px;
  text-decoration: underline;
  text-underline-position: under;
  color: #61b9e7;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:first-child a:hover {
  color: #197cb2;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:first-child:after {
  display: block;
  height: 2px;
  background: blue;
  margin-top: 20px;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 57px;
  color: #292929;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td a:hover {
  color: #585858;
}
.inner-page#dashboard .inner-content .main-content table.order-details {
  width: 100%;
  max-width: 100%;
  margin: 20px 0 30px 0;
}
.inner-page#dashboard .inner-content .main-content table.order-details tr th {
  border-bottom: 2px solid #292929;
  font-size: 18px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 15px 0px;
  line-height: 18px;
  text-align: left;
}
.inner-page#dashboard .inner-content .main-content table.order-details tr th:nth-child(2) {
  text-align: right;
}
.inner-page#dashboard .inner-content .main-content table.order-details tr td {
  padding: 8px 0px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
}
.inner-page#dashboard .inner-content .main-content table.order-details tr td:nth-child(2) {
  text-align: right;
}
.inner-page#dashboard .inner-content .main-content table.order_total {
  margin: 0 0 35px 0;
  width: 100%;
  max-width: 100%;
}
.inner-page#dashboard .inner-content .main-content table.order_total tr th {
  text-align: left;
}
.inner-page#dashboard .inner-content .main-content table.order_total tr th strong {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}
.inner-page#dashboard .inner-content .main-content table.order_total tr td {
  padding: 24px 0;
  text-align: right;
}
.inner-page#dashboard .inner-content .main-content a.order-again {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  padding: 0 0 35px 0;
}
.inner-page#dashboard .inner-content .main-content a.order-again i.fa-plus {
  font-size: 16px;
  padding: 5px;
}
.inner-page#dashboard .inner-content .main-content .address {
  width: 65%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.inner-page#dashboard .inner-content .main-content .address h3 {
  padding: 20px 0 10px 0;
}
.inner-page#dashboard .inner-content .main-content .address a {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 18px;
}
.inner-page#dashboard .inner-content .main-content .address p.btn-edit {
  padding: 0;
}
@media screen and (max-width: 768px) {
  .inner-page#dashboard .inner-content .main-content .address {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}
@media screen and (max-width: 1700px) {
  .inner-page#dashboard .inner-content {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  .inner-page#dashboard .inner-content {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  .inner-page#dashboard .inner-content {
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .inner-page#dashboard .inner-content .sidebar {
    width: 35%;
  }
  .inner-page#dashboard .inner-content .main-content {
    width: 65%;
  }
}
@media screen and (max-width: 768px) {
  .inner-page#dashboard .inner-content .main-content table.order {
    display: block;
  }
  .inner-page#dashboard .inner-content .main-content table.order td {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .inner-page#dashboard .inner-content .main-content table.order thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: block;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody {
    display: block;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr {
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 30px;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td {
    padding: 2px 0 2px 50%;
    border-bottom: none;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(1) {
    padding: 2px 0 2px 50%;
    text-align: right;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(1):before {
    content: "Order #";
    color: #292929;
    text-align: left;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(2) {
    text-align: right;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(2):before {
    content: "Status";
    text-align: left;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(3) {
    text-align: right;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(3):before {
    content: "Date";
    text-align: left;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(4) {
    text-align: right;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(4):before {
    content: "Total";
    text-align: left;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(5) {
    text-align: right;
  }
  .inner-page#dashboard .inner-content .main-content table.order.table-striped tbody tr td:nth-of-type(5):before {
    content: "Actions";
    text-align: left;
  }
}
.inner-page#dashboard.admin {
  width: 100%;
}
.inner-page#dashboard.admin .inner-content {
  width: 100%;
  margin: 0;
}
.inner-page#dashboard.admin .inner-content .sidebar ul {
  background: #eeeeee;
  padding: 0 30px;
  margin: 0 60px 0 0;
  min-height: 700px;
}
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 0;
}
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li .circle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: red;
  color: #ffffff;
  text-align: center;
  opacity: 0.8;
  margin: 0 0 0 10px;
}
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li .material-icons {
  margin: 0 10px 0 0;
}
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li a {
  padding: 0;
}
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li:hover .material-icons,
.inner-page#dashboard.admin .inner-content .sidebar ul.admin-sidebar li:hover a {
  color: #61b9e7;
}
.inner-page#dashboard.admin .inner-content .pages {
  width: 70%;
  padding: 30px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.inner-page#dashboard.admin .inner-content .pages .page-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background: #eeeeee;
  padding: 30px;
  margin: 0 0 20px 0;
}
.inner-page#dashboard.admin .inner-content .pages .page-item a {
  text-underline-position: under;
  text-decoration: underline;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin {
  width: 90%;
  position: relative;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form {
  margin: 50px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form input[type=file] {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form input[type=checkbox] {
  margin: 0 10px 0 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form .form-row {
  position: relative;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form .form-row .input-field .uploader {
  position: relative;
  top: 0;
  background: #eeeeee;
  width: 100%;
  height: 250px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin form .form-row .input-field .uploader p {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100px;
  font-size: 18px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  margin: 50px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .success {
  color: green;
  padding: 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .first-item {
  margin: 0 50px 0 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item a {
  font-size: 25px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .search input[type=text] {
  padding: 25px 25px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0 10px 0 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .status {
  width: 18%;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b7b7b7;
  margin: 50px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users td {
  vertical-align: middle;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 57px;
  text-align: left;
  color: #292929;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users thead th {
  font-size: 13px;
  font-weight: bold;
  border-top: 0;
  border-right: 0;
  padding: 30px 10px;
  line-height: 18px;
  text-align: left;
  border-bottom: 1px solid #b7b7b7;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users thead tr {
  background-color: #eeeeee;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr:nth-of-type(even) {
  background-color: #d5d5d5;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr td {
  padding: 2px 10px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr td a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 57px;
  color: #292929;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr td a:hover {
  color: #585858;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr td span.delete-btn {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 57px;
  color: #292929;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.users.table-striped tbody tr td span.delete-btn:hover {
  color: #585858;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details {
  padding: 50px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr th {
  border: none;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr th:nth-child(1) {
  padding: 0 20px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr td {
  background: #eeeeee;
  width: 10%;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr td:nth-child(1) {
  width: 10%;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr td:nth-child(1) .product-outer {
  max-height: 85px;
  width: auto;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr td:nth-child(1) .product-outer img {
  margin: 0 0 0 -20px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order-details.admin-order-details tr td:nth-child(2) {
  width: 60%;
  text-align: left;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order_total.order_total_admin {
  width: 15%;
  padding: 50px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin table.order_total.order_total_admin tr td {
  padding: 10px 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top {
  padding: 40px;
  background: #eeeeee;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top h3 {
  font-size: 18px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top .three-section {
  padding: 40px 0 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top .three-section .section-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top .three-section .section-item p.head {
  color: #585858;
  margin: 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top .three-section .section-item p.text {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal {
  display: none;
  position: absolute;
  z-index: 1;
  left: 25%;
  width: 60%;
  height: auto;
  overflow: auto;
  -webkit-box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);
  padding: 50px 30px;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal p.alert {
  color: red;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal .row-item {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal .row-item span.cancel {
  color: #292929;
  font-size: 18px;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin ul {
  padding: 50px 0;
  margin: 0;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin ul.pagination {
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin ul.pagination a {
  padding: 8px 16px;
  color: #292929;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin ul.pagination a:hover {
  color: #585858;
}
.inner-page#dashboard.admin .inner-content .inner-content-admin ul.pagination a.active {
  color: #61b9e7;
}
@media screen and (max-width: 1290px) {
  .inner-page#dashboard.admin .inner-content .sidebar {
    width: 30%;
  }
  .inner-page#dashboard.admin .inner-content .main-content {
    width: 70%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order {
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order td {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody {
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr {
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 30px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td {
    padding: 2px 0 2px 50%;
    border-bottom: none;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(1) {
    padding: 2px 0 2px 50%;
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(1):before {
    content: "Order #";
    color: #292929;
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(2) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(2):before {
    content: "Status";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(3) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(3):before {
    content: "Date";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(4) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(4):before {
    content: "Total";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(5) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(5):before {
    content: "Actions";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(6) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(6):before {
    content: "View";
    text-align: left;
  }
}
@media screen and (max-width: 950px) {
  .inner-page#dashboard.admin .inner-content .sidebar {
    width: 88%;
    margin: 0 auto;
  }
  .inner-page#dashboard.admin .inner-content .sidebar ul {
    margin: 0;
  }
  .inner-page#dashboard.admin .inner-content .main-content {
    width: 88%;
    margin: 0 auto;
    padding: 50px 0 0;
  }
  .inner-page#dashboard.admin .inner-content .pages {
    width: 100%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .first-item {
    margin: 0 0 40px 0;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .status {
    width: 80%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal {
    position: relative;
    left: 0;
    width: 100%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .delete-modal .row-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin form .row-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: center;
    align-items: center;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td {
    padding: 2px 0;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users {
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users td {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody {
    display: block;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr {
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 10px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td {
    border-bottom: none;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(1) {
    padding: 2px 0 2px 50%;
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(1):before {
    content: "Username";
    color: #292929;
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(2) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(2):before {
    content: "Email";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(3) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(3):before {
    content: "Status";
    text-align: left;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(4) {
    text-align: right;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.manage-users.table-striped tbody tr td:nth-of-type(4):before {
    content: "Action";
    text-align: left;
  }
}
@media screen and (max-width: 640px) {
  .inner-page#dashboard.admin .inner-content .main-content form .form-row .input-field.two-section:first-child {
    margin: 0 0 20px 0;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .search input[type=text] {
    padding: 25px 15px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .status {
    width: 70%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .row-item .status .custom-select-variation .cs-placeholder {
    padding: 25px 15px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin .order-section-top .three-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order_total.order_total_admin {
    width: 50%;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr {
    padding: 20px 10px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td {
    font-size: 14px;
    padding: 2px 0;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:nth-of-type(5) a {
    font-size: 10px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin table.order.table-striped tbody tr td:before {
    font-size: 14px;
  }
  .inner-page#dashboard.admin .inner-content .inner-content-admin ul.pagination li a {
    padding: 8px 5px;
  }
}
@media screen and (max-width: 1700px) {
  section.dashboard .page-outer-wrapper .page-title-wrapper .page-title {
    width: 84.5%;
  }
}
@media screen and (max-width: 1480px) {
  section.dashboard .page-outer-wrapper .page-title-wrapper .page-title {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  section.dashboard .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
form.form .form-response {
  display: none;
  margin-bottom: 40px;
  padding: 20px 10px;
  padding-left: 50px;
  border: 1px solid;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
form.form .form-response p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
form.form .form-response a {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: underline;
}
form.form .form-response a:hover {
  color: #ffffff;
}
form.form .form-response::before {
  color: white;
  position: absolute;
  left: 15px;
  top: 18px;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
form.form .form-response.error {
  background-color: #f73737;
  color: white;
  padding-left: 50px;
}
form.form .form-response.error::before {
  content: "\e000";
}
form.form .form-response.success {
  background-color: #197cb2;
  color: white;
}
form.form .form-response.success::before {
  content: "\e5ca";
}
form.form.show-response .form-response {
  display: block;
}
form.form .fields-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
form.form .fields-container.single .form-row {
  width: 100%;
}
form.form .fields-container.double .form-row {
  width: calc(50% - 9px);
}
@media screen and (max-width: 640px) {
  form.form .fields-container.double .form-row {
    width: 100%;
  }
}
form.form .fields-container.triple .form-row {
  width: calc(33% - 9px);
}
@media screen and (max-width: 640px) {
  form.form .fields-container.triple .form-row {
    width: 100%;
  }
}
form.form .fields-container.two-half {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
form.form .fields-container.two-half .form-row {
  width: calc(25% - 9px);
}
@media screen and (max-width: 960px) {
  form.form .fields-container.two-half .form-row {
    width: calc(50% - 9px);
  }
}
@media screen and (max-width: 640px) {
  form.form .fields-container.two-half .form-row {
    width: 100%;
  }
}
form.form .form-row {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 12px 0;
}
@media screen and (max-width: 768px) {
  form.form .form-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    padding: 0;
  }
}
form.form .form-row.final-row.jc_end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
form.form .form-row .row-item {
  padding: 5px 0;
}
form.form .form-row .row-item p.tax-exempt {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
form.form .form-row .custom {
  opacity: 0;
  position: absolute;
  display: inline-block;
  cursor: pointer;
}
form.form .form-row .custom + .text:before {
  content: '';
  background: none;
  border: 2px solid #61b9e7;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 5px;
  text-align: center;
  border-radius: 20%;
}
form.form .form-row .custom:checked + .text:before {
  background: #61b9e7;
  box-shadow: inset 0px 0px 0px 4px #fff;
}
form.form .form-row label {
  font-weight: 700;
  color: #585858;
  font-size: 16px;
}
form.form .form-row label a {
  font-size: 16px;
}
form.form .form-row .input-field {
  width: 100%;
}
@media screen and (max-width: 768px) {
  form.form .form-row .input-field {
    padding: 0 0 25px 0;
  }
}
form.form .form-row .input-field.two-section {
  width: 48%;
}
@media screen and (max-width: 768px) {
  form.form .form-row .input-field.two-section {
    width: 100%;
  }
}
form.form .form-row .input-field .input {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
}
form.form .form-row .input-field .input .input--filled .input__label {
  -webkit-animation: anim-chisato-1 0.25s forwards;
  animation: anim-chisato-1 0.25s forwards;
}
form.form .form-row .input-field .input .input--filled .input__label .input__label-content {
  -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
  animation: anim-chisato-2 0.25s forwards ease-in;
}
form.form .form-row .input-field .input .input__field {
  position: relative;
  display: block;
  -webkit-appearance: none;
  background: transparent;
  padding: 25px 25px;
  border: 1px solid #b7b7b7;
  color: #585858;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
form.form .form-row .input-field .input .input__field:focus + .input__label {
  -webkit-animation: anim-chisato-1 0.25s forwards;
  animation: anim-chisato-1 0.25s forwards;
}
form.form .form-row .input-field .input .input__field:focus + .input__label .input__label-content {
  -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
  animation: anim-chisato-2 0.25s forwards ease-in;
}
form.form .form-row .input-field .input .input__label {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  position: absolute;
  top: -6px;
  text-align: left;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  -webkit-transform: translate3d(0, 3em, 0);
  transform: translate3d(0, 3em, 0);
}
form.form .form-row .input-field .input .input__label .input__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 25px;
  font-weight: 700;
  color: #585858;
  font-size: 14px;
}
form.form .form-row .input-field .input .input__label .input__label-content::after {
  content: attr(data-content);
  position: absolute;
  top: -210%;
  left: 25px;
  color: #262626;
  font-weight: 700;
  font-size: 14px;
}
form.form .form-row .input-field .input .input__label .input__label-content .required-tip {
  color: #f84444;
  padding-left: 2px;
}
form.form .form-row .input-field .input .field-tip {
  position: absolute;
  bottom: -2px;
  font-size: 14px;
}
form.form .form-row .input-field .input .field-tip.error {
  color: #f73737;
}
form.form .form-row .input-field.valid .input .field-tip {
  display: none;
}
form.form .form-row .form-button {
  padding: 0;
  border: none;
  background: transparent;
  color: #61b9e7;
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
}
form.form .form-row .form-button span {
  font-family: Montserrat-Bold;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
}
form.form .form-row .form-button span.process {
  position: relative;
  padding-right: 5px;
}
form.form .form-row .form-button span i.fa-plus {
  font-size: 20px;
}
form.form .form-row .form-button.btn_login {
  font-size: 23px;
  line-height: 20px;
}
form.form .form-row .form-button .lds-ring {
  display: inline-block;
  display: none;
  position: relative;
  width: 16px;
  height: 20px;
}
form.form .form-row .form-button .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 20px;
  margin: 0px;
  border: 6px solid #61b9e7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #61b9e7 transparent transparent transparent;
}
form.form .form-row .form-button .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
form.form .form-row .form-button .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
form.form .form-row .form-button .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
form.form .form-row .form-button:hover {
  cursor: pointer;
  color: #197cb2;
}
form.form .form-row .form-button.active span.process .base {
  display: none;
}
form.form .form-row .form-button.active span.process .lds-ring {
  display: inline-block;
}
form.form .form-row .form-button:disabled {
  color: #197cb2;
}
form.form .form-row a.btn_forgot_password {
  font-size: 19px;
  line-height: 20px;
  color: #c7c7c7;
}
form.form .input__field:focus + .input__label,
form.form .input--filled .input__label {
  -webkit-animation: anim-chisato-1 0.25s forwards;
  animation: anim-chisato-1 0.25s forwards;
}
form.form .input__field:focus + .input__label .input__label-content,
form.form .input--filled .input__label-content {
  -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
  animation: anim-chisato-2 0.25s forwards ease-in;
}
form.form.error .form-row .form-button {
  color: #f73737;
}
form.form.error .form-row .form-button.active {
  color: #61b9e7;
}
select {
  border: 2px solid #d7d7d7;
  color: #000000;
  line-height: 1.5em;
  padding: 0.5em 2.5em 0.5em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  background-image: linear-gradient(45deg, transparent 50%, #61b9e7 0), linear-gradient(135deg, #61b9e7 50%, transparent 0);
  background-position: calc(100% - 21px) calc(1em + 0px), calc(100% - 16px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  margin: 0 40px 0 0;
  align-self: center;
}
select::-ms-expand {
  display: none;
}
select option {
  font-family: "Montserrat", sans-serif;
}
select .font-ms {
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 768px) {
  #about-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
#about-wrap .inner-page {
  padding-bottom: 120px;
}
#about-wrap .inner-page p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
}
#about-wrap .inner-page h2,
#about-wrap .inner-page h3,
#about-wrap .inner-page h4,
#about-wrap .inner-page h5,
#about-wrap .inner-page h6 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}
#about-wrap .inner-page .blue_border {
  border-bottom: 13px solid #61b9e7;
  width: 145px;
  margin-bottom: 30px;
}
#about-wrap .inner-page .inner-content {
  padding: 0 30px;
}
#about-wrap .inner-page .inner-content .content-section {
  width: 89%;
}
#about-wrap .inner-page .inner-content .content-section p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
#about-wrap .inner-page .inner-content .content-section p a {
  font-size: 14px;
  line-height: 30px;
}
#about-wrap .inner-page .inner-content .quote-section {
  width: 84%;
  margin: 0 auto;
  padding: 30px 0 50px;
}
#about-wrap .inner-page .inner-content .quote-section .text {
  font-size: 24px;
}
@media screen and (max-width: 639px) {
  #about-wrap .inner-page .inner-content .quote-section .text {
    font-size: 18px;
  }
}
#about-wrap .inner-page .inner-content .two-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#about-wrap .inner-page .inner-content .two-section.customer-support {
  -webkit-align-items: center;
  align-items: center;
}
#about-wrap .inner-page .inner-content .two-section .image-shadow {
  width: 40%;
}
#about-wrap .inner-page .inner-content .two-section .image-shadow img {
  -webkit-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
}
#about-wrap .inner-page .inner-content .two-section .image-shadow p.caption {
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
}
#about-wrap .inner-page .inner-content .two-section .content-section {
  width: 54%;
}
#about-wrap .inner-page .inner-content .two-section .content-section.support {
  width: 50%;
}
#about-wrap .inner-page .inner-content .staff-section {
  margin: 90px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url('../images/staff.png');
  background-position: center, center;
  background-repeat: no-repeat;
  -webkit-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  height: 420px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 99%;
}
#about-wrap .inner-page .inner-content .staff-section p {
  text-align: center;
  color: #ffffff;
  width: 62%;
  margin: 0 auto;
  font-weight: 500;
  padding-bottom: 30px;
}
#about-wrap .inner-page .inner-content .staff-section h3 {
  color: #ffffff;
}
@media screen and (max-width: 1700px) {
  #about-wrap .inner-page {
    width: 88%;
  }
  #about-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 40%;
  }
}
@media screen and (max-width: 1600px) {
  #about-wrap .inner-page {
    width: 88%;
  }
  #about-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 40%;
  }
}
@media screen and (max-width: 1120px) {
  #about-wrap .inner-page {
    width: 100%;
  }
  #about-wrap .inner-page .inner-content {
    padding: 0;
  }
  #about-wrap .inner-page .inner-content .quote-section {
    width: 100%;
    width: 82%;
    padding: 20px 0 60px;
  }
  #about-wrap .inner-page .inner-content .two-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  #about-wrap .inner-page .inner-content .two-section.customer-support {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-flex-flow: column-reverse nowrap;
    flex-flow: column-reverse nowrap;
  }
  #about-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 82%;
    margin: 0 auto;
  }
  #about-wrap .inner-page .inner-content .two-section .content-section {
    width: 82%;
    padding: 50px 0 0;
    margin: 0 auto;
  }
  #about-wrap .inner-page .inner-content .staff-section {
    margin: 60px 0;
  }
}
@media screen and (max-width: 586px) {
  #about-wrap .inner-page {
    width: 100%;
  }
  #about-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 100%;
    margin: 0;
  }
  #about-wrap .inner-page .inner-content .staff-section {
    min-height: 780px;
  }
  #about-wrap .inner-page .inner-content .staff-section p {
    width: 85%;
  }
}
#literature-wrap .categories {
  padding-bottom: 20px;
}
#literature-wrap .categories .category {
  padding-top: 80px;
  margin: 40px 0 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
@media screen and (max-width: 1290px) {
  #literature-wrap .categories .category {
    padding-top: 45px;
    margin: 40px 0 55px;
  }
}
#literature-wrap .categories .category .top {
  background: #eeeeee;
  padding-bottom: 30px;
}
#literature-wrap .categories .category .top .heading {
  padding: 0 0 0 40px;
}
@media screen and (max-width: 1290px) {
  #literature-wrap .categories .category .top .heading {
    padding: 0 35px;
  }
}
@media screen and (max-width: 420px) {
  #literature-wrap .categories .category .top .heading {
    padding: 0 10px;
  }
}
#literature-wrap .categories .category .top .heading h2 {
  margin: -40px 0 0;
  font-family: Titillium-BoldUpright, Titillium Web;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
}
@media screen and (max-width: 639px) {
  #literature-wrap .categories .category .top .heading h2 {
    font-size: 36px;
    line-height: 40px;
    margin: -25px 0 0;
  }
}
@media screen and (max-width: 420px) {
  #literature-wrap .categories .category .top .heading h2 {
    font-size: 30px;
    line-height: 36px;
  }
}
#literature-wrap .categories .category .items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-top: 10px;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
#literature-wrap .categories .category .items li {
  width: 22%;
  margin: 0 2%;
  padding-top: 150px;
  position: relative;
}
@media screen and (max-width: 980px) {
  #literature-wrap .categories .category .items li {
    width: 46%;
    padding-top: 120px;
  }
}
@media screen and (max-width: 639px) {
  #literature-wrap .categories .category .items li {
    width: 100%;
    margin: 0;
    padding-top: 80px;
  }
}
#literature-wrap .categories .category .items li.first {
  margin-left: 0;
}
@media screen and (max-width: 980px) {
  #literature-wrap .categories .category .items li.first {
    margin-left: 0;
  }
}
#literature-wrap .categories .category .items li.last {
  margin-right: 0;
}
@media screen and (max-width: 980px) {
  #literature-wrap .categories .category .items li.last {
    margin-left: 0;
  }
}
#literature-wrap .categories .category .items li .thumb-content {
  background: #eeeeee;
  padding: 50px 10px 20px 10px;
  text-align: center;
}
#literature-wrap .categories .category .items li .thumb-content img {
  max-width: 100%;
  height: auto;
  margin-top: -100px;
  padding: 0 30px;
  animation-duration: 0.3s;
  -webkit-animation-name: moveDownImg;
  animation-name: moveDownImg;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 1180px) {
  #literature-wrap .categories .category .items li .thumb-content img {
    padding: 0 15px;
  }
}
@media screen and (max-width: 639px) {
  #literature-wrap .categories .category .items li .thumb-content img {
    padding: 0 30px;
    margin-top: -65px;
  }
}
#literature-wrap .categories .category .items li .thumb-content h3 {
  font-size: 24px;
  padding: 0;
  margin: 5px 0;
}
@media screen and (max-width: 1180px) {
  #literature-wrap .categories .category .items li .thumb-content h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 980px) {
  #literature-wrap .categories .category .items li .thumb-content h3 {
    font-size: 30px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 639px) {
  #literature-wrap .categories .category .items li .thumb-content h3 {
    font-size: 26px;
  }
}
#literature-wrap .categories .category .items li .thumb-content a {
  font-size: 20px;
}
@media screen and (max-width: 1180px) {
  #literature-wrap .categories .category .items li .thumb-content a {
    font-size: 18px;
  }
}
@media screen and (max-width: 980px) {
  #literature-wrap .categories .category .items li .thumb-content a {
    font-size: 22px;
  }
}
#literature-wrap .categories .category .items li .thumb-content .thumb-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#literature-wrap .categories .category .items li:hover {
  cursor: pointer;
}
#literature-wrap .categories .category .items li:hover .thumb-content img {
  animation-duration: 0.3s;
  -webkit-animation-name: moveUpImg;
  animation-name: moveUpImg;
  animation-fill-mode: forwards;
}
#literature-wrap .categories .category .items li:hover .thumb-content a {
  color: #197cb2;
}
.administrator footer .container {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .administrator footer .container {
    max-width: 100%;
    padding: 40px 20px;
    display: block;
  }
}
.administrator footer .container p {
  color: #ffffff;
  margin: 0;
  padding: 5px 0;
}
@media screen and (max-width: 768px) {
  .administrator footer .container p {
    text-align: center;
  }
}
#main-filters legend {
  font-size: 16px;
}
#main-filters legend span {
  font-size: 12px;
}
#main-filters ul {
  padding-left: 15px;
}
#main-filters ul label {
  font-size: 14px;
  color: #000000;
}
#main-filters ul.collapsed div:nth-child(n+6) {
  display: none;
}
#main-filters ul.collapsed div:last-child {
  display: block;
}
#main-filters ul.collapsed .list-toggle span i {
  font-size: 22px;
  color: #61b9e7;
}
#main-filters ul.collapsed .list-toggle span.collapse {
  display: none;
}
#main-filters ul.expanded .list-toggle span i {
  font-size: 22px;
  color: #61b9e7;
}
#main-filters ul.expanded .list-toggle span.expand {
  display: none;
}
#main-filters ul li {
  list-style-type: none;
}
#main-filters .list-toggle {
  cursor: pointer;
}
#main-filters .list-toggle span {
  font-size: 12px;
}
#main-filters .form-row.btn {
  justify-content: right;
  padding-right: 25px;
}
#main-filters .form-row .form-button span {
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
#main-filters .form-row .form-button.btn_reset {
  margin-right: 15px;
  color: #9f9f9f;
}
#main-filters .form-row .input-field .input .field-tip {
  bottom: -22px;
}
#main-filters .form-row .row-item label {
  color: #000000;
}
#main-filters .form-row .row-item .tooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  color: #61b9e7;
  margin: 20px 0 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
#main-filters .form-row .row-item .tooltip.pressure {
  font-size: 22px;
  margin-top: 10px;
  padding-left: 10px;
}
#main-filters .form-row .row-item .tooltip.pressure .tooltiptext {
  background-color: #61b9e7;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  z-index: 999;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
#main-filters .form-row .row-item .tooltip .tooltiptext {
  display: none;
  width: 225px;
  color: #585858;
  top: 35px;
  left: 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  color: #61b9e7;
  font-size: 14px;
}
#main-filters .form-row .row-item .tooltip:hover .tooltiptext {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
}
#main-filters .form-row .row-item .ui-slider {
  margin-left: 10px;
  max-width: 190px;
}
#main-filters .form-row .row-item .ui-slider .ui-slider-handle {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 17px;
  border-radius: 20%;
  background: #61b9e7;
  cursor: pointer;
  opacity: 1;
  border: none;
}
#main-filters .form-row .row-item .angle-slider-container {
  width: 100%;
}
#main-filters .form-row .row-item .angle-slider-container input {
  border: none;
  color: #585858;
}
#main-filters .form-row .row-item .textbox-container {
  display: flex;
  flex-flow: row wrap;
}
#main-filters .form-row .row-item .textbox-container .textfield {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid #b7b7b7;
  border-radius: 2px;
  background: #ffffff none repeat scroll 0% 0%;
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  min-width: 180px;
  justify-content: space-between;
}
#main-filters .form-row .row-item .textbox-container .textfield input[type=text] {
  padding: 10px;
  color: #585858;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  opacity: 1;
  border: none;
  width: 95px;
  text-align: right;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-prefix {
  background: #d5d5d5 none repeat scroll 0% 0%;
  color: #727372;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  line-height: 1px;
  white-space: nowrap;
  flex-shrink: 0;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-prefix span {
  padding-bottom: 1px;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-suffix {
  background: #d5d5d5 none repeat scroll 0% 0%;
  color: #727372;
  display: flex;
  align-items: center;
  line-height: 1px;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 60px;
  justify-content: center;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-suffix span {
  padding-bottom: 1px;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-suffix.flow {
  padding: 0px 10px;
}
#main-filters .form-row .row-item .textbox-container .textfield .textfield-suffix.pressure {
  padding: 0px 17px;
}
#main-filters form#top-filters-form {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid #262626;
  max-width: 300px;
}
@media screen and (max-width: 768px) {
  #main-filters form#top-filters-form {
    width: 80%;
    margin: 15px;
  }
}
#main-filters form#main-filters-form {
  padding-top: 15px;
}
@media screen and (max-width: 768px) {
  #main-filters form#main-filters-form {
    width: 80%;
    margin: 15px auto;
  }
}
#main-filters form .trigger {
  display: none;
}
.product_section #main-filters {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  #shipping-wrap .page-outer-wrapper .page-title-wrapper .page-title {
    width: 100%;
  }
}
#shipping-wrap .inner-page {
  padding-bottom: 120px;
}
#shipping-wrap .inner-page p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
}
#shipping-wrap .inner-page h2 {
  font-size: 38px;
  line-height: 45px;
  margin: 0;
}
#shipping-wrap .inner-page h3 {
  font-size: 32px;
  line-height: 40px;
  color: #61b9e7;
  margin: 0;
}
#shipping-wrap .inner-page h4,
#shipping-wrap .inner-page h5,
#shipping-wrap .inner-page h6 {
  font-size: 40px;
  line-height: 48px;
  margin: 0;
}
#shipping-wrap .inner-page .blue_border {
  border-bottom: 13px solid #61b9e7;
  width: 145px;
  margin-bottom: 30px;
}
#shipping-wrap .inner-page .inner-content {
  padding: 0 30px;
}
#shipping-wrap .inner-page .inner-content .content-section {
  width: 89%;
}
#shipping-wrap .inner-page .inner-content .content-section p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
#shipping-wrap .inner-page .inner-content .content-section p a {
  font-size: 14px;
  line-height: 30px;
}
#shipping-wrap .inner-page .inner-content .quote-section {
  width: 84%;
  margin: 0 auto;
  padding: 30px 0 50px;
}
#shipping-wrap .inner-page .inner-content .quote-section .text {
  font-size: 24px;
}
@media screen and (max-width: 639px) {
  #shipping-wrap .inner-page .inner-content .quote-section .text {
    font-size: 18px;
  }
}
#shipping-wrap .inner-page .inner-content .two-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#shipping-wrap .inner-page .inner-content .two-section.customer-support {
  -webkit-align-items: center;
  align-items: center;
}
#shipping-wrap .inner-page .inner-content .two-section .image-shadow {
  width: 40%;
}
#shipping-wrap .inner-page .inner-content .two-section .image-shadow img {
  -webkit-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
}
#shipping-wrap .inner-page .inner-content .two-section .content-section {
  width: 54%;
}
#shipping-wrap .inner-page .inner-content .two-section .content-section.support {
  width: 50%;
}
#shipping-wrap .inner-page .inner-content .staff-section {
  margin: 90px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url('../images/staff.png');
  background-position: center, center;
  background-repeat: no-repeat;
  -webkit-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  box-shadow: -10px 37px 59px rgba(0, 0, 0, 0.13);
  height: 420px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 99%;
}
#shipping-wrap .inner-page .inner-content .staff-section p {
  text-align: center;
  color: #ffffff;
  width: 62%;
  margin: 0 auto;
  font-weight: 500;
  padding-bottom: 30px;
}
#shipping-wrap .inner-page .inner-content .staff-section h3 {
  color: #ffffff;
}
@media screen and (max-width: 1700px) {
  #shipping-wrap .inner-page {
    width: 88%;
  }
  #shipping-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 40%;
  }
}
@media screen and (max-width: 1600px) {
  #shipping-wrap .inner-page {
    width: 88%;
  }
  #shipping-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 40%;
  }
}
@media screen and (max-width: 1120px) {
  #shipping-wrap .inner-page {
    width: 100%;
  }
  #shipping-wrap .inner-page .inner-content {
    padding: 0;
  }
  #shipping-wrap .inner-page .inner-content .quote-section {
    width: 100%;
    width: 82%;
    padding: 20px 0 60px;
  }
  #shipping-wrap .inner-page .inner-content .two-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  #shipping-wrap .inner-page .inner-content .two-section.customer-support {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-flex-flow: column-reverse nowrap;
    flex-flow: column-reverse nowrap;
  }
  #shipping-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 82%;
    margin: 0 auto;
  }
  #shipping-wrap .inner-page .inner-content .two-section .content-section {
    width: 82%;
    padding: 50px 0 0;
    margin: 0 auto;
  }
  #shipping-wrap .inner-page .inner-content .staff-section {
    margin: 60px 0;
  }
}
@media screen and (max-width: 586px) {
  #shipping-wrap .inner-page {
    width: 100%;
  }
  #shipping-wrap .inner-page .inner-content .two-section .image-shadow {
    width: 100%;
    margin: 0;
  }
  #shipping-wrap .inner-page .inner-content .staff-section {
    min-height: 780px;
  }
  #shipping-wrap .inner-page .inner-content .staff-section p {
    width: 85%;
  }
}
#survey {
  margin: auto;
  width: 1000px;
}
#survey .fixed-header {
  min-height: 20px;
}
#survey header .header_top {
  margin: auto;
  width: 1000px;
  padding-top: 20px;
  position: relative;
}
@media screen and (max-width: 500px) {
  #survey header .header_top {
    width: 100%;
  }
}
#survey header .header_top .container .header_right .country_icon {
  display: block;
}
#survey header .header_top .container .header_right .language {
  display: block;
}
#survey header .header_top .container .header_right .language select {
  display: block;
}
@media screen and (max-width: 1024px) {
  #survey header .header-menu {
    display: block;
  }
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper {
  width: 80%;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .notification {
  width: 100%;
  padding: 20px;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .notification::after {
  display: none;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .notification.success {
  color: #000000;
  background-color: transparent;
  box-shadow: none;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .page-title {
  width: 100%;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .page-title h2 {
  margin-bottom: 0;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .survey-form form {
  margin-top: 15px;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .survey-form form .radio-container .field-tip {
  color: #f84444;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .survey-form form .radio-container .input__label .input__label-content .required-tip {
  color: #f84444;
  padding-left: 2px;
}
#survey #form-wrap .page-outer-wrapper .page-title-wrapper .catalog-form {
  width: 100%;
}
#survey form .radio-container label {
  line-height: 22px;
}
#survey form .radio-fields-wrapper {
  display: flex;
  justify-content: space-evenly;
}
#survey form .radio-fields-wrapper .radio-wrap {
  text-align: center;
}
#survey form .radio-fields-wrapper .radio-wrap .radio-label label {
  font-size: 12px;
}
#survey footer p.copyright {
  color: #ffffff;
}
@media screen and (max-width: 500px) {
  #survey {
    width: 100%;
  }
}
.not-found .inner-page {
  padding-bottom: 120px;
}
.not-found .inner-page .inner-content {
  width: 80.5%;
  margin: 0 auto;
}
.not-found .inner-page .inner-content .three-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 0 65px;
}
.not-found .inner-page .inner-content .three-section h6 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 29px;
  margin: 0;
}
.not-found .inner-page .inner-content .three-section p {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 0;
  font-weight: 500;
}
.not-found .inner-page .inner-content .three-section p strong {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
}
.not-found .inner-page .inner-content .three-section a {
  font-size: 14px;
  line-height: 19px;
}
#close_cookie {
  display: none;
}
#close_cookie:checked + #cookie_consent_popup {
  display: none;
}
#cookie_consent_popup {
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 400px;
  height: 250px;
  background-color: #61b9e7;
  padding: 20px;
  z-index: 2;
}
#cookie_consent_popup h1 {
  font-size: 1.2em;
}
#cookie_consent_popup h1:before {
  content: "";
  padding: 0;
}
#cookie_consent_popup p {
  font-size: 0.8em;
  line-height: 25px !important;
}
#cookie_consent_popup a {
  color: #ffffff !important;
  font-size: 1em !important;
  font-weight: 500 !important;
}
#cookie_consent_popup a:hover {
  color: #197cb2 !important;
}
#cookie_consent_popup #close_cookie_box {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 1.3em;
}
#cookie_consent_popup #ok_cookie_box {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 1.6em;
  padding: 10px 20px;
  font-weight: 700;
  color: #ffffff;
}
@media screen and (max-width: 460px) {
  #cookie_consent_popup {
    bottom: 10px;
    left: 10px;
    width: 350px;
    height: 300px;
  }
}
.inner-content.cookies form label {
  color: #000000;
}
.required-cookies:before {
  content: '';
  border: 2px solid #b7b7b7;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 5px;
  text-align: center;
  border-radius: 20%;
  background: #b7b7b7;
  box-shadow: inset 0px 0px 0px 4px #ffffff;
}
