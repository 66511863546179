.products{
    @media screen and (max-width: 1700px) {
        .product {
            .product_section {
                .products-header-bottom {
                    select {
                        padding: 25px 25px 25px 15px;
                    }
                    .psi {
                        .select-btn {
                            padding: 25px 25px 25px 15px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1690px) {
        .product {
            .product_section {
                .products-header-bottom {
                    select {
                      font-size: 14px;
                    }
                    .psi {
                        .select-btn {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1500px) {
        .product {
            .product_section {
                margin: 60px auto 40px;
                .products-header-bottom {
                    select {
                        font-size: 12px;
                        padding: 25px 25px 25px 10px;
                    }
                    .psi {
                        .select-btn {
                            font-size: 14px;
                            padding: 25px 25px 25px 10px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1290px) {
        .breadcrumb {
            .hierarchy {
                width: 95.8%;
                }
            }
        .product {
            &.container {
                width: 100%;
                margin: 0;
            }
            .products_menu {
                width: 100px;
                margin: 0;
                h2 {
                    display: none;
                }
            }
            .product_section {
                width: 75.5%;
                margin: 30px 0 40px auto;
                .products-header-top {
                    .flex-container(row,wrap);
                    .left {
                        width: 100%;
                        text-align: center;
                    }
                    .right {
                        width:100%;
                        .flex-container(column,nowrap);
                        .align-items(center);
                        p {
                            margin-top: 15px;
                        }                      
                        .top-description {
                            .left {
                                text-align: left;
                            }
                        }
                    }
                }
                .products-header-bottom {
                    .flex-container(row,wrap);
                    width: 85%;
                    margin: auto;

                    .variation {
                        width: 100%;
                        margin: 10px 0;
                    }
                    select {
                        //padding: 25px;
                        font-size: 14px;
                    }
                    .psi {
                        .select-btn {
                            padding: 25px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:1080px) {
        .product {
            .product_section {
                width:70.5%;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .product {
            &.container {
                .flex-container(column,nowrap);
            }
            .products_menu {
                width: 100%;
                margin: 0;
            }
            .product_section {
                width: 100%;
                .products-header-top {                    
                    .right {
                        width: 100%;
                        .heading {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        #main-sidebar {
            display: none;
        }

        .moby-button {
            display: inline-flex;
            width: 100%;
            height: auto;
            color: @white;
            .justify-content(flex-start);
            .align-items(center);
            padding: 0 5.6%;
            h2 {
                display: inline-flex !important;
                font-size: 26px !important;;
                line-height: 30px !important;
                margin: 0 !important;
                padding-left: 20px;
            }

        }
        .breadcrumb {
            .hierarchy {
                width: 88.8%;
                span{
                    &.arrow {
                        padding: 0 1%;
                    }
                }
            }
        }
        .product {

            .product_section {
                margin: 30px auto 40px;
                .products-header-top {
                    .right {
                        width: 100%;
                        margin-top:65px;
                        .heading {
                            align-self: center;
                            h3 {font-size:45px;}
                            .blue_border {
                                margin: 5px 0 10px 0;
                            }
                        }
                        p {
                            font-size: 18px;
                            line-height: 23px;
                            margin: 40px 15% 10px 0;

                        }
                        .top-description {
                            flex-wrap: wrap;
                        }
                    }
                }
                .products-header-bottom {
                    width: 100%;
                    .variation {
                       &.angle{width:100%;}
                        &.psi{width:100%;}
                    }
                    select {
                        font-size:14px;
                    }
                    .psi {
                        .select-btn {
                            font-size:14px;
                        }

                    }
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        .breadcrumb {
            .hierarchy {
                width: 88.8%;
            }
        }
    }
    @media screen and (max-width: 375px) {
        .product {
            .product_section {
                .products-header-bottom{
                    .variation{
                        width: 100%;
                    }
                }
            }
        }
    }
}