#survey {
    margin: auto;
    width: 1000px;

    .fixed-header {
        min-height: 20px;
    }

    header {
        .header_top {
            margin: auto;
            width: 1000px;
            padding-top: 20px;
            position: relative;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            .container {
                .header_right {
                    .country_icon {
                        display: block;
                    }
                    .language {
                        display: block;
                        select {
                            display: block;
                        }
                    }
                }
            }
        }
        .header-menu {
            @media screen and (max-width: 1024px) {
                display: block;
            }
        }
    }
    #form-wrap {
        .page-outer-wrapper {
            .page-title-wrapper {
                width: 80%;
                .notification {
                    width: 100%;
                    padding: 20px;
                    &::after {
                        display: none;
                    }
                    &.success {
                        color: @dark;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
                .page-title {
                    width: 100%;
                    h2 {
                        margin-bottom: 0;
                    }
                }
                .survey-form {
                    form {
                        margin-top: 15px;
                        .radio-container {
                            .field-tip {
                                color: @red;
                            }
                            .input__label {
                                .input__label-content {
                                    .required-tip {
                                        color: @red;
                                        padding-left: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
                .catalog-form {
                    width: 100%;
                }
            }
        }
    }
    form {
        .radio-container {
            label {
                line-height: 22px;
            }
        }
        .radio-fields-wrapper {
            display: flex;
            justify-content: space-evenly;
            .radio-wrap {
                text-align: center;
                .radio-label {
                    label {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    footer {
        p {
            &.copyright {
                color: @white;
            }
        }
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}