/*
 * MOBY.LESS VERSION 2.0.7
 */

@moby-background:@white;
@moby-color: @dark;
@moby-width: 333px;
@moby-blue: blue;
@border:#efefef;

.moby-overlay {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: fixed;
    .moby-transform(~"translate3d(0,0,0)");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6000;
    visibility: hidden;
    opacity: 0;
    .moby-transition(all, 0.2s);
    &.light {
        background: #289DCC;
    }
    &.moby-overlay-active {
        visibility: visible !important;
        opacity: 0.2 !important;
        &#moby-overlay2{background:none;}
    }
}

.moby-body-fixed {
    //overflow: visible !important;
    //height: 100%;
    overflow: hidden !important;
    //overflow-y:visible !important;
}
.moby {
&.left-side{


    position: fixed;
    z-index: 13000;
    top: 25%;
    //height: auto;
    left: 0px;
    width: 40%;
    max-height: 100%;
    height: auto;
   // overflow-y:auto;
    //-webkit-overflow-scrolling: touch;
    min-width: @moby-width;
    background: transparent;
    .moby-transform(~"translateX(-100%)");
    &.moby-active {
        .moby-transform(~"translateX(0)");
         box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);

    }

    &.moby-demo {
        color: @moby-background;
        background: @moby-blue;
        position: fixed;
        z-index: 3000;
        top: 0;
        height: 100%;
        width: 30%;
        right: 0;
        min-width: @moby-width;
        .moby-transform(~"translateX(100%)");
        padding: 60px 0 20px 0;
        overflow: auto;
        &.moby-active {
            .moby-transform(~"translateX(0)");
        }
        & .moby-inner {
            height: 100%;
            min-height: 520px;
            & > p {
                height: 40px;
                .moby-flex-container();
                .moby-align-items(center);
                .moby-justify-content(center);
                padding: 0 30px;
                font-size: 13px;
                text-align: center;
            }
        }

        & .moby-close {
            color: @moby-background;
            cursor: pointer;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            padding: 12px 20px;
            .moby-flex-container();
            .moby-align-items(center);
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            & .moby-close-icon {
                margin-right: 0;
                height: 20px;
                width: 20px;
                display: inline-block;
                position: relative;
                &::before,
                &::after {
                    background: @moby-background;
                }
            }
        }
        & .moby-wrap {
            height: ~"calc(100% - 40px)";
            .moby-flex-container(row, wrap);
            .moby-align-items(center);
            .moby-justify-content(center);
            background:@white;

            & > div {
                width: 100%;
            }
            & img {
                margin: 0 auto 40px auto;
                display: block;
                width: 100px;
                height: auto;
            }
            & .moby-menu {
                width: 100%;
                & > * {
                    width: 100%;
                }
            }
            .moby-menu {
                width: 80%;
                background: @moby-background;
                height: auto;
            }

        }
        & ul {
            & li {
                & ul {
                    & a {
                        padding: 20px 70px 20px 70px;
                        font-size: 16px;
                        background: darken(@moby-blue, 10%);
                    }
                }
                & a {
                    position: relative;
                    color: @moby-background;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 400;
                    display: inline-block;
                    padding: 20px 70px 20px 70px;
                    text-align: center;
                    .moby-transition(color, 0.3s);
                    &:hover {
                        color: #e0e0e0;
                    }
                    & .moby-expand {
                        display: none;
                        &:hover {
                            color: #e0e0e0;
                        }
                        & i,
                        & span {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    &.fade {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;

        }
        & ul {
            width: 100%;
        }

    }

    &.top-full {
        position: fixed;
        z-index: 13000;
        top: 0;
        .moby-transform(~"translateY(-100%)");
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        &.moby-active {
            .moby-transform(~"translateY(0)");
        }
    }
    &.bottom-full {
        position: fixed;
        z-index: 13000;
        .moby-transform(~"translateY(100%)");
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        &.moby-active {
            .moby-transform(~"translateY(0)");
        }
    }
    &.grow-out{
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        .moby-transform(~"scale(.7)");
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;
            .moby-transform(~"scale(1)");
        }
    }
    &.shrink-in {
        position: fixed;
        z-index: 13000;
        top: 0;
        height: 100%;
        left: 0px;
        width: 100%;
        & ul {
            width: 100%;
        }
        .moby-transform(~"scale(1.2)");
        visibility: hidden;
        opacity: 0;
        &.moby-active {
            opacity: 1;
            visibility: visible;
            .moby-transform(~"scale(1)");

        }
    }
    & ul {
        list-style: none;
        .moby-transform(~"translate3d(0,0,0)");
        padding: 40px 0 30px 40px;
        & ul {
            display: none;
            padding: 0 0 0 25px;
            & ul {
                display: none;
                padding: 0 0 0 25px;
            }
        }
    }
    & ul {
        & li {
            & ul {
                & li {
                    & ul{
                        & li{
                            a{
                                padding-left: 42px;
                            }
                        }
                    }

                }
            }
            & a {
                position: relative;
                color: @moby-color;
                text-decoration: none;
                font-weight: normal;
                display: inline-block;
                padding: 20px 10% 20px 5%;
                text-align: left;
                position: relative;
                .font(@font1, 16px, bold, normal);
                .trans(all 0.2s);
                &:before{
                    content: none;
                }
                &:hover{
                    color: @mainblue;
                    &::after{
                        width: 100%;
                    }
                }
                & .moby-expand {
                    display: none;
                    margin: 0px 0 0 0;
                    & i,
                    & span {
                        font-size: 30px;
                        color: @mainblue;
                    }
                }

            }

            .dropdown-toggle{
                display: none;
            }
        }
    }
    & .moby-wrap {
        .flex-container(row-reverse,nowrap);
        //box-shadow: 0px 0px 2px #000;
        .align-items(flex-start);
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
         height: auto;
        -webkit-overflow-scrolling: touch;
        min-height: 25%;
        background: @white;
        @media screen and (max-width: 640px) {
            height: auto;
            .flex-container(column,nowrap);
        }
    }
    & .moby-menu {
        width: 80%;
        background:@white;
        //overflow-y:visible;
        //overflow-x: hidden;
        @media screen and (max-width: 640px) {
         width: 100%;
        }
    }
    & .moby-close {
        width: 22%;
        color: @moby-background;
        cursor: pointer;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        padding: 30px 0;
        background: @mainblue;
        .moby-flex-container();

        @media screen and (max-width: 640px) {
            width: 100%;
        }
        &:hover{
            color: lighten(@moby-background, 8%);
        }
        & .moby-close-icon {
            font-weight: bold;
            margin:0 auto;
            position: relative;
            height: auto;
            width: auto;
            .material-icons{font-size:32px;}
            //.moby-transform(~"rotate(45deg)");
            &:hover{
                &::before{
                    background: lighten(@moby-background, 8%);
                }
                &::after{
                    background: lighten(@moby-background, 8%);
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        top: 16%;
        width: 45%;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
 }
}

//*************************************************************

.second-level-menu(){

    > ul{
        position: absolute;

        top: 51px;
        left: 0px;
        width: 200px;
        opacity: 0;
        visibility: hidden;
        //.transform(translate(0px, 100px));
        .trans(all 0.0s);
        //padding-top: 35px;
        //padding-bottom: 15px;
        padding: 0;
        display: block;
        z-index: 5;
        //border-top: 3px solid @shadow;

        li{
            position: relative;
            display: block;
            list-style: none;
            text-align: left;
            padding-right: 0;
            background: @menu-bg;
            padding: 3px 0;
            border-bottom: 1px solid @bg-gray;
            &:last-child{border-bottom:none;}
            a{
                color: @white1;
                padding: 8px 20px;
                display: inline-block;
                font-size: 14px;
                text-transform: capitalize;
                width: 100%;

            }
            &:hover{

                a {
                    color: @sub-hover;
                }
            }
            //&:hover{
            //  background: @white1;
            //  a {
            //    color: @green;
            //  }
            //}
            .third-level-menu();
        }
    }

    &:hover{
        >ul{
            opacity: 1;
            visibility: visible;
        }
    }

}


.third-level-menu(){

    > ul{
        position: absolute;

        top: 0px;
        left: 200px;
        opacity: 0;
        visibility: hidden;
        //.transform(translate(0px, 100px));
        .trans(all 0.0s);
        //padding-top: 35px;
        //padding-bottom: 15px;
        padding: 0;
        display: block;
        z-index: 5;
        //border-top: 3px solid @shadow;

        li{
            display: block;
            list-style: none;
            text-align: left;
            padding-right: 0;
            background: @menu-bg;
            padding: 3px 0;
            border-bottom: 1px solid @bg-gray;
            a{
                color: @white1 !important;
                padding: 8px 20px;
                display: inline-block;
                font-size: 14px;
                text-transform: capitalize;
                width: 100%;

            }
            &:hover{

                a {
                    color: @sub-hover !important;
                }
            }
            //&:hover{
            //  background: @white1;
            //  a {
            //    color: @green;
            //  }
            //}

        }
    }

    &:hover{
        ul{
            opacity: 1;
            visibility: visible;
        }
    }

}







