// ************************************************
//              Footer
// ************************************************

footer {
  width: 100%;
  background: @bgheaderdark;

  .container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding: 40px 0 10px;

    .flex-container(row, wrap);
    .align-items(center);
    .justify-content(space-between);
    color: @white;
    list-style: none;
    @media screen and (max-width: @responsive-411) {
      .flex-container(column);
    }

    .footer_left {
      width: 20%;
      .flex-container(column, wrap);
      @media screen and (max-width: @responsive-411) {
        .justify-content(center);
        .align-items(center);
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        &.social_links {
          img {
            max-width: 60%;
            margin-bottom: 20px;
          }
        }
      }
    }

    .footer_center {
      width: 65%;
      .flex-container(column, wrap);
      .align-items(flex-start);

      .footer_center_top {
        width: 100%;
        @media screen and (max-width: 1200px) {
          width: 50%;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
        }

        ul {
          .flex-container(row, wrap);
          .justify-content(space-between);
          .align-items(center);
          padding: 0;
          list-style: none;
          @media screen and (max-width: 1200px) {
            .flex-container(column, wrap);
            height: 160px;
            .align-items(flex-start);
          }
          @media screen and (max-width: 640px) {
            height: auto;
            .align-items(center);
          }
          li {
            @media screen and (max-width: 1200px) {
              flex: 1 1 40px;;
              width: 45%;
            }
            @media screen and (max-width: 640px) {
              width: auto;
            }
            a {
              color: @white !important;
              .font(@font1, 15px, 500, 30px);
            }
          }

        }
      }

      .footer_center_bottom {
        width: 100%;
        .flex-container(row, wrap);
        .justify-content(space-between);
        .align-items(flex-start);
        @media screen and (max-width: 1200px) {
          width: 45%;
          .flex-container(column, wrap);
        }
        @media screen and (max-width: 768px) {
          width: 50%;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
        }
        p {
          color: @white;
          .font(@font1, 15px, 500, 30px);
        }

        .left {
          .flex-container(column, wrap);
          width: 40%;

          .support {
            .font(@head-font, 45px, bold, 40px);

            .support_mail {
              .font(@head-font, 45px, bold, normal);
              color: @mainblue;

              &:hover {
                cursor: pointer;
                color: @darkblue;
                .trans(all 0.2s);
              }

            }
          }

        }

        .center {
          .flex-container(column, wrap);
          width: 30%;
          padding: 30px 0;

          p {
            .font(@font1, 18px, 700, 23px);
          }

        }

        .right {
          .flex-container(column, wrap);
          width: 30%;
          padding: 30px 0;

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
          }
        }


      }

    }

    .footer_right {
      width: 15%;
      .flex-container(column, wrap);
      .align-items(flex-end);
      img {
        &.iso-logo {
          width: 100px;
        }
      }
      
    }
    .footer_bottom {
      width: 100%;
      text-align: center;
      p {
        color: @white;

        &.copyright {
          .font(Lato, 12px, 500, normal);
          padding-bottom: 0;
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 1520px) {
      max-width: 80%;

    }
    @media screen and (max-width: 1440px) {
      max-width: 80%;
      .footer_center {
        .footer_center_bottom {
          .left {
            .support {
              font-size: 35px;

              .support_mail {
                font-size: 35px;
              }
            }

          }

        }

      }
    }
    @media screen and (max-width: 1366px) {
      .footer_center {
        .footer_center_bottom {
          .left {
            .support {
              font-size: 35px;

              .support_mail {
                font-size: 35px;
              }
            }

          }

          .center {
            p {
              font-size: 14px;
            }
          }

        }

      }

      .footer_right {
        p {
          &.website {
            font-size: 8px;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      max-width: 88%;
      .align-items(baseline);

      .footer_center {
        width: 90%;
        .flex-container(row, nowrap);

        .footer_center_bottom {
          .left {
            margin-top: -50px;
            width: 100%;

            .support {
              font-size: 39px;
              line-height: 42px;
              text-align: right;

              .support_mail {
                font-size: 39px;
                line-height: 42px;
              }
            }
          }

          .center {
            width: 100%;
            margin-top: -70px;
            text-align: right;
          }

          .right {
            display: none;
          }

        }
      }

      .footer_right {
        width: 100%;
        .align-items(center);

        p {
          &.copyright {
            margin-bottom: 0px;
          }

        }
      }
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 20px 40px;
      .flex-container(column, wrap);
      .justify-content(center);
      .footer_left{
        width: 100%;
        ul{
          .flex-container(row, wrap);
          .justify-content(center);
          li{
            padding: 0 10px;
          }
        }
      }

      .footer_center {
        width: 100%;
        .flex-container(column, nowrap);
        .align-items(center);

        .footer_center_bottom {
          .left {
            margin-top: 10px;

            .support {
              text-align: center;

              .support_mail {
                margin-top: 10px;
              }
            }
          }

          .center {
            text-align: center;
            padding: 0;
            margin-top: -10px;
          }

          .right {
            display: block;
            width: 100%;
            text-align: center;
            padding: 0;
          }

        }
      }
    }
    @media screen and (max-width: @responsive-411) {
      padding: 20px;
      .footer_right {
        p {
          &.website {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 13000;
    padding-top: 100px;
    left: 0;
    overflow-y: auto;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    .modal-content {
        background-color: @white;
        margin: 0 auto;
        padding: 15px 40px 30px;
        width: 650px;
        top: 32%;
        position: absolute;
        left: 25%;
        right: 25%;
        @media screen and (max-width: 700px) {
            width: 325px;
            left: 15%;
        }

        .modal-top {
            .flex-container(row, nowrap);
            .justify-content(space-between);
            .align-items(center);

            .close {
                color: @mainblue;

                &:hover {
                    cursor: pointer;
                }

                .material-icons {
                    font-size: 37px;
                    line-height: 96px
                }
            }

            h6 {
                font-size: 37px;
                line-height: 56px;
                margin: 0;
                color: @dark;
            }
        }
    }
  }
}

