#shipping-wrap {
    & .page-outer-wrapper{
        & .page-title-wrapper{
            & .page-title{
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }
    & .inner-page {
        padding-bottom: 120px;
        p {
            font-size: 14px;
            line-height: 30px;
            font-weight: 600;
        }
        h2 {
            font-size: 38px;
            line-height: 45px;
            margin: 0;
        }
        h3 {
            font-size: 32px;
            line-height: 40px;
            color:@mainblue;
            margin: 0;
        }
        h4, h5, h6 {
            font-size: 40px;
            line-height: 48px;
            margin: 0;
        }
        .blue_border {
            border-bottom: 13px solid @mainblue;
            width: 145px;
            margin-bottom: 30px;
        }
        .inner-content {
            padding: 0 30px;
            .content-section {
                width: 89%;
                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 30px;
                    a {
                        font-size: 14px;
                        line-height: 30px;
                    }

                }
            }
            .quote-section {
                width: 84%;
                margin: 0 auto;
                padding: 30px 0 50px;
                .text {
                    //width: 65%;
                    font-size: 24px;
                    @media screen and (max-width: 639px) {
                        font-size: 18px;
                    }
                }
            }
            .two-section {
                .flex-container(row, nowrap);
                .justify-content(space-between);
                &.customer-support{
                    .align-items(center);
                }
                .image-shadow {
                    width: 40%;
                    img {
                        .box-shadow(-10px 37px 59px rgba(0, 0, 0, 0.13));
                    }
                }
                .content-section {
                    width: 54%;
                    &.support{
                        width: 50%;
                    }
                }
            }
            .staff-section {
                margin: 90px 0;
                .background-size(cover);
                background-image: url('../images/staff.png');
                background-position: center, center;
                background-repeat: no-repeat;
                .box-shadow(-10px 37px 59px rgba(0, 0, 0, 0.13));
                height: 420px;
                .flex-container(column, nowrap);
                .justify-content(center);
                .align-items(center);
                width: 99%;
                p {
                    text-align: center;
                    color: @white;
                    width: 62%;
                    margin: 0 auto;
                    font-weight: 500;
                    padding-bottom: 30px;
                }
                h3 {
                    color: @white;
                }
            }
        }
        @media screen and (max-width: 1700px) {
            width: 88%;
            .inner-content {
                .two-section {
                    .image-shadow {
                        width: 40%;

                    }
                }

            }
        }
        @media screen and (max-width: 1600px) {
            width: 88%;
            .inner-content {
                .two-section {
                    .image-shadow {
                        width: 40%;

                    }
                }

            }
        }
        @media screen and (max-width: 1120px) {
            width: 100%;
            .inner-content {
                padding: 0;
                .quote-section {
                    width: 100%;
                    width: 82%;
                    padding: 20px 0 60px;
                    //.text {
                    //    width: 100%;
                    //}
                }
                .two-section {

                    .flex-container(column, nowrap);
                    &.customer-support {
                        .flex-container(column-reverse, nowrap);
                    }
                    .image-shadow {
                        width: 82%;
                        margin: 0 auto;
                    }
                    .content-section {
                        width: 82%;
                        padding: 50px 0 0;
                        margin: 0 auto;
                    }
                }
                .staff-section {
                    margin: 60px 0;
                }
            }
        }
        @media screen and (max-width: 586px) {
            width: 100%;
            .inner-content {
                .two-section {
                    .image-shadow {
                        width: 100%;
                        margin: 0;
                    }
                }
                .staff-section {
                    min-height: 780px;
                    p {
                        width: 85%;
                    }
                }
            }
        }
    }
}