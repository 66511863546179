// ************************************************
//              Product Slider
// ************************************************
.products_divider {
    height: 90px;
    clear: both;
    @media screen and (max-width: 1100px) {
        height: 100px;
    }
    @media screen and (max-width: 640px) {
        height: 40px;
    }
}

.our_product_conatainer {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;

    .products {
        .flex-container(row, wrap);
        .align-items(flex-end);
        .p_sub_container {
            width: 100%;
            max-width: 1520px;
            margin: 0 auto 0 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            h2 {
                color: @darkcolor1;
                font-size: 40px;
                .font(@head-font, 55px, bold, 65px);
                margin: 0;
                margin-left: 12%;
            }
            .blue_border {
                border-bottom: 10px solid @mainblue;
                width: 30%;
                width: 145px;
                position: relative;
                top: 10px;
                margin-top: -10px;
                z-index: 555;
            }
        }

        .product_right_button {
            position: absolute;
            right: 4%;
            margin: -90px 0;
            a {
                text-decoration: none;
                color: @mainblue;
                writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                text-orientation: mixed;
                padding-bottom: 115px;
                .font(@font1bold, 25px, bold, 33px);
                .trans(all 0.2s);
                &:hover {
                    cursor: pointer;
                    color: @darkblue;
                }
                i {
                    &.fa-plus {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .phero-slider {
            background-image: url('../images/bgfinal.png') !important;
            background-position: center center !important;
            background-size: cover;
            background-repeat: no-repeat;
            height: 595px;
            .phero-slide {
                display: flex !important;
                align-items: flex-end;
                position: relative;
                align-items: center;

                &.slick-active {
                    .slide-content {
                        .slide-content-top {
                            animation-name: fadeInUpSD;
                            animation-duration: 0.5s;
                            animation-delay: 0.2s;
                            animation-fill-mode: backwards;
                        }
                        .slide-content-bottom {
                            h3 {
                                animation-name: fadeInUpSD;
                                animation-duration: 0.5s;

                            }
                            .slide-content-bottom_wht {
                                animation-name: fadeInLeft;
                                animation-duration: 0.5s;
                                animation-delay: 0.1s;
                            }
                        }
                    }
                }
                & .container {
                    width: 100%;
                }

                .slide-content {
                    width: auto;
                    .flex-container(column, wrap);
                    margin: 0 auto;
                    .justify-content(center);
                    .align-items(flex-start);

                    .slide-content-top {
                        align-self: center;
                        img {
                            padding-top: 70px;
                            height: 506px;
                            //max-width: 100%;
                            //height: auto;
                            //max-height: 100%;
                        }
                    }

                    .slide-content-bottom {
                        position: relative;
                        bottom: 140px;
                        h3 {
                            .font(@head-font, 55px, bold, 63px);
                            .align-self(flex-start);
                            padding-left: 12%;
                            span {
                                .font(@head-font, 55px, bold, 63px);
                            }
                            &.split {
                                margin-top: -10px;
                            }
                        }
                        .slide-content-bottom_wht {
                            padding-left: 12%;
                            //background: #ffffff;
                            width: 55%;

                            .text {
                                text-align: left;
                                padding: 0 30% 0 0;

                            }
                            .btn {
                                a {
                                    color: @mainblue;
                                    .font(@font1bold, 25px, normal, 33px);
                                    .trans(all 0.2s);
                                    &:hover {
                                        cursor: pointer;
                                        color: @darkblue;
                                    }
                                }
                            }
                        }

                    }

                }

                .slide-btn {
                    position: relative;
                    padding-left: 0;
                    :after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 100%;
                        border-bottom: 3px solid #d2282e;
                        transition: width .3s ease-in-out;
                    }
                    &:hover:after {
                        width: 90%;
                    }
                }

            }

            .slick-dots {
                position: absolute;
                right: 14%;
                bottom: 0px;
                transform: translate(-50%, 0%);
                .flex-container(row, wrap);
                margin-bottom: 35px;
                .justify-content(center);
                .align-items(center);
                list-style: none;

                li {
                    position: relative;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                    padding: 0 10px;
                    cursor: pointer;

                    &.slick-active button:before {
                        background: @mainblue;
                        box-shadow: inset 0px 0px 0px 3px #ffffff;
                        border: 2px solid @mainblue;
                    }
                    button {
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        width: 15px;
                        height: 15px;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: none;
                        background: transparent;

                        &::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 15px;
                            height: 15px;
                            content: "";
                            text-align: center;
                            border: 2px solid @darkcolor1;
                            border-radius: 50%;
                            -webkit-font-smoothing: antialiased;
                        }
                    }
                }
            }

            .slick-prev {
                cursor: pointer;
                position: absolute;
                bottom: -50px;
                width: auto;
                padding: 34px 40px;
                color: @white;
                font-weight: normal;
                font-size: 0px;
                user-select: none;
                -webkit-user-select: none;
                background-color: @bgheaderdark;
                z-index: 1;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                border: none;
                left: 55%;
                .trans(all 0.2s);
                .material-icons {
                    font-size: 28px;
                }
                &:hover {
                    svg {
                        .trans(all 0.2s);
                        transform: scale(1.5);
                    }
                }
            }
            .slick-next {
                font-size: 0;
                line-height: 0;
                position: absolute;
                bottom: -50px;
                width: auto;
                padding: 34px 40px;
                font-weight: normal;
                font-size: 0px;
                user-select: none;
                -webkit-user-select: none;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                cursor: pointer;
                color: @white;
                border: none;
                outline: none;
                background-color: @bgheaderdark;
                z-index: 1;
                right: 31%;
                .trans(all 0.2s);
                .material-icons {
                    font-size: 28px;
                }
                &:hover {
                    svg {
                        .trans(all 0.2s);
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1770px) {
        max-width: 80%;
        margin: 0 auto;
        .products {
            .p_sub_container {
                max-width: 100%;
            }
        }
    }
    @media screen and (max-width: 1680px) {
        .products {
            .phero-slider {
                .phero-slide {
                    .slide-content {
                        .slide-content-top {
                            img {
                                height: 500px;
                            }

                        }
                    }
                }
                .slick-dots {
                    right: 11%;
                }
                .slick-next {
                    right: 29%;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .products {
            .p_sub_container {
                h2 {
                    margin-left: 75px;
                }
            }
            .phero-slider {
                .slick-prev {
                    left: 56%;
                }
                .slick-next {
                    right: 27%;
                }
                .slick-dots {
                    right: 5%;
                }
                .phero-slide {
                    .slide-content {
                        .slide-content-bottom {
                            h3 {
                                padding-left: 75px;
                            }
                            .slide-content-bottom_wht {
                                padding-left: 75px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1380px) {
        max-width: 80%;
        margin: 0 auto;
        .products {
            .phero-slider {
                .phero-slide {
                    .slide-content {
                        .slide-content-top {
                            img {
                                height: 450px;
                            }
                        }
                        .slide-content-bottom {
                            bottom: 90px;
                        }
                    }
                }
                .slick-dots {
                    right: 3%;
                    right: 5%;
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .products {
            .product_right_button {
                right: 17%;
                margin: 50px 0;
                position: relative;
                width: 100%;
                text-align: right;
                a {
                    writing-mode: horizontal-tb;
                }
            }
            .phero-slider {
                .slick-next {
                    right: 23%;
                }
                .slick-dots {
                    right: -1%;
                }
            }
        }
    }
    @media screen and (max-width: 1100px) {
        max-width: 88%;
        margin: 0 auto;
        .products {
            .flex-container(column, wrap);
            .p_sub_container {
                h2 {
                    margin-left: 90px;
                }
            }
            .product_right_button {
                right: 125px;
                margin: 50px 0;
                position: relative;
            }
            .phero-slider {

                .slick-next {
                    right: 22%;
                }
                .phero-slide {
                    .slide-content {
                        .slide-content-bottom {
                            h3 {
                                padding-left: 35px;
                            }
                            .slide-content-bottom_wht {
                                padding-left: 35px;
                            }
                        }

                    }
                }
                .slick-dots {
                    right: -4%;
                }

            }
        }
    }
    @media screen and (max-width: 900px) {
        .products {
            .align-items(center);
            .p_sub_container {
                h2 {
                    margin-left: 42px;
                    font-size: 50px;
                    line-height: 55px;
                }
                .blue_border {
                    //margin-left: 42px;
                    width: 140px;
                }
            }
            .product_right_button {
                right: 0;
                position: relative;
                width: 100%;
                text-align: left;
                margin: 0 auto 160px;
                padding-left: 25px;
                bottom: -360px;
                a {
                    writing-mode: horizontal-tb;
                }
            }
            .phero-slider {
                height: 410px;
                background: @lightgrey !important;
                .slick-prev {
                    left: 0%;
                }
                .slick-next {
                    left: 14%;
                }
                .phero-slide {
                    .slide-content {
                        .slide-content-top {
                            img {
                                height: 340px;
                            }
                        }
                        .slide-content-bottom {
                            bottom: 115px;
                            h3 {
                                padding-left: 25px;
                                margin: 200px 0 0PX 0;
                                width: 100%;
                                min-height: inherit;
                                font-size: 45px;
                                line-height: 50px;
                                &.split {
                                    margin: 200px 0 0 0;
                                }
                                span {
                                    font-size: 45px;
                                    line-height: 50px;
                                }
                            }
                            .slide-content-bottom_wht {
                                padding-left: 25px;
                                width: 95%;
                                .text {
                                    padding: 20px 6% 0 0;
                                }
                            }
                        }

                    }
                }
                .slick-dots {
                    right: 0px;
                }

            }
        }
    }
    @media screen and (max-width: 640px) {
        .products {
            .product_right_button {
                bottom: -435px;
                margin: 0 auto 340px;
            }
            .phero-slider {
                height: 415px;
                .slick-next {
                    left: 19%;
                }
                .phero-slide {
                    .slide-content {
                        .slide-content-top {
                            img {
                                height: 250px;
                                margin-bottom: 70px;
                            }
                        }
                        .slide-content-bottom {
                            bottom: 85px;
                        }
                    }
                }
                .slick-dots {
                    right: -50px;
                }
            }

        }
    }
    @media screen and (max-width: 420px) {
        .products {
            .product_right_button {
                bottom: -468px;
                margin: 0 auto 355px;
            }
            .phero-slider {
                .slick-dots {
                    right: 65px;                
                }
                .slick-prev {
                    display: none !important;
                }
                .slick-next {
                    display: none !important;
                }
            }
        }
    }
}
