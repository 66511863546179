
#main-filters {
    legend {
        font-size: 16px;
        span {
            font-size: 12px;
        }
    }       
    ul {
        padding-left: 15px;
        label {
            font-size: 14px;
            color: @dark;
        }
        &.collapsed {
            div {
                &:nth-child(n+6) {
                display: none;
                }
            }
            div {
                &:last-child {
                    display: block;
                }
            }
            .list-toggle {
                span {
                    i {
                        font-size: 22px;
                        color: @mainblue;
                    }
                    &.collapse {
                        display: none;
                    }
                }
            }
        }
        &.expanded {
            .list-toggle {
                span {
                    i {
                        font-size: 22px;
                        color: @mainblue;
                    }
                    &.expand {
                        display: none;
                    }
                }
            }
        }
        li {
            list-style-type: none;
        }
    }
    
    .list-toggle {
        cursor: pointer;
        span {
            font-size: 12px;
        }
    }

    .form-row {
        &.btn {
            justify-content: right;
            padding-right: 25px;
        }
        .form-button {
            span {
                font-family: Montserrat-Bold;
                font-size: 20px;
                font-weight: 700;
                line-height: 40px;
            }
            &.btn_reset {
                margin-right: 15px;
                color:@light-a;
            }
        }
        .input-field {
            .input {
                .field-tip {
                    bottom: -22px;
                }
            }
        }
        .row-item {
            label {
                color: @dark;
            }

            .tooltip {
                position: relative;
                .flex-container(column, nowrap);
                color: @mainblue;
                margin: 20px 0 0;
                .trans(all 0.5s);

                &.pressure{
                    font-size: 22px;
                    margin-top: 10px;
                    padding-left: 10px;
                    .tooltiptext {                                
                        background-color: @mainblue;
                        color: @white;
                        padding: 15px;
                        border-radius: 6px;
                        z-index: 999;
                        font-size: 12px;
                        font-weight:500;
                        text-align: left;
                    }
                }

                .tooltiptext {
                    display: none;
                    width: 225px;
                    color: @select-text;
                    top: 35px;
                    left: 0;
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    color: @mainblue;
                    font-size: 14px;
                }

                &:hover {
                    .tooltiptext {
                        .flex-container(row, nowrap);
                        animation-name: fadeInLeft;
                        animation-duration: 0.5s;
                    }
                }
            }

            .ui-slider {
                margin-left: 10px;
                max-width: 190px;
                .ui-slider-handle {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 10px;
                    height: 17px;
                    border-radius: 20%; 
                    background: @mainblue;
                    cursor: pointer;
                    opacity: 1;
                    border: none;
                }
            }
            
            .angle-slider-container {
                width: 100%;
                input {
                    border:none;
                    color: @select-text;
                }
            }

            .textbox-container {
                display:flex;
                flex-flow:row wrap;
                .textfield {
                    box-shadow: none;
                    margin: 0px;
                    padding: 0px;
                    box-sizing: border-box;
                    border: 1px solid @table-border;
                    border-radius: 2px;
                    background: @white none repeat scroll 0% 0%;
                    cursor: text;
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    position: relative;
                    min-width: 180px;
                    justify-content: space-between;
                    input[type=text] {
                        padding: 10px;
                        color: @select-text;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: normal;
                        opacity: 1;
                        border:none;
                        width:95px;
                        text-align: right;
                    }
                    .textfield-prefix {
                        background: @bg-table none repeat scroll 0% 0%;
                        color: @textGrey;
                        display: flex;
                        align-items: center;
                        padding: 0px 10px;
                        line-height: 1px;
                        white-space: nowrap;
                        flex-shrink: 0;
                        span {
                            padding-bottom: 1px;
                        }
                    }
                    .textfield-suffix {
                        background: @bg-table none repeat scroll 0% 0%;
                        color: @textGrey;
                        display: flex;
                        align-items: center;
                        line-height: 1px;
                        white-space: nowrap;
                        flex-shrink: 0;
                        min-width: 60px;
                        justify-content: center;
                        span {
                            padding-bottom: 1px;
                        }
                        &.flow {
                            padding: 0px 10px;
                        }
                        &.pressure {
                            padding: 0px 17px;
                        }
                    }
                }
            }
        }
    }

    form {
        &#top-filters-form {
            border-radius:5px;
            -webkit-border-radius:5px;
            -moz-border-radius:5px;
            border: 1px solid @grey2;
            max-width: 300px;
            @media screen and (max-width: 768px) {
                width: 80%;
                margin: 15px;
            }
        }
        &#main-filters-form {
            padding-top: 15px;
            @media screen and (max-width: 768px) {
                width: 80%;
                margin: 15px auto;
            }
        }
        .trigger {
            display: none;
        }        
    }
}

.product_section {
    #main-filters {
        display: flex;
        justify-content: space-between;
    }
}