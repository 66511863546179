#literature-wrap{
    & .categories{
        padding-bottom: 20px;
        & .category{
            padding-top: 80px;
            margin: 40px 0 80px;
            .flex-container(column, nowrap);
            @media screen and (max-width: 1290px) {
                padding-top: 45px;
                margin: 40px 0 55px;
            }
            & .top{
                background: @lightgrey;
                padding-bottom: 30px;
                & .heading{
                    padding: 0 0 0 40px;
                    @media screen and (max-width: 1290px) {
                        padding: 0 35px;
                    }
                    @media screen and (max-width: 420px) {
                        padding: 0 10px;
                    }
                    & h2{
                        margin: -40px 0 0;
                        .font(@head-font, 40px, bold, 48px);
                        @media screen and (max-width: 639px) {
                            font-size: 36px;
                            line-height: 40px;
                            margin: -25px 0 0;
                        }
                        @media screen and (max-width: 420px) {
                            font-size: 30px;
                            line-height: 36px;
                        }
                    }
                }
            }
            & .items{
                list-style: none;
                padding: 0;
                margin: 0;
                .flex-container(row, wrap);
                margin-top: 10px;
                .align-items(flex-end);
                & li{
                    width: 22%;
                    margin: 0 2%;
                    padding-top: 150px;
                    position: relative;
                    @media screen and (max-width: 980px) {
                        width: 46%;
                        padding-top: 120px;
                    }
                    @media screen and (max-width: 639px) {
                        width: 100%;
                        margin: 0;
                        padding-top: 80px;
                    }
                    &.first{
                        margin-left: 0;
                        @media screen and (max-width: 980px) {
                            margin-left: 0;
                        }
                    }
                    &.last{
                        margin-right: 0;
                        @media screen and (max-width: 980px) {
                            margin-left: 0;
                        }
                    }
                    & .thumb-content{
                        background: @lightgrey;
                        padding: 50px 10px 20px 10px;
                        text-align: center;
                        & img{
                            max-width: 100%;
                            height: auto;
                            margin-top: -100px;
                            padding: 0 30px;
                            animation-duration: 0.3s;
                            -webkit-animation-name: moveDownImg;
                            animation-name: moveDownImg;
                            animation-fill-mode: forwards;
                            @media screen and (max-width: 1180px) {
                                padding: 0 15px;
                            }
                            @media screen and (max-width: 639px) {
                                padding: 0 30px;
                                margin-top: -65px;
                            }
                        }
                        & h3{
                            font-size: 24px;
                            padding: 0;
                            margin: 5px 0;
                            @media screen and (max-width: 1180px) {
                                font-size: 20px;
                            }
                            @media screen and (max-width: 980px) {
                                font-size: 30px;
                                margin-top: 15px;
                            }
                            @media screen and (max-width: 639px) {
                                font-size: 26px;
                            }
                        }
                        & a{
                            font-size: 20px;
                            @media screen and (max-width: 1180px) {
                                font-size: 18px;
                            }
                            @media screen and (max-width: 980px) {
                                font-size: 22px;
                            }
                        }
                        & .thumb-action{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                        & .thumb-content{
                            & img{
                                animation-duration: 0.3s;
                                -webkit-animation-name: moveUpImg;
                                animation-name: moveUpImg;
                                animation-fill-mode: forwards;
                            }
                            & a{
                                color: @darkblue;
                            }
                        }
                    }
                }
            }
        }
    }
}