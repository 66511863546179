// ************************************************
//              Technical Assistance
// ************************************************
.assistance_divider{height:90px;clear: both;
  @media screen and (max-width: 640px){
    height: 80px;
  }
}

.assistance_container{
  width: 100%;
  max-width: 1520px;
  //margin: 0 100px 0 auto;
  margin: 0 auto;
  .assistance{
    .flex-container(row, wrap);
    .align-items(center);
    .assistance_subcontainer
    { width: 100%;
      max-width: 1520px;
      margin:0 auto 0 0;
      .flex-container(row, wrap);
      .justify-content(space-between);
      .assistance_left{
        position: relative;
        width:27%;
        img{    position: absolute;
          left: -58px;
          z-index: -1;    width: auto;
          max-width: unset;}
      }
      .assistance_right
      {
        width:73%;
        .assistance_right_top
        {
          .flex-container(column, wrap);
          margin-left:25%;    margin-bottom: -117px;
          h6{.font(@head-font, 55px, bold, 65px);width: 19%; margin: 0;
          }
          .blue_border{border-bottom: 10px solid @mainblue;width:145px;margin:20px 0 10px;
          }
        }
        .assistance_right_bottom
        {
          .aowl-carousel {
            .flex-container(column-reverse, wrap);
            .owl-stage-outer{
              background: @white;
              width:100%;
              padding:50px 45px 100px;
              .owl-stage {
                .flex-container(row, wrap);
                .align-items(baseline);
                width: 100%;
                .owl-item {

                  &.active
                  {
                    &:nth-child(3)
                    {
                      margin-right: 0px !important;
                    }
                  }
                  .item {
                    width: 75%;

                    .head {
                      .font(@head-font, 25px, 600, 33px);
                      color: @darkcolor1;    min-height: 65px;
                      width:86%;
                    }
                    .btn {
                      a {
                        color: @mainblue;
                        .font(@font1bold, 25px, normal, 33px);
                        .trans(all 0.2s);
                        &:hover{
                          cursor: pointer;
                          color: @darkblue;
                        }
                      }
                    }

                    div {
                      display: block;
                      width: 100%;
                      height: auto;
                    }

                  }
                }
              }
            }
            .owl-nav{

              margin: 0;
              .align-self(flex-end);
              .owl-prev{background: @bgheaderdark;    width: auto;
                padding: 40px !important;font-size:15px;border-radius: 0;color: @white;margin:0;outline:none;  .trans(all 0.2s);
                .material-icons{font-size:28px;}
                &:hover{
                  svg{.trans(all 0.2s);transform: scale(1.5);}
                }
              }
              .owl-next{background: @bgheaderdark;    width: auto;
                padding: 40px !important;font-size:15px;border-radius: 0;color: @white;margin:0;outline:none; .trans(all 0.2s);
                .material-icons{font-size:28px;}
                &:hover{
                  cursor: pointer;
                  svg{ .trans(all 0.2s); transform: scale(1.5);}
                }}
            }
          }

        }
      }

    }

    .technical_right{
      position: absolute;
      right: 4%;
      a {
        text-decoration: none;
        color:@mainblue;
        writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
        text-orientation: mixed;
        padding-bottom: 115px;
        .font(@font1bold,25px, bold, 33px);
        .trans(all 0.2s);
        &:hover{
          cursor: pointer;
          color: @darkblue;
        }
        i {
          &.fa-plus {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1770px) {
    max-width: 80%;
    margin: 0 auto;
    .assistance {
      .assistance_subcontainer {
        max-width: 100%;
        .assistance_left {
          img{
            left:-55px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1670px){
    .assistance {
      .assistance_subcontainer {
        .assistance_left {
          width: 10%;
          img{left:-35px;
            width: 455px;
            height: 456px;}

        }
        .assistance_right {
          width: 90%;
          margin-top: 70px;
          .assistance_right_top {
            margin-left: 34%;
            h6 {
              width: 31%;
            }
          }
          .assistance_right_bottom{
            .aowl-carousel{
              .owl-stage-outer {
                .owl-stage {
                  .justify-content(flex-start);
                  .owl-item {
                    .item {
                      width: 100%;
                      div{width: 90%;}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1350px){
    max-width: 80%;
    .assistance {
      .assistance_subcontainer {
        .assistance_right{
          .assistance_right_top {
            margin-left: 35%;

          }
          .assistance_right_bottom{
            .aowl-carousel{
              .owl-stage-outer {
                padding: 50px 50px 50px;
              }
            }
          }
        }
      }
      .technical_right{
        right: 0;
        position: relative;
        width: 100%;
        text-align: center;
        padding-bottom: 90px;
        a{
          writing-mode: horizontal-tb;
        }
      }
    }
  }

  @media screen and (max-width:1280px){
    //max-width:88%;
    .assistance {
      .flex-container(column,wrap);
      .assistance_subcontainer {
        .assistance_left {
          display: none;
        }
        .assistance_right {
          width: 100%;
          margin-top: 0px;
          .assistance_right_top {
            margin: 0 0 -117px 0;
            h6 {
              margin-left:0;
            }
            .blue_border {
              margin-left: 0;
            }
          }
          .assistance_right_bottom{
            .aowl-carousel{
              .owl-stage-outer {
                width: 100%;
                padding: 50px 0 0 0px;
                .owl-stage {
                  .justify-content(flex-start);
                  .owl-item {
                    &:nth-child(3){margin-right: 0;
                    }
                    .item {
                      width: 100%;

                      div{width: 100%;
                        &.btn{
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .technical_right{
        margin: 80px 0 0;
        a{
          writing-mode: horizontal-tb;
        }
      }
    }
  }
  @media screen and (max-width:1100px){
    max-width:88%;
    .assistance {
      .flex-container(column,wrap);
      .assistance_subcontainer {
        .assistance_left {
          display: none;
        }
        .assistance_right {
          width: 100%;
          margin-top: 0px;
          .assistance_right_top {
            margin: 0 0 -117px 0;
            h6 {
              margin-left:0;
            }
            .blue_border {
              margin-left: 0;
            }
          }
          .assistance_right_bottom{
            .aowl-carousel{
              //padding-left:35px;
              .owl-stage-outer {
                width: 100%;
                padding: 50px 0 0 0px;
                .owl-stage {
                  .justify-content(flex-start);
                  .owl-item {
                    &:nth-child(3){margin-right: 0;
                    }
                    .item {
                      width: 100%;

                      div{width: 100%;
                        &.btn{
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .technical_right{
        margin: 80px 0 0;
        a{
          writing-mode: horizontal-tb;
        }
      }
    }
  }
  @media screen and (max-width:768px){
    .assistance {
      .assistance_subcontainer {
        .flex-container(column,nowrap);
        margin: 0 auto;
        .assistance_left {
          display: none;
        }
        .assistance_right {
          .assistance_right_top {
            margin: 0 0 0 0;
            h6 {
              margin-left: 0px;
              width:60%;
              font-size:50px;line-height: 55px;
            }
            .blue_border {
              margin-left: 0px;
            }
          }
          .assistance_right_bottom{
            .aowl-carousel{
              .owl-stage-outer {
                padding:50px 0px 0 ;
                .owl-stage {
                  .flex-container(column,nowrap);
                  transform: none !important;
                  transition: none !important;
                  .owl-item {
                    display: none;
                    width: 100% !important;
                    &.active{display: block;}
                    &.cloned{display: none;}
                    .item {
                      width: 100%;
                      margin-bottom: 60px;
                      div{width:52%;}
                      .head{min-height: auto;}
                    }
                  }
                }
              }
              .owl-nav{display: none;}
            }
          }
        }
      }
      .technical_right{
        right: 0;
        margin: 35px auto 125px AUTO;
        bottom: 0px;
        text-align: left;
        PADDING:0;
      }
    }


  }
  @media screen and (max-width:640px){
    width:74%;
    .assistance {
      .assistance_subcontainer {
        .flex-container(column,nowrap);
        max-width: 100%;
        width:100%;
        .assistance_left {
          display: none;
        }
        .assistance_right {
          width: 100%;
          .assistance_right_bottom{
            .aowl-carousel{
              .flex-container(column,nowrap);
              .owl-stage-outer {

                .owl-stage {
                  .flex-container(column,nowrap);
                  transform: none !important;
                  transition: none !important;
                  .owl-item {
                    width: 80% !important;

                    &.cloned{display: none;}
                    .item {
                      margin-bottom: 60px;
                      div{width:58%;}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
