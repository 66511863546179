/***INNER PAGE***/

.inner-page {
    h1 {
        font-size: 40px;
        line-height: 48px;
        margin: 0;
    }
    .blue_border {
        width: 145px;
        border-bottom: 13px solid @mainblue;
        margin: 10px 0 0 0;
    }
    &.container {
        margin: 0 auto;
        width: 79.2%;
        @media screen and (min-width: 1700px) {
            width: 1520px;
            margin: 0 auto;
        }
        @media screen and (max-width: 1600px) {
            width: 84.6%;
        }
        @media screen and (max-width: 1290px) {
            width: 88%;
        }
    }
    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
    & .inner-content{
        & a{
            font-size: inherit;
            &.action-btn{
                font-size: 25px;
            }
        }
        & .alignleft{
           float: left;
            padding-right: 20px;
            padding-bottom: 20px;
            @media screen and (max-width: 639px) {
                float: none;
                padding-right: 0;
                padding-bottom: 10px;
            }
        }
        & .alignright{
            float: right;
            padding-left: 20px;
            padding-bottom: 20px;
            @media screen and (max-width: 639px) {
                float: none;
                padding-left: 0;
                padding-bottom: 10px;
            }
        }
        & .cl{
            clear: both;
            display: block;
        }
    }
}

/////***********PAGE TITLE**********//////////////////

.page-outer-wrapper {
    background: @lightgrey;
    min-height: 120px;
    .flex-container(row, wrap);
    .align-items(center);
    .justify-content(center);
    margin: 0 0 10px 0;
    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
    .page-title-wrapper {
        width: 79.2%;
        @media screen and (min-width: 1700px) {
            width: 1520px;
            margin: 0 auto;
        }
        @media screen and (max-width: 1600px) {
            width: 84.6%;
        }
        @media screen and (max-width: 1290px) {
            width: 88%;
        }
        margin: 0 auto;
        .flex-container(row, wrap);
        .align-items(center);
        .justify-content(flex-start);
        .page-title {
            .flex-container(column, wrap);
            width: 80.5%;
            margin: 0 auto;
            h1 {
                font-size: 40px;
                line-height: 48px;
                margin: 0;
                padding-top: 15px;
            }
            .blue_border {
                width: 145px;
                border-bottom: 13px solid @mainblue;
                margin: 10px 0 0 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .page-title-wrapper {
            width: 82%;
        }
    }
}

/**** About Page***/

/////*******LOGIN & REGISTRATION PAGE*********/
section {
    &.bg-image {
        background: url('../images/hero.jpg') no-repeat center center;
        .background-size(cover);
        min-height: 1100px;
        .flex-container(column, nowrap);
        .align-items(center);
        padding: 40px 0;
        .login {
            width: 40%;
            .tabs {
                position: relative;
                clear: both;
                .flex-container(row, nowrap);
                .align-items(center);
                .tab {
                    .flex-container(column, nowrap);
                    .align-items(flex-start);

                    label {
                        &.tab-label {
                            padding: 10px 15px;
                            position: relative;
                            left: 90px;
                            top: 60px;
                            .trans(all 0.2s);
                            color: @mainblue;
                            font-size: 18px;
                            font-weight: bold;
                            font-weight: bold;
                            cursor: pointer;
                            z-index: 2;
                            &:hover {
                                color: @darkblue;
                            }
                        }

                    }
                    .content {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        opacity: 0;
                        width: 100%;
                        background: @white;
                        .box-shadow(-10px 37px 59px rgba(0, 0, 0, 0.13));
                        padding: 60px 100px;
                        &.show {
                            opacity: 1;
                            top: 100px;
                        }
                        h1 {
                            font-size: 40px;
                            line-height: 48px;
                            margin: 35px 0 30px;
                        }
                        .register-location{
                            display: none;
                        }

                    }
                    [type=radio] {
                        display: none;
                        &:checked {
                            ~ label {
                                z-index: 3;
                                color: @account-text;
                                ~ .content {
                                    z-index: 1;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1675px) {
            .login {
                width: 50%;
            }
        }
        @media screen and (max-width: 1340px) {
            .login {
                width: 60%;
            }
        }
        @media screen and (max-width: 1140px) {
            .login {
                width: 70%;
            }
        }
        @media screen and (max-width: 960px) {
            .login {
                width: 80%;
            }
        }
        @media screen and (max-width: 860px) {
            min-height: 820px;
            .login {
                .tabs {
                    .tab {
                        .content {
                            padding: 60px 80px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            min-height: 925px;
            .login {
                .tabs {
                    .tab {
                        label {
                            &.tab-label {
                                left: 52px;
                            }
                        }
                        .content {
                            padding: 60px 60px;
                            h1 {
                                font-size: 50px;
                            }
                            form {
                                .form-row {
                                    //.flex-container(column,wrap);
                                    //padding: 0;
                                    //.input-field{
                                    //    padding: 0 0 25px 0;
                                    //    &.two-section{
                                    //        width:100%;
                                    //    }
                                    //}
                                    .row-item {
                                        padding: 0 0 25px 0;
                                    }
                                    a {
                                        &.btn_forgot_password {
                                            padding: 0 0 25px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 735px) {
            min-height: 1500px;
        }
        @media screen and (max-width: 420px) {
            .login {
                width: 92%;
                .tabs {
                    .tab {
                        label {
                            &.tab-label {
                                left: 12px;
                                top: 25px;
                            }
                        }
                        .content {
                            h1 {
                                font-size: 38px;
                            }
                            padding: 35px 20px;
                        }
                    }
                }
            }
        }

    }
}