.administrator{
    & footer{
        & .container{
            padding: 50px;
            @media screen and (max-width: 768px) {
                max-width: 100%;
                padding: 40px 20px;
                display: block;
            }
            & p{
                color: #ffffff;
                margin: 0;
                padding: 5px 0;
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }
        }
    }
}