body {
  margin: 0;
}

.fixed-header {
  width: 100%;
  min-height: 96px;
  @media screen and (max-width: 640px) {
    min-height: 85px;
  }
}

header {
  .header_top {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: @white;
    height: 96px;

    .container {
      width: 100%;

      margin: 0 auto;
      .flex-container(row, nowrap);
      .align-items(center);
      .justify-content(space-between);

      .header_left {
        margin-left: 2%;
      }

      .header_right {
        .flex-container(row, nowrap);
        .justify-content(flex-end);
        .align-items(stretch);

        & .location_sel {
          list-style: none;
          padding: 0;
          margin: 0;

          & li {
            position: relative;

            & > span,
            & a {
              .flex-container();
              .align-items(center);
              color: @mainblue;
              padding: 0 10px;
            }

            & img {
              max-width: 30px;
              margin: 0;
              margin-right: 5px;
            }

            & > ul {
              list-style: none;
              padding: 0;
              margin: 0;
              position: absolute;
              top: 110%;
              left: 0;
              max-width: 100%;
              opacity: 0;
              visibility: hidden;
              z-index: -1;
              -webkit-transition: all 0.3s;
              -moz-transition: all 0.3s;
              -ms-transition: all 0.3s;
              -o-transition: all 0.3s;
              transition: all 0.3s;
              background: #ffffff;
              padding-top: 30px;

              & li {
                margin: 5px 0;
              }
            }

            &:hover {
              & > ul {
                opacity: 1;
                visibility: visible;
                z-index: 10;
                top: 100%;
              }
            }
          }
        }

        .header_item_right {
          &.language,
          &.country_icon {
            margin-right: 20px;

            select {
              border: none;
              margin-right: 0;
            }
          }

          &.menu {
            @media screen and (min-width: 1025px) {
              display: none;
            }
          }
          .cart-quantity {
            background-color:@mainblue;
            color: @white;
            border-radius: 50%;
            font-size: 10px;
            position: relative;
            top: -15px;
            left: -10px;
            font-weight: 700;
            width: 26px;
            height: 26px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .material-icons {
          color: #ffffff;
          font-size: 22px;
          line-height: inherit;          
        }

        .language, .country_icon {
          margin: 0 40px 0 0;
          color: @dark;
          .font(@font1, 15px, 600, 20px);
          align-self: center;

          img {
            margin: 10px 0 0 0;
          }
        }

        .register, .login {
          margin: 0 40px 0 0;
          align-self: center;
          @media screen and (max-width: 640px) {
            margin: 0 10px 0 0;
            display: none;
          }

          a {
            text-decoration: none;
            color: @mainblue;
            .font(@font1, 16px, 700, 30px);
            .trans(all 0.2s);

            &:hover {
              cursor: pointer;
              color: @darkblue;
            }
          }
        }

        ul {
          &.login-link {
            list-style: none;
            li {
              display: inline-block;
              position: relative;
              line-height: 21px;
              text-align: center;
              ul {
                &.logout-link {
                  display: none;
                  min-width: 125px;
                  position: absolute;
                  z-index: 999;
                  right: 5px;
                  list-style: none;
                  top: 10px;
                  li {              
                    background-color: #65b9e7;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    -webkit-border-bottom-right-radius: 5px;
                    -webkit-border-bottom-left-radius: 5px;
                    -moz-border-radius-bottomright: 5px;
                    -moz-border-radius-bottomleft: 5px;
                    width: 99px;             
                    a {
                      text-decoration: none;
                      color: @white !important;
                      .font(@font1, 16px, 700, 30px);
                      .trans(all 0.2s);
          
                      &:hover {
                        cursor: pointer;
                        color: @darkblue;
                      }
                    }
                  }
                }
              }
            }          
            &:hover {
              ul.logout-link {
                display: block;
                li {
                  display: block;
                }
              }
            }
          }
        }

        .search {
          background: @bgheaderdark;
          margin: 0  -1px  0 0;
          .trans(all 0.2s);
          position: relative;
          height: 96px;
          .flex-container();
          .align-items(center);

          a {
            padding: 30px 36px;
            line-height: 30px;
            @media screen and (max-width: 640px) {
              padding: 30px 10px;
            }
          }

          &:hover {
            cursor: pointer;
            background: @bgGrey;
          }

          .search-box {
            position: absolute;
            top: 100%;
            width: 350px;
            right: 0;
            display: none;
            @media screen and (max-width: 500px) {
              position: fixed;
              top: 96px;
              width: 100%;
              left: 0;
              right: auto;
            }

            form.form {
              .form-row {
                padding: 0;

                .input-field {
                  .input {
                    position: relative;
                    background: white;
                    padding: 0;

                    .input__field {
                      padding: 20px 30px 20px 15px;
                    }

                    .form-button {
                      position: absolute;
                      top: 15px;
                      right: 8px;

                      i {
                        color: @darkblue;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .cart {
          background: @bgheaderdark;
          height: 96px;
          .flex-container();
          .align-items(center);

          a {
            padding: 30px 36px;
            line-height: 30px;
            @media screen and (max-width: 640px) {
              padding: 30px 10px;
            }
          }

          .trans(all 0.2s);

          &:hover {
            cursor: pointer;
            background: @bgGrey;
          }
        }

        .catalog {
          background: @mainblue;
          .trans(all 0.2s);
          height: 96px;
          .flex-container();
          .align-items(center);

          a {
            height: 100%;
            .flex-container();
            .align-items(center);
            padding: 0 75px;
            color: @white !important;
            .font(@font1semibold, 14px, normal, 30px);
            @media screen and (max-width: 1200px) {
              padding: 0 45px;
            }
          }

          &:hover {
            cursor: pointer;
            background: @darkblue;
          }
        }

        .menu {
          background: @bgheaderdark;

          .header-menu {
            display: none;
          }

          .navbars {
            display: inline-block;
            padding: 30px 30px;
            height: 96px;
            .trans(all 0.2s);
            @media screen and (max-width: 640px) {
              padding: 30px 15px;
            }

            .material-icons {
              font-size: 32px;
            }
          }

          &:hover {
            cursor: pointer;
            background: @bgGrey;
          }
        }

        .fa {
          color: @white;
          font-size: 18px;
        }
      }

      @media screen and (max-width: @responsive-1366) {
        .header_left {
          margin-left: 32px;
        }
      }
      @media screen and (max-width: 1300px) {
        .header_left {
          margin-left: 2%;
        }

        .header_right {
          select {
            margin: 0 20px 0 0;
          }

          .language, .country_icon {
            margin: 0 20px 0 0;
          }

          .register, login {
            margin: 0 20px 0 0;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        width: 100%;
        margin: 0;
        .header_left {
          margin-left: 22px;
        }

        .header_right {
          .catalog {
            a {
              font-size: 12px;
            }
          }
        }
      }
      @media screen and (max-width: 1024px) {
        .header_right {
          .country_icon {
            display: none;
          }

          select {
            display: none;
          }

          .language {
            display: none;
          }

          .catalog {
            a {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
      @media screen and (max-width: 900px) {
        .header_right {
          .catalog {
            display: none;

          }
        }
      }
      @media screen and (max-width: 640px) {
        .header_left {
          margin-left: 20px;

          img {
            width: 80%;
          }
        }

        .header_right {

          .search {
            padding: 10px;
          }

          .menu {
            .navbars {
              padding: 30px;
            }
          }

          .cart {
            padding: 10px;
          }

          .mob-top {
            display: inline-block;
          }
        }
      }

      &.add-shadow {
        box-shadow: 0 11px 67px 0 rgba(0, 0, 0, 0.2);

        .header_right {
          .header_item_right {
            &.menu {
              display: block;
              height: 96px;
            }
          }
        }
      }
    }

  }

  .header-menu {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .container {
      .menu {
        .flex-container();
        .align-items(center);
        padding: 0 20px;
        background: #1f1f1f;
        list-style: none;
        height: 55px;
        margin: 0;

        li {
          a {
            display: block;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: @white !important;
            padding: 10px 30px;
            font-family: 'Montserrat', sans-serif;
            height: 55px;
            .flex-container();
            .align-items(center);
          }

          &:hover {
            a {
              color: @fr_blue;
            }
          }
        }
      }
    }
  }
}